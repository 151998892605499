$schedule-page-non-working-day-padding: 48px;
$schedule-page-non-working-day-padding-st: 40px 20px;
$schedule-page-non-working-day-padding-st-small: 20px 10px;
$schedule-page-non-working-day-br: 16px;
$schedule-page-non-working-day-bg-color: #eee;
$schedule-page-non-working-day-icon-height: 74px;
$schedule-page-non-working-day-icon-width: 96px;

.non-working-day__item {
  padding: $schedule-page-non-working-day-padding;
  background-color: $schedule-page-non-working-day-bg-color;
  border-radius: $schedule-page-non-working-day-br;
  display: flex;
  justify-content: center;

  &--student {
    padding: $schedule-page-non-working-day-padding-st;

    @media (max-width: 960px) {
      padding: $schedule-page-non-working-day-padding-st-small;

     svg {
       height: $schedule-page-non-working-day-icon-height;
       width: $schedule-page-non-working-day-icon-width;
     }
    }
  }
}
