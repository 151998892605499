$calendar-view-hint-left-xs: 60%;
$calendar-view-hint-left: 50%;
$calendar-view-hint-top-xs: -5%;
$calendar-view-hint-top: -7%;
$calendar-view-title-width-xs: 58%;
$content-block-border-color: #e0e0e0;
$content-block-border-radius: 40px;
$content-block-box-shadow: 0 4px 8px 2px RGB(0 0 0 / 6%);
$content-block-min-height-xs: 400px;
$content-block-min-height: 600px;
$planner-view-hint-bottom-xs: 200%;
$planner-view-hint-bottom: 40%;
$planner-view-hint-left-xs: 8%;
$planner-view-hint-left: 18%;
$planner-view-title-bottom-xs: -12px;
$planner-view-title-width-xs: 26%;


@keyframes slow-appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.ayo-lesson-planner-landing__what-is-it-section {
  &__content {
    border-radius: $content-block-border-radius;
    border: 1px solid $content-block-border-color;
    background: $operational-surface;
    box-shadow: $content-block-box-shadow;
    position: relative;
    min-width: fit-content;
    min-height: $content-block-min-height;

    @media (max-width: 600px) {
      min-height: $content-block-min-height-xs;
    }

    &--calendar-view {
      animation-name: slow-appear;
      animation-duration: 1s;
      animation-timing-function: ease-in-out;

      &__hint {
        position: absolute;
        left: $calendar-view-hint-left;
        top: $calendar-view-hint-top;

        @media (max-width: 600px) {
          left: $calendar-view-hint-left-xs;
          top: $calendar-view-hint-top-xs;
        }
      }

      &__title {
        @media (max-width: 600px) {
          width: $calendar-view-title-width-xs;
        }
      }

      &__card {
        max-width: 100%;
        height: auto;
        cursor: pointer;

        &:hover {
          fill: $primary-050;

          .outline {
            stroke: $primary-100;
            stroke-width: 2;
          }
        }

        &:focus {
          fill: $secondary-050;
          outline: none;

          .outline {
            stroke: $secondary-500;
            stroke-width: 2;
          }
        }
      }
    }

    &--planner-view {
      animation-name: slow-appear;
      animation-duration: 1s;
      animation-timing-function: ease-in-out;

      &__button {
        cursor: pointer;
        position: absolute;
        left: 0;

        &:focus {
          fill: $primary-800;
          outline: none;

          .text {
            fill: $primary-800;
          }
        }

        @media (max-width: 600px) {
          width: $planner-view-title-width-xs;
          bottom: $planner-view-title-bottom-xs;
        }
      }

      &__title {
        @media (max-width: 600px) {
          width: $planner-view-title-width-xs;
        }
      }

      &__hint {
        position: absolute;
        left: $planner-view-hint-left;
        bottom: $planner-view-hint-bottom;

        @media (max-width: 600px) {
          left: $planner-view-hint-left-xs;
          bottom: $planner-view-hint-bottom-xs;
        }

      }

      &__planner {
        width: 100%;
        height: auto;
      }
    }
  }

}
