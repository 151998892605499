$month-day-card-padding-y: 16px;
$month-day-card-padding-x: 16px;
$month-day-card-padding: $month-day-card-padding-y $month-day-card-padding-x;

.month-day-card {
  height: 100%;

  .ayo-card__main-content {
    height: 100%;
    box-sizing: border-box;
    padding: $month-day-card-padding;

    & > * {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }

  .day-content {
    flex: 1;

    &:has(.non-working-day__item) {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .non-working-day__item {
      padding: 0;
      background-color: transparent;
    }
  }


  &.different-month:not(:hover, :focus) {
    background-color: $operational-background-grey;
  }

  &:not(.different-month):has(.non-working-day__item):not(:hover, :focus) {
    background-color: $operational-background-grey;
  }

  .date-display {
    color: $primary-500;
  }
}

.month-day-wrapper {
  height: 100%;

  .day-options__container {
    position: absolute;
    top: $month-day-card-padding-y;
    right: $month-day-card-padding-x;
  }

  .ayo-day-lesson-planner-day-options {
    visibility: hidden;
  }

  &:hover {
    .ayo-day-lesson-planner-day-options {
      visibility: visible;
    }
  }
}

$month-day-lesson-status-indicator-size: 8px;
$month-day-lesson-status-indicator-mr: 8px;
$month-day-lesson-status-indicator-draft-bg: #adadad;
$month-day-lesson-status-indicator-draft-color: #737373;
$month-day-lesson-status-indicator-published-bg: #6faf5d;
$month-grid-row-height: 175px;
$month-grid-mb: 12px;
$month-grid-gap: 24px;

.month-day-lesson {
  display: flex;
  align-items: center;

  .lesson-status-indicator {
    display: inline-block;
    min-width: $month-day-lesson-status-indicator-size;
    min-height: $month-day-lesson-status-indicator-size;
    border-radius: 50%;
    margin-right: $month-day-lesson-status-indicator-mr;
  }

  &.draft {
    .lesson-status-indicator {
      background-color: $month-day-lesson-status-indicator-draft-bg;
    }

    .lesson-title {
      color: $month-day-lesson-status-indicator-draft-color;
    }
  }

  &.published {
    .lesson-status-indicator {
      background-color: $month-day-lesson-status-indicator-published-bg;
    }

    .lesson-title {
      color: $typography-titles;
    }
  }
}

.lessons-container {
  & > :not(.month-day-lesson) {
    padding-left: $month-day-lesson-status-indicator-size + $month-day-lesson-status-indicator-mr;
  }
}

.month-container {
  grid-auto-rows: $month-grid-row-height;
  margin-bottom: $month-grid-mb;

  &, &__header {
    display: grid;
    box-sizing: border-box;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    gap: $month-grid-gap;
  }
}
