$students-group-header-divider-mx: 16px;
$students-group-header-divider-height: 2rem;

.accordion-class-group-header {
  &__displayname {
    min-width: 0;
    display: flex;
    white-space: nowrap;
  }

  &__divider {
    margin-left: $students-group-header-divider-mx;
    margin-right: $students-group-header-divider-mx;
    height: $students-group-header-divider-height;
    align-self: center;
  }

  &__count {
    white-space: nowrap;
  }

  &__classname {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
  }
}
