$ayo-content-moderation-opportunities-accordion-padding: 16px;
$ayo-content-moderation-opportunities-headers-margin-bottom: 8px;
$ayo-content-moderation-opportunities-headers-padding: 0 54px 0 25px;
$ayo-content-moderation-opportunities-title-margin-right: 8px;
$ayo-content-moderation-opportunities-approve-button-margin-top: 8px;
$ayo-content-moderation-opportunities-row-padding: 16px 24px 4px;
$ayo-content-moderation-opportunities-row-border-radius: 16px;

.ayo-content-moderation-opportunities {
  .ayo-accordion {
    background-color: transparent;

    .MuiAccordionDetails-root {
      padding: $ayo-content-moderation-opportunities-accordion-padding;
    }

    ::-webkit-scrollbar-track {
      background: $operational-surface;
    }
  }

  &__headers {
    padding: $ayo-content-moderation-opportunities-headers-padding;
    margin-bottom: $ayo-content-moderation-opportunities-headers-margin-bottom;
  }

  &__icon {
    @include extra-large-icon;
  }

  &__title {
    display: inline;
    color: $primary-500;
    margin-right: $ayo-content-moderation-opportunities-title-margin-right;

    + svg {
      display: inline-flex;
      vertical-align: top;
    }

    &__translation {
      word-wrap: break-word;
    }
  }

  &__row {
    background-color: $operational-surface;
    padding: $ayo-content-moderation-opportunities-row-padding;
    border-radius: $ayo-content-moderation-opportunities-row-border-radius;

    &:hover {
      background-color: $primary-050;
    }

    .MuiFormGroup-root {
      flex-direction: initial;
    }

    &__buttons {
      display: flex;
      justify-content: center;
      align-items: flex-start;

      @media (min-width: 1920px) {
        .approve-button {
          margin-top: $ayo-content-moderation-opportunities-approve-button-margin-top;
        }
      }
    }
  }

  &__description {
    @include wrapped-text(2);

    &--collapsed {
      @include wrapped-text(100);
    }
  }
}
