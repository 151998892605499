$notifications-badge-size: 10px;
$notifications-badge-border-radius: 6px;
$notifications-badge-offset: 25%;

.ayo-dot-indicator {
  .MuiBadge-dot {
    background-color: $data-vizualization-red;
    border: 2px solid $operational-background-grey;
    border-radius: $notifications-badge-border-radius;
    height: $notifications-badge-size;
    min-width: $notifications-badge-size;

    &.MuiBadge-anchorOriginTopRightCircular {
      right: $notifications-badge-offset;
      top: $notifications-badge-offset;
    }
  }
}
