$leadership-attributes-definition-position-top: 600px;
$leadership-attributes-definition-position-top-mobile-width-400: 528px;
$leadership-attributes-definition-position-top-mobile-width-500: 620px;
$leadership-attributes-definition-position-top-mobile-width-600: 720px;
$leadership-attributes-definition-max-width: 600px;

.ayo-leadership-attributes-definition {
  display: flex;
  justify-content: center;

  &__text-block {
    position: absolute;
    top: $leadership-attributes-definition-position-top;
    max-width: $leadership-attributes-definition-max-width;
    text-align: center;

    @media (max-width: 600px) {
      top: $leadership-attributes-definition-position-top-mobile-width-600;
    }

    @media (max-width: 500px) {
      top: $leadership-attributes-definition-position-top-mobile-width-500;
    }

    @media (max-width: 400px) {
      top: $leadership-attributes-definition-position-top-mobile-width-400;
    }
  }
}
