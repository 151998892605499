$ayo-create-feed-post-br: 16px;
$ayo-create-feed-post-mt: 16px;
$ayo-feed-post-menu-items-mt: 8px;
$ayo-create-feed-post-p: 24px;
$ayo-create-feed-post-inputs-row-gap: 20px;
$ayo-create-feed-post-inputs-pb: 24px;
$ayo-create-feed-post-file-wrapper-pb: 24px;

.ayo-feed-post {
  &__form {
    background-color: $operational-surface;
    border-radius: $ayo-create-feed-post-br;
    margin-top: $ayo-create-feed-post-mt;
    padding: $ayo-create-feed-post-p;

    &__inputs {
      display: flex;
      flex-direction: column;
      row-gap: $ayo-create-feed-post-inputs-row-gap;
      padding-bottom: $ayo-create-feed-post-inputs-pb;

      &__menu {
          margin-top: $ayo-feed-post-menu-items-mt;
      }
    }

    &__drodown-items{
      text-wrap: wrap;
    }
  }

  &__file-upload-wrapper {
    padding-bottom: $ayo-create-feed-post-file-wrapper-pb;
  }
}
