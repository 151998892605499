$ayo-endorsement-dialog-max-width: 532px;
$ayo-endorsement-dialog-padding: 0 25px 0 0;

.ayo-endorsement-dialog {
  .ayo-dialog__container {
    @media (min-width: 1920px) {
      max-width: $ayo-endorsement-dialog-max-width;
    }
  }

  .MuiDialogContent-root {
    @media (max-width: 1920px) {
      padding: $ayo-endorsement-dialog-padding;
    }
  }
}
