$filter-widget-padding: 8px;
$filter-widget-width: 250px;
$filter-widget-mr: 16px;
$filter-widget-button-size: 54px;
$filter-widget-button-size-lg: 71px;
$filter-widget-button-mb: 16px;
$filter-widget-legend-spot-size: 16px;
$filter-widget-legend-spot-mr: 8px;
$filter-widget-legend-spot-mt-mobile: 10px;
$filter-widget-legend-spot-mt: 5px;
$filter-widget-br: 16px;
$filter-checkbox-label-padding: 12px;

.filter-widget {
  background: $interests-map-widgets-bg-color;
  padding: $filter-widget-padding;
  border-radius: $filter-widget-br;
  box-sizing: border-box;

  &--container {
    width: $filter-widget-width;
    display: flex;
    flex-direction: column;
    margin-right: $filter-widget-mr;
    pointer-events: auto;

    .ayo-button {
      margin-bottom: $filter-widget-button-mb;
      height: $filter-widget-button-size;

      @media (min-width: 1920px) {
        height: $filter-widget-button-size-lg;
      }

      svg path {
        fill: $typography-white;
      }

      &:focus {
        box-shadow: none;
        border: 2px solid $operational-border;
      }
    }
  }
}

.filter-checkboxgroup {
  .ayo-legend-spot {
    display: inline-block;
    width: $filter-widget-legend-spot-size;
    height: $filter-widget-legend-spot-size;
    min-width: $filter-widget-legend-spot-size;
    min-height: $filter-widget-legend-spot-size;
    border-radius: 50%;
    margin-right: $filter-widget-legend-spot-mr;
    margin-top: $filter-widget-legend-spot-mt;

    @media (min-width: 1920px) {
      margin-top: $filter-widget-legend-spot-mt-mobile;
    }

    &.selected {
      background-color: $primary-500;
    }

    &.suggested {
      background-color: $secondary-500;
    }

    &.other {
      background-color: $operational-border;
    }

    &.not-interested {
      background-color: #49425d;
    }
  }

  .MuiFormControlLabel-label {
    display: flex;
    align-items: flex-start;
  }

  .MuiFormGroup-root {
    .ayo-form-control-label {
      padding: $filter-checkbox-label-padding;

      .MuiButtonBase-root {
        padding: 0;
      }
    }
  }

  &.dark {
    .MuiFormControlLabel-label {
      color: $typography-white;
    }

    .ayo-checkbox {
      input[type='checkbox'] {
        & + svg * {
          @include fill-icon-element($typography-white);
        }

        &:hover + svg * {
          @include fill-icon-element($primary-100);
        }

        &:focus + svg {
          box-shadow: $checkbox-focus-shadow-position $secondary-500;
    
          * {
            @include fill-icon-element($secondary-050);
          }
        }
      }

      &.Mui-checked {
        input[type='checkbox']:hover + svg {
          box-shadow: $checkbox-focus-shadow-position $primary-100;
    
          * {
            @include fill-icon-element($typography-white);
          }
        }
      }
    }
  }
}

.filter-dialog.ayo-dialog__paper {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  justify-content: center;

  .ayo-dialog__container {
    width: 100%;
    box-sizing: border-box;

    .MuiDialogActions-root {
      flex-direction: column;
    }
  }
}