$attachments-icon-wrapper-margin-bottom: 4px;
$attachments-icon-wrapper-padding: 12px 20px;
$attachments-icon-wrapper-border-radius: 12px;
$attachments-icon-width: 32px;
$attachments-icon-width-xl: 46px;

.ayo-portfolio-attachments {
  &__icon-wrapper {
    display: flex;
    margin-bottom: $attachments-icon-wrapper-margin-bottom;
    align-items: center;
    justify-content: center;
    padding: $attachments-icon-wrapper-padding;
    border: 1px solid $operational-border;
    border-radius: $attachments-icon-wrapper-border-radius;

    &:hover {
      border-color: $primary-100;
      outline: 1px solid $primary-100;
    }

    &:focus {
      background-color: $secondary-050;
      border-color: $secondary-500;
      outline: 1px solid $secondary-500;
    }

    svg {
      width: $attachments-icon-width;
      height: auto;

      @media (min-width: 1920px) {
        width: $attachments-icon-width-xl;
      }
    }
  }

  &__file-title {
    @include wrapped-text;
  }

  &__file-type {
    min-width: max-content;
  }
}
