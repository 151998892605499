$ayo-test-score-card-bg: RGB(57 128 78 / 10%);
$ayo-test-score-card-p: 24px;

.ayo-portfolio__test-scores-card {
  text-align: center;
  background-color: $ayo-test-score-card-bg;

  .ayo-card__main-content {
    padding: $ayo-test-score-card-p;
  }
}
