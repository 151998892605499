$recommendations-card-padding: 16px;
$recommendations-card-action-menu-svg-height: 24px;
$recommendations-card-title-min-height: 53px;
$recommendations-card-title-min-height-xl: 70px;
$dialog-container-min-width-xl: 640px;

.ayo-portfolio-recommendations-section {
  &__read_button {
    svg * {
      @include fill-icon-element($primary-500);
    }
  }

  .ayo-card .MuiCardContent-root {
    padding: $recommendations-card-padding;
  }

  &__title {
    min-height: $recommendations-card-title-min-height;

    @include wrapped-text(2);

    color: $primary-500;
    word-break: break-word;

    @media (min-width: 1920px) {
      min-height: $recommendations-card-title-min-height-xl;
    }
  }

}

.ayo-portfolio-recommendations-card__action-menu {
  .MuiMenuItem-root {
    svg {
      height: $recommendations-card-action-menu-svg-height;
      width: auto;
    }
  }
}

.ayo-portfolio__recommendations-request-dialog {
  &__label {
    @include wrapped-text(1);

    word-break: break-word;
    max-width: fit-content;
  }
}

.ayo-portfolio__add-recommendations-request-dialog {
  .ayo-dialog__container {
    @media (min-width: 1920px) {
      max-width: $dialog-container-min-width-xl;
    }
  }
}
