$ayo-teks-dialog-min-height: 706px;
$ayo-teks-dialog-max-width: 1101px;
$ayo-teks-dialog-padding: 0 16px 0 0;
$ayo-teks-dialog-content-min-height: 470px;
$ayo-teks-dialog-buttons-margin-top: 8px;
$ayo-teks-dialog-search-margin-bottom: 24px;

.ayo-teks-dialog {
  background-color: $operational-background;
  min-height: $ayo-teks-dialog-min-height;
  max-width: $ayo-teks-dialog-max-width;
  min-width: Min(#{$ayo-teks-dialog-max-width}, calc(100% - 32px));

  .MuiDialogContent-root {
    min-height: $ayo-teks-dialog-content-min-height;
    padding: $ayo-teks-dialog-padding;
  }

  &__buttons {
    margin-top: $ayo-teks-dialog-buttons-margin-top;
  }

  &__title {
    margin-bottom: $ayo-teks-dialog-search-margin-bottom;
  }

  &__search-field {
    margin-bottom: $ayo-teks-dialog-search-margin-bottom;
    margin-top: $ayo-teks-dialog-search-margin-bottom;
  }

  &__chips {
    margin-top: 0;
  }

  &__chip {
    @include removable-chip-black;
  }
}
