$add-classes-placeholder-bg: #fff;
$add-classes-tooltip-padding: 8px;
$draggable-card-outline-color: #6bad6a;
$class-item-min-height: 56px;
$class-item-br: 16px;
$class-item-outline-size: 2px;
$delete-icon-pos-right: -16px;
$delete-icon-pos-top: -10px;
$delete-icon-size: 30px;
$drop-placeholder-bg: #f0fff0;

.ayo-day-schedule {
  position: relative;

  &__item {
    min-height: $class-item-min-height;
    display: flex;
    background-color: var(--background-main);

    .ayo-card {
      background-color: transparent;
    }

    &:hover {
      background-color: $primary-050;
      outline: $class-item-outline-size solid $primary-100;
    }

    &:focus {
      background-color: $secondary-050;
      outline: $class-item-outline-size solid $secondary-500;
    }

    border-radius: $class-item-br;

    &--draggable {
      outline: $class-item-outline-size solid $draggable-card-outline-color;
    }
  }

  &__delete-button {
    right: $delete-icon-pos-right;
    top: $delete-icon-pos-top;
    position: absolute;

    svg {
      height: $delete-icon-size;
      width: $delete-icon-size;
    }
  }

  &__add-classes {
    .ayo-tooltip.ayo-tooltip--light {
      padding: $add-classes-tooltip-padding;
    }

    &__tooltip {
      width: 100%;
    }

    &__placeholder {
      width: 100%;
      min-height: $class-item-min-height;
      border-radius: $class-item-br;
      background-color: $add-classes-placeholder-bg;
    }
  }

  .drop-placeholder {
    position: absolute;
    background-color: $drop-placeholder-bg;
    border-radius: $class-item-br;
    border: 2px solid $data-visualization-green-080-090;
  }
}
