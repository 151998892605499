$ayo-bottom-navigation-icon-rotation: 180deg;
$ayo-bottom-navigation-margin-left: 8px;

.ayo-bottom-navigation {
  &__button-next {
    text-align: end;
    margin-left: $ayo-bottom-navigation-margin-left;
  }

  &__button-next-icon {
    transform: rotate($ayo-bottom-navigation-icon-rotation);
  }

  &__text {
    color: $typography-labels-dark;
  }
}
