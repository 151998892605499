$ayo-lesson-assignment-display-students-padding: 8px 12px;
$ayo-lesson-assignment-display-students-margin-left: 8px;

.ayo-lesson-assignment-display-students {
  display: flex;
  flex-wrap: wrap;

  &__card {
    padding: $ayo-lesson-assignment-display-students-padding;
    box-sizing: border-box;

    &.ayo-card .MuiCardContent-root {
      padding: 0;

      svg {
        margin-left: $ayo-lesson-assignment-display-students-margin-left;
      }

      .ayo-clickable-card__content-container--w-status-icon {
        max-width: 100%;
      }

    }
  }

}
