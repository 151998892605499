$ayo-share-post-with-dialog-container-w-md: 644px;
$ayo-share-post-with-dialog-container-w-lg: 864px;

.ayo-share-post-with-dialog {
  background-color: $operational-background-grey;

  @include dialog-full-width-mobile;

  @media (min-width: 960px) {
    .ayo-dialog__container {
      box-sizing: border-box;
      max-width: $ayo-share-post-with-dialog-container-w-md;
      min-width: $ayo-share-post-with-dialog-container-w-md;
    }
  }

  @media (min-width: 1920px) {
    .ayo-dialog__container {
      box-sizing: border-box;
      max-width: $ayo-share-post-with-dialog-container-w-lg;
      min-width: $ayo-share-post-with-dialog-container-w-lg;
    }
  }
}
