$insight-accordion-padding-x: 24px;
$insight-accordion-padding-y: 24px;
$insight-accordion-padding-left: $insight-accordion-padding-x + 56px;
$insight-accordion-cancel-button-min-width: 180px;

.ayo-insight-accordion {
  background-color: $secondary-200;

  .ayo-accordion__summary {
    padding: $insight-accordion-padding-y $insight-accordion-padding-x $insight-accordion-padding-y $insight-accordion-padding-left;

    .ayo-accordion__close-icon,
    .ayo-accordion__open-icon {
      top: $insight-accordion-padding-y;
      left: $insight-accordion-padding-x;
    }
  }

  .MuiAccordionDetails-root {
    padding: 0 $insight-accordion-padding-x $insight-accordion-padding-y;
  }

  .cancel-button {
    min-width: $insight-accordion-cancel-button-min-width;
    background-color: $secondary-100;
  }

  .tailor-button {
    background-color: $primary-050;
  }
}
