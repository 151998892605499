$dropdown-list-item-border-radius: 12px;
$dropdown-list-item-padding: 15px;
$dropdown-list-item-margin-bottom: 8px;
$dropdown-list-padding: 8px;
$dropdown-list-icon-right: 26px;
$dropdown-list-icon-top: calc(50% - 12px);
$dropdown-list-popover-margin-top: 10px;
$dropdown-list-popover-max-height: 196px;
$dropdown-list-border-radius: 9px;
$dropdown-list-box-shadow: 0 1px 6px 1px RGB(30 32 64 / 25%);
$dropdown-menu-scroll-width: 8px;
$dropdown-responsive-padding: 0 8px 0 0;
$dropdown-responsive-margin: 10px 0 10px 8px;
$dropdown-responsive-min-height: 172px;

.ayo-dropdown {
  .MuiSelect-iconOutlined {
    right: $dropdown-list-icon-right;
    top: $dropdown-list-icon-top;
  }

  .MuiTypography-root {
    max-width: 90%;
  }

  &__menu-item {
    border-radius: $dropdown-list-item-border-radius;
    padding: $dropdown-list-item-padding;
    background-color: $operational-surface;
    margin-bottom: $dropdown-list-item-margin-bottom;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__popover {
    max-width: min-content;
    background-color: transparent;
    margin-top: $dropdown-list-popover-margin-top;

    .MuiList-padding {
      padding: $dropdown-list-padding;
      max-height: $dropdown-list-popover-max-height;
    }

    .MuiPaper-elevation8 {
      box-shadow: $dropdown-list-box-shadow;
      border-radius: $dropdown-list-border-radius;
    }

    &--responsive {
      .MuiList-root {
        min-height: $dropdown-responsive-min-height;
        overflow: auto;
        padding:$dropdown-responsive-padding;
        margin: $dropdown-responsive-margin;
      }

      .MuiPaper-root {
        overflow: hidden;
      }

      .MuiList-root::-webkit-scrollbar {
        width: $dropdown-menu-scroll-width;
      }
    }
  }
}
