$portfolio-update-notification-border-radius: 16px;
$portfolio-update-notification-padding: 16px;
$portfolio-update-notification-button-min-width: 101px;
$portfolio-update-notification-box-shadow: 0 12px 16px 6px RGB(0 0 0 / 6%);

.ayo-portfolio-notification {
  background-color: $operational-surface;
  box-shadow: $portfolio-update-notification-box-shadow;
  border-radius: $portfolio-update-notification-border-radius;
  padding: $portfolio-update-notification-padding;

  .ayo-button {
    min-width: $portfolio-update-notification-button-min-width;

    &--text {
      color: $primary-500;
    }
  }
}
