$academic-years-semesters-block-border-radius: 16px;
$academic-years-semesters-block-background-color: #F6F6FA;
$validation-block-disabled-error-outline: 2px solid $operational-error;

.ayo-academic-years {
  .ayo-validation-block__card--disabled {
    .ayo-typography--subtitle2 {
      color: $typography-disabled;
    }

    &.ayo-validation-block__card--error {
      outline: $validation-block-disabled-error-outline;

      &:hover {
        outline-color: $operational-error;
      }
    }
  }

  &__semesters-block {
    border-radius: $academic-years-semesters-block-border-radius;
    background-color: $academic-years-semesters-block-background-color;
  }
}
