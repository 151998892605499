$ayo-teks-row-border-radius: 16px;
$ayo-teks-row-padding: 16px;
$ayo-teks-row-margin-bottom: 8px;
$ayo-teks-row-text-margin-right: 24px;
$ayo-teks-row-label-bc: #ffc107;
$ayo-teks-row-label-margin-left: 8px;
$ayo-teks-row-label-padding: 0 10px;
$ayo-teks-row-label-border-radius: 16px;
$ayo-teks-row-label-line-height: 18px;
$ayo-teks-row-display-width: 80px;

.ayo-teks-row {
  display: flex;
  justify-content: space-between;
  background-color: $operational-surface;
  border-radius: $ayo-teks-row-border-radius;
  padding: $ayo-teks-row-padding;
  margin-bottom: $ayo-teks-row-margin-bottom;

  &__text {
    flex: 1;
    margin-right: $ayo-teks-row-text-margin-right;
  }

  &__label {
    background-color: $ayo-teks-row-label-bc;
    margin-left: $ayo-teks-row-label-margin-left;
    padding: $ayo-teks-row-label-padding;
    border-radius: $ayo-teks-row-label-border-radius;
    font-weight: bold;
    display: inline-block;
    line-height: $ayo-teks-row-label-line-height;

    &--readiness {
      background-color: $data-visualization-green-090-100;
      color: $typography-white;
    }
  }

  &__display {
    min-width: $ayo-teks-row-display-width;
  }
}
