$radio-buttons-spacing: 24px;
$date-picker-margin: 8px 24px;
$share-item-padding: 8px 12px;
$share-item-border-radius: 16px;

.ayo-events-block__dialog {
  .MuiFormGroup-row {
    gap: $radio-buttons-spacing;
  }

  .ayo-typography--label {
    font-style: italic;
  }

  .ayo-typography--caption {
    color: $typography-labels;
  }

  &__details {
    &__description {
      white-space: pre-line;
    }

    svg {
      min-width: fit-content;
    }

    @include extra-large-icon;
  }

  &__visibility-settings {
    .share-with-item {
      width: 100%;
      padding: $share-item-padding;
      border-radius: $share-item-border-radius;
      border: 1px solid $operational-border;
    }
  }

  .ayo-chips-block {
    .ayo-button--text {
      background-color: $primary-050;
    }

    &__wrapper {
      min-width: fit-content;
    }

    .ayo-editing-button {
      min-width: 100%;
    }

    svg {
      min-width: fit-content;
    }
  }
}

.ayo-events-block__dialog__date-picker {
  margin: $date-picker-margin !important;

  .MuiClock-pmButton, .MuiClock-amButton {
    &:hover {
      background-color: $primary-500;
    }
  }
}
