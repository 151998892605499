$ayo-maturity-levels-box-accordion-icon-min-width: 36px;
$ayo-maturity-levels-box-accordion-icon-height: 36px;

.ayo-maturity-levels-box {
  &__notification {
    .ayo-informational-message {
      padding: 0;
    }
  }

  &__label:hover {
    cursor: default;
  }

  &__accordion {
    .ayo-button--icon {
      width: initial;
      min-width: $ayo-maturity-levels-box-accordion-icon-min-width;
      height: $ayo-maturity-levels-box-accordion-icon-height;
    }
  }
}
