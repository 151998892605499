$validation-card-padding: 24px;
$validation-card-outline: 2px;
$validation-card-helper-text-margin: 3px 10px 0;

.ayo-validation-block {
  &__card {
    .ayo-card__main-content {
      padding: $validation-card-padding;
    }

    box-sizing: border-box;
    outline: $validation-card-outline solid transparent;

    &--disabled {
      outline: none;
    }

    &:hover {
      outline-color: $primary-100;
    }

    &--error {
      outline-color: $operational-error;

      &:hover {
        outline-color: $operational-error;
      }
    }
  }

  &__helper-text {
    margin: $validation-card-helper-text-margin;
    color: $operational-error;
  }
}
