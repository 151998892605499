$titles-max-width-lg: 60%;
$titles-max-width-xl: 80%;

.ayo-lesson-planner-landing__definition-section {
  .ayo-typography--subtitle1, .ayo-typography--display {
    @media (min-width: 960px) {
      max-width: $titles-max-width-lg;
    }

    @media (min-width: 1920px) {
      max-width: $titles-max-width-xl;
    }
  }

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}
