$ayo-edit-club-dialog-form-padding: 0;
$ayo-edit-club-dialog-form-margin-top: 24px;
$ayo-edit-club-dialog-container-w-md: 644px;
$ayo-edit-club-dialog-container-w-lg: 864px;
$ayo-edit-club-dialog-back-button-margin-bottom: 8px;

.ayo-edit-club-post-dialog {
  .ayo-feed-post__form {
    margin-top: $ayo-edit-club-dialog-form-margin-top;
    padding: $ayo-edit-club-dialog-form-padding;
  }

  @include dialog-full-width-mobile;

  @media (min-width: 960px) {
    .ayo-dialog__container {
      box-sizing: border-box;
      max-width: $ayo-edit-club-dialog-container-w-md;
      min-width: $ayo-edit-club-dialog-container-w-md;
    }
  }

  @media (min-width: 1920px) {
    .ayo-dialog__container {
      box-sizing: border-box;
      max-width: $ayo-edit-club-dialog-container-w-lg;
      min-width: $ayo-edit-club-dialog-container-w-lg;
    }
  }
}
