$block-br: 16px;
$initiative-empty-state-block-divider-height: 26px;
$initiative-empty-state-block-divider-mx: 24px;

.ayo-initiative-empty-state {
  border-radius: $block-br;
  background-color: $typography-white;

  &__buttons-box {
    hr {
      height: $initiative-empty-state-block-divider-height;
      align-self: center;
      margin: 0 $initiative-empty-state-block-divider-mx;
    }

    @media (max-width: 600px) {
      hr {
        display: none;
      }

      flex-direction: column;
    }
  }
}
