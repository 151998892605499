$interest-moderation-row-px: 24px;
$interest-moderation-row-py: 4px;
$interest-moderation-row-min-height: 60px;
$interest-moderation-row-padding: $interest-moderation-row-py $interest-moderation-row-px;
$interest-moderation-inner-level-padding-offset: 7%;
$interest-moderation-expansion-button-mr: 16px;
$interest-moderation-expansion-button-size: 24px;
$interest-moderation-expansion-button-stroke-width: 2;
$interest-moderation-expansion-button-circle-radius: 20;
$interests-grid-header-mb: 8px;

.interests-moderation {
  &__container {
    .interest-row {
      padding: $interest-moderation-row-padding;
      position: relative;
      min-height: $interest-moderation-row-min-height;

      .interest-cell {
        display: flex;
        align-items: center;
        
        &.center {
          justify-content: center;
        }
      }

      &:hover {
        background-color: $primary-050;
      }

      &.interest-level-1 {
        .with-offset {
          padding-left: $interest-moderation-inner-level-padding-offset;
        }
      }

      &.interest-level-2 {
        .with-offset {
          padding-left: $interest-moderation-inner-level-padding-offset * 2;
        }
      }

      .expansion-button {
        all: unset;
        margin-right: $interest-moderation-expansion-button-mr;
        cursor: pointer;

        svg {
          width: $interest-moderation-expansion-button-size;
          height: $interest-moderation-expansion-button-size;

          circle {
            r: $interest-moderation-expansion-button-circle-radius;
            stroke-width: $interest-moderation-expansion-button-stroke-width;
          }
        }

        &:hover {
          svg circle {
            stroke: $primary-100;
          }
        }

        &:focus {
          svg {
            circle {
              stroke: $secondary-500;
            }

            path, rect {
              @include fill-icon-element($primary-800);
            }
          }

          .expand-icon circle {
            fill: $secondary-050;
          }

          .collapse-icon circle {
            fill: $secondary-200;
          }
        }
      }
    }

    .interests-grid-header {
      padding: 0 $interest-moderation-row-px;
      margin-bottom: $interests-grid-header-mb;
    }
  }

  &__card.ayo-card {
    .MuiCardContent-root {
      padding: 0;
    }

    &.inner-card {
      border-radius: 0;
    }
  }

  &__switch {
    label {
      margin-right: 0;
    }
  }
}
