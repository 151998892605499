$primary-050: #f2f3ff;
$primary-100: #bcbee6;
$primary-200: #a1a4d6;
$primary-300: #878bc8;
$primary-400: #7175af;
$primary-500: #6065a8;
$primary-600: #434981;
$primary-700: #373b71;
$primary-800: #2f325c;
$primary-900: #1f224a;
$secondary-050: #fff3e0;
$secondary-100: #ffe0b2;
$secondary-200: #ffcc80;
$secondary-500: #ffa726;
$secondary-700: #f57c00;
$secondary-900: #e65100;
$operational-background: #f3f4f5;
$operational-background-grey: #f6f6f6;
$operational-black: #1e152a;
$operational-border: #c1c0c9;
$operational-border-alpha: RGB(193 192 201 / 30%);
$operational-disabled: RGB(0 0 0 / 5%);
$operational-disabled-bg: #ececec;
$operational-disabled-fill: RGB(0 0 0 / 30%);
$operational-error: #c1292e;
$operational-error-hover: #a8292d;
$operational-error-hover-background: RGB(193 41 46 / 5%);
$operational-error-focus: #872125;
$operational-error-focus-background: RGB(193 41 46 / 10%);
$operational-error-focus-border: #ef9195;
$operational-surface: #fff;
$operational-overlay: RGB(0 0 0 / 85%);
$typography-body: RGB(0 0 0 / 75%);
$typography-disabled: #9c9c9c;
$typography-labels: #737373;
$typography-labels-dark: #636363;
$typography-titles: RGB(0 0 0 / 85%);
$typography-white: #fff;
$data-visualization-green-080-090: #6bad6a;
$data-visualization-green-090-100: #39804e;
$data-visualization-dark-violet: #3b3355;
$data-vizualization-pink: #f45b69;
$data-vizualization-red: #d26556;
$progressbar-bar: #ffa726;
$tooltip-background: #404040;
$content-placeholder: #f9f9fa;
