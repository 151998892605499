$welcome-width: 100%;
$educator-illustration-block-content-max-width-lg: 400px;
$educator-illustration-block-content-max-width-xl: 500px;
$educator-illustration-block-content-max-height: 242px;

.ayo-welcome {
  &__proceed {
    width: $welcome-width;
    position: relative;
  }

  .ayo-typography--inline-link {
    font-size: inherit;
    line-height: inherit;
  }

  .ayo-svg-illustration--secondary {
    max-height: $educator-illustration-block-content-max-height;
}

.ayo-page__screen--centered {
  &--TEACHER, &--ADMINISTRATOR {
    .ayo-page-illustration-block__content {
      @media (min-width: 1280px) {
        max-width: $educator-illustration-block-content-max-width-lg;
      }

      @media (min-width: 1920px) {
        max-width: $educator-illustration-block-content-max-width-xl;
      }
    }
  }

  &--STUDENT, &--GUARDIAN {
      @media (min-width: 1280px) {
        max-width: $educator-illustration-block-content-max-width-lg;
      }

      @media (min-width: 1920px) {
        max-width: $educator-illustration-block-content-max-width-xl;
      }
    }
  }
}
