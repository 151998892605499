$typography-h1-max-width: 818px;
$main-image-width: 322px;
$main-image-width-xs: 156px;
$decorations-image-width-xs: 57px;
$decorations-image-top: 12px;
$decorations-image-left: -56px;

.ayo-lesson-planner-landing__about-section {
  &__header {
    background-color: $secondary-050;
    background-image: url("../../../../../../resources/images/lesson-planner/about_section_header_desktop_bg_top.svg"), url("../../../../../../resources/images/lesson-planner/about_section_header_desktop_bg_bottom.svg");
    background-position: right top, left bottom;
    background-repeat: no-repeat, no-repeat;

    @media (max-width: 900px) {
      background-image: url("../../../../../../resources/images/lesson-planner/about_section_header_mobile_bg_top.svg"), url("../../../../../../resources/images/lesson-planner/about_section_header_mobile_bg_bottom.svg");
    }

    .ayo-typography--h1 {
      max-width: $typography-h1-max-width;
    }
  }

  &__main {
    background-image: url("../../../../../../resources/images/lesson-planner/about_section_main_bg.svg");
    background-size: cover;

    &__image {
      width: $main-image-width;
      height: auto;

      @media (max-width: 600px) {
        width: $main-image-width-xs;
      }
    }

    &__decorations {
      position: absolute;

      &--even {
        top: $decorations-image-top;
        left: $decorations-image-left;
      }

      @media (max-width: 600px) {
        width: $decorations-image-width-xs;
        height: auto;
      }
    }
  }
}

