$card-border-radius: 16px;
$card-box-shadow: 0 4px 8px 2px RGB(0 0 0 / 6%);
$card-width: 100%;
$card-content-padding-y: 16px;
$card-content-padding-x: 24px;
$card-content-padding: $card-content-padding-y $card-content-padding-x;
$card-actions-padding: 24px;

.ayo-card {
  position: relative;
  border: none;
  border-radius: $card-border-radius;
  width: $card-width;
  padding: 0;

  &.MuiPaper-elevation1 {
    box-shadow: $card-box-shadow;
  }

  &.MuiPaper-outlined {
    border: 1px solid $operational-border;
  }

  svg.MuiCardMedia-root {
    height: auto;
    width: 100%;
  }

  .MuiCardContent-root {
    padding: $card-content-padding;
  }

  .MuiCardActions-root  {
    padding: $card-actions-padding;
  }
}
