$analytics-stats-card-padding: 24px;
$analytics-stats-card-divider-mb: 4px;
$analytics-stats-tabs-mb: 32px;
$analytics-stats-district-paper-br: 16px;
$analytics-stats-district-paper-card-p: 24px;
$analytics-stats-district-paper-card-shadow: 0 8px 12px 4px RGB(0 0 0 / 6%);
$analytics-stats-district-paper-card-min-width: 540px;
$analytics-stats-district-paper-card-list-pl: 24px;

.ayo-analytics {
  flex: 1;

  &__tabs {
    margin-bottom: $analytics-stats-tabs-mb;
  }

  &__district-popover {
    background-color: transparent;

    .MuiPopover-paper {
      border-radius: $analytics-stats-district-paper-br;
    }

    &__card {
      padding: $analytics-stats-district-paper-card-p;
      box-shadow: $analytics-stats-district-paper-card-shadow;
      min-width: $analytics-stats-district-paper-card-min-width;

      .MuiList-root {
        padding-left: $analytics-stats-district-paper-card-list-pl;

        .MuiListItem-root {
          display: list-item;
          list-style-type: disc;

          @include body2;
        }
      }
    }
  }

  .ayo-highlights-block {
    margin: 0;
  }

  &__stats {
    .MuiDivider-root {
      margin-bottom: $analytics-stats-card-divider-mb;
    }

    .MuiCardContent-root {
      padding: $analytics-stats-card-padding;
    }

    &--achievements {
      &--MIDDLE {
        grid-column-start: 1;
      }
    }
  }
}
