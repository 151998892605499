$ayo-aptitudes-presentation-section-min-height: calc(100vh - 80px);
$ayo-aptitudes-presentation-section-img-width-xl: 1200px;
$ayo-aptitudes-presentation-section-background-color: #F1F2FF;

.ayo-aptitudes-presentation-section {
  background-color: $ayo-aptitudes-presentation-section-background-color;
  background-image: url("../../../../../../resources/images/aptitudes/landing-page/aptitudes-presentation-desktop-bg.svg");
  background-size: 100% auto;
  background-position: bottom;
  background-repeat: no-repeat;

  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 600px) {
    background-image: url("../../../../../../resources/images/aptitudes/landing-page/aptitudes-presentation-mobile-bg.svg");
  }

  @media (min-width: 1920px) {
    img {
      width: $ayo-aptitudes-presentation-section-img-width-xl;
    }
  }

  &__content {
    min-height: $ayo-aptitudes-presentation-section-min-height;
  }
}
