$ayo-leadership-attributes-graph-scrollbar-height: 0;
$ayo-leadership-attributes-graph-mt: -32px;
$ayo-leadership-attributes-graph-max-size: 500px;
$ayo-leadership-attributes-graph-max-size-xl: 800px;
$ayo-leadership-attributes-legend-icon-height: 24px;
$ayo-leadership-attributes-legend-icon-width: 32px;
$ayo-leadership-attributes-legend-icon-mr: 8px;
$icon-self: '../../../resources/icons/leading_self_legend.svg';
$icon-others: '../../../resources/icons/leading_others_legend.svg';
$icon-change: '../../../resources/icons/leading_change_legend.svg';

@mixin add-icon($icon) {
  &::before {
    background-image: url($icon);
    background-position: center;
    background-repeat: no-repeat;
    content: '';
    display: inline-block;
    height: $ayo-leadership-attributes-legend-icon-height;
    width: $ayo-leadership-attributes-legend-icon-width;
    margin-right: $ayo-leadership-attributes-legend-icon-mr;
    vertical-align: middle;
  }
}

.ayo-leadership-attributes-graph {
  background-color: $operational-surface;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: $scrollbar-width-0;
    height: $ayo-leadership-attributes-graph-scrollbar-height;
  }

  canvas {
    @media (min-width: 600px) {
      max-width: $ayo-leadership-attributes-graph-max-size;
      max-height: $ayo-leadership-attributes-graph-max-size;
      margin-top: $ayo-leadership-attributes-graph-mt;
    }

    @media (min-width: 1920px) {
      max-width: $ayo-leadership-attributes-graph-max-size-xl;
      max-height: $ayo-leadership-attributes-graph-max-size-xl;
      margin-top: $ayo-leadership-attributes-graph-mt;
    }
  }

  .labels-group,
  .ayo-checkboxgroup {
    .leading-self {
      .view-options,
      .MuiFormControlLabel-label {
        @include add-icon($icon-self);
      }
    }

    .leading-others {
      .view-options,
      .MuiFormControlLabel-label {
        @include add-icon($icon-others);
      }
    }

    .leading-change {
      .view-options,
      .MuiFormControlLabel-label {
        @include add-icon($icon-change);
      }
    }
  }
}
