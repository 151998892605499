$students-list-page-card-label-my: 6px;
$students-list-page-informational-message-padding: 8px 16px;
$students-list-page-common-spacing: 16px;
$students-list-page-feedback-border-radius: 25px;
$students-list-page-feedback-background-size-small: 80%;
$draggable-placeholder-min-height: 2.1875rem;
$draggable-placeholder-background-color: #dadada;
$draggable-border-color-focus: #bcbee6;
$draggable-border-color-drag-over: #6bad6a;
$draggable-destination-placeholder-color: #b7dbb5;
$droppable-dragged-over-color: #e8f7e8;
$editing-actions-padding: 0 4px;
$editing-actions-margin: 12px 0 24px;
$class-grid-margin-y: 12px;
$class-grid-pb: 16px;
$class-group-mx: 12px;
$class-group-divider-mx: 16px;
$class-group-divider-height: 2rem;
$class-group-type-label-mr: 16px;
$class-group-header-line-spacing: 8px;
$class-items-mt: 8px;

.ayo-students-list-page {
  &__content {
    flex: 1;
  }

  &__no-students-text {
    max-width: 400px;
  }

  &__feedback {
    display: flex;
    box-sizing: border-box;
    border-radius: $students-list-page-feedback-border-radius;
    align-items: center;
    background-color: $data-visualization-dark-violet;
    background-image: url("../../../../resources/images/students-list-feedback-bg.svg");
    background-repeat: no-repeat;
    background-position: right bottom;

    @media (max-width: 680px) {
      text-align: center;
      background-size: $students-list-page-feedback-background-size-small;
    }

    &.error {
      background-color: $operational-surface;
      background-image: url("../../../../resources/images/students-list-feedback-error-bg.svg");
    }
  }

  &.main-wrapper {
    flex: 1;
  }

  &__divider {
    align-self: center;
    margin: 0 $students-list-page-common-spacing;
    height: $students-list-page-common-spacing;

    @media (max-width: 600px) {
      margin: 0 $students-list-page-common-spacing / 2;
    }

    & + .ayo-typography--label {
      flex: 1;
    }
  }

  &__status {
    @include sr-only;
  }

  &__group {
    background-color: $operational-disabled;
    border-radius: $students-list-page-description-border-radius;
    border: 2px solid transparent;
    margin-left: $class-group-mx;
    margin-right: $class-group-mx;
    max-width: calc(100% - #{$class-group-mx} * 2);
    box-sizing: border-box;

    .MuiDivider-root {
      margin-left: $class-group-divider-mx;
      margin-right: $class-group-divider-mx;
      height: $class-group-divider-height;
    }

    .ayo-interest-chip-container {
      padding-bottom: 0;

      .ayo-chip {
        background-color: $operational-surface;
      }
    }

    &-type-label {
      margin-right: $class-group-type-label-mr;
    }

    &-header {
      & > * {
        margin-bottom: $class-group-header-line-spacing;
      }
    }

    &-items {
      margin-top: $class-items-mt;
    }

    .ayo-hotspot {
      margin-left: 0;
      margin-right: $class-group-divider-mx;
    }
  }

  &__info-icon {
    margin-right: $students-list-page-common-spacing;
  }

  .ayo-card {
    .ayo-form-control-label {
      margin-top: $students-list-page-card-label-my;
      margin-bottom: $students-list-page-card-label-my;
    }

    .ayo-numericfield,
    .ayo-numericfield + p,
    .MuiCardActions-root > div > button {
      flex: 1;
    }
  }

  .ayo-drag-placeholder {
    .MuiCardContent-root {
      min-height: $draggable-placeholder-min-height;
    }

    .ayo-card {
      border: none;
      background-color: $draggable-placeholder-background-color;
    }
  }

  .ayo-draggable-item {
    &:not(.is-dragging) {
      transform: none !important;
    }

    &:focus {
      outline: none;

      .ayo-card {
        background-color: $secondary-050;
        outline: 2px solid $secondary-500;
      }
    }

    &.is-dragging-over-other {
      .ayo-card {
        background-color: $secondary-050;
        outline: 2px solid $draggable-border-color-drag-over;
      }
    }
  }

  .ayo-editing-actions {
    padding: $editing-actions-padding;
    margin: $editing-actions-margin;
    width: 75%;

    @media (min-width: 600px) {
      width: 50%;
    }
  }

  .allowed-target {
    background-color: $primary-050;
    border: 2px dashed $primary-200;

    &.is-dragging-over {
      border: 2px solid $draggable-border-color-drag-over;
      background: $droppable-dragged-over-color;

      .ayo-drag-placeholder {
        .ayo-card {
          background-color: $draggable-destination-placeholder-color;
        }
      }
    }
  }

  .ayo-action-menu-container {
    display: flex;
    align-items: center;
  }

  .my-students-grid {
    max-width: calc(100% + #{$class-grid-margin-y} * 2);
    padding-bottom: $class-grid-pb;

    @media (max-width: 600px) {
      .ayo-informational-message {
        padding: $students-list-page-informational-message-padding;
      }
    }
  }
}

.overlay {
  position: absolute;
  z-index: 1300;
  background-color: $operational-overlay;
  inset: 0;
  mix-blend-mode: hard-light;
}
