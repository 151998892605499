$chip-focus-border-size: 2px;

.ayo-clickable-chip {
  &.MuiChip-clickable {
    box-shadow: 0 4px 8px 2px RGB(0 0 0 / 6%);

    &:hover {
      background-color: $primary-050;
      border: $chip-focus-border-size solid $primary-100;
    }

    &:focus-visible {
      background-color: $secondary-200;
      border: $chip-focus-border-size solid $secondary-500;
      color: $primary-800;
    }
  }

  &--selected {
    &.MuiChip-clickable {
      background-color: $primary-050;
      border: $chip-focus-border-size solid $primary-300;
      color: $primary-800;
      box-shadow: 0 1px 6px 1px RGB(30 32 64 / 25%);
    }
  }

  &--disabled {
    background-color: $operational-disabled;
    border: $operational-disabled;
    color: $typography-disabled;
  }
}
