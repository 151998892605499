$ayo-post-filter-border-radius: 16px;
$ayo-post-filter-padding-not-mobile: 8px 0;
$ayo-post-filter-button-margin: 0 8px;
$popover-filter-padding: 16px;
$popover-border-radius: 16px;
$popover-margin-top: 8px !important;
$menu-margin-left: 8px;
$menu-margin-top: 24px;
$menu-margin-top-xl: 32px;
$popover-radio-buttons-margin-bottom: 16px;
$popover-apply-button-margin: 8px 0 24px 0;
$container-padding: 16px;

.ayo-post-filter {
  background-color: $operational-surface;
  border-radius: $ayo-post-filter-border-radius;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 600px) {
    width: 100%;
    padding: $ayo-post-filter-padding-not-mobile;
  }

  &__hidden {
    visibility: hidden;
  }

  .filters-popover {
    background-color: $operational-surface;
    border-radius: $popover-border-radius;
    padding: $popover-filter-padding;
    width: calc(100vw - #{$popover-filter-padding} * 2 - #{$container-padding} * 2);
    margin-top: $popover-margin-top;
  }

  .ayo-form-control-label.MuiFormControlLabel-root {
    margin-bottom: $popover-radio-buttons-margin-bottom;
  }

  &__popover-apply-button {
    margin: $popover-apply-button-margin;
  }

  &__buttons-block {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__button {
    margin: $ayo-post-filter-button-margin;
    width: max-content;

    &--active {
      border-color: $primary-300;
      background-color: $primary-050;
    }
  }

  &__menu {
    margin-top: $menu-margin-top;
    margin-left: $menu-margin-left;

    @media (min-width: 1960px) {
      margin-top: $menu-margin-top-xl;
    }
  }
}
