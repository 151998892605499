$aptitude-progress-card-progress-main-color: #D9D9D9;
$aptitude-progress-card-progress-bar-color: $data-visualization-green-080-090;
$aptitude-progress-card-progress-height: 8px;

.ayo-aptitude-card.ayo-aptitude-progress-card {
  position: relative;

  .MuiCardContent-root {
    padding: $aptitude-card-padding-y $aptitude-card-padding-x $aptitude-card-padding-x + $aptitude-progress-card-progress-height;
  }

  .ayo-linear-progress {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: $aptitude-progress-card-progress-height;
    background-color: $aptitude-progress-card-progress-main-color;

    .MuiLinearProgress-bar {
      background-color: $aptitude-progress-card-progress-bar-color;
    }
  }
}