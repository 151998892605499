$home-page-main-block-min-height: calc(100vh - 80px);
$home-page-personalization-block-background: linear-gradient(
  180deg,
  #f2f3ff 0%,
  RGB(255 224 178 / 12%) 100%
);
$home-page-middle-block-min-height: 100vh;
$home-page-try-mobile-block-min-height: 83vh;
$home-page-roadmap-block-background-size: cover;
$home-page-roadmap-block-timeline-padding-mobile: 96px 6px 24px 6px;
$home-page-roadmap-block-background-size-tablet: 85%, 85%;
$home-page-roadmap-block-background-size-mobile: 100%, 100%;
$home-page-timeline-current-item-border-radius: 4px;
$home-page-timeline-current-item-padding: 8px 18px;
$home-page-timeline-current-item-padding-mobile: 8px;
$home-page-timeline-current-item-arrow-size: 12px;
$home-page-vision-block-background-color: RGB(182 140 184 / 10%);
$home-page-vision-block-inspiration-book-position-top-md: -120px;
$home-page-media-block-divider-ml: 8px;
$home-page-quote-block-background-quote-desktop-size: 250px;
$home-page-quote-block-background-quote-mobile-size: 130px;
$home-page-quote-block-min-height: 83vh;

.ayo-home-page {
  background-color: $operational-surface;

  &__main-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    min-height: $home-page-main-block-min-height;
    white-space: pre-wrap;
  }

  &__personalization-block {
    background: $home-page-personalization-block-background;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    min-height: $home-page-middle-block-min-height;
  }

  &__try-mobile-app-block {
    box-sizing: border-box;
    min-height: $home-page-try-mobile-block-min-height;
    background-image: url('../../../../resources/images/download_mobile_app_bg_desktop.svg');
    background-repeat: no-repeat;
    background-size: cover;

    @media (max-width: 960px) {
      background-image: none;
    }
  }

  &__quote-block {
    background: url('../../../../resources/icons/quote.svg') left bottom no-repeat,
      url('../../../../resources/icons/quote_reverse.svg') right top no-repeat,
      url('../../../../resources/images/landing_background_qoute_dots.svg') top no-repeat;
    background-color: $primary-500;
    background-size: $home-page-quote-block-background-quote-desktop-size,
      $home-page-quote-block-background-quote-desktop-size, auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    min-height: $home-page-quote-block-min-height;

    .ayo-typography--h2,
    .ayo-typography--body2 {
      color: $primary-050;
    }

    @media (max-width: 600px) {
      background: url('../../../../resources/icons/quote.svg') left bottom no-repeat,
        url('../../../../resources/icons/quote_reverse.svg') right top no-repeat,
        url('../../../../resources/images/landing_background_qoute_mobile.svg') center no-repeat;
      background-color: $primary-500;
      background-size: $home-page-quote-block-background-quote-mobile-size,
        $home-page-quote-block-background-quote-mobile-size, auto;
    }
  }

  &__initiatives-block {
    background-color: $operational-background-grey;
    text-align: center;
  }

  &__roadmap-block {
    box-sizing: border-box;
    min-height: $home-page-middle-block-min-height;
    background-image: url('../../../../resources/images/landing_background_memphis.svg');
    background-size: $home-page-roadmap-block-background-size;

    @media (max-width: 1024px) {
      background-image: url('../../../../resources/images/landing_roadmap_mobile_bg1.svg'),
        url('../../../../resources/images/landing_roadmap_mobile_bg2.svg');
      background-position: top right, bottom left;
      background-size: $home-page-roadmap-block-background-size-tablet;
      background-repeat: no-repeat, no-repeat;
    }

    @media (max-width: 600px) {
      background-size: $home-page-roadmap-block-background-size-mobile;

      .ayo-timeline {
        padding: $home-page-roadmap-block-timeline-padding-mobile;
      }
    }
  }

  &__media-block {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .ayo-media-card__divider {
      margin-left: $home-page-media-block-divider-ml;
    }
  }

  .ayo-typography--display {
    color: $primary-800;
  }

  .ayo-timeline--current {
    background-color: $primary-050;
    border-radius: $home-page-timeline-current-item-border-radius;
    padding: $home-page-timeline-current-item-padding;
    position: relative;
    display: inline-block;

    @media (max-width: 600px) {
      padding: $home-page-timeline-current-item-padding-mobile;
    }
  }

  .ayo-timeline--current::after {
    content: '';
    width: $home-page-timeline-current-item-arrow-size;
    height: $home-page-timeline-current-item-arrow-size;
    background-color: $primary-050;
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  .MuiTimelineItem-content {
    .ayo-timeline--current::after {
      left: 100%;
    }
  }

  .MuiTimelineOppositeContent-root {
    .ayo-timeline--current::after {
      left: 0;
    }
  }

  &__vision-block {
    min-height: $home-page-middle-block-min-height;
    background-color: $home-page-vision-block-background-color;
    position: relative;

    &--inspiration-book {
      @media (min-width: 960px) {
        position: absolute;
        top: $home-page-vision-block-inspiration-book-position-top-md;
      }
    }
  }

  &__contained-link {
    font-style: inherit;
    font-size: inherit;
  }

  &__instruction-text {
    font-style: italic;
  }
}
