$questionnaire-chip-background-color: #b0d475;
$questionnaire-chip-padding: 0 8px;

.ayo-train-ayo-page {
  flex: 1;

  .ayo-clickable-card__status {
    overflow: visible;
    white-space: nowrap
  }

  .ayo-chip {
    background-color: $questionnaire-chip-background-color;
    padding: $questionnaire-chip-padding;

    @include subtitle2;
  }

  .MuiCardHeader-root {
    position: absolute;
    right: 0;
  }
}
