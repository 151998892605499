$full-height: 100vh;
$centered-screen-padding-top: 24px;
$centered-screen-padding-bottom: 40px;
$aspect-ratio-16-by-9: 56.25%;
$video-container-mb: 24px;
$menu-padding: 24px;
$icon-button-size: 40px;
$icon-button-br: 6px;
$icon-button-mr: 4px;
$icon-button-icon-size: 24px;
$icon-button-error-color: RGB(0 0 0 / 8%);

.ayo-page {
  background-color: $operational-background-grey;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: $full-height;

  &__screen--centered {
    flex: 1;
    padding-top: $centered-screen-padding-top;
    padding-bottom: $centered-screen-padding-bottom;

    @include flex-center-column;
  }

  #main-content {
    position: absolute;
    top: 0;
    left: 0;
  }
}

$secondary-illustration-max-width: 601px;
$secondary-illustration-mb: 2rem;

.ayo-svg-illustration {
  height: auto;
  width: 100%;

  * {
    overflow: visible;
  }

  &--secondary {
    height: auto;
    max-width: $secondary-illustration-max-width;
    width: 100%;
    margin-bottom: $secondary-illustration-mb;
  }
}

$skip-to-main-content-position-left: 292px;
$skip-to-main-content-position-left-xl: 414px;
$skip-to-main-content-position-top: 16px;

.ayo-skip-to-main-content {
  position: absolute;
  left: $skip-to-main-content-position-left;
  top: $skip-to-main-content-position-top;
  width: 1px;
  height: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  opacity: 0;

  @media (min-width: 1920px) {
    left: $skip-to-main-content-position-left-xl;
  }

  &:focus {
    width: auto;
    height: auto;
    opacity: 1;
    clip: auto;
    z-index: 999;
  }
}

#focusReset {
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0;
}

.MuiFab-root {
  box-shadow: none;
  background-color: $operational-surface;
}

.MuiList-padding {
  padding: $menu-padding;
}

.MuiBackdrop-root,
.MuiPopover-root {
  background-color: $typography-titles;
}

.MuiBackdrop-invisible {
  background-color: transparent;
}

// Our custom scrollbar
$scrollbar-width-0: 0;
$scrollbar-width-sm: 8px;
$scrollbar-width-lg: 12px;
$scrollbar-border-radius: 6px;

::-webkit-scrollbar {
  width: $scrollbar-width-lg;
}

::-webkit-scrollbar-track {
  background-color: $operational-background;
  border-radius: $scrollbar-border-radius;
}

::-webkit-scrollbar-thumb {
  background-color: $operational-border;
  border-radius: $scrollbar-border-radius;
}

$list-padding: 0 0 0 21px;

ol,
ul {
  padding: $list-padding;
  margin: 0;
}

$ordered-list-padding-x: 12px;
$ordered-list-padding-x-mobile: 8px;

ol {
  .list-item-text {
    position: relative;
    left: $ordered-list-padding-x;
    display: block;
    padding-right: $ordered-list-padding-x;

    @media (max-width: 600px) {
      left: $ordered-list-padding-x-mobile;
      padding-right: $ordered-list-padding-x-mobile;
    }
  }
}

$unordered-list-padding-x: 26px;
$unordered-list-padding-x-mobile: 16px;

ul {
  .list-item-text {
    position: relative;
    left: $unordered-list-padding-x;
    display: block;
    padding-right: $unordered-list-padding-x;

    @media (max-width: 600px) {
      left: $unordered-list-padding-x-mobile;
      padding-right: $unordered-list-padding-x-mobile;
    }
  }
}

.__floater__open {
  button {
    border: 2px solid transparent;

    &:focus {
      outline: none;
      border: 2px solid $primary-100 !important;
    }
  }
}

.videoContainer {
  position: relative;
  padding-bottom: $aspect-ratio-16-by-9;
  height: 0;
  width: 100%;

  @media (max-width: 600px) {
    margin-bottom: $video-container-mb;
  }
}

.videoContainer iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ayo-icon-button {
  height: $icon-button-size;
  width: $icon-button-size;
  padding: 0;
  border-radius: $icon-button-br;
  margin-right: $icon-button-mr;

  svg {
    height: $icon-button-icon-size;
    width: $icon-button-icon-size;
  }
}

.sr-only {
  @include sr-only;
}

.ayo-draggable-item[data-rbd-drag-handle-context-id] {
  cursor: move;
}

@function pow($number, $exponent) {
  $value: 1;

  @if $exponent > 0 {
    @for $i from 1 through $exponent {
      $value: $value * $number;
    }
  }

  @return $value;
}

.primary-icon path {
  @include fill-icon-element($primary-500);
}

.reversed {
  transform: rotate(180deg);
}

$list-item-border-radius: 16px;
$list-item-padding: 12px 16px;
$list-item-mb: 16px;

.MuiList-root {
  .MuiListItem-button,
  .MuiMenuItem-root {
    border-radius: $list-item-border-radius;
    border: 2px solid $operational-surface;
    padding: $list-item-padding;
    margin-bottom: $list-item-mb;

    .MuiListItemText-root {
      margin: 0;
    }

    &.active,
    &.Mui-selected {
      background-color: $primary-050;
      border-color: $primary-300;

      svg.recolor * {
        @include fill-icon-element($primary-800);
      }

      a,
      span {
        color: $primary-800;
      }
    }

    &:hover {
      background-color: $primary-050;

      svg.recolor * {
        @include fill-icon-element($primary-500);
      }

      a,
      span {
        color: $primary-500;
      }
    }

    &:focus {
      background-color: $secondary-050;
      border-color: $secondary-500;

      svg.recolor * {
        @include fill-icon-element($primary-800);
      }

      a,
      span {
        color: $primary-800;
      }
    }
  }
}

.fit-content-container {
  width: fit-content;
}
