$goal-milestone-card-deadline-pt: 4px;
$goal-milestone-card-deadline-icon-top: 6px;
$goal-milestone-card-deadline-icon-mr: 10px;
$goal-milestone-card-controls-pt: 24px;
$goal-milestone-card-padding: 24px 28px;
$goal-milestone-card-divider-margin: 0 16px;

.ayo-goal-milestone-card {
  .ayo-form-control-label {
    width: 100%;
  }

  &__deadline {
    padding-top: $goal-milestone-card-deadline-pt;

    @include dialog-full-width-mobile;

    svg {
      position: relative;
      top: $goal-milestone-card-deadline-icon-top;
      margin-right: $goal-milestone-card-deadline-icon-mr;
    }
  }

  &__controls-container {
    padding-top: $goal-milestone-card-controls-pt;
  }

  .MuiCardContent-root {
    padding: $goal-milestone-card-padding;
  }

  .MuiDivider-root {
    margin: $goal-milestone-card-divider-margin;
  }

  &__confirm-dialog {
    @include dialog-full-width-mobile;
  }
}
