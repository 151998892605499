$ayo-moods-page-card-illustration-width: 70px;
$ayo-moods-page-card-illustration-height: 54px;
$ayo-moods-page-card-padding: 6px 10px;
$ayo-moods-page-card-min-height: 170px;
$ayo-moods-page-read-text-block-margin-top: 16px;
$ayo-moods-page-read-text-block-text-padding-right: 8px;

.ayo-moods-page {
  &__card {
    .ayo-main-illustration {
      width: $ayo-moods-page-card-illustration-width;
      height: $ayo-moods-page-card-illustration-height;
    }

    svg {
      * {
        fill: $primary-500;
      }
    }

    .MuiCardContent-root {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      padding: $ayo-moods-page-card-padding;
      min-height: $ayo-moods-page-card-min-height;
    }
  }

  &__read-text-block {
    display: flex;
    margin-top: $ayo-moods-page-read-text-block-margin-top;
    align-items: center;

    svg {
      * {
        fill: $primary-500;
      }
    }

    &__text {
      padding-right: $ayo-moods-page-read-text-block-text-padding-right;
      color: $primary-500;
    }
  }
}
