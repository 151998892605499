$ayo-youtube-link-icon-lg-width: 32px;
$ayo-youtube-link-button-padding: 16px;
$ayo-youtube-link-button-box-shadow: 0 12px 16px 6px RGB(0 0 0 / 6%);
$ayo-youtube-link-logo-height-lg: 23px;

.ayo-youtube-link-button {
  width: max-content;

  .MuiCardContent-root {
    padding: $ayo-youtube-link-button-padding;
  }

  .ayo-card {
    &:hover {
      background: $operational-surface;
    }

    &:focus {
      background: $operational-surface;
    }

  }

  .ayo-card.MuiPaper-elevation1 {
    box-shadow: $ayo-youtube-link-button-box-shadow;
  }

  a {
    text-decoration: none;
  }

  &__logo {
    @media (min-width: 1920px) {
      width: auto;
      height: $ayo-youtube-link-logo-height-lg;
    }
  }
}

.ayo-youtube__link-icon {
  @media (min-width: 1920px) {
    svg {
      width: $ayo-youtube-link-icon-lg-width;
      height: auto;
    }
  }
}
