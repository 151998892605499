@function get-container-width($min-width, $padding-x) {
  @return MIN(calc(95vw - #{$padding-x} * 2), #{$min-width} - #{$padding-x} * 2);
}

$dialog-container-min-width: 440px;
$dialog-container-min-width-lg: 640px;
$dialog-paper-border-radius: 16px 0 0 16px;
$dialog-paper-animation-duration: 225ms;
$dialog-paper-px: 24px;
$dialog-paper-px-md: 40px;
$dialog-container-padding: 56px 24px 24px;
$dialog-container-padding-md: 56px 40px 24px;

.ayo-side-dialog {
  min-height: 100%;

  &.ayo-dialog__paper {
    position: absolute;
    right: 0;
    margin: 0;
    border-radius: $dialog-paper-border-radius;
    justify-content: flex-start;
    animation-name: rtl;
    animation-duration: $dialog-paper-animation-duration;
  }

  .ayo-dialog__container {
    height: 100%;
    min-width: get-container-width($dialog-container-min-width, $dialog-paper-px);
    max-width: get-container-width($dialog-container-min-width, $dialog-paper-px);
    padding: $dialog-container-padding;

    @media (min-width: 960px) {
      padding: $dialog-container-padding-md;
      min-width: get-container-width($dialog-container-min-width, $dialog-paper-px-md);
      max-width: get-container-width($dialog-container-min-width, $dialog-paper-px-md);
    }

    @media (min-width: 1920px) {
      min-width: get-container-width($dialog-container-min-width-lg, $dialog-paper-px-md);
      max-width: get-container-width($dialog-container-min-width-lg, $dialog-paper-px-md);
    }
  }
}

@keyframes rtl {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0%);
  }
}
