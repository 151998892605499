$ayo-clickable-gallery-item-margin: 12px 8px;
$ayo-clickable-gallery-item-content-padding: 16px 24px;
$ayo-clickable-gallery-item-content-padding-mobile: 16px 8px;
$ayo-clickable-gallery-item-title-mb: 16px;
$ayo-clickable-gallery-item-title-mb-mobile: 4px;

.ayo-clickable-gallery-item {
  .ayo-card {
    margin: $ayo-clickable-gallery-item-margin;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .MuiCardContent-root {
      padding: $ayo-clickable-gallery-item-content-padding;
      width: 100%;
      box-sizing: border-box;
      flex: 1;

      @media (max-width: 600px) {
        padding: $ayo-clickable-gallery-item-content-padding-mobile;
      }

      & > div {
        height: 100%;
      }

      .ayo-clickable-card__content-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        div {
          display: initial;
        }

        .ayo-clickable-card__title {
          margin-bottom: $ayo-clickable-gallery-item-title-mb;

          @media (max-width: 600px) {
            margin-bottom: $ayo-clickable-gallery-item-title-mb-mobile;

            @include label-highlighted;

            color: $primary-500;
            font-weight: 700;
          }
        }
      }
    }
  }
}
