// Load custom fonts
@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  src: url('../../resources/fonts/Lato-Regular.ttf') format('truetype');
}

@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 700;
  src: url('../../resources/fonts/Lato-Bold.ttf') format('truetype');
}

// Basic variables and definitions
@import '../../theme/mixins';
@import '../../theme/colors';
@import '../../theme/common';
@import '../atoms/typography/typography';

html {
  font-size: 12px;
  background-color: $operational-surface;
}

@media (min-width: 1920px) {
  html {
    font-size: 16px;
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $operational-surface;
}

// Material UI overrides
@import '../atoms/accordion/accordion';
@import '../atoms/actions-menu/actions-menu';
@import '../atoms/alert/alert';
@import '../atoms/avatar/avatar';
@import '../atoms/button/button';
@import '../atoms/calendar-date-display/calendar-date-display';
@import '../atoms/calendar-non-working-days/calendar-non-working-day';
@import '../atoms/card/card';
@import '../atoms/checkbox/checkbox';
@import '../atoms/date-picker/date-picker';
@import '../atoms/dialog/dialog';
@import '../atoms/error-wrapper/error-wrapper';
@import '../atoms/dot-indicator/dot-indicator';
@import '../atoms/dynamic-grid/dynamic-grid';
@import '../atoms/form-control-label/form-control-label';
@import '../atoms/hotspot/hotspot';
@import '../atoms/linear-progress/linear-progress';
@import '../atoms/link/link';
@import '../atoms/lottie/lottie';
@import '../atoms/numericfield/numericfield';
@import '../atoms/post-image/post-image';
@import '../atoms/radiogroup/radiogroup';
@import '../atoms/scroll-to-top-button/scroll-to-top-button';
@import '../atoms/skeleton-mask/skeleton-mask';
@import '../atoms/slider/slider';
@import '../atoms/snackbar/snackbar';
@import '../atoms/switch/switch';
@import '../atoms/tabs/tabs';
@import '../atoms/textfield/textfield';
@import '../atoms/timeline/timeline';
@import '../atoms/tooltip/tooltip';

// Custom components
@import '../../tours/tours';
@import '../moleculas/accordion-class-group-header/accordion-class-group-header';
@import '../moleculas/add-courses-dropdown/add-courses-dropdown';
@import '../moleculas/adjust-schedule-dialog/adjust-schedule-dialog';
@import '../moleculas/aptitude-cards/aptitude-card-base/aptitude-card-base';
@import '../moleculas/aptitude-cards/aptitude-progress-card/aptitude-progress-card';
@import '../moleculas/back-navigation-link/back-navigation-link';
@import '../moleculas/badge-card/badge-card';
@import '../moleculas/banner/banner';
@import '../moleculas/breadcrumbs_navigation/breadcrumbs_navigation';
@import '../moleculas/calendar-class-card/class-card';
@import '../moleculas/calendar-day/calendar-day';
@import '../moleculas/calendar-day-options/day-options';
@import '../moleculas/carousel/carousel';
@import '../moleculas/chat-drawer/chat-drawer';
@import '../moleculas/chips/editable-chip/editable-chip';
@import '../moleculas/chips/chip/chip';
@import '../moleculas/chips/ml-chip/ml-chip';
@import '../moleculas/chips/timeline-chip/timeline-chip';
@import '../moleculas/clickable-card/clickable-card';
@import '../moleculas/clickable-gallery-item/clickable-gallery-item';
@import '../moleculas/data-grid/data-grid';
@import '../moleculas/day-schedule/day-schedule';
@import '../moleculas/delete-item-wrapper/delete-item-wrapper';
@import '../moleculas/dropdown/dropdown';
@import '../moleculas/drag-drop-content/drag-drop-content';
@import '../moleculas/editing-button/editing-button';
@import '../moleculas/extracurriculars-year/extracurriculars-year';
@import '../moleculas/filelink-removable-item/filelink-removable-item';
@import '../moleculas/flippable-card/flippable-card';
@import '../moleculas/image-file-preview/image-file-previewer';
@import '../moleculas/informational-caption/informational-caption';
@import '../moleculas/informational-message/informational-message';
@import '../moleculas/initiative-empty-state-block/initiative-empty-state-block';
@import '../moleculas/interest-bubble-figure/interest-bubble-figure';
@import '../moleculas/lesson-card/lesson-card';
@import '../moleculas/link-with-snackbar/link-with-snackbar';
@import '../moleculas/loader/loader';
@import '../moleculas/media-card/media-card';
@import '../moleculas/month-picker/month-picker';
@import '../moleculas/mood-card/mood-card';
@import '../moleculas/mood-get-help-card/mood-get-help-card';
@import '../moleculas/page-end-scroll-to-top-button/page-end-scroll-to-top-button';
@import '../moleculas/preview-gallery/preview-gallery';
@import '../moleculas/profile-actions-buttons/profile-actions-buttons';
@import '../moleculas/question/question';
@import '../moleculas/radar-chart/radar-chart';
@import '../moleculas/searchfield/searchfield';
@import '../moleculas/selectable-icon-button/selectable-icon-button';
@import '../moleculas/side-dialog/side-dialog';
@import '../moleculas/stt-tooltip/stt-tooltip';
@import '../moleculas/student-aptitude-chip/student-aptitude-chip';
@import '../moleculas/table/table';
@import '../moleculas/text-badge/text-badge';
@import '../moleculas/tooltip-wrappers/link-with-tooltip/link-with-tooltip';
@import '../moleculas/tooltip-wrappers/text-with-tooltip/text-with-tooltip';
@import '../moleculas/validation-card/validation-card';
@import '../moleculas/week-picker/week-picker';
@import '../moleculas/youtube-components/youtube-card/youtube-card';
@import '../moleculas/youtube-components/youtube-link-button/youtube-link-button';
@import '../organisms/action-step-dialogs/action-step-reflection-add-dialog/action-step-reflection-add-dialog';
@import '../organisms/action-step-dialogs/action-step-reflection-view-dialog/action-step-reflection-view-dialog';
@import '../organisms/activity-log/activity-log';
@import '../organisms/achievements-section/achievements-section';
@import '../organisms/avatar-edit-dialog/avatar-edit-dialog';
@import '../organisms/bottom-bar/bottom-bar';
@import '../organisms/bottom-navigation/bottom-navigation';
@import '../organisms/calendar-views/month-view/month-view';
@import '../organisms/chat/family-feed-chat';
@import '../organisms/chips-block/chips-block';
@import '../organisms/club-hub/club-card/club-card';
@import '../organisms/club-hub/club-hub-section/club-hub-section';
@import '../organisms/club-hub/club-status/club-status';
@import '../organisms/comments/comments-block/comments-block';
@import '../organisms/comments/comments-components/comments-card/comments-card';
@import '../organisms/comments/comments-dialog/comments-dialog';
@import '../organisms/contact-popover-info/contact-info-popover';
@import '../organisms/data-processing-footer/data-processing-footer';
@import '../organisms/demo-user-snackbar/demo-user-snackbar';
@import '../organisms/endorsements-section/endorsements-section';
@import '../organisms/endorsements-section/components/endorsement-card/endorsement-card';
@import '../organisms/endorsements-section/components/endorsement-dialog/endorsement-dialog';
@import '../organisms/events/events';
@import '../organisms/evidence-of-growth/evidence-of-growth-card/evidence-of-growth';
@import '../organisms/extracurriculars-section/extracurriculars-section';
@import '../organisms/family-feed-post/family-feed-post';
@import '../organisms/feedback/feedback-popup/feedback-popup';
@import '../organisms/fileupload/fileupload';
@import '../organisms/action-step-dialogs/action-step-create-dialog/action-step-create';
@import '../organisms/grades-section/grades-section';
@import '../organisms/highlights-block/highlights';
@import '../organisms/highlights-block/components/pie-chart/pie-chart-component';
@import '../organisms/interests-plot/interests-plot';
@import '../organisms/interests-viewmap/interests-viewmap';
@import '../organisms/intervention-alert/intervention-alert';
@import '../organisms/landing-block/landing-block';
@import '../organisms/leadership-attributes-dialog/leadership-attributes-dialog';
@import '../organisms/leadership-attributes-graph/leadership-attributes-graph';
@import '../organisms/leadership-profile-section/leadership-profile-section';
@import '../organisms/milestone-card/milestone-card';
@import '../organisms/mood-section/student/mood-section-student';
@import '../organisms/mood-section/teacher/mood-section-teacher';
@import '../organisms/activity-description-dialog/activity-description-dialog';
@import '../organisms/nav-drawer/nav-drawer';
@import '../organisms/no-students-screen/no-students-screen';
@import '../organisms/notifications-popover/notifications-popover';
@import '../organisms/opportunities-block/opportunities-block';
@import '../organisms/opportunity-card/opportunity-card';
@import '../organisms/portfolio-sections/components/portfolio-academic-record-card/portfolio-academic-record-card';
@import '../organisms/portfolio-sections/components/portfolio-attachments/portfolio-attachments';
@import '../organisms/portfolio-sections/components/portfolio-file-preview-dialog/portfolio-file-preview-dialog';
@import '../organisms/portfolio-sections/components/portfolio-section/portfolio-section';
@import '../organisms/portfolio-sections/components/portfolio-test-score-card/portfolio-test-score-card';
@import '../organisms/portfolio-sections/components/portfolio-update-notification/portfolio-update-notification';
@import '../organisms/portfolio-sections/sections/portfolio-academic-record-section/portfolio-academic-record-section';
@import '../organisms/portfolio-sections/sections/portfolio-achievements-section/portfolio-achievements-section';
@import '../organisms/portfolio-sections/sections/portfolio-aptitudes-section/portfolio-aptitudes-sections';
@import '../organisms/portfolio-sections/sections/portfolio-employments-section/portfolio-employments-section';
@import '../organisms/portfolio-sections/sections/portfolio-essay-section/portfolio-essays-section';
@import '../organisms/portfolio-sections/sections/portfolio-certificates-section/portfolio-certificates-sections';
@import '../organisms/portfolio-sections/sections/portfolio-endorsements-section/portfolio-endorsements-section';
@import '../organisms/portfolio-sections/sections/portfolio-extracurriculars-section/portfolio-extracurriculars-sections';
@import '../organisms/portfolio-sections/sections/portfolio-interests-section/portfolio-interests-section';
@import '../organisms/portfolio-sections/sections/portfolio-leadership-attributes-section/portfolio-leadership-attributes-section';
@import '../organisms/portfolio-sections/sections/portfolio-personal-information-section/portfolio-personal-information-section';
@import '../organisms/portfolio-sections/sections/portfolio-recommendations-section/portfolio-recommendations-section';
@import '../organisms/portfolio-sections/sections/portfolio-recommendations-section/portfolio-add-recommendation-dialog/portfolio-add-recommendation-dialog';
@import '../organisms/portfolio-sections/sections/portfolio-volunteering-section/portfolio-volunteering-section';
@import '../organisms/post-card/post-card';
@import '../organisms/publication-status-badge/publication-status-badge';
@import '../organisms/reset-tour-menu/reset-tour-menu';
@import '../organisms/rich-text-editor/rich-text-editor';
@import '../organisms/session-timeout-dialog/session-timeout-dialog';
@import '../organisms/sharing-groups/personalized-block/personalized-block';
@import '../organisms/sharing-groups/roles-visibility-picker/roles-visibility-picker';
@import '../organisms/student-general-info/student-general-info';
@import '../organisms/student-list-views/accordion-class-view/accordion-grade-view';
@import '../organisms/student-list-views/intervention-required-group-view/intervention-required-group-view';
@import '../organisms/suggested-activities-card/suggested-activities';
@import '../organisms/support-dialog/support-dialog';
@import '../organisms/top-bar/top-bar';
@import '../organisms/try-mobile-informational-message/try-mobile-informational-message';
@import '../organisms/welcome/welcome';

// Pages
@import '../pages/admin/admin-page/admin-page';
@import '../pages/admin/admin-page/components/delete-user-data-dialog/delete-user-data-dialog';
@import '../pages/admin/admin-page/components/manage-user-privileges-dialog/manage-user-privileges-dialog';
@import '../pages/analytics/analytics-page';
@import '../pages/analytics/components/analytics-stat-value/analytics-stat-value';
@import '../pages/analytics/components/heat-map-stat-card/heat-map-stat-card';
@import '../pages/aptitudes/aptitudes-page';
@import '../pages/aptitudes/components/aptitudes-dialog/aptitudes-dialog';
@import '../pages/aptitudes/components/sections/aptitudes-benefit/aptitudes-benefit-section';
@import '../pages/aptitudes/components/sections/aptitudes-definition/aptitudes-definition-section';
@import '../pages/aptitudes/components/sections/aptitudes-explore-more/aptitudes-explore-more-section';
@import '../pages/aptitudes/components/sections/aptitudes-gallery/aptitudes-gallery-section';
@import '../pages/aptitudes/components/sections/aptitudes-how-to-learn/aptitudes-how-to-learn-section';
@import '../pages/aptitudes/components/sections/aptitudes-presentation/aptitudes-presentation-section';
@import '../pages/badges/components/badges-dialog/badges-dialog';
@import '../pages/campus-selection/campus-selection';
@import '../pages/content/content-edit-page/content-edit-page';
@import '../pages/content/content-main-page/content-main-page';
@import '../pages/club-hub/club-hub-page';
@import '../pages/club-hub/club-hub-create-page/club-hub-create-page';
@import '../pages/club-hub/club-hub-details-page/club-hub-details-page';
@import '../pages/club-hub/club-hub-details-page/sections/club-post-and-events-section/sections/post-section/components/edit-club-post-dialog/edit-post-dialog';
@import '../pages/family-feed/family-feed-creation-center/family-feed-creation-center';
@import '../pages/family-feed/pages/family-feed-events/family-feed-events';
@import '../pages/home/faq-page/faq-page';
@import '../pages/home/home-page/home-page';
@import '../pages/home/initiatives-page/initiatives-page';
@import '../pages/issue-report/issue-report-page';
@import '../pages/leadership-attributes/leadership-attributes-assessment-home-page/leadership-attributes-assessment-home-page';
@import '../pages/leadership-attributes/leadership-attributes-assessment-tool-page/components/maturity-level-rubric/maturity-level-rubric';
@import '../pages/leadership-attributes/leadership-attributes-assessment-tool-page/components/maturity-levels-box/maturity-levels-box';
@import '../pages/leadership-attributes/leadership-attributes-page/components/sections/leadership-attributes-definition/leadership-attributes-definition';
@import '../pages/leadership-attributes/leadership-attributes-page/components/sections/leadership-attributes-explore-more/leadership-attributes-explore-more';
@import '../pages/leadership-attributes/leadership-attributes-page/components/sections/leadership-attributes-gallery/leadership-attributes-galery';
@import '../pages/leadership-attributes/leadership-attributes-page/components/sections/leadership-attributes-how-does-it-work/leadership-attributes-how-does-it-work';
@import '../pages/leadership-attributes/leadership-attributes-page/components/sections/leadership-attributes-what-is/LeadershipAttributesWhatIsSection';
@import '../pages/leadership-attributes/leadership-attributes-page/components/sections/leadership-attributes-who-enjoy-it/leadership-attributes-who-enjoy-it';
@import '../pages/leadership-attributes/leadership-attributes-page/leadership-attributes-page';
@import '../pages/lesson-planner/lesson-planner';
@import '../pages/login/login-page';
@import '../pages/my-classes/my-classes-page';
@import '../pages/my-kids/components/family-activity-card/family-activity-card';
@import '../pages/my-kids/components/family-activity-dialog/family-activity-dialog';
@import '../pages/my-kids/components/like-button/like-button';
@import '../pages/my-kids/family-activities-page/family-activities-page';
@import '../pages/my-kids/kids-list-page/kids-list-page';
@import '../pages/my-lessons/lesson-page/lesson-page';
@import '../pages/my-lessons/components/lesson-planner-card/lesson-planner-card';
@import '../pages/my-lessons/lesson-planner-create/lesson-planner-create-page';
@import '../pages/my-lessons/lesson-planner-create/components/basic-settings/components/academic-years/academic-years';
@import '../pages/my-lessons/lesson-planner-create/components/basic-settings/components/schedule-types/schedule-types';
@import '../pages/my-lessons/lesson-planner-create/components/continue-button/continue-button';
@import '../pages/my-lessons/lesson-planner-done/lesson-planner-done-page';
@import '../pages/my-lessons/my-lessons-page';
@import '../pages/my-lessons/planner-schedule/planner-schedule-page';
@import '../pages/portfolio-details/components/portfolio-settings-dialog/portfolio-settings-dialog';
@import '../pages/portfolio-main-page/components/create-portfolio-section/create-portfolio-section';
@import '../pages/portfolio-main-page/components/portfolio-get-link-dialog/portfolio-get-link-dialog';
@import '../pages/portfolio-shared-page/portfolio-shared-page';
@import '../pages/my-profile/my-profile-page';
@import '../pages/my-profile-teacher/my-profile-teacher-page';
@import '../pages/my-students/student-details/student-details';
@import '../pages/my-students/mood-details/mood-details-page';
@import '../pages/my-students/mood-details/components/follow-up-questions-block/follow-up-questions-block';
@import '../pages/my-students/mood-details/components/mood-graph/mood-graph';
@import '../pages/my-students/mood-details/components/negative-trend-alert/negative-trend-alert';
@import '../pages/my-students/students-list-page/students-list-page';
@import '../pages/opportunities/opportunities-page';
@import '../pages/opportunities/components/sections/opportunities-definition/opportunities-definition-section';
@import '../pages/opportunities/components/sections/opportunities-explore-more/opportunities-explore-more-section';
@import '../pages/opportunities/components/sections/opportunities-goal/opportunities-goal-section';
@import '../pages/opportunities/components/sections/opportunities-how-it-works/opportunities-how-it-works-section';
@import '../pages/portfolio-main-page/portfolio-main-page';
@import '../pages/portfolio-details/portfolio-details-page';
@import '../pages/settings/settings-page';
@import '../pages/train-ayo/explore-opportunities-page/explore-opportunities-page';
@import '../pages/train-ayo/extracurriculars-page/extracurriculars-page';
@import '../pages/train-ayo/goals-page/goals-actions/components/goal-add-edit-form/goal-add-edit-form';
@import '../pages/train-ayo/goals-page/goals-actions/components/goal-inputs/goal_inputs';
@import '../pages/train-ayo/goals-page/goals-actions/goal-create-page/goal-create-page';
@import '../pages/train-ayo/goals-page/goals-actions/goal-details-page/goal-details-page';
@import '../pages/train-ayo/goals-page/goals-actions/goal-edit-page/goal-edit-page';
@import '../pages/train-ayo/goals-page/goals-page';
@import '../pages/train-ayo/moods-page/components/mood-dialog/mood-dialog';
@import '../pages/train-ayo/moods-page/components/mood-questions-dialog/mood-questions-dialog';
@import '../pages/train-ayo/moods-page/mood-recommendations-page/mood-recommendations-page';
@import '../pages/train-ayo/train-ayo-page/train-ayo-page';
@import '../pages/train-ayo/train-ayo-questionnaire-pages/train-ayo-questionnaire-page';
@import '../pages/train-ayo/train-ayo-questionnaire-pages/train-ayo-select-page';
@import '../pages/train-ayo/train-ayo-student-selection-pages/components/train-ayo-kids-selection/train-ayo-kids-selection-page';
@import '../pages/train-ayo/train-ayo-student-selection-pages/components/train-ayo-student-selection/train-ayo-student-selection-page';
@import '../pages/train-ayo/moods-page/ayo-moods-page';
@import '../pages/unsubscribe-page/unsubscribe-page';
