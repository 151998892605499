$ayo-evidence-of-growth-chip-border-radius: 9px;
$ayo-evidence-of-growth-card-padding: 24px;
$ayo-evidence-of-growth-chip-max-width: 80%;
$ayo-evidence-of-growth-dialog-padding: 54px 16px 24px;
$ayo-evidence-of-growth-dialog-width: calc(100% - 32px);
$ayo-evidence-of-growth-details-dialog-margin-top: 16px;
$ayo-evidence-of-growth-details-dialog-padding: 16px;
$ayo-evidence-of-growth-details-dialog-border-radius: 16px;
$ayo-evidence-of-growth-details-dialog-header-padding-right: 24px;
$ayo-evidence-of-growth-details-dialog-header-padding-bottom: 8px;
$ayo-evidence-of-growth-details-dialog-scroll-distance-mobile: 23px;
$ayo-evidence-of-growth-icon-height-xl: 28px;

.ayo-evidence-of-growth {
  @include extra-large-icon($ayo-evidence-of-growth-icon-height-xl);

  .ayo-chip {
    max-width: $ayo-evidence-of-growth-chip-max-width;
    color: $typography-labels-dark;
    background-color: $operational-background;
  }

  &.ayo-card .MuiCardContent-root{
    padding: $ayo-evidence-of-growth-card-padding;
  }

    @media (max-width: 600px) {
      &.MuiDialog-paperFullWidth.MuiDialog-paper {
        width: 100%;
        justify-content: center;
      }

      &.MuiDialog-paperScrollPaper {
        height: inherit;
      }

      .MuiDialogContent-root {
        padding: 0 $ayo-evidence-of-growth-details-dialog-scroll-distance-mobile 0 0;
      }

      .ayo-dialog__container {
        padding: $ayo-evidence-of-growth-dialog-padding;
        min-width: $ayo-evidence-of-growth-dialog-width;
        max-width: $ayo-evidence-of-growth-dialog-width;
      }
  }

  &__title-box {
    white-space: nowrap;
    overflow: hidden;
    align-self: center;
  }

  &__title {
    @include wrapped-text(1);
  }

  &__view-details {
    margin-top: $ayo-evidence-of-growth-details-dialog-margin-top;
    padding: $ayo-evidence-of-growth-details-dialog-padding;
    border-radius: $ayo-evidence-of-growth-details-dialog-border-radius;
    background-color: $operational-background;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 600px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__view-details h4 {
    padding-right: $ayo-evidence-of-growth-details-dialog-header-padding-right;
    max-width: 100%;

    @media (max-width: 600px) {
      padding-bottom: $ayo-evidence-of-growth-details-dialog-header-padding-bottom;
    }
  }

  p, h3 {
    overflow-wrap: break-word;
  }
}
