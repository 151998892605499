$actions-container-padding: 24px;
$actions-border-radius: 16px;
$actions-border-border: 1px;

.ayo-lesson-assignment-materials {
  .ayo-lesson-page__materials-actions-container {
    padding: $actions-container-padding;
    border: $actions-border-border solid $operational-border;
    border-radius: $actions-border-radius;
  }
}
