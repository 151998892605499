$ayo-suggested-activities-header-padding: 16px 24px;
$ayo-suggested-activities-body-padding: 24px;
$ayo-suggested-activities-card-border-radius: 9px;
$ayo-suggested-activities-padding: 0;
$ayo-suggested-activities-description-padding-top: 16px;
$ayo-suggested-activities-divider-margin: 0 8px;
$ayo-suggested-activities-padding: 0;
$ayo-suggested-activities-books-bg-color: #2ec0c4;
$ayo-suggested-activities-courses-bg-color: #ef9194;
$ayo-suggested-activities-activities-bg-color: #ffc107;
$ayo-suggested-activities-videos-bg-color: #9f7de1;


.ayo-suggested-activities {
  &__header {
    padding: $ayo-suggested-activities-header-padding;

    &.books {
      background-color: $ayo-suggested-activities-books-bg-color;
    }

    &.courses {
      background-color: $ayo-suggested-activities-courses-bg-color;
    }

    &.activities {
      background-color: $ayo-suggested-activities-activities-bg-color;
    }

    &.videos {
      background-color: $ayo-suggested-activities-videos-bg-color;
    }
  }

  &__body {
    padding: $ayo-suggested-activities-body-padding;
  }

  &.ayo-card .MuiCardContent-root {
    padding: $ayo-suggested-activities-padding;
  }

  &__divider {
    margin: $ayo-suggested-activities-divider-margin;
  }

  &__description {
    @include wrapped-text(3);
  }
}
