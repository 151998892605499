$ayo-settings-page-switches-block-padding: 24px 24px 8px;
$ayo-settings-page-switches-block-border-radius: 16px;

.ayo-settings-page {
  flex: 1;

  &__switches-block {
    padding: $ayo-settings-page-switches-block-padding;
    background-color: $operational-surface;
    border-radius: $ayo-settings-page-switches-block-border-radius;

    .MuiFormControlLabel-root{
      margin-right: 0;
    }
  }
}
