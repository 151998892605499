$slider-height: 16px;
$slider-margin: 32px 0 48px;
$slider-mb-mobile: 72px;
$slider-br: 21px;
$slider-mark-color: #9c9ba3;
$slider-mark-width: 1px;
$slider-mark-opacity: 0.5;
$slider-mark-label-top: 32px;
$slider-mark-label-font-size: 1rem;
$slider-mark-label-line-height: 1.5rem;

.ayo-slider {
  color: $operational-background;
  padding: 0;
  margin: $slider-margin;

  @media (max-width: 600px) {
    margin-bottom: $slider-mb-mobile;
  }

  &,
  .MuiSlider-rail {
    border-radius: $slider-br;
  }

  .MuiSlider-track {
    background: $primary-500;
    border-radius: $slider-br;
  }

  .MuiSlider-thumb {
    width: unset;
    height: unset;
    border-radius: unset;
    background-color: unset;
    box-shadow: none;
    pointer-events: none;

    &.with-thumb-icon::before {
      display: none;
    }

    circle:first-child {
      display: none;
    }

    input:focus + svg circle:first-child {
      display: block;
    }
  }

  .MuiSlider-mark {
    opacity: $slider-mark-opacity;
    box-sizing: border-box;
    border-top: $slider-mark-width dashed $slider-mark-color;

    &.MuiSlider-markActive,
    &[style='bottom: 100%;'],
    &[style='left: 100%;'] {
      opacity: 0;
    }
  }

  .MuiSlider-markLabel {
    @include ayo-typography-body;
    @include ayo-typography-label;

    font-size: $slider-mark-label-font-size;
    line-height: $slider-mark-label-line-height;
    transform: none;
  }

  &:not(.MuiSlider-vertical) {
    &,
    .MuiSlider-rail {
      height: $slider-height;
    }

    .MuiSlider-track {
      height: $slider-height;
    }

    .MuiSlider-thumb {
      .thumb-0 {
        transform: translateX(25%);
      }

      .thumb-last {
        transform: translateX(-25%);
      }
    }

    .MuiSlider-mark {
      box-sizing: border-box;
      border-left: $slider-mark-width dashed $slider-mark-color;
      height: $slider-height;
    }

    .MuiSlider-markLabel {
      top: $slider-mark-label-top;

      @media (max-width: 600px) {
        white-space: pre;
      }

      &:nth-last-child(2) {
        transform: translateX(-100%);
        text-align: end;
      }
    }
  }
}
