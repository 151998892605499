$timeline-padding: 80px 6px 12px 6px;
$timeline-padding-mobile: 40px 6px 12px 6px;
$timeline-line-width: 2px;
$timeline-separator-pt: 4rem;
$timeline-line-height: 120px;
$timeline-line-height-mobile: 120px;
$timeline-content-side-padding: 16px;
$timeline-content-py: 3rem;

.ayo-timeline {
  padding: $timeline-padding;

  @media (max-width: 600px) {
    padding: $timeline-padding-mobile;
  }

  .MuiTimelineDot-root,
  .MuiTimelineConnector-root {
    background-color: $primary-100;
  }

  .MuiTimelineDot-root {
    box-shadow: none;
    margin: 0;
    position: relative;
  }

  .MuiTimelineDot-root::before {
    content: '';
    position: absolute;
    left: calc(50% - 1px);
    bottom: 100%;
    width: $timeline-line-width;
    height: calc(4rem + 2px);
    background-color: $primary-100;
  }

  .MuiTimelineItem-root:first-child .MuiTimelineDot-root::before {
    height: $timeline-line-height;

    @media (max-width: 600px) {
      height: $timeline-line-height-mobile;
    }
  }

  .MuiTimelineItem-root .MuiTimelineSeparator-root {
    padding-top: $timeline-separator-pt;
  }

  .MuiTimelineContent-root {
    text-align: right;
    padding-right: $timeline-content-side-padding;
    padding-left: 0;
  }

  .MuiTimelineOppositeContent-root {
    text-align: left;
    padding-left: $timeline-content-side-padding;
    padding-right: 0;
  }

  .MuiTimelineContent-root,
  .MuiTimelineOppositeContent-root {
    padding-top: $timeline-content-py;
    padding-bottom: $timeline-content-py;
  }
}
