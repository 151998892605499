$opportunities-page-background-color: #FFFDEB;
$opportunities-page-button-min-width: 184px;
$opportunities-page-button-min-width-xl: 300px;
$opportunities-page-typography-blue-color: #1C0065;
$opportunities-page-typography-paragraph-margin-bottom: 8px;

.ayo-opportunities-page {
  background-color: $opportunities-page-background-color;

  &__content {
    background-color: $operational-surface;

    .MuiTypography-paragraph {
      margin-bottom: $opportunities-page-typography-paragraph-margin-bottom;
    }

    .ayo-button {
      z-index: 1;
      min-width: $opportunities-page-button-min-width;

      @media (min-width: 1920px) {
        width: $opportunities-page-button-min-width-xl;
      }
    }

    &--typography-blue {
      color: $opportunities-page-typography-blue-color;
    }

    &__text {
      max-width: 32%;

      @media (min-width: 1920px) {
        max-width: 45%;
      }

      @media (max-width: 960px) {
        max-width: 50%;
      }

      @media (max-width: 600px) {
        max-width: 100%;
      }
    }
  }
}
