$card-title-pt: 8px;

.ayo-content-main-page {
  flex: 1;

  .ayo-clickable-card {
    &__content-container--w-status-icon {
      max-width: 25%;
    }

    &__title {
      @include subtitle1;

      color: $primary-500;
      overflow: visible;
      padding-top: $card-title-pt;
      white-space: normal;
    }
  }
}
