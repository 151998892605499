$ayo-portfolio__volunteering-section-margin-top: 24px;
$ayo-portfolio__volunteering-section-textmargin-left: 8px;
$ayo-portfolio__volunteering-section-wrapper-width: 50%;
$ayo-portfolio__volunteering-section-card-padding: 24px;
$ayo-portfolio__volunteering-section-background-color: linear-gradient(90deg, #FFE7F9 0%, #FFEBDE 100%);

.ayo-portfolio__volunteering-section {
  background: url('../../../../../resources/images/portfolio/volunteering-bg.svg') no-repeat right bottom, $ayo-portfolio__volunteering-section-background-color;

  .ayo-delete-item-wrapper {
    width: $ayo-portfolio__volunteering-section-wrapper-width;
  }

  &__card {
    margin-top: $ayo-portfolio__volunteering-section-margin-top;

    &.ayo-card .MuiCardContent-root {
      padding: $ayo-portfolio__volunteering-section-card-padding;
    }

    &__content {
      display: flex;
      justify-content: space-between;

      &__text {
        margin-left: $ayo-portfolio__volunteering-section-textmargin-left;
      }
    }

    &__cropped {
      width: $ayo-portfolio__volunteering-section-wrapper-width;
    }
  }
}

.ayo-portfolio-add-volunteering-dialog {
  .ayo-dialog__container {
    @media (min-width: 1920px) {
      max-width: min-content;
    }
  }
}
