$ayo-lesson-assignment-students-form-control-padding: 12px 16px;
$ayo-lesson-assignment-students-form-control-border-radius: 16px;
$ayo-lesson-assignment-students-form-control-border: 1px solid $operational-border;
$ayo-lesson-assignment-students-error-message-margin-top: 8px;
$ayo-lesson-assignment-students-error-message-font-size: 1rem;
$ayo-lesson-assignment-students-error-message-line-height: 1.333rem;

.ayo-lesson-assignment-students {
  &__form-control {
    padding: $ayo-lesson-assignment-students-form-control-padding;
    border-radius: $ayo-lesson-assignment-students-form-control-border-radius;
    border: $ayo-lesson-assignment-students-form-control-border;
    box-sizing: border-box;
    background-color: $operational-surface;
    width: 100%;

    &__student-label {
      &__text {
        color: $primary-500;
      }
    }

    &--error {
      border-color: $operational-error;
    }
  }

  &__error-message {
    margin-top: $ayo-lesson-assignment-students-error-message-margin-top;
    color: $operational-error;
    font-size: $ayo-lesson-assignment-students-error-message-font-size;
    line-height: $ayo-lesson-assignment-students-error-message-line-height;
  }
}
