$faq-page-questions-container-pb: 150px;
$faq-page-ask-question-min-height: calc(100vh - 48px - 2.0625rem);
$faq-page-ask-question-background-size: 22% 100%, 22% 100%;
$faq-page-ask-question-background-size-desktop-large: 30% 100%, 30% 100%;
$faq-page-ask-question-background-size-mobile: 90%, 90%;
$faq-page-ask-question-background-size-tablet: 50%, 50%;
$faq-page-ask-question-background-padding-tablet: 120px 0;
$faq-page-ask-question-w-bg-background-padding: 80px 0 136px;
$faq-page-ask-question-w-bg-background-padding-mobile: 40px 0 297px;
$faq-page-ask-question-w-bg-background-size: 38%;
$faq-page-ask-question-w-bg-background-size-mobile: 60%;
$faq-page-ask-question-w-bg-background-position-mobile: 82%;

.ayo-faq-page {
  &--questions-container {
    padding-bottom: $faq-page-questions-container-pb;

    &.no-padding {
      padding-bottom: 0;
    }
  }

  &--ask-a-question {
    box-sizing: border-box;
    min-height: $faq-page-ask-question-min-height;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../../../../resources/images/faq_ask_question_bgleft.svg'),
      url('../../../../resources/images/faq_ask_question_bgright.svg');
    background-position: left, right;
    background-size: $faq-page-ask-question-background-size;
    background-repeat: no-repeat;

    @media (min-width: 1920px) {
      background-size: $faq-page-ask-question-background-size-desktop-large;
    }

    @media (max-width: 960px) {
      background-image: url('../../../../resources/images/faq_ask_question_mobile_bgtop.svg'),
        url('../../../../resources/images/faq_ask_question_mobile_bgbottom.svg');
      background-position: top, bottom;
      background-size: $faq-page-ask-question-background-size-tablet;
      padding: $faq-page-ask-question-background-padding-tablet;
    }

    @media (max-width: 600px) {
      background-size: $faq-page-ask-question-background-size-mobile;
    }
  }

  &.ayo-faq-page--w-bg {
    .ayo-bottom-bar {
      margin-top: 0;
    }

    .ayo-faq-page--ask-a-question {
      background-image: url('../../../../resources/images/faq_hand_sending_feedback.svg');
      background-position: right;
      background-size: $faq-page-ask-question-w-bg-background-size;
      min-height: initial;
      padding: $faq-page-ask-question-w-bg-background-padding;

      @media (max-width: 600px) {
        background-position: bottom;
        background-position-y: $faq-page-ask-question-w-bg-background-position-mobile;
        background-size: $faq-page-ask-question-w-bg-background-size-mobile;
        padding: $faq-page-ask-question-w-bg-background-padding-mobile;
      }
    }
  }
}
