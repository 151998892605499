$bg-spacing-top: 16px;

.ayo-lesson-planner-landing__faq-section {
  background-color: $secondary-100;
  background-image: url("../../../../../../resources/images/lesson-planner/faq_section_desktop_bg_top.svg"), url("../../../../../../resources/images/lesson-planner/faq_section_desktop_bg_bottom.svg");
  background-position: center top, center bottom;
  background-repeat: no-repeat, no-repeat;

  @media (max-width: 900px) {
    background-image: url("../../../../../../resources/images/lesson-planner/faq_section_mobile_bg_top.svg"), url("../../../../../../resources/images/lesson-planner/faq_section_mobile_bg_bottom.svg");
    background-position: center top $bg-spacing-top, center bottom;
  }
}
