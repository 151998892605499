$page-block-border-radius: 16px;

.ayo-club-hub-create-page {
  flex: 1;

  &__description, &__links, &__requirements {
    border-radius: $page-block-border-radius;
    background-color: $operational-surface;
  }

  &__back-button {
    font-weight: normal;
  }
}
