$flippable-card-height: 280px;
$flippable-card-height-xl: 320px;
$flippable-card-width: 240px;
$flippable-card-width-xl: 260px;
$flippable-card-padding: 8px;
$flippable-card-perspective: 1000px;

.ayo-flippable-card {
  height: $flippable-card-height;
  width: $flippable-card-width;
  perspective: $flippable-card-perspective;

  @media (min-width: 1920px) {
    height: $flippable-card-height-xl;
    width: $flippable-card-width-xl;
  }

  &__inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;

    &--flipped {
      transform: rotateY(180deg);

      .MuiCardActions-root {
        .ayo-button {
          transform: scaleX(-1);
        }
      }
    }
  }

  &__front, &__back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    perspective: 0;
  }

  &__back {
    transform: rotateY(180deg);
  }

  .MuiCardContent-root {
    padding: $flippable-card-padding;
    height: calc(100% - 2 * #{$flippable-card-padding});
  }

  .MuiCardActions-root {
    .ayo-button {
      position: absolute;
      bottom: $flippable-card-padding;
      right: $flippable-card-padding;
    }
  }
}
