$initiatives-page-text-block-max-width: 540px;
$initiatives-page-text-block-max-width-extra-large: 680px;
$initiatives-page-card-title-margin: 0 8px 10px;
$initiatives-page-leadership-attributes-card-background: linear-gradient(90deg, #FDCBF1 0%, #FFD8BF 100%);
$initiatives-page-aptitudes-card-background: linear-gradient(180deg, #FFD8CF 0%, #C2FEB2 100%);
$initiatives-page-opportunities-card-background: linear-gradient(180deg, RGB(142 48 120 / 65%) 0%, RGB(0 0 0 / 15%) 100%), linear-gradient(180deg, #6065A8 0%, RGB(92 67 161 / 84%) 100%);
$initiatives-page-lesson-planner-background: #ffe0b2;
$initiatives-page-lesson-chip-border-radius: 9px;
$initiatives-page-lesson-chip-padding: 0 8px;
$initiatives-page-lesson-chip-background: #dbc9fe;

@mixin initiatives-page-ayo-card-media($background) {
  .ayo-card__media {
    background: $background;
  }
}

.ayo-initiatives-page {
  &__text-block {
    max-width: $initiatives-page-text-block-max-width;

    @media (min-width: 1920px) {
      max-width: $initiatives-page-text-block-max-width-extra-large;
    }
  }

  &__card {
    .MuiCardHeader-root {
      position: absolute;
      right: 0;
    }

    .ayo-clickable-card__title {
      margin: $initiatives-page-card-title-margin;
    }

    &--leadership-attributes {
      @include initiatives-page-ayo-card-media($initiatives-page-leadership-attributes-card-background);
    }

    &--opportunities {
      @include initiatives-page-ayo-card-media($initiatives-page-opportunities-card-background);
    }

    &--aptitudes {
      @include initiatives-page-ayo-card-media($initiatives-page-aptitudes-card-background);
    }

    &--lesson-planner {
      @include initiatives-page-ayo-card-media($initiatives-page-lesson-planner-background);

      .ayo-chip {
        border-radius: $initiatives-page-lesson-chip-border-radius;
        background: $initiatives-page-lesson-chip-background;
        padding: $initiatives-page-lesson-chip-padding;

        @include subtitle2;
      }
    }
  }
}
