$ql-common-padding: 24px;
$ql-toolbar-border-radius: 16px 16px 0 0;
$ql-picker-label-border-radius: 8px;
$ql-picker-label-padding: 4px 8px;
$ql-picker-options-border-radius: 16px;
$ql-picker-options-padding: 24px 16px;
$ql-format-buttons-border-radius: 9px;
$ql-format-buttons-margin: 0 4px;
$ql-format-buttons-padding: 4px;
$ql-editor-border-radius: 0 0 16px 16px;
$ql-editor-padding: 16px 24px;
$ql-editor-padding-static: 0 0 24px;

.ayo-rich-text-editor {
  &__links {
    background-color: $operational-background-grey;
    border-radius: $ql-picker-options-border-radius;
  }

  &.quill {
    .ql-toolbar {
      border-radius: $ql-toolbar-border-radius;
      padding: $ql-common-padding;

      .ql-picker {
        height: auto;
      }

      .ql-picker-label {
        border-color: $operational-border;
        border-radius: $ql-picker-label-border-radius;
        color: $typography-body;
        line-height: initial;
        height: auto;
        padding: $ql-picker-label-padding;

        &[data-text]::before {
          content: attr(data-text);
        }

        &:focus {
          border-color: $primary-500;
          outline: 1px solid $primary-500;
        }

        .ql-stroke {
          stroke: $typography-body;
        }

        &[disabled="true"] {
          pointer-events: none;
          color: $typography-disabled;

          .ql-stroke {
            stroke: $typography-disabled;
          }
        }
      }

      .ql-picker-options {
        border-radius: $ql-picker-options-border-radius;
        margin-top: 0;
        padding: $ql-picker-options-padding;

        .ql-picker-item {
          border-radius: $ql-picker-label-border-radius;
          color: $typography-body;
          padding: $ql-picker-label-padding;

          &[data-text]::before {
            content: attr(data-text);
          }

          &:hover {
            background-color: $primary-050;

            &::before {
              color: $primary-500;
            }
          }

          &:focus-visible {
            background-color: $secondary-050;
            outline-color: $secondary-500;

            &::before {
              color: $primary-800;
            }
          }
        }
      }

      .ql-formats {
        button {
          border-radius: $ql-format-buttons-border-radius;
          height: auto;
          margin: $ql-format-buttons-margin;
          padding: $ql-format-buttons-padding;

          &:hover {
            cursor: pointer;

            .ql-fill {
              fill: $primary-500;
            }

            .ql-stroke {
              stroke: $primary-500;
            }
          }

          &:focus {
            outline: none;

            .ql-fill {
              fill: $primary-800;
            }

            .ql-stroke {
              stroke: $primary-800;
            }
          }

          &.ql-active {
            background-color: $primary-050;
            border-color: $primary-300;

            .ql-fill {
              fill: $primary-800;
            }
            
            .ql-stroke {
              stroke: $primary-800;
            }
          }

          &[disabled="true"] {
            pointer-events: none;

            .ql-fill {
              fill: $typography-disabled;
            }
            
            .ql-stroke {
              stroke: $typography-disabled;
            }
          }
        }
      }
    }

    .ql-container {
      border-radius: $ql-editor-border-radius;
    }

    .ql-editor {
      padding: $ql-editor-padding;

      &.ql-blank::before {
        font-style: normal;
        left: $ql-common-padding;
        right: $ql-common-padding;
      }

      ol,
      ul {
        padding-left: 0;
      }
    }

    .ql-toolbar,
    .ql-editor {
      @include body2;

      h1,
      .ql-picker-item[data-value='1']::before {
        @include h2;
      }

      h2,
      .ql-picker-item[data-value='2']::before {
        @include h3;
      }

      h3,
      .ql-picker-item[data-value='3']::before {
        @include subtitle1;
      }

      h4,
      .ql-picker-item[data-value='4']::before {
        @include subtitle2;
      }
    }

    .ql-tooltip {
      &::before,
      .ql-action::after,
      .ql-remove::before {
        content: attr(data-text);
      }

      &.ql-editing{
        &::before,
        .ql-action::after {
          content: attr(data-text-edit);
        }
      }
    }
  }

  &--static.quill {
    .ql-editor {
      padding: $ql-editor-padding-static;
    }

    .ql-toolbar {
      display: none;
    }

    .ql-container {
      border: none;
    }
  }

  &--w-error.quill {
    .ql-toolbar {
      border-left: 2px solid $operational-error;
      border-right: 2px solid $operational-error;
      border-top: 2px solid $operational-error;
    }

    .ql-container {
      border: 2px solid $operational-error;
    }

    & + .error-msg {
      color: $operational-error;
    }
  }
}

$table-popup-border-radius: 16px;
$table-popup-padding: 8px;
$table-popup-icon-min-width: 40px;
$table-popup-cell-size: 32px;
$table-popup-cell-margin: 2px;

.table-create-popup {
  background-color: transparent;

  .MuiPaper-rounded {
    border-radius: $table-popup-border-radius;
    padding: $table-popup-padding;

    .MuiList-root {
      padding: 0;

      .MuiListItemIcon-root {
        min-width: $table-popup-icon-min-width;
      }

      .MuiListItem-root {
        margin-bottom: 0;
      }
    }
  }

  &__cell {
    border: 1px solid $typography-labels-dark;
    height: $table-popup-cell-size;
    width: $table-popup-cell-size;
    margin: $table-popup-cell-margin;

    &--hovered {
      cursor: pointer;
      background-color: $primary-050;
      border: 1px solid $primary-100;
      outline: 1px solid $primary-100;
    }
  }
}
