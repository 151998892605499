$ayo-login-page-device-option-distance: 32px;
$ayo-login-page-device-option-mb: 32px;
$ayo-login-page-device-option-card-padding: 16px 24px;
$ayo-login-page-device-option-card-radio-mb: 24px;
$ayo-login-page-radio-label-correcting-margin: 12px;
$login-page-helper-footer-bg-color: RGB(243 244 245 / 75%);

.ayo-login-page {
  &__device-option-container {
    @media (min-width: 600px) {
      &:nth-child(odd) {
        padding-right: $ayo-login-page-device-option-distance / 2;
      }

      &:nth-child(even) {
        padding-left: $ayo-login-page-device-option-distance / 2;
      }
    }

    @media (max-width: 600px) {
      &:not(:last-child) {
        margin-bottom: $ayo-login-page-device-option-mb;
      }
    }
  }

  .ayo-login-page__device-option-card {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: $ayo-login-page-device-option-card-padding;
    width: auto;

    &.selected {
      outline: 2px solid $primary-200;
    }

    .ayo-form-control-label {
      margin-bottom: $ayo-login-page-device-option-card-radio-mb;

      .MuiFormControlLabel-label {
        div {
          margin-right: $ayo-login-page-radio-label-correcting-margin;
        }

        span {
          margin-left: -$ayo-login-page-radio-label-correcting-margin;
        }
      }
    }

    &__illustration {
      width: 50%;
      height: auto;
    }
  }

  .ayo-login-step {
    &__container {
      margin-bottom: 40px;
    }
  }

  .ayo-bottom-bar.helper {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: $login-page-helper-footer-bg-color;
    width: 100%;
  }
}
