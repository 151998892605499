$portfolio-main-page-card-padding: 16px;
$portfolio-main-page-card-title-margin-bottom: 8px;
$portfolio-main-page-card-title-margin-right: 12px;
$portfolio-main-page-card-stroke-width: 1.5;
$add-dialog-dot-indicator-right: -8px;
$add-dialog-dot-indicator-top: 50%;

.ayo-portfolio-main-page {
  flex: 1;

  &__title {
    color: $primary-500;
    margin-right: $portfolio-main-page-card-title-margin-right;

    &.ayo-text-with-tooltip-content {
      word-break: break-word;
    }
  }

  &__visibility-svg {
    svg * {
      @include fill-icon-element($typography-labels);

      stroke-width: $portfolio-main-page-card-stroke-width;
    }
  }

  .ayo-card .MuiCardContent-root {
    padding: $portfolio-main-page-card-padding;

  }
}

.ayo-portfolio__add-dialog {
  .ayo-dot-indicator .MuiBadge-dot.MuiBadge-anchorOriginTopRightCircular {
    right: $add-dialog-dot-indicator-right;
    top: $add-dialog-dot-indicator-top;
  }

  &--PORTFOLIO__RECOMMENDATIONS,
  &--PORTFOLIO__ESSAYS {
    .ayo-portfolio__add-dialog__title {
      @include wrapped-text(1);

      word-break: break-word;
      max-width: fit-content;
    }
  }
}

.ayo-portfolio-delete-action {
  &.MuiMenuItem-root {
    svg * {
      @include fill-icon-element($operational-error);
    }

    .ayo-typography--body2 {
      color: $operational-error;
    }

    &:hover {
      svg * {
        @include fill-icon-element($operational-error-hover);
      }

      .ayo-typography--body2 {
        color: $operational-error-hover;
      }
    }

    &:focus {
      svg * {
        @include fill-icon-element($operational-error-focus);
      }

      .ayo-typography--body2 {
        color: $operational-error-focus;
      }
    }
  }
}
