$card-title-margin-bottom: 8px;
$card-date-border-radius: 16px;

.ayo-events-block__card {
  .MuiCardContent-root {
    padding: 0;
  }

  .ayo-typography--caption {
    color: $typography-labels;
  }

  .ayo-clickable-card__title {
    margin-bottom: $card-title-margin-bottom;
    word-break: normal;
  }

  &__visibility-icon {
    svg * {
      @include fill-icon-element($primary-500);
    }
  }

  &__date {
    background-color: $operational-background-grey;
    border-radius: $card-date-border-radius;

    .ayo-typography--body3 {
      font-weight: bold;
    }
  }
}


