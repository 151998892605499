$leadership-attributes-what-is-section-text-max-width: 600px;
$leadership-attributes-what-is-section-text-max-width-mobile: 700px;

.ayo-leadership-attributes-what-is-section {
  display: flex;
  justify-content: center;

  @media (max-width: 600px) {
    flex-direction: column;
  }

  &__text-block {
    max-width: $leadership-attributes-what-is-section-text-max-width;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: 1920px) {
      max-width: $leadership-attributes-what-is-section-text-max-width-mobile;
    }
  }
}
