$alert-border-radius: 16px;
$alert-padding: 16px;
$alert-margin-bottom: 40px;
$alert-close-icon-font-size: 1.5rem;
$alert-close-button-focus-outline: 2px solid $secondary-500;
$alert-icon-margin-right: 8px;
$alert-info-severity-background-color: #404040;
$alert-padding: 16px;
$alert-action-padding: 10px 16px;
$alert-action-button-padding: 24px 0 0 32px;
$alert-grid-template-columns: 36px auto;

.ayo-alert {
  border-radius: $alert-border-radius;
  padding: $alert-padding;
  margin-bottom: $alert-margin-bottom;

  .MuiAlert-action {
    align-items: start;
    margin-right: 0;

    .MuiSvgIcon-fontSizeSmall {
      font-size: $alert-close-icon-font-size;
    }
  }

  .MuiTouchRipple-root {
    display: none;
  }

  .Mui-focusVisible {
    outline: $alert-close-button-focus-outline;
    background-color: $secondary-050;
    color: $primary-800;

    svg * {
      @include fill-icon-element($primary-800);
    }
  }

  .MuiAlert-message, .MuiAlert-icon {
    padding: 0;
  }

  .MuiAlert-icon {
    margin-right: $alert-icon-margin-right;
  }

  &__title {
    margin-bottom: 0;
    display: initial;
  }

  &--info {
    color: $typography-white;
    background-color: $alert-info-severity-background-color;

    .ayo-typography--caption {
      color: $typography-white;
    }
  }

  &--warning {
    color: $typography-body;
    background-color: $secondary-050;

    .ayo-typography--caption {
      color: $typography-body;
    }
  }

  &.action {
    padding: $alert-action-padding;

    .MuiAlert-message {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    @media (max-width: 600px) {
      padding: $alert-padding;
      display: grid;
      grid-template-columns: $alert-grid-template-columns;

      .MuiAlert-action {
        padding: $alert-action-button-padding;
        min-width: max-content;
      }
    }
  }
}

.ayo-alert-wrapper {
  .MuiCollapse-wrapperInner {
    width: auto;

    @media (max-width: 600px) {
      width: 100%;
    }
  }
}
