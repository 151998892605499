$date-display-size: 36px;
$date-display-size-xl: 48px;
$date-display-ml: -8px;

.calendar-date-display {
  .date-display {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: $date-display-size;
    height: $date-display-size;
    margin-left: $date-display-ml;
    border-radius: 50%;

    @media (min-width: 1920px) {
      width: $date-display-size-xl;
      height: $date-display-size-xl;
    }

    &.today {
      color: $typography-white;
      background-color: $primary-500;
    }
  }

  &--clickable {
    cursor: pointer;

    .date-display {
      color: $primary-500;
    }

    &:focus {
      outline: none;

      .date-display {
        color: $primary-800;

        &.today {
          color: $secondary-500;
        }
      }
    }

    &:hover {
      .date-display.today {
        color: $primary-100;
      }
    }
  }
}
