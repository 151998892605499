$card-focus-outline-size: 2px;
$card-active-indicator-width: 6px;
$card-active-indicator-height: 100%;
$card-hotspot-margin-top: -2px;
$actions-menu-button-size: 40px;
$actions-card-w-buttons-height: 145px;
$actions-card-w-buttons-height-large: 190px;

.ayo-clickable-card {
  position: relative;
  display: block;
  text-decoration: none;
  cursor: pointer;

  &__content-container {
    max-width: 100%;

    &--w-status-icon {
      max-width: 85%;
    }

    .ayo-hotspot {
      margin-top: $card-hotspot-margin-top;
    }
  }

  &__title {
    color: $primary-500;
  }

  &__leftRibbon {
    position: absolute;
    background-color: $data-visualization-green-090-100;
    width: $card-active-indicator-width;
    height: $card-active-indicator-height;
  }

  &:hover {
    background-color: $primary-050;
    outline: $card-focus-outline-size solid $primary-100;
  }

  &:focus {
    background-color: $secondary-050;
    outline: $card-focus-outline-size solid $secondary-500;

    .ayo-clickable-card__title {
      color: $primary-800;
    }
  }

  &.ayo-clickable-card--w-action-button {
    &.ayo-card .MuiCardContent-root {
      min-height: $actions-card-w-buttons-height;

      @media (min-width: 1920px) {
        min-height: $actions-card-w-buttons-height-large;
      }
    }
  }

  &.ayo-clickable-card--inactive {
    background-color: $operational-surface;
    box-shadow: none;
    cursor: default;
    outline: none;

    .ayo-clickable-card__title {
      color: $typography-titles;
    }
  }

  &.ayo-clickable-card--disabled {
    background-color: $operational-disabled-bg;
    box-shadow: none;
    cursor: initial;
    outline: $card-focus-outline-size solid $operational-border;

    .ayo-clickable-card__title {
      color: $typography-disabled;
    }

    svg {
      opacity: 0.75;
    }
  }

  .ayo-linear-progress {
    background-color: transparent;
  }

  &__wrapped-text-tooltip {
    margin: 0;
  }
}

.ayo-clickable-card-container {
  position: relative;

  .actions-spacer {
    width: $actions-menu-button-size;
    flex-shrink: 0;
  }

  .actions-container {
    position: absolute;
    top: $card-content-padding-y;
    right: $card-content-padding-x;

    button {
      width: $actions-menu-button-size;
      height: $actions-menu-button-size;
    }
  }

  .action-button-container {
    position: absolute;
    bottom: $card-content-padding-y;
    left: $card-content-padding-x;
  }
}
