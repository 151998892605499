$top-bar-pt: 16px;
$top-bar-pb: 24px;
$top-bar-min-height: 48px;
$top-bar-back-link-ml: 72px;
$top-bar-hotspot-offset: -18px;

.ayo-top-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: $top-bar-pt;
  padding-bottom: $top-bar-pb;
  min-height: $top-bar-min-height;

  a + .ayo-back-navigation-link {
    margin-left: $top-bar-back-link-ml;
  }

  .ayo-notifications-buttons-container .ayo-hotspot {
    margin-left: $top-bar-hotspot-offset;
  }
}
