$text-button-padding: 8px 16px;

.ayo-negative-trend-alert {
  max-width: 100%;

  .ayo-button--text {
    outline: none;
    padding: $text-button-padding;
    color: $typography-white;

    &:hover, &:focus, &.Mui-active {
      color: $primary-500;
    }
  }

  &.action {
    .MuiAlert-icon {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    @media (max-width: 600px) {
      .MuiAlert-icon {
        justify-content: flex-start;
      }
    }
  }
}
