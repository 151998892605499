$rotation-block-outline: 2px;
$rotation-block-item-padding: 10px 19px;
$rotation-block-item-padding-lg: 10px 23px;

.ayo-rotation-block {
  .MuiRating-iconFilled {
    background-color: $primary-100;
    outline: $rotation-block-outline solid $primary-300;
  }

  .MuiRating-iconHover {
    outline: none;
    background-color: $primary-100;
    transform: none;
  }

  &__item {
    transform: none;
    background-color: $primary-050;
    padding: $rotation-block-item-padding;
    border-radius: 90%;

    @media (min-width: 1920px) {
      padding: $rotation-block-item-padding-lg;
    }
  }
}
