$ayo-editing-button-icon-size: 24px;
$ayo-editing-button-mx: 8px;

.ayo-editing-button {
  all: unset;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  .ayo-typography--body2 {
    color: $primary-500;
  }

  svg {
    height: $ayo-editing-button-icon-size;
    width: $ayo-editing-button-icon-size;

    &:first-child {
      margin-right: $ayo-editing-button-mx;
    }

    &:last-child {
      margin-left: $ayo-editing-button-mx;
    }
  }

  &:focus-visible {
    outline: none;

    .ayo-typography--body2 {
      color: $primary-900;
    }

    svg path {
      @include fill-icon-element($primary-900);
    }
  }

  &.Mui-disabled {
    cursor: default;

    * {
      color: $typography-disabled;
    }

    svg * {
      @include fill-icon-element($typography-disabled);
    }
  }
}

.MuiAutocomplete-paper {
  box-shadow: 0 1px 6px 1px RGB(30 32 64 / 25%);

  &:empty {
    box-shadow: none;
  }
}
