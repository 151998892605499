$extracurriculars-autocomplete-popper-min-width: 260px;
$extracurriculars-autocomplete-popper-mt: 8px;
$extracurriculars-card-padding: 24px;

.ayo-extracurricular-year-card {
  .MuiCardContent-root {
    padding: $extracurriculars-card-padding;
  }

  .ayo-input-chip {
    &-autocomplete {
      display: inline-block;
      max-width: 100%;
    }

    .MuiChip-label p {
      text-overflow: initial;
    }

    &-textfield {
      &:focus {
        outline: none;
      }
    }
  }
}

.ayo-input-chip-autocomplete-popper {
  min-width: $extracurriculars-autocomplete-popper-min-width;
  margin-top: $extracurriculars-autocomplete-popper-mt;
  z-index: 3;
}