.ayo-lesson-card {
  text-align: left;

  .ayo-lesson-status-badge {
    margin: 0;
  }

  .ayo-typography--subtitle2 {
    word-break: break-word;
  }

  &--draft {
    .ayo-typography--subtitle2 {
      color: $typography-labels;
    }
  }
}
