$numericfield-padding-x: 8px;

.ayo-textfield.ayo-numericfield {
  align-items: center;

  .MuiOutlinedInput-root {
    padding: 0 $numericfield-padding-x;

    .MuiInputBase-input {
      text-align: center;
      padding-left: 0;
      padding-right: 0;
    }

    .ayo-icon-button {
      margin-right: 0;
    }
  }

  .MuiFormHelperText-root {
    &.Mui-error {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
