$delete-dialog-max-width: 600px;
$delete-dialog-max-width-large: 750px;
$delete-dialog-first-name-max-width: 100%;

.ayo-delete-dialog {
  max-width: $delete-dialog-max-width;

  @media (min-width: 1400px) {
    max-width: $delete-dialog-max-width-large;
  }

  &__firstName {
    display: inline-block;
    max-width: $delete-dialog-first-name-max-width;
    vertical-align: top;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .MuiDialogActions-root {
    justify-content: flex-end;
  }
}
