$bottom-bar-horizontal-divider-width: 100%;
$bottom-bar-horizontal-divider-margin: 16px 0 0;
$bottom-bar-vertical-divider-width: 2px;
$bottom-bar-vertical-divider-height: 10px;
$bottom-bar-link-icon-pl: 8px;

.ayo-bottom-bar {
  background-color: $operational-background;
  box-sizing: border-box;
  display: block;

  &--simple {
    @media (min-width: 960px) {
      background-color: transparent;
    }
  }

  &__divider {
    background-color: $operational-border-alpha;

    &--horizontal {
      position: relative;
      width: $bottom-bar-horizontal-divider-width;
      margin: $bottom-bar-horizontal-divider-margin;
    }

    &--vertical {
      height: $bottom-bar-vertical-divider-height;
      width: $bottom-bar-vertical-divider-width;
    }
  }

  &__google-logo {
    display: block;
  }

  &__softserve-logo {
    display: block;
  }

  &__link-icon {
    cursor: pointer;
    padding-left: $bottom-bar-link-icon-pl;
  }
}
