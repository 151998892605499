$ayo-lesson-assignment-action-buttons-divider-height: 19px;
$ayo-lesson-assignment-action-buttons-divider-margin: 0 16px;

.ayo-lesson-assignment-action-buttons {
  &__divider {
    height: $ayo-lesson-assignment-action-buttons-divider-height;
    align-self: center;
    margin: $ayo-lesson-assignment-action-buttons-divider-margin;
  }
}
