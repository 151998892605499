$expandable-menu-border-radius: 9px;
$expandable-menu-shadow: 0 1px 6px 1px RGB(30 32 64 / 25%);
$expandable-menu-width: 224px;
$expandable-menu-button-size: 48px;
$expandable-menu-item-margin: 8px;
$expandable-menu-item-padding: 12px 16px;
$expandable-menu-item-border-radius: 12px;
$expandable-menu-wrapper-padding: 0;

.ayo-expandable-menu {
  border-radius: $expandable-menu-border-radius;
  box-shadow: $expandable-menu-shadow;
  min-width: $expandable-menu-width;

  .MuiIconButton-root {
    width: $expandable-menu-button-size;
    height: $expandable-menu-button-size;
  }

  .MuiMenuItem-root {
    margin: $expandable-menu-item-margin;
    padding: $expandable-menu-item-padding;
    border-radius: $expandable-menu-item-border-radius;

    svg:not(.no-recolor) *:not(.not-recolor) {
      @include fill-icon-element($typography-body);
    }

    &:hover {
      background-color: $primary-050;

      .ayo-typography--body2 {
        color: $primary-500;
      }

      svg:not(.no-recolor) *:not(.not-recolor) {
        @include fill-icon-element($primary-500);
      }
    }
  }

  &--popover {
    background-color: transparent;
  }

  .MuiList-root {
    padding: $expandable-menu-wrapper-padding;
  }
}
