$bricks-image-height: 120%;
$bricks-image-margin-bottom-xs: -60px;
$bricks-image-position-left-xs: -20px;
$bricks-image-position-right-md: -20px;
$bricks-image-position-top-md: 10px;
$bricks-image-position-top: -32px;
$bricks-image-width-xs: 110%;
$container-border-radius: 32px;
$container-margin-top-xs: -60px;
$teacher-image-position-bottom: 40px;
$teacher-image-position-top-xs: -60px;
$teacher-image-top-width: 90%;

.ayo-lesson-planner-landing__for-who-section {
  &__container {
    background-color: $primary-050;
    border-radius: $container-border-radius;

    &__content {
      @media (max-width: 600px) {
        margin-top: $container-margin-top-xs;
      }
    }

    &__teacher {
      position: relative;

      img {
        width: $teacher-image-top-width;
        height: auto;
        position: absolute;
        bottom: $teacher-image-position-bottom;

        @media (max-width: 960px) {
          position: inherit;
          bottom: 0;
        }

        @media (max-width: 600px) {
          position: inherit;
          width: 100%;
          height: auto;
          top: $teacher-image-position-top-xs;
        }
      }
    }

    &__bricks {
      position: relative;

      @media (max-width: 600px) {
        margin-bottom: $bricks-image-margin-bottom-xs;
      }

      img {
        height: $bricks-image-height;
        width: auto;
        position: absolute;
        right: 0;
        top: $bricks-image-position-top;

        @media (max-width: 960px) {
          height: 100%;
          top: $bricks-image-position-top-md;
          right: $bricks-image-position-right-md;
        }

        @media (max-width: 600px) {
          position: inherit;
          width: $bricks-image-width-xs;
          height: auto;
          left: $bricks-image-position-left-xs;
        }
      }
    }
  }
}
