$family-activity-card-content-padding: 8px 8px 16px;
$family-activity-card-content-padding-md: 16px;
$family-activity-card-media-border-radius: 16px;
$family-activity-card-media-height: 80px;
$family-activity-card-media-height-md: 120px;
$family-activity-card-media-height-xl: 160px;
$family-activity-card-media-background-color: RGB(202 217 255);
$family-activity-card-chip-indent: 8px;
$family-activity-card-chip-indent-md: 16px;
$suggested-family-activity-card-preview-media-content-padding: 16px;

.family-activity-card {
  .ayo-card__main-content.MuiCardContent-root {
    padding: $family-activity-card-content-padding;
    text-align: left;
  }

  &__title {
    word-break: normal;
    color: $primary-500;
    font-weight: 700;
  }

  &__description {
    @include wrapped-text(3);
  }

  .ayo-card__media {
    border-radius: $family-activity-card-media-border-radius;
    height: $family-activity-card-media-height;

    @media (min-width: 960px) {
      height: $family-activity-card-media-height-md;
    }

    @media (min-width: 1920px) {
      height: $family-activity-card-media-height-xl;
    }
  }

  &--suggested {
    .family-activity-card__title {
      color: $primary-800;
    }

    &__chip {
      position: absolute;
      top: $family-activity-card-chip-indent;
      right: $family-activity-card-chip-indent;
    }
  }

  &--md {
    .ayo-card__main-content.MuiCardContent-root {
      padding: $family-activity-card-content-padding-md;
    }

    .family-activity-card {
      &__title {
        color: $primary-800;
      }

      &--suggested {
        &__chip {
          top: $family-activity-card-chip-indent-md;
          right: $family-activity-card-chip-indent-md;
        }
      }
    }
  }
}

.suggested-family-activity-card-preview {
  display: flex;

  &__title {
    color: $primary-800;
  }

  .ayo-card {
    &__media {
      width: 30%;
      border-radius: $family-activity-card-media-border-radius;
    }

    &__main-content {
      padding: $suggested-family-activity-card-preview-media-content-padding;
    }
  }
}

.suggested-family-activity-background {
  background-color: $secondary-100;
}

.family-activity-card-default-image {
  .ayo-card__media {
    min-width: 30%;
    background-color: $family-activity-card-media-background-color;
    background-image: url('../../../../../resources/images/family_interest_default_image.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 80%;
  }
}
