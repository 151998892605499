$badges-dialog-text-border-radius: 16px;
$badges-dialog-image-svg-dimentions: 136px;
$badges-dialog-image-svg-dimentions-small: 64px;
$badges-dialog-image-margin: 8px;
$badges-dialog-text-padding: 16px;
$badges-dialog-date-max-width: 112px;
$badges-dialog-school-max-width: 130px;
$badges-dialog-accordion-padding: 16px;
$badges-dialog-accordion-box-shadow: 0 4px 8px 2px RGB(0 0 0 / 6%);
$badges-dialog-accordion-margin-bottom: 16px;
$badges-dialog-modal-width-large: 624px;
$badges-dialog-container-padding: 56px 0 40px 0;
$badges-dialog-container-padding-mobile: 56px 0 16px 0;
$badges-dialog-achieved-container-padding: 56px 0 0;

.ayo-badges-dialog {
  &__badge-image {
    display: flex;
    justify-content: center;
    margin-bottom: $badges-dialog-image-margin;

    svg {
      width: $badges-dialog-image-svg-dimentions-small;
      height: $badges-dialog-image-svg-dimentions-small;
    }

    &--achieved {
      svg {
        width: $badges-dialog-image-svg-dimentions;
        height: $badges-dialog-image-svg-dimentions;
      }
    }

    @media (max-width: 960px) {
     margin-top: $badges-dialog-image-margin;
    }
  }

  &__text {
    background-color: $operational-background-grey;
    border-radius: $badges-dialog-text-border-radius;
    padding: $badges-dialog-text-padding;
  }

  &__date {
    @media (max-width: 960px) {
      max-width: $badges-dialog-date-max-width;
      text-align: end;
    }
  }

  &__school {
    @media (max-width: 960px) {
      max-width: $badges-dialog-school-max-width;
      text-align: start;
    }
  }

  &__accordion {
    box-shadow: $badges-dialog-accordion-box-shadow;
  }

  &--achieved {
    .ayo-dialog__container {
      background-image: url("../../../../../resources/images/badges/background-stars.svg");
      background-repeat: no-repeat;
      background-size: contain;

      @media (max-width: 960px) {
        padding: $badges-dialog-achieved-container-padding;
      }
    }
  }

  .ayo-dialog__container {
    padding: $badges-dialog-container-padding;

    @media (min-width: 960px) {
      min-width: $badges-dialog-modal-width-large;
      max-width: $badges-dialog-modal-width-large;
    }

    @media (max-width: 960px) {
      min-width: 100%;
      padding: $badges-dialog-container-padding-mobile;
    }
  }

  .MuiDialogContent-root {
    padding: 0;
    width: auto;
  }

  .ayo-accordion .MuiAccordionDetails-root {
    padding: $badges-dialog-accordion-padding;
  }

  .MuiAccordion-rounded {
    margin-bottom: $badges-dialog-accordion-margin-bottom;
  }

  &.MuiDialog-paperFullWidth {
    @media (max-width: 960px) {
      width: 100%;
    }
  }

  .MuiAccordionSummary-content {
    display: block;
  }

  .MuiDialogTitle-root {
    width: auto;
  }

  ::-webkit-scrollbar{
    width: $scrollbar-width-sm;
  }
}
