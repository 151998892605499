@import '../../../../node_modules/react-image-gallery/styles/scss/image-gallery';

$preview-gallery-overlay-color: RGB(0 0 0 / 95%);
$preview-gallery-icon-size: 24px;
$preview-gallery-close-btn-offset: 20px;
$preview-gallery-back-btn-bottom: 40px;
$preview-gallery-back-btn-left: 324px;
$preview-gallery-back-btn-min-width: 250px;
$preview-gallery-slide-wrapper-ml: 238px;
$preview-gallery-slide-wrapper-width: calc(100% - 270px);
$preview-gallery-slide-height: calc(100vh - 168px);
$preview-gallery-slide-mt: 50px;
$preview-gallery-image-mt: 80px;
$preview-gallery-thumbnails-wrapper-width: 206px;
$preview-gallery-thumbnails-bg-color: #3b3b3b;
$preview-gallery-thumbnails-height: 100vh !important; // needing to override inline style
$preview-gallery-thumbnail-margin: 24px auto;
$preview-gallery-thumbnail-width: 160px;
$preview-gallery-thumbnail-border: 1px solid #53555a;
$preview-gallery-thumbnail-border-radius: 12px;
$preview-gallery-thumbnail-padding: 20px 55px;
$preview-gallery-thumbnail-active-bg-color: #7e7f80;

.ayo-preview-gallery {
  position: fixed;
  inset: 0;
  z-index: 1401;
  background-color: $preview-gallery-overlay-color;

  &--hidden {
    display: none;
  }

  & img {
    margin-top: $preview-gallery-image-mt;
  }

  &--action-buttons__desktop {
    position: absolute;
    bottom: $preview-gallery-back-btn-bottom;
    left: $preview-gallery-back-btn-left;
    z-index: 1;
  }

  &--action-buttons__mobile {
    z-index: 1;
  }

  &__back-button {
    min-width: $preview-gallery-back-btn-min-width;
  }

  &__reject-button {
    min-width: $preview-gallery-back-btn-min-width;
  }

  &__close-button {
    position: absolute;
    right: $preview-gallery-close-btn-offset;
    top: $preview-gallery-close-btn-offset;
    z-index: 1;

    svg {
      height: $preview-gallery-icon-size;
      width: $preview-gallery-icon-size;

      * {
        @include fill-icon-element($typography-white);
      }
    }

    &:hover svg * {
      @include fill-icon-element($primary-500);
    }
  }

  .image-gallery-slide-wrapper.left {
    margin-left: $preview-gallery-slide-wrapper-ml;
    width: $preview-gallery-slide-wrapper-width;

    .image-gallery-slide {
      height: $preview-gallery-slide-height;
      margin-top: $preview-gallery-slide-mt;
    }

    .image-gallery-left-nav,
    .image-gallery-right-nav {
      svg {
        height: $preview-gallery-icon-size;
        width: auto;
      }
    }
  }

  .image-gallery-thumbnails-wrapper.left {
    width: $preview-gallery-thumbnails-wrapper-width;

    .image-gallery-thumbnails {
      background-color: $preview-gallery-thumbnails-bg-color;
      height: $preview-gallery-thumbnails-height;
      overflow-y: auto;

      .image-gallery-thumbnails-container {
        transform: none !important;
      }

      .image-gallery-thumbnail {
        border-width: 0;
        margin: $preview-gallery-thumbnail-margin;
        width: $preview-gallery-thumbnail-width;

        svg {
          border: $preview-gallery-thumbnail-border;
          border-radius: $preview-gallery-thumbnail-border-radius;
          padding: $preview-gallery-thumbnail-padding;
        }

        .ayo-typography--subtitle2 {
          color: $typography-white;
        }

        &.active svg {
          background-color: $preview-gallery-thumbnail-active-bg-color;
        }
      }
    }
  }
}
