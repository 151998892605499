$ayo-dynamic-auto-rows: 1px;

.ayo-dynamic-grid {
  &--masonry {
    overflow: hidden;
    display: grid;
    grid-auto-rows: $ayo-dynamic-auto-rows;
    justify-content: center;
  }
}
