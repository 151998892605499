$ayo-get-help-card-border-radius: 16px;
$ayo-get-help-card-max-width: 456px;
$ayo-get-help-card-padding: 24px;
$ayo-get-help-card-paragraph-max-width: 68%;
$ayo-get-help-card-background-position: center right 24px;
$ayo-get-help-card-need-to-talk-background-position: bottom right 24px;
$ayo-get-help-card-need-to-talk-background-size: 164px;

.ayo-get-help-card {
  background-color: $operational-surface;
  background-image: url('../../../resources/images/moods/all_moods.svg');
  background-repeat: no-repeat;
  background-position: $ayo-get-help-card-background-position;
  border-radius: $ayo-get-help-card-border-radius;
  max-width: $ayo-get-help-card-max-width;
  padding: $ayo-get-help-card-padding;

  &__paragraph {
    max-width: $ayo-get-help-card-paragraph-max-width;
  }

  &--need-to-talk {
    background-image: url('../../../resources/images/moods/support_girl.svg');
    background-position: $ayo-get-help-card-need-to-talk-background-position;
    background-size: $ayo-get-help-card-need-to-talk-background-size;

    &-sm {
      background-image: none;

      .ayo-get-help-card__paragraph {
        max-width: 100%;
      }
    }
  }

  &__link {
    svg path {
      @include fill-icon-element($primary-500);
    }
  }
}
