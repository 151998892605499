$ayo-aptitudes-gallery-section-min-height: 100vh;
$ayo-aptitudes-gallery-section-background-color: #3B3355;
$ayo-aptitudes-gallery-section-informational-message-box-shadow: 0 8px 12px 4px RGB(0 0 0 / 6%);
$ayo-aptitudes-gallery-section-cards-container-margin: 0 -16px;
$ayo-aptitudes-gallery-section-cards-container-margin-sm: 0 -8px;
$ayo-aptitudes-gallery-section-card-margin: 12px;
$ayo-aptitudes-gallery-section-card-margin-sm: 8px;
$ayo-aptitudes-gallery-section-card-media-padding-top: 18px;
$ayo-aptitudes-gallery-section-card-main-content-padding-top: 8px;

.ayo-aptitudes-gallery-section {
  background-image: url("../../../../../../resources/images/aptitudes/landing-page/aptitudes-gallery-bg.svg");
  min-height: $ayo-aptitudes-gallery-section-min-height;
  display: flex;
  align-items: center;
  background-color: $ayo-aptitudes-gallery-section-background-color;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: bottom;

  .ayo-informational-message__description {
    box-shadow: $ayo-aptitudes-gallery-section-informational-message-box-shadow;
  }

  &__cards-container {
    margin: $ayo-aptitudes-gallery-section-cards-container-margin;

    @media (max-width: 600px) {
      margin: $ayo-aptitudes-gallery-section-cards-container-margin-sm;
    }

    &__card {
      max-width: 16.667%;

      @media (min-width: 1920px) {
        max-width: 20%;
      }

      @media (max-width: 1280px) {
        max-width: 20%;
      }

      @media (max-width: 960px) {
        max-width: 25%;
      }

      @media (max-width: 600px) {
        max-width: 50%;
        min-width: 50%;
      }

      .ayo-card__media {
        background-size: 60% auto;
        background-position: left;
      }
    }

    .ayo-clickable-gallery-item .ayo-card {
      background-image: url("../../../../../../resources/images/aptitudes/landing-page/gallery-card-bg.svg");
      background-size: cover;
      align-items: center;
      margin: $ayo-aptitudes-gallery-section-card-margin;

      .ayo-card__media {
        padding-top: $ayo-aptitudes-gallery-section-card-media-padding-top;
        height: 50%;
        width: 45%;
      }

      &__main-content {
        box-sizing: content-box;
        width: 80%;
        padding-top: $ayo-aptitudes-gallery-section-card-main-content-padding-top;
      }

      @media (max-width: 960px) {
        margin: $ayo-aptitudes-gallery-section-card-margin-sm;
      }
    }

    .ayo-clickable-card__title {
      text-align: center;
      width: 100%;
    }
  }
}
