$students-list-page-description-shadow-position: 0 1px 6px 1px;
$students-list-page-description-border-radius: 16px;
$students-list-page-description-padding: 14px 16px;
$students-list-page-message-padding: 8px 16px;
$students-list-page-message-padding-mobile: 16px 0;
$students-list-page-description-py-desktop-large: 18px;
$students-list-page-description-width-small: 100%;
$students-list-page-description-button-px: 16px;
$students-list-page-description-button-height: 25px;

.ayo-informational-message {
  &__description {
    background-color: $operational-surface;
    box-shadow: $students-list-page-description-shadow-position $operational-border;
    border-radius: $students-list-page-description-border-radius;
    width: fit-content;
    padding: $students-list-page-description-padding;

    @media (min-width: 1920px) {
      padding-top: $students-list-page-description-py-desktop-large;
      padding-bottom: $students-list-page-description-py-desktop-large;
    }

    @media (max-width: 960px) {
      width: $students-list-page-description-width-small;
    }

    &__button {
      padding-left: $students-list-page-description-button-px;
      padding-right: $students-list-page-description-button-px;
      min-width: fit-content;
      height: $students-list-page-description-button-height;
    }

    &__indicator {
      font-style: normal;
      font-weight: bold;
      letter-spacing: 0;
      color: $data-visualization-green-090-100;
    }
  }
}
