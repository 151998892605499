$leadership-attributes-dialog-text-border-radius: 9px;
$leadership-attributes-dialog-text-padding: 16px;
$leadership-attributes-dialog-actions-padding-top: 48px;
$leadership-attributes-dialog-content-padding-right: 20px;
$leadership-attributes-dialog-content-padding-right-mobile: 12px;
$leadership-attributes-dialog-content-padding-right-mobile: 12px;
$leadership-attributes-dialog-modal-width: 624px;

.ayo-leadership-attributes-dialog {
  &--text {
    background-color: #fafaff;
    border-radius: $leadership-attributes-dialog-text-border-radius;
    padding: $leadership-attributes-dialog-text-padding;
  }

  .MuiDialogActions-root {
    padding-top: $leadership-attributes-dialog-actions-padding-top;
    justify-content: space-between;
  }

  .MuiDialogContent-root {
    border-radius: $leadership-attributes-dialog-text-border-radius;
    padding-right: $leadership-attributes-dialog-content-padding-right;

    @media (max-width: 600px) {
      padding-right: $leadership-attributes-dialog-content-padding-right-mobile;
    }
  }

  .ayo-dialog__container {
    @media (min-width: 1280px) {
      min-width: $leadership-attributes-dialog-modal-width;
      max-width: $leadership-attributes-dialog-modal-width;
    }

    @media (max-width: 600px) {
      min-width: auto;
    }
  }
}
