$assignments-insights-card-padding: 16px;
$assignments-insights-card-stats-gap: 8px;
$assignments-insights-card-illustration-size: 48px;
$assignments-insights-card-illustration-mb: 8px;
$assignments-insights-card-recommended-badge-py: 3px;
$assignments-insights-card-recommended-badge-br: 6px;

.ayo-assignments-insights-card {
  padding: $assignments-insights-card-padding;
  box-sizing: border-box;
  position: relative;
  overflow: visible;

  &__suggested-badge {
    background-color: $secondary-900;
    color: $typography-white;
    position: absolute;
    top: 0;
    left: $assignments-insights-card-padding;
    transform: translateY(-50%);
    margin: 0;
    padding-top: $assignments-insights-card-recommended-badge-py;
    padding-bottom: $assignments-insights-card-recommended-badge-py;
    border-radius: $assignments-insights-card-recommended-badge-br;
  }

  &__name {
    color: $primary-500;
  }

  &__stats {
    display: flex;
    gap: $assignments-insights-card-stats-gap;
    align-items: center;
  }

  svg.MuiCardMedia-root {
    width: $assignments-insights-card-illustration-size;
    height: $assignments-insights-card-illustration-size;
    margin-bottom: $assignments-insights-card-illustration-mb;
  }

  .ayo-card__main-content {
    padding: 0;
  }
}
