$ayo-dialog-container-min-width: 800px;
$ayo-dialog-container-min-width-large: 770px;
$ayo-dialog-container-max-width: 800px;
$ayo-dialog-container-content-max-height: 416px;
$ayo-dialog-container-content-padding: 16px;
$ayo-dialog-container-content-background-color: RGB(243 244 245 / 65%);
$ayo-dialog-container-content-margin-top: 32px;
$ayo-dialog-container-content-border-radius: 16px;
$ayo-dialog-container-editable-field-border-radius: 16px;
$ayo-dialog-container-editable-field-padding: 14px 16px;
$ayo-dialog-container-name-padding: 14px 24px 0 0;
$ayo-dialog-buttons-max-width: 80%;

.ayo-translation-edit-dialog {
  .ayo-dialog__container {
    min-width: $ayo-dialog-container-min-width;
    max-width: $ayo-dialog-container-max-width;

    @media (min-width: 1920px) {
      min-width: $ayo-dialog-container-min-width-large;
    }
  }

  &__buttons {
    max-width: $ayo-dialog-buttons-max-width;
  }

  &__name{
    padding: $ayo-dialog-container-name-padding;
  }

  &__content {
    max-height: $ayo-dialog-container-content-max-height;
    overflow-x: auto;
    padding: $ayo-dialog-container-content-padding;
    background-color: $ayo-dialog-container-content-background-color;
    border-radius: $ayo-dialog-container-content-border-radius;

    .MuiGrid-container {
      margin-top: $ayo-dialog-container-content-margin-top;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  &__editable-field {
    &__name {
      &.ayo-textfield {
        .MuiOutlinedInput-root {
          .MuiInputBase-input {
            @include subtitle2;
          }
        }
      }
    }

    &__description {
      &.ayo-textfield {
        .MuiOutlinedInput-root {
          .MuiInputBase-input {
            @include body2;
          }
        }
      }
    }

    .ayo-textfield--w-bottom-space {
      margin-bottom: 0;
    }
  }
}
