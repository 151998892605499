.grade-accordion {
  background: transparent;

  .MuiAccordionDetails-root {
    padding: 0;
  }

  &.ayo-accordion .ayo-accordion__summary {
    .ayo-accordion__open-icon {
      circle {
        fill: $operational-surface;
      }
    }
  }
}