$dialog-container-min-width: calc(1032px - 48px);
$dialog-container-min-width-md: calc(100vw - 96px);
$dialog-container-padding: 24px;
$text-editor-height: 286px;
$text-editor-border-radius: 16px;
$request-block-padding: 16px;
$request-block-border-radius: 16px;
$request-block-subtitle-margin-bottom: 24px;

.ayo-portfolio__add-recommendation-dialog {
  .ayo-dialog__container {
    min-width: $dialog-container-min-width;
    padding: $dialog-container-padding;

    @media (max-width: 1032px) {
      min-width: $dialog-container-min-width-md;
    }
  }

  &__request-block {
    padding: $request-block-padding;
    min-height: 100%;
    width: 50%;
    background-color: $operational-background;
    border-radius: $request-block-border-radius;

    .ayo-portfolio-attachments {
      max-width: 60%;

      .ayo-typography--subtitle2 {
        margin-bottom: $request-block-subtitle-margin-bottom;
      }
    }
  }


  &__text-editor-wrapper {
    background-color: $operational-background;
    border-radius: $text-editor-border-radius;

    .ayo-rich-text-editor {
      background-color: $operational-surface;
      border-radius: $text-editor-border-radius;
      border: none;

      .ql-container,
      .ql-toolbar {
        border: none;
      }

      .ql-toolbar {
        border-bottom: 1px solid $operational-border;
      }

      .ql-editor {
        height: $text-editor-height;
      }
    }
  }
}
