$text-badge-border-radius: 16px;
$text-badge-line-height: 1.5rem;
$text-badge-ml: 16px;
$text-badge-padding: 0 8px;

.ayo-text-badge {
  background-color: $data-vizualization-red;
  border-radius: $text-badge-border-radius;
  margin-left: $text-badge-ml;
  padding: $text-badge-padding;

  .ayo-typography--body3 {
    color: $typography-white;
    line-height: $text-badge-line-height;
  }
}
