.ayo-opportunities-goal-section {
  background-image: url("../../../../../../resources/images/opportunities/landing-page/opportunities-goal-bg-desktop.svg");
  background-size: cover;

  @media (max-width: 600px) {
    background-position: center;
    background-image: url("../../../../../../resources/images/opportunities/landing-page/opportunities-goal-bg-mobile.svg");
  }
}

