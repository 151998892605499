$ayo-error-wrapper-outline: 2px solid $operational-error;
$ayo-error-wrapper-label-pt: 4px;
$ayo-error-wrapper-label-position-top: 100%;

.ayo-error-wrapper {
  position: relative;

  &--error > :first-child {
    outline: $ayo-error-wrapper-outline;
  }

  &__label {
    padding-top: $ayo-error-wrapper-label-pt;
    color: $operational-error;

    &--absolute {
      position: absolute;
      top: $ayo-error-wrapper-label-position-top;
    }
  }
}
