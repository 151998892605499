$feedback-popup-close-btn-pos: 8px;
$feedback-popup-close-btn-padding: 6px;
$feedback-popup-border-radius: 16px;
$feedback-popup-padding: 56px 16px 16px 16px;
$feedback-popup-success-padding: 16px 16px 0 16px;
$feedback-popup-slider-min-width: 320px;
$feedback-popup-slider-thumb-shadow: 0 1px 6px 1px RGB(30 32 64 / 25%);
$feedback-popup-slider-thumb-size-default: 24px;
$feedback-popup-slider-thumb-size-icon: 52px;
$feedback-popup-slider-thumb-focus-size: calc(100% + 10px);
$feedback-popup-slider-thumb-focus-offset: -5px;
$feedback-popup-slider-thumb-focus-opacity: 0.35;
$feedback-popup-dark-bg-color: #4a4263;

.ayo-feedback-popup {
  left: 50%;
  right: auto;
  z-index: 1199;

  &__close {
    height: initial;
    min-width: initial;
    width: initial;
    position: absolute;
    top: $feedback-popup-close-btn-pos;
    right: $feedback-popup-close-btn-pos;

    span {
      padding: $feedback-popup-close-btn-padding;
    }
  }

  .MuiPaper-root {
    background-color: $operational-surface;
    border-radius: $feedback-popup-border-radius;
    min-width: initial;
    padding: $feedback-popup-padding;
  }

  .MuiSnackbarContent-message {
    padding: 0;
  }

  .ayo-slider {
    margin-top: 0;
    min-width: $feedback-popup-slider-min-width;

    .MuiSlider-thumb {
      background-color: $primary-500;
      border-radius: $feedback-popup-border-radius;
      box-shadow: $feedback-popup-slider-thumb-shadow;
      height: $feedback-popup-slider-thumb-size-default;
      width: $feedback-popup-slider-thumb-size-default;

      &.with-thumb-icon {
        background-color: transparent;
        box-shadow: none;
        height: $feedback-popup-slider-thumb-size-icon;
        width: $feedback-popup-slider-thumb-size-icon;
      }

      input:focus + div::before {
        position: absolute;
        width: $feedback-popup-slider-thumb-focus-size;
        height: $feedback-popup-slider-thumb-focus-size;
        top: $feedback-popup-slider-thumb-focus-offset;
        left: $feedback-popup-slider-thumb-focus-offset;
        border-radius: 50%;
        content: '';
        background-color: $primary-500;
        opacity: $feedback-popup-slider-thumb-focus-opacity;
      }
    }
  
    .MuiSlider-markLabel {
      max-width: 15%;
      white-space: break-spaces;

      &:nth-last-child(2) {
        text-align: left;
      }
    }
  }

  &--success {
    .MuiPaper-root {
      padding: $feedback-popup-success-padding;
    }

    .MuiSnackbarContent-message {
      line-height: 0;
    }
  }

  &--dark {
    .MuiPaper-root {
      background-color: $feedback-popup-dark-bg-color;
    }

    .ayo-button, .ayo-checkbox {
      &:not(.Mui-focusVisible, :hover) {
        color: $typography-white;

        svg * {
          @include fill-icon-element($typography-white);
        }
      }
    }

    .ayo-typography--subtitle2,
    .ayo-form-control-label .MuiFormControlLabel-label {
      color: $typography-white;
    }
  }
}
