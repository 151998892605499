.ayo-club-hub__club-data-grid-actions {
  &--ACTIVE {
    .ayo-typography--body2 {
      color: $data-visualization-green-090-100;
    }
  }

  &--DECLINED {
    .ayo-typography--body2 {
      color: $operational-error;
    }
  }

  .ayo-editing-button {
    .ayo-typography--body2 {
      font-weight: bold;
    }
  }
}
