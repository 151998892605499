$student-details-page-answer-border-radius: 16px;
$student-details-page-experemental-feature-border: 1px;
$student-details-page-experemental-feature-border-radius: 6px;
$student-details-page-experemental-feature-padding: 0 8px;
$student-details-page-no-answers-block-border-radius: 12px;
$student-details-page-no-answers-block-min-height: 357px;
$student-details-page-no-answers-text-max-width: 416px;
$student-details-page-no-answers-button-max-width: 326px;
$student-details-page-no-answers-image-height: 150px;
$student-details-page-no-answers-image-height-mobile: 137px;
$student-details-page-year-margin-top: 24px;
$student-details-page-questions-margin-top: 40px;
$student-details-page-questions-divider-margin-top-bottom: 24px;
$student-details-page-questions-margin-top-first-child: 16px;
$student-details-page-no-answers-image-height-mobile: 137px;

.ayo-student-details-page {
  flex: 1;

  &__answer {
    background-color: $operational-surface;
    border-radius: $student-details-page-answer-border-radius;

    &__previous-answer {
      height: auto;

      &--collapsed {
        height: 0;
        overflow: hidden;
      }
    }

    &__text {
      font-style: italic;
    }
  }

  &__question {
    margin-top: $student-details-page-questions-margin-top;

    &:first-child {
      margin-top: $student-details-page-questions-margin-top-first-child;
    }
  }

  &__questions-divider {
    margin-top: $student-details-page-questions-divider-margin-top-bottom;
    margin-bottom: $student-details-page-questions-divider-margin-top-bottom;
  }

  &__year {
    margin-top: $student-details-page-year-margin-top;

    &:first-child {
      margin-top: $student-details-page-questions-margin-top-first-child;
    }
  }

  &--no-answers {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: $content-placeholder;
    border-radius: $student-details-page-no-answers-block-border-radius;
    min-height: $student-details-page-no-answers-block-min-height;

    &__text {
      max-width: $student-details-page-no-answers-text-max-width;
    }

    &__button {
      max-width: $student-details-page-no-answers-button-max-width;
    }

    &__img {
      width: auto;
      height: $student-details-page-no-answers-image-height;

      @media (max-width: 600px) {
        height: $student-details-page-no-answers-image-height-mobile;
      }
    }
  }

  .bold-btn * {
    font-weight: bold;
  }
}
