@import './components/event-card/event-card';
@import './components/events-dialog/events-dialog';

$event-divider-height: 12px;
$event-divider-color: #d8d8d8;
$event-add-button-padding: 38px;
$event-location-padding: 0 8px;
$event-location-border-radius: 8px;
$event-location-background-color: #E6F6E1;

.ayo-events-block {
  &__side-block {
    &__show-all {
      width: fit-content;
    }
  }

  &__divider {
    height: $event-divider-height;
    background-color: $event-divider-color;
  }

  &__add-button {
    background-color: $primary-050;
    border-color: $primary-100;
    padding: $event-add-button-padding;
    min-width: 100%;
  }

  &__location {
    max-width: fit-content;

    @include body3;

    font-weight: bold;
    padding: $event-location-padding;
    border-radius: $event-location-border-radius;
    background-color: $event-location-background-color;
    color: $data-visualization-green-090-100;
  }
}

