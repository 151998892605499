$fab-size: 56px;
$fab-color: #3e3e3e;
$fab-border-hover: 2px solid $primary-100;
$fab-border-focus: 2px solid $secondary-500;
$fab-outer-color: #d4dfd7;
$fab-green-main-color: #296139;
$fab-green-focus-border-color: RGB(41 97 57 / 60%);
$tour-menu-border-radius: 16px;

.ayo-tours-fab {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: $fab-color;


  &:hover {
    background-color: $fab-color;
    border: $fab-border-hover;
  }

  &:focus {
    background-color: $secondary-050;
    border: $fab-border-focus;

    svg * {
      @include fill-icon-element($primary-800);
    }
  }

  &--outer {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    background: $fab-outer-color;
    display: none;
    transform-origin: center center;
    animation: 1.2s ease-in-out 0s infinite ayo-hotspot--outer;
    box-sizing: border-box;
    z-index: 1;
  }

  &__wrapper {
    display: flex;
    justify-content: flex-end;
  }

  &__container {
    position: relative;
    height: $fab-size;
    width: $fab-size;

    .MuiFab-root {
      transition: none;
    }

    &.with-new-features {
      .ayo-tours-fab--outer {
        display: block;
      }

      .ayo-tours-fab {
        background: $fab-green-main-color;

        svg * {
          @include fill-icon-element($operational-surface);
        }

        &:focus, &:hover {
          border: none;
        }

        &:focus {
          box-shadow: none;

          & + .ayo-tours-fab--outer {
            border: 2px solid $fab-green-focus-border-color;
          }
        }
      }
    }
  }
}

.ayo-tours-menu {
  .MuiPaper-root {
    border-radius: $tour-menu-border-radius;
  }

  &__button {
    justify-content: flex-start;
  }
}

@keyframes ayo-hotspot--outer {
  0% {
    transform: scale(1);
  }

  45% {
    transform: scale(0.75);
  }

  100% {
    transform: scale(1);
  }
}
