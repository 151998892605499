$carousel-wrapper-mx: 8px;
$carousel-padding-bottom-mobile: 80px;
$carousel-wrapper-width: calc(100% - 120px);
$carousel-dot-list-margin-bottom: 24px;
$carousel-dot-button-margin-left: 8px;
$carousel-dot-button-padding: 2px;
$carousel-dot-button-border-radius: 16px;

.ayo-carousel {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;

  &--slider {
    @media (max-width: 600px) {
      position: relative;
      padding-bottom: $carousel-padding-bottom-mobile;

      & > .ayo-button {
        position: absolute;
        bottom: 0;

        &:first-child {
          left: 0;
        }

        &:last-child {
          right: 0;
        }
      }
    }
  }

  &__wrapper {
    width: $carousel-wrapper-width;
    flex: 1;
    margin-left: $carousel-wrapper-mx;
    margin-right: $carousel-wrapper-mx;

    & > div > div {
      opacity: 1 !important; /* Needed to override default opacity for react-spring-3d-carousel lib */
    }

    .react-multi-carousel-list {
      display: flex;
      align-items: center;
      overflow: hidden;
      position: relative;
    }

    .react-multi-carousel-track {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: row;
      position: relative;
    }

    .react-multi-carousel-dot-list {
      list-style: none;
      display: flex;
      justify-content: center;
      padding: 0;
      margin-bottom: $carousel-dot-list-margin-bottom;
    }

    .react-multi-carousel-dot > button {
      background-color: $primary-100;
      margin-left: $carousel-dot-button-margin-left;
      padding: $carousel-dot-button-padding;
      border-radius: $carousel-dot-button-border-radius;
      border: 1px solid $primary-100;
    }

    .react-multi-carousel-dot--active > button {
      background-color: $primary-600;
      border: 1px solid $primary-600;
    }
  }
}
