$opportunities-definition-section-min-height: calc(100vh - 92px);
$opportunities-definition-section-min-height-mobile: calc(100vh - 168px);
$opportunities-definition-section-background-color: #FFFDEB;
$opportunities-definition-section-description-max-width: 524px;
$opportunities-definition-section-description-max-width-lg: 722px;
$opportunities-definition-section-img-width-lg: 1280px;

.ayo-opportunities-definition-section {
  min-height: $opportunities-definition-section-min-height;

  @media (max-width: 600px) {
    min-height: $opportunities-definition-section-min-height-mobile;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  @media (min-width: 1920px) {
    img {
      width: $opportunities-definition-section-img-width-lg;
    }
  }

  background-color: $opportunities-definition-section-background-color;

  &__description {
    max-width: $opportunities-definition-section-description-max-width;

    @media (min-width: 1920px) {
      max-width: $opportunities-definition-section-description-max-width-lg;
    }
  }
}
