$chip-block-border-radius: 16px;

.ayo-club-sections {
  &__chip-block {
    background-color: $operational-surface;
    border-radius: $chip-block-border-radius;
  }

  .ayo-button--text {
    max-width: fit-content;
  }
}
