$portfolio-section-border-radius: 16px;
$portfolio-section-margin-bottom: 40px;
$portfolio-section-divider-height: 24px;
$portfolio-section-divider-width: 3px;
$portfolio-section-divider-margin-right: 24px;
$paragraph-margin-bottom: 8px;
$actions-menu-margin-left: 8px;
$actions-menu-typography-margin-right: 8px;


.ayo-portfolio-section {
  border-radius: $portfolio-section-border-radius;
  margin-bottom: $portfolio-section-margin-bottom;
  background-color: $operational-surface;

  .ayo-initiative-empty-state {
    padding: 0;
  }

  .MuiTypography-paragraph {
    margin-bottom: $paragraph-margin-bottom;
  }

  &__position-button {
    background-color: $operational-surface;
    border-color: transparent;
  }

  &__divider {
    height: $portfolio-section-divider-height;
    width: $portfolio-section-divider-width;
    margin-right: $portfolio-section-divider-margin-right;
  }

  &--dark {
    .expand-button {
      svg * {
        @include fill-icon-element($typography-white);
      }

      .ayo-typography--body2 {
        color: $typography-white;
      }
    }

    .ayo-button--text {
      color: $typography-white;

      svg * {
        @include fill-icon-element($typography-white);
      }

      &:hover,
      &:focus,
      &.Mui-active {
        color: $primary-500;

        svg * {
          @include fill-icon-element($primary-500);
        }
      }
    }
  }

  &__reflection {
    &--cropped {
      @include wrapped-text(3);
    }
  }
}

.ayo-portfolio-section__actions-menu {
  width: fit-content;
}

.ayo-portfolio-add-actions-menu {
  $actions-menu-margin-left: $actions-menu-margin-left;

  .MuiMenuItem-root div {
    flex-direction: row-reverse;

    .MuiTypography-root {
      color: $primary-500;
      margin-right: $actions-menu-typography-margin-right;
    }
  }
}

.fade-out-bottom {
  animation: fade-out-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes fade-out-bottom {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(50px);
    opacity: 0;
  }
}

.fade-out-top {
  animation: fade-out-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes fade-out-top {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(-50px);
    opacity: 0;
  }
}

.fade-in-top {
  animation: fade-in-top 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@keyframes fade-in-top {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.fade-in-bottom {
  animation: fade-in-bottom 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@keyframes fade-in-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
