$accordion-margin: 0;
$accordion-border-radius: 16px;
$accordion-summary-padding: 16px 16px 16px 72px;
$accordion-summary-padding-mobile: 16px 16px 16px 64px;
$accordion-summary-min-height: 32px;
$accordion-expand-icons-position-top: 16px;
$accordion-expand-icons-position-left: 16px;
$accordion-expand-icons-size: 32px;
$accordion-details-padding: 0 16px 16px 72px;
$accordion-details-padding-mobile: 0 16px 24px 16px;
$accordion-details-text-mb: 24px;

.ayo-accordion {
  margin: $accordion-margin;

  &.MuiAccordion-rounded {
    border-radius: $accordion-border-radius;
    border-color: transparent;

    &::before {
      content: none;
    }
  }

  .ayo-accordion__summary {
    padding: $accordion-summary-padding;
    min-height: $accordion-summary-min-height;

    @media (max-width: 600px) {
      padding: $accordion-summary-padding-mobile;
    }

    &.MuiAccordionSummary-root.Mui-focused {
      border-radius: $accordion-border-radius;
    }

    .MuiAccordionSummary-content {
      margin: 0;
    }

    .ayo-accordion__close-icon,
    .ayo-accordion__open-icon {
      position: absolute;
      top: $accordion-expand-icons-position-top;
      left: $accordion-expand-icons-position-left;
      height: $accordion-expand-icons-size;
      width: $accordion-expand-icons-size;
    }

    &:not(.Mui-expanded) {
      .ayo-accordion__close-icon {
        display: none;
      }
    }

    &.Mui-expanded {
      .ayo-accordion__open-icon {
        display: none;
      }
    }
  }

  .MuiAccordionDetails-root {
    padding: $accordion-details-padding;
    flex-direction: column;

    @media (max-width: 600px) {
      padding: $accordion-details-padding-mobile;
    }

    // TODO: check all the spacing specs with XD after MVP and overwrite those on typography-level
    .ayo-typography--body2.MuiTypography-paragraph {
      margin-bottom: $accordion-details-text-mb;
    }
  }
}
