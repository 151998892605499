$mood-card-wrapper-padding: 8px 16px 8px 8px;

.ayo-mood-section-student {
  .ayo-mood-card__wrapper{
    padding: $mood-card-wrapper-padding;

    @media (max-width: 600px) {
      padding-right: 0;
    }
  }
}
