$media-card-bg-position-top: -25px;
$media-card-arrow-width: 24px;
$media-card-background-height: 200px;
$media-card-background-container-max-width: 1524px;
$media-card-header-pb: 16px;
$media-card-header-pb-small: 8px;
$media-card-text-min-height: 110px;
$media-card-content-label-border-radius: 6px;
$media-card-content-label-position-top: 8px;
$media-card-content-label-position-left: 8px;
$media-card-image-height: 100%;
$media-card-image-height-md: 340px;
$media-card-image-height-sm: 224px;

.ayo-media-card {
  &__divider {
    background-color: $operational-border-alpha;
  }

  &__arrow {
    transform: scale(-1, 1);
    width: $media-card-arrow-width;
  }

  &__background {
    height: $media-card-background-height;
    background-image: url('../../../resources/images/landing_media_dots.svg');
    background-position-x: right;
    background-repeat: no-repeat;
  }

  &__bgContainer {
    max-width: $media-card-background-container-max-width;
    position: absolute;
    top: $media-card-bg-position-top;
  }

  &__header {
    padding-bottom: $media-card-header-pb;

    @media (max-width: 600px) {
      padding-bottom: $media-card-header-pb-small;
    }
  }

  &__actions {
    &.MuiCardActions-root {
      padding: 0;
    }
  }

  &__text {
    min-height: $media-card-text-min-height;
  }

  &__content-label {
    background-color: $operational-surface;
    border: 2px solid $data-visualization-green-090-100;
    border-radius: $media-card-content-label-border-radius;
    width: fit-content;
    position: relative;
    top: $media-card-content-label-position-top;
    left: $media-card-content-label-position-left;
  }

  &__image {
    height: $media-card-image-height;

    @media (max-width: 960px) {
      height: $media-card-image-height-md;
    }

    @media (max-width: 600px) {
      height: $media-card-image-height-sm;
    }
  }
}

.Clickable {
  cursor: pointer;
}
