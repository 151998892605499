$choice-board-delete-notification-icon-size: 24px;

.choice-boards__update-notification__icon {
  width: $choice-board-delete-notification-icon-size;
  height: $choice-board-delete-notification-icon-size;

  * {
    @include fill-icon-element($typography-body);
  }
}

$choices-path-submitted-padding: 40px;
$choices-path-submitted-br: 24px;
$choices-path-submitted-layer-row-gap: 18px;
$choices-path-submitted-bubble-size: 56px;

.choices-path-submitted {
  &__container {
    padding: $choices-path-submitted-padding;
    background: linear-gradient(180deg, #151744 0%, #31012f 100%);
    border-radius: $choices-path-submitted-br;
    display: flex;
    flex-direction: column;
    align-items: center;

    .MuiTypography-root {
      color: $typography-white;
    }

    .ayo-button {
      background-color: transparent;
      border: 2px solid $operational-surface;
    }
  }

  &__layer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: $choices-path-submitted-layer-row-gap;

    &__item {
      flex-basis: 30%;
      align-self: stretch;

      &:nth-child(6n + 1) {
        padding-left: 10%;
      }

      &:nth-child(6n) {
        padding-right: 10%;
      }
    }
  }

  &__bubble {
    border: 2px solid transparent;
    width: $choices-path-submitted-bubble-size;
    height: $choices-path-submitted-bubble-size;
    border-radius: 50%;
    background-color: var(--main-color);
    cursor: pointer;

    @include choices-path-bubble-box-shadow(var(--main-shadow-color));

    &:hover {
      border-color: $primary-500;
      background-color: $primary-050;

      @include choices-path-bubble-box-shadow(rgba($primary-500, 0.25));
    }

    &:focus {
      border-color: $secondary-500;
      background-color: $secondary-050;
      outline: none;

      @include choices-path-bubble-box-shadow(rgba($secondary-500, 0.25));
    }
  }
}

$choices-path-display-padding: 40px 56px;
$choices-path-display-br: 24px;
$choices-path-display-dots-padding: 24px;
$choices-path-display-step-size: 24px;
$choices-path-display-stepper-control-button-size: 48px;
$choices-path-display-step-opacity: 0.75;
$choices-path-display-options-column-gap: 32px;
$choices-path-display-primary-button-width: 200px;
$choices-path-display-action-chip-padding: 0 10px;
$choices-path-full-text-popover-max-width: 600px;
$choices-path-full-text-popover-max-text-height: 350px;

.choices-path-display {
  &__layer-info {
    .MuiTypography-root {
      color: $typography-white;
    }
  }

  &__stepper-controll-button {
    border-color: $typography-white;

    svg {
      width: $choices-path-display-stepper-control-button-size;
      height: $choices-path-display-stepper-control-button-size;

      * {
        @include fill-icon-element($typography-white);
      }
    }

    &:hover svg * {
      @include fill-icon-element($primary-500);
    }

    &.Mui-focusVisible svg * {
      @include fill-icon-element($secondary-200);
    }
  }

  &__container {
    padding: $choices-path-display-padding;
    border-radius: $choices-path-display-br;
    background-image: url('../../../../../../resources/images/choices-path/dots.svg'),
      url('../../../../../../resources/images/choices-path/dots.svg'),
      url('../../../../../../resources/images/choices-path/background-1.svg');
    background-repeat: no-repeat, no-repeat, repeat;
    background-position: top $choices-path-display-dots-padding left
        $choices-path-display-dots-padding,
      bottom $choices-path-display-dots-padding right $choices-path-display-dots-padding, 0 0;
    background-size: auto, auto, 100% auto;

    &.layer-1 {
      background-image: url('../../../../../../resources/images/choices-path/dots.svg'),
        url('../../../../../../resources/images/choices-path/dots.svg'),
        url('../../../../../../resources/images/choices-path/background-2.svg');
    }

    &.layer-2 {
      background-image: url('../../../../../../resources/images/choices-path/dots.svg'),
        url('../../../../../../resources/images/choices-path/dots.svg'),
        url('../../../../../../resources/images/choices-path/background-3.svg');
    }

    .choices-path__options-container {
      column-gap: $choices-path-display-options-column-gap;
    }

    .MuiStep-root {
      .MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel {
        .MuiStepIcon-root {
          width: $choices-path-display-step-size;
          height: $choices-path-display-step-size;

          circle {
            color: $primary-050;
          }

          .MuiStepIcon-text {
            fill: $primary-500;

            @include body3;
          }

          &.Mui-active {
            circle {
              color: $primary-500;
            }

            .MuiStepIcon-text {
              fill: $primary-050;
            }
          }
        }
      }

      .MuiStepLabel-labelContainer .MuiStepLabel-label {
        .MuiTypography-root {
          color: $typography-white;
          opacity: $choices-path-display-step-opacity;
        }

        &.Mui-active {
          .MuiTypography-root {
            opacity: 1;
          }
        }
      }

      .MuiStepLabel-iconContainer.Mui-completed svg *:first-child {
        @include fill-icon-element($primary-500);
      }
    }
  }

  &__full-text__popover {
    z-index: 10;

    .ayo-rich-text-editor--static.quill .ql-editor {
      padding: 0;
    }

    .ayo-card__main-content {
      max-width: $choices-path-full-text-popover-max-width;

      .ayo-rich-text-editor--static {
        max-height: $choices-path-full-text-popover-max-text-height;
        overflow: auto;
      }
    }
  }

  &__action-chip {
    @include label-highlighted;

    background-color: $typography-labels-dark;
    color: $typography-white;
    padding: $choices-path-display-action-chip-padding;
    height: fit-content;
  }

  &__primary-control {
    width: $choices-path-display-primary-button-width;
  }
}
