$ayo-lesson-assignment-settings-svg-margin-left: 10px;

.ayo-lesson-assignment-settings {
  display: flex;
  align-items: center;

  svg {
    margin-left: $ayo-lesson-assignment-settings-svg-margin-left;
  }
}
