$radio-border-radius: 50%;
$radio-padding: 4px;
$radio-mr: 8px;
$radio-focus-shadow-position: inset 0 0 0 2px;
$radio-icon-size: 2rem;

.ayo-radiogroup,
.ayo-radio {
  input[type='radio'] {
    & + span {
      border-radius: $radio-border-radius;
    }

    &:hover + span {
      color: $primary-600;
    }

    &:focus + span {
      box-shadow: $radio-focus-shadow-position $secondary-100;
      color: $secondary-500;
    }
  }

  &.Mui-active input[type='radio'] {
    & + span {
      box-shadow: none;
      color: $primary-500;
    }
  }

  &.Mui-disabled input[type='radio'] {
    & + span {
      color: $operational-disabled-fill;
    }
  }

  &.MuiRadio-root {
    color: $primary-500;
    padding: $radio-padding;
    margin-right: $radio-mr;

    &:hover {
      background-color: transparent;
    }
  }

  .MuiFormLabel-root {
    @include subtitle1;
  }

  .MuiSvgIcon-root {
    font-size: $radio-icon-size;
  }
}
