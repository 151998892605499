$student-filters-gap: 12px 16px;
$student-filters-chip-padding: 4px 12px;
$student-filters-chip-max-width: 250px;

.students-filters {
  flex-wrap: wrap;
  align-items: center;
  gap: $student-filters-gap;

  .filter-chip {
    background-color: $operational-black;
    padding: $student-filters-chip-padding;
    height: 100%;
    max-width: $student-filters-chip-max-width;

    .MuiChip-label p {
      color: $typography-white;
    }

    &:focus {
      background-color: $secondary-200;
      border-color: $secondary-500;

      .MuiChip-label p {
        color: $primary-800;
      }
    }
  }

  .clear-button {
    svg {
      * {
        @include fill-icon-element($primary-500);
      }
    }
  }
}

$highlights-block-padding: 24px;
$highlights-block-br: 24px;
$highlights-tabs-border-bottom: #30263f;
$highlights-block-mt: 16px;
$highlights-interest-image-max-width: 96px;
$highlights-interest-button-br: 12px;
$highlights-interest-button-padding: 8px;
$highlights-interest-button-height: 200px;
$highlights-interest-button-flex-width: 18%;
$highlights-interest-container-gap: 0 12px;
$highlights-vertical-divider-margin: 0 12px;
$highlights-divider-bg-color: #342843;
$highlights-multiline-tick-value-max-width: 128px;
$highlights-multiline-tick-value-max-width-tight: 77px;
$highlights-multiline-tick-value-max-width-tight-xl: 108px;
$highlights-dropdown-min-width-sm: 200px;
$highlights-dropdown-min-width-md: 328px;
$highlights-legend-py: 16px;
$highlights-legend-list_column-gap: 16px;
$highlights-legend-row-gap: 8px;
$highlights-dropdown-bg-color: #393144;

.ayo-highlights-block {
  background-color: $operational-black;
  padding: $highlights-block-padding;
  border-radius: $highlights-block-br;
  margin-top: $highlights-block-mt;

  .ayo-dropdown {
    min-width: $highlights-dropdown-min-width-sm;

    .MuiSelect-select {
      background-color: $highlights-dropdown-bg-color;
    }

    .MuiInputBase-root {
      background: none;
    }

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }

    .MuiSelect-icon * {
      @include fill-icon-element($typography-white);
    }

    @media (min-width: 960px) {
      min-width: $highlights-dropdown-min-width-md;
    }
  }

  .ayo-highlights-legend {
    padding-top: $highlights-legend-py;
    padding-bottom: $highlights-legend-py;

    &__title {
      font-weight: 700;
      padding: 0;
    }

    &__list {
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      column-gap: $highlights-legend-list_column-gap;
      row-gap: $highlights-legend-row-gap;

      &__item {
        display: inline-flex;
        align-items: center;
      }
    }
  }

  .ayo-sel-recommendations-barchart {
    .recharts-surface {
      overflow: visible;
    }

    .ayo-chart-axis {
      .recharts-cartesian-axis-line {
        stroke: $operational-surface;
      }
    }
  }

  .MuiTypography-root,
  .ayo-tabs:not(.ayo-tabs--outlined) .MuiTabs-fixed .MuiTab-root.Mui-selected .MuiTab-wrapper {
    color: $typography-white;
  }

  .ayo-tabs:not(.ayo-tabs--outlined) .MuiTabs-indicator {
    background-color: $operational-surface;
  }

  .ayo-tabs:not(.ayo-tabs--outlined) .MuiTabs-fixed {
    border-bottom-color: $highlights-tabs-border-bottom;
  }

  .axis-text,
  .recharts-cartesian-axis-tick-value,
  .recharts-label {
    @include body3;

    fill: $typography-white;
  }

  .goals-chart-container {
    .recharts-cartesian-axis-tick-value {
      @include body2;
    }
  }

  .x-axis-tick-multiline {
    overflow: visible;

    &-value {
      width: fit-content;
      max-width: $highlights-multiline-tick-value-max-width;
      transform: translateX(-50%);
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;

      &.oneword {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.tight {
        max-width: $highlights-multiline-tick-value-max-width-tight;

        @media (min-width: 1920px) {
          max-width: $highlights-multiline-tick-value-max-width-tight-xl;
        }
      }
    }
  }

  .bar-label {
    @include label-highlighted;

    fill: $primary-900;
  }

  .bar-label-outside {
    @include subtitle1;

    fill: $typography-white;
  }

  .recharts-legend-item-text {
    @include caption;
  }

  .decorative-rect {
    display: none;

    &.active {
      display: block;
    }

    &.hover {
      display: block;
      stroke: $primary-100;
    }

    &.focus {
      display: block;
      stroke: $secondary-500;
    }
  }

  .main-rect-wrapper {
    .main-rect {
      &:hover {
        fill: $primary-050;
      }
    }

    &:focus {
      outline: none;

      .main-rect {
        fill: $secondary-050;
      }
    }
  }

  .MuiDivider-root {
    background-color: $highlights-divider-bg-color;

    &.MuiDivider-vertical {
      margin: $highlights-vertical-divider-margin;
    }
  }

  .info-icon path {
    fill: $typography-white;
  }

  .interest-buttons-container {
    gap: $highlights-interest-container-gap;

    .interest-button {
      appearance: none;
      background: transparent;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      flex: 0 $highlights-interest-button-flex-width;
      align-items: center;
      height: $highlights-interest-button-height;
      padding: $highlights-interest-button-padding;
      border-radius: $highlights-interest-button-br;
      border: 2px solid transparent;

      svg {
        width: 100%;
        height: auto;
        max-width: $highlights-interest-image-max-width;

        circle {
          @include fill-icon-element($primary-400);
        }
      }

      &.active {
        border: 2px solid $primary-500;
      }

      &:hover {
        border: 2px solid $primary-100;

        svg circle {
          @include fill-icon-element($primary-100);
        }
      }

      &:focus {
        border: 2px solid $secondary-500;

        svg circle {
          @include fill-icon-element($secondary-500);
        }
      }
    }
  }
}

$highlights-dropdown-br: 16px;
$highlights-dropdown-selected-bg-color: #4a4058;
$highlights-dropdown-menu-item-margin-bottom: 8px;

.highlights-selection {
  .MuiPaper-root {
    background-color: $highlights-dropdown-bg-color;
    border-radius: $highlights-dropdown-br;

    .ayo-dropdown__menu-item {
      background-color: $highlights-dropdown-bg-color;
      border-color: transparent;
      border-radius: $highlights-dropdown-br;
      margin-bottom: $highlights-dropdown-menu-item-margin-bottom;

      span {
        color: $typography-white;
      }

      &:hover,
      &:focus {
        background-color: $highlights-dropdown-selected-bg-color;
      }

      &:focus {
        border-color: $operational-surface;
      }
    }
  }

  .MuiList-root::-webkit-scrollbar {
    &-thumb {
      background-color: $operational-border;
    }

    &-track {
      background-color: $highlights-dropdown-selected-bg-color;
    }
  }
}

$endorsement-reasons-main-color: #f45bd2;
$endorsement-reasons-max-height: 290px;
$endorsement-reasons-grid-gap: 8px;
$endorsement-reasons-br: 12px;
$endorsement-reasons-content-gap: 16px;
$endorsement-reasons-content-px: 8px;

.endorsements-highlights {
  .drilldown__back:not(:hover, .Mui-focusVisible) {
    color: $typography-white;

    svg * {
      @include fill-icon-element($typography-white);
    }
  }

  .drilldown__title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .reasons-grid {
    display: flex;
    flex-flow: column wrap;
    max-height: $endorsement-reasons-max-height;
    row-gap: $endorsement-reasons-grid-gap;

    .reason {
      background-color: transparent;
      width: calc(33.3333% - #{$endorsement-reasons-grid-gap});
      flex-basis: calc(33.3333% - #{$endorsement-reasons-grid-gap});
      padding: 0;
      box-sizing: border-box;
      border: 2px solid transparent;
      border-radius: $endorsement-reasons-br;

      &__content {
        height: 100%;
        display: flex;
        align-items: center;
        column-gap: $endorsement-reasons-content-gap;
        box-sizing: border-box;
        padding: 0 $endorsement-reasons-content-px 0;
      }

      @mixin change-reason-color($color) {
        border-color: $color;

        .reason__stat {
          color: $color;
        }
      }

      &__stat {
        color: $endorsement-reasons-main-color;
      }

      &.active {
        .reason__text {
          @include ayo-typography-titles;

          color: $typography-white;
        }

        @include change-reason-color($endorsement-reasons-main-color);
      }

      &:focus {
        outline: none;

        @include change-reason-color($secondary-500);
      }

      &:hover:not(.disabled) {
        cursor: pointer;

        @include change-reason-color($primary-100);
      }
    }
  }
}

$filters-popover-mt: 12px;
$filters-popover-z-index: 10;
$filters-popover-px-mobile: 16px;
$filters-paper-br: 16px;
$filters-container-bg-color: #fafafa;
$filters-container-padding: 24px 24px 40px;
$filters-container-width-desktop: 520px;
$filters-scrollable-filters-mobile-max-height: 350px;
$filters-checkbox-mb: 16px;
$filters-divider-margin: 16px 0;
$filters-controls-box-gap: 16px;

.filters-popover {
  background-color: transparent;
  margin-top: $filters-popover-mt;
  z-index: $filters-popover-z-index;

  @media (max-width: 600px) {
    width: calc(100% - #{$filters-popover-px-mobile} * 2);
  }

  .MuiPaper-root {
    border-radius: $filters-paper-br;

    .MuiCardContent-root {
      padding: 0;
    }

    .filters-container {
      background-color: $filters-container-bg-color;
      padding: $filters-container-padding;

      @media (min-width: 600px) {
        min-height: 100%;
        min-width: $filters-container-width-desktop;
        max-width: $filters-container-width-desktop;
      }

      @media (max-width: 600px) {
        width: 100%;
        box-sizing: border-box;
      }

      .scrollable-filters {
        @media (max-width: 600px) {
          max-height: $filters-scrollable-filters-mobile-max-height;
          overflow-y: auto;
        }
      }

      .filters-checkbox {
        margin-bottom: $filters-checkbox-mb;
      }

      .MuiDivider-root {
        margin: $filters-divider-margin;
        flex-basis: 100%;
      }

      .filters-controls-box {
        gap: $filters-controls-box-gap;

        .filters-controls-grid {
          gap: $filters-controls-box-gap 0;

          & > .MuiGrid-item {
            margin: 0;
          }
        }

        & > * {
          margin: 0;
        }
      }
    }

    .MuiList-root {
      padding: 0;
      width: 100%;

      .MuiListItem-root {
        margin-bottom: 0;
        justify-content: space-between;
      }

      .MuiListItemIcon-root {
        justify-content: flex-end;

        svg * {
          @include fill-icon-element(black);
        }
      }
    }
  }
}

.endorsements-filters {
  .main-options__grid,
  .drilldown-options__grid {
    row-gap: $filters-controls-box-gap;
  }
}
