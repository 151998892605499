$leadership-attributes-galery-maturity-text-padding-top: 80px;
$leadership-attributes-galery-maturity-text-padding-top-mobile: 56px;
$leadership-attributes-galery-maturity-text-max-width: 620px;
$leadership-attributes-galery-leadership-attributes-text-max-width: 520px;
$leadership-attributes-galery-maturity-levels-padding-top: 56px;
$leadership-attributes-galery-maturity-levels-title-padding-top: 56px;
$leadership-attributes-galery-maturity-levels-images-block-padding-top: 40px;
$leadership-attributes-galery-maturity-levels-images-block-padding-top-mobile: 32px;
$leadership-attributes-galery-maturity-button-padding: 16px;
$leadership-attributes-galery-maturity-button-padding-mobile: 8px;

.ayo-leadership-attributes-galery {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: linear-gradient(180deg, #f9f2ff 0%, #fff6e9 100%);

  &__maturity-text,
  &__leadership-attributes-text {
    padding-top: $leadership-attributes-galery-maturity-text-padding-top;
    text-align: center;

    @media (max-width: 600px) {
      padding-top: $leadership-attributes-galery-maturity-text-padding-top-mobile;
    }
  }

  &__maturity-text {
    max-width: $leadership-attributes-galery-maturity-text-max-width;
  }

  &__leadership-attributes-text {
    max-width: $leadership-attributes-galery-leadership-attributes-text-max-width;
  }

  &__maturity-levels {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    text-align: center;
    padding-top: $leadership-attributes-galery-maturity-levels-padding-top;

    @media (max-width: 960px) {
      flex-direction: column;
      align-items: center;
    }

    &__arrow {
      @media (max-width: 960px) {
        transform: rotate(90deg);
      }
    }
  }

  &__images-block {
    &__images {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-top: $leadership-attributes-galery-maturity-levels-images-block-padding-top;

      @media (max-width: 600px) {
        margin-top: $leadership-attributes-galery-maturity-levels-images-block-padding-top-mobile;
      }
    }

    &__card {
      .ayo-card {
        padding: $leadership-attributes-galery-maturity-button-padding;

        @media (max-width: 600px) {
          padding: $leadership-attributes-galery-maturity-button-padding-mobile;
        }

        .MuiCardContent-root {
          padding-left: 0;
          padding-right: 0;

          .ayo-clickable-card__content-container .ayo-clickable-card__title {
            text-align: center;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
