$intervention-required-group-view-border-radius: 16px;
$intervention-required-group-view-divider-margin: 0 18px;
$intervention-required-group-view-divider-margin-sm: 0 10px;
$informational-caption-margin-left: 10px;

.ayo-intervention-required-group-view {
  background-color: $secondary-050;
  border-radius: $intervention-required-group-view-border-radius;

  &__divider {
    margin: $intervention-required-group-view-divider-margin;

    @media (max-width: 600px) {
      margin: $intervention-required-group-view-divider-margin-sm;
    }
  }

  .ayo-informational-caption {
    margin-left: $informational-caption-margin-left;
  }
}
