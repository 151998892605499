$ayo-radar-legend-label-py: 16px;

.ayo-radar-chart {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .ayo-card {
    height: fit-content;
    width: fit-content;
  }

  .ayo-form-control-label {
    padding: $ayo-radar-legend-label-py 0;
  }
}
