$link-focus-outline: $primary-900 auto 1px;
$post-card-avatar-size: 48px;
$post-card-avatar-space: 16px;
$post-card-avatar-shifted-left: 16px;
$post-card-avatar-shifted-top: 24px;
$post-card-avatar-border-radius: 9px;
$post-card-edited-pt: 3px;
$post-card-footer-icon-br: 9px;
$post-card-footer-icon-p: 4px;
$post-card-footer-row-gap: 8px;

.ayo-post-card {
  &__title {
    color: $primary-500;

    &:hover {
      cursor: pointer;
    }

    &--bold {
      font-weight: 700;
    }
  }

  &__logos {
    position: relative;
  }

  &__avatar-logo {
    width: $post-card-avatar-size;
    height: $post-card-avatar-size;

    &--shifted {
      position: absolute;
      left: $post-card-avatar-shifted-left;
      top: $post-card-avatar-shifted-top;
    }
  }

  &--edited {
    padding-top: $post-card-edited-pt;
  }

  &__description {
    @include wrapped-text(100);

    a {
      @include link1;

      &:hover {
        color: $primary-600;
      }

      &:focus {
        outline: $link-focus-outline;
      }
    }

    &--collapsed {
      @include wrapped-text(3);

      @media (max-width: 600px) {
        @include wrapped-text(4);
      }
    }
  }

  &__source-logo {
    width: $post-card-avatar-size;
    height: $post-card-avatar-size;
    border-radius: $post-card-avatar-border-radius;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    row-gap: $post-card-footer-row-gap;

    &__icon {
      background-color: $primary-050;
      border-radius: $post-card-footer-icon-br;
      box-sizing: border-box;
      display: flex;
      padding: $post-card-footer-icon-p;

      svg * {
        @include fill-icon-element($primary-500);
      }
    }
  }
}
