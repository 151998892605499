$language-buttons-mr: 8px;
$language-buttons-px: 12px;

.ayo-language-buttons {
  button {
    min-width: initial;
    margin-right: $language-buttons-mr;
    padding-left: $language-buttons-px;
    padding-right: $language-buttons-px;

    &.Mui-hover {
      background-color: $primary-050;
      border-color: $primary-300;
      color: $primary-800;
    }

    &.Mui-focusVisible {
      background-color: $secondary-050;
      border-color: $secondary-500;
      color: $primary-800;
    }
  }
}
