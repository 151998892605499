.ayo-text-with-tooltip-content {
  @include wrapped-text;

  word-break: break-all;

  &--one-line {
    word-break: break-all;
  }

  &--no-wrap {
    word-wrap: inherit;
    word-break: inherit;
    overflow: visible;
  }

  &:focus-visible {
    color: $primary-600;
    outline: none;
  }
}
