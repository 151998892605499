$ayo-manage-access-dialog-max-width: 824px;
$ayo-manage-access-dialog-container-padding: 40px;
$ayo-manage-access-dialog-padding: 0 16px 0 0;
$ayo-manage-access-dialog-content-min-height: 440px;
$ayo-manage-access-dialog-buttons-margin-top: 8px;
$ayo-manage-access-dialog-search-margin-bottom: 24px;
$ayo-manage-access-dialog-buttons-margin-top: 24px;
$ayo-manage-access-dialog-teacher-row-border-radius: 16px;
$ayo-manage-access-dialog-teacher-row-padding: 8px 16px;
$ayo-manage-access-dialog-teacher-row-column-gap: 8px;
$ayo-manage-access-dialog-add-btn-size: 40px;

.ayo-manage-lesson-access-dialog {
  background-color: $operational-background;
  max-width: $ayo-manage-access-dialog-max-width;
  min-width: Min(#{$ayo-manage-access-dialog-max-width}, calc(100% - 32px));

  .ayo-dialog__container {
    padding: $ayo-manage-access-dialog-container-padding;
  }

  .MuiDialogContent-root {
    min-height: $ayo-manage-access-dialog-content-min-height;
    padding: $ayo-manage-access-dialog-padding;
  }

  &__title {
    margin-bottom: $ayo-manage-access-dialog-search-margin-bottom;
  }

  &__search-field {
    margin-bottom: $ayo-manage-access-dialog-search-margin-bottom;
    margin-top: $ayo-manage-access-dialog-search-margin-bottom;
  }

  &__chip {
    @include removable-chip-black;
  }

  &__buttons {
    margin-top: $ayo-manage-access-dialog-buttons-margin-top;
  }

  &__teacher-row {
    background-color: $operational-surface;
    border-radius: $ayo-manage-access-dialog-teacher-row-border-radius;
    padding: $ayo-manage-access-dialog-teacher-row-padding;
    column-gap: $ayo-manage-access-dialog-teacher-row-column-gap;

    &__add-btn {
      height: $ayo-manage-access-dialog-add-btn-size;
      width: $ayo-manage-access-dialog-add-btn-size;
    }
  }
}
