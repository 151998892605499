$ayo-lesson-status-badge-pr: 16px;
$ayo-lesson-status-badge-hotspot-size: 24px;
$ayo-lesson-status-badge-hotspot-margin: 0;
$ayo-lesson-status-badge-draft-bcolor: #f3f3f3;
$ayo-lesson-status-badge-draft-hotspot-bcolor: #adadad;
$ayo-lesson-status-badge-published-bcolor: #e9f3e6;
$ayo-lesson-status-badge-published-hotspot-bcolor: #6faf5d;

.ayo-status-badge {
  padding-right: $ayo-lesson-status-badge-pr;
  width: fit-content;
  text-transform: capitalize;

  .ayo-hotspot {
    min-height: $ayo-lesson-status-badge-hotspot-size;
    min-width: $ayo-lesson-status-badge-hotspot-size;
    height: $ayo-lesson-status-badge-hotspot-size;
    width: $ayo-lesson-status-badge-hotspot-size;
    margin: $ayo-lesson-status-badge-hotspot-margin;

    &--inner {
      background-color: $ayo-lesson-status-badge-draft-hotspot-bcolor;
    }
  }

  &--draft {
    background-color: $ayo-lesson-status-badge-draft-bcolor;

    .MuiTypography-root {
      color: $typography-titles;
    }
  }

  &--published {
    background-color: $ayo-lesson-status-badge-published-bcolor;

    .ayo-hotspot--inner {
      background-color: $ayo-lesson-status-badge-published-hotspot-bcolor;
    }
  }
}
