$ayo-portfolio-essay-section-card-title-mb: 8px;
$ayo-portfolio-essay-section-card-action-menu-svg-height: 24px;
$ayo-portfolio-create-essay-dialog-container-p: 24px 24px 40px 24px;
$ayo-portfolio-create-essay-dialog-container-min-width: calc(1032px - 48px);
$ayo-portfolio-create-essay-dialog-container-min-width-md: calc(100vw - 96px);
$ayo-portfolio-create-essay-dialog-content-border-radius: 16px;
$ayo-portfolio-create-essay-dialog-editor-height: 288px;

.ayo-portfolio__essay-background {
  background-color: #eef3fa;
  background-image: url('../../../../../resources/images/portfolio/essay_bg.svg');
  background-position: right bottom -160px;
  background-repeat: no-repeat;
}

.ayo-portfolio__essays-section {
  &__card {
    &__title {
      margin-bottom: $ayo-portfolio-essay-section-card-title-mb;
      word-break: break-word;

      @include wrapped-text(2);
    }

    &__read-button {
      svg * {
        @include fill-icon-element($primary-500);
      }
    }
  }
}

.ayo-portfolio__essay-section__card__action-menu {
  .MuiMenuItem-root {
    svg {
      height: $ayo-portfolio-essay-section-card-action-menu-svg-height;
      width: auto;
    }
  }
}

.ayo-portfolio__create-essay-dialog {
  .ayo-dialog__container {
    min-width: $ayo-portfolio-create-essay-dialog-container-min-width;
    padding: $ayo-portfolio-create-essay-dialog-container-p;

    @media (max-width: 1032px) {
      min-width: $ayo-portfolio-create-essay-dialog-container-min-width-md;
    }
  }

  &__content {
    background-color: $operational-surface;
    border-radius: $ayo-portfolio-create-essay-dialog-content-border-radius;

    .ayo-rich-text-editor {
      .ql-editor {
        height: $ayo-portfolio-create-essay-dialog-editor-height;
      }
    }
  }
}
