$ayo-post-card-image-border-radius: 16px;
$ayo-post-card-image-margin-bottom: 16px;
$ayo-post-card-image-margin-top: 3px;
$ayo-post-card-image-mood-height: 280px;

.ayo-post-card__image {
  width: 100%;
  border-radius: $ayo-post-card-image-border-radius;
  margin-bottom: $ayo-post-card-image-margin-bottom;
  margin-top: $ayo-post-card-image-margin-top;

  &--MOOD_CHECK_IN {
    @media (max-width: 600px) {
      height: $ayo-post-card-image-mood-height;
      object-fit: cover;
    }
  }
}
