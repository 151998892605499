$ayo-issue-report-page-title-margin-bottom: 24px;
$ayo-issue-report-page-button-max-width: 152px;

.ayo-issue-report {
  .ayo-fileupload__list-item--success {
    background-color: $operational-surface;
  }

  .ayo-typography--subtitle1 {
    margin-bottom: $ayo-issue-report-page-title-margin-bottom;
  }

  .ayo-button {
    max-width: $ayo-issue-report-page-button-max-width;
  }
}

