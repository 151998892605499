$mood-graph-border-radius: 16px;
$mood-graph-offsets: 16px;
$mood-graph-tooltip-bg-color: #404040;

.ayo-mood-graph {
  background-color: $operational-surface;
  border-radius: $mood-graph-border-radius;
  padding: $mood-graph-offsets;

  .ayo-dropdown {
    flex: 1;

    @media (min-width: 960px) {
      margin-left: $mood-graph-offsets;
    }

    @media (max-width: 960px) {
      margin-top: $mood-graph-offsets;
    }
  }

  .ayo-button.sr-only {
    background: transparent;
    border: none;
    color: transparent;
    padding: 0;
  }

  .recharts-cartesian-axis-tick {
    tspan {
      @include caption;
    }

    @media (max-width: 960px) {
      transform-box: fill-box;
      transform-origin: right;
      transform: rotate(-45deg);
    }
  }

  .recharts-tooltip-wrapper {
    background-color: $mood-graph-tooltip-bg-color;
    border-radius: $mood-graph-border-radius;
    padding: $mood-graph-offsets;
  }
}
