$question-nolabel-pt: 48px;
$question-description-height: 4.375rem;
$question-description-height-small: 6.5625rem;
$question-option-mb: 24px;
$question-helper-pb: 20px;
$question-previous-answer-border-radius: 16px;
$question-previous-answer-padding: 16px;
$question-previous-answer-quote-font-size: 1.333rem;
$question-previous-answer-quote-line-height: 2.1875rem;

.ayo-question {
  &--no-label {
    padding-top: $question-nolabel-pt;
  }

  &__description {
    height: $question-description-height;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @media (max-width: 900px) {
      height: $question-description-height-small;
    }
  }

  &__previous-answer-wrapper {
    border: 1px solid $operational-border;
    border-radius: $question-previous-answer-border-radius;
    padding: $question-previous-answer-padding;
    margin-bottom: $question-option-mb / 2;

    .ayo-typography {
      &--label {
        color: $typography-labels-dark;
        font-weight: bold;
      }

      &--quote {
        font-size: $question-previous-answer-quote-font-size;
        line-height: $question-previous-answer-quote-line-height;

        @include insert-quote-image($url: '../resources/icons/quote_split.svg', $height: 68px, $width: 124px, $left: 75%, $bottom: -16px);
      }
    }
  }

  &__next {
    position: relative;
    padding-bottom: $question-helper-pb;

    &__helpertext {
      position: absolute;
      width: 100%;
    }
  }

  .options {
    & > *:not(:last-child) {
      padding-bottom: $question-option-mb;
    }
  }

  &__fieldset {
    width: 100%;

    &__label {
      font-style: italic;
      text-transform: capitalize;
    }
  }
}
