$ayo-aptitudes-definition-section-content-width: 808px;

.ayo-aptitudes-definition-section {
  background-image: url("../../../../../../resources/images/aptitudes/landing-page/aptitudes-definition-desktop-bg.svg");
  background-size: 100% auto;
  background-position: bottom;
  background-repeat: no-repeat;

  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 600px) {
    background-image: url("../../../../../../resources/images/aptitudes/landing-page/aptitudes-definition-mobile-bg.svg");
  }

  &__content {
    width: $ayo-aptitudes-definition-section-content-width;

    &__description {
      max-width: 60%;

      @media (max-width: 600px) {
        max-width: 100%
      }
    }

    @media (min-width: 1920px) {
      width: 100%
    }
  }
}
