$snackbar-text-max-width: 467px;
$snackbar-text-padding-left: 8px;
$snackbar-text-svg-padding-top-large: 4px;
$snackbar-br: 9px;
$snackbar-icon-margin: 0 8px 5px 0;
$snackbar-icon-min-width: 25px;

.ayo-snackbar {
  &--comment {
    .MuiSnackbarContent-message div {
      align-items: center;
    }
  }

  &--text {
    padding-left: $snackbar-text-padding-left;
    color: $operational-surface;
    max-width: $snackbar-text-max-width;

    @include icon-text-align($snackbar-icon-margin);
  }

  svg {
    min-width: $snackbar-icon-min-width;

    @media (min-width: 1920px) {
      padding-top: $snackbar-text-svg-padding-top-large;
    }
  }

  .check-outlined {
    & * {
      @include fill-icon-element($operational-surface);
    }
  }

  .ayo-button {
    color: $operational-surface;

    &:hover,
    &:focus {
      background-color: transparent;
    }
  }

  .MuiSnackbarContent-message {
    flex: 2;
  }

  .MuiPaper-root {
    border-radius: $snackbar-br;
    background-color: $tooltip-background;
    box-shadow: 0 4px 18px RGB(94 94 94 / 25%);
  }

  .MuiSnackbarContent-root {
    min-width: auto;
  }
}
