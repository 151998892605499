$login-option-caption-max-width: 200px;
$login-option-caption-text-pl: 12px;
$login-option-caption-text-mt: 8px;
$login-option-caption-bottom-mb: 24px;
$login-option-caption-bottom-mt: -24px;
$login-option-caption-icon-size: 40px;
$login-option-caption-bottom-text-mr: 8px;
$login-option-caption-bottom-text-mt: -16px;

.ayo-main-illustration {
  width: 85%;

  @media (min-width: 1920px) {
    width: auto;
  }
}

.instruction-text {
  font-style: italic;
}

.login-option {
  &__container {
    position: relative;
  }

  &__caption {
    position: absolute;
    left: 100%;
    top: 50%;
    max-width: $login-option-caption-max-width;
    width: 100%;
    display: flex;
    flex-direction: column;
  
    p {
      margin-top: $login-option-caption-text-mt;
      padding-left: $login-option-caption-text-pl;
    }
  
    @media (max-width: 1365px) {
      position: relative;
      left: unset;
      top: unset;
      margin-bottom: $login-option-caption-bottom-mb;
      max-width: 100%;
      margin-top: $login-option-caption-bottom-mt;
  
      svg {
        align-self: flex-end;
        transform: rotate(135deg);
      }
  
      p {
        box-sizing: border-box;
        width: calc(100% - #{$login-option-caption-icon-size} - #{$login-option-caption-text-pl} - #{$login-option-caption-bottom-text-mr});
        margin-top: $login-option-caption-bottom-text-mt;
      }
    }
  
    @media (max-width: 600px) {
      margin-top: 0;
    }
  }
}
