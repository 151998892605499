$loader-blur-value: 3px;
$loader-background-color: RGB(255 255 255 / 90%);
$loader-image-width: 64px;
$loader-image-width-global: 100px;

.ayo-simple-loader {
  display: flex;
  align-items: baseline;
  justify-content: center;

  img {
    width: $loader-image-width;
    height: auto;
  }

  &--global {
    flex-direction: column-reverse;
    align-items: center;
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur($loader-blur-value);
    background-color: $loader-background-color;
    z-index: 10000;

    img {
      width: $loader-image-width-global;
    }
  }
}
