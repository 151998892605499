.personalized-block {
  display: flex;
  flex-direction: column;
  flex: 1;

  .personalized-block__content {
    flex: 1;

    ::-webkit-scrollbar {
      width: $scrollbar-width-sm;
    }
  }

  &__student-label {
    color: $primary-500;
  }
}
