$chat-drawer-min-width: MIN(95vw, 440px);
$chat-drawer-min-width-lg: MIN(95vw, 640px);
$chat-drawer-max-width: MIN(95vw, 440px);
$chat-drawer-max-width-lg: MIN(95vw, 440px);
$chat-drawer-paper-border-radius: 16px 0 0 16px;
$chat-drawer-close-icon-indent: 8px;
$chat-drawer-close-icon-size: 24px;
$chat-drawe-paper-br: 16px 0 0 16px;

.ayo-chat-drawer {
  &__paper {
    box-sizing: border-box;
    min-width: $chat-drawer-min-width;
    max-width: $chat-drawer-max-width;
    border-radius: $chat-drawe-paper-br;

    @media (min-width: 1920px) {
      min-width: $chat-drawer-min-width-lg;
      max-width: $chat-drawer-max-width-lg;
    }
  }

  &__close-icon {
    padding: 0;
    position: absolute;
    right: $chat-drawer-close-icon-indent;
    top: $chat-drawer-close-icon-indent;

    svg {
      height: $chat-drawer-close-icon-size;
      width: $chat-drawer-close-icon-size;

      * {
        fill: $typography-labels-dark;
      }
    }
  }
}
