$leadership-attributes-who-enjoy-it-section-background-color: #fafaff;
$leadership-attributes-who-enjoy-it-section-illustration-mt: 12px;
$leadership-attributes-who-enjoy-it-section-illustration-mt-small: 32px;
$leadership-attributes-who-enjoy-it-section-illustration-width-small: 188px;
$leadership-attributes-who-enjoy-it-section-headline-mb: 24px;
$leadership-attributes-who-enjoy-it-section-headline-mb-small: 0;
$leadership-attributes-who-enjoy-it-section-headline-max-width: 720px;
$leadership-attributes-who-enjoy-it-section-content-block-max-width: 600px;
$leadership-attributes-who-enjoy-it-section-content-block-max-width-extra-large: 820px;
$leadership-attributes-who-enjoy-it-section-content-block-headline-mb: 8px;

.ayo-leadership-attributes-who-enjoy-it {
  background-color: $leadership-attributes-who-enjoy-it-section-background-color;

  &__headline {
    max-width: $leadership-attributes-who-enjoy-it-section-headline-max-width;
    text-align: center;
    margin-bottom: $leadership-attributes-who-enjoy-it-section-headline-mb;

    @media (max-width: 600px) {
      text-align: left;
    }
  }

  &__content {
    max-width: $leadership-attributes-who-enjoy-it-section-content-block-max-width;

    @media (min-width: 1920px) {
      max-width: $leadership-attributes-who-enjoy-it-section-content-block-max-width-extra-large;
    }

    &__headline {
      margin-bottom: $leadership-attributes-who-enjoy-it-section-content-block-headline-mb;
    }
  }

  &__illustration {
    height: auto;
    max-width: 100%;
    margin-top: $leadership-attributes-who-enjoy-it-section-illustration-mt;
  }

  @media (max-width: 600px) {
    &__illustration {
      width: $leadership-attributes-who-enjoy-it-section-illustration-width-small;
      margin-top: $leadership-attributes-who-enjoy-it-section-illustration-mt-small;
    }

    &__headline {
      margin-bottom: $leadership-attributes-who-enjoy-it-section-headline-mb-small;
    }
  }
}
