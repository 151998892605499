.ayo-action-step-create-dialog {
  overflow: visible;

  .MuiDialogContent-root {
    overflow: visible;
  }

  .ayo-dialog__container {
    overflow: visible;
  }
}
