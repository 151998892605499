$ayo-selectable-icon-button-min-width: 48px;
$ayo-selectable-icon-button-min-width-xl: 64px;

.ayo-selectable-icon-button {
  box-sizing: border-box;
  min-width: $ayo-selectable-icon-button-min-width;

  @media (min-width: 1920px) {
    min-width: $ayo-selectable-icon-button-min-width-xl;
  }

  &--selected {
    svg * {
      fill: $primary-500;
    }
  }
}
