$data-grid-row-border-radius: 16px;

.ayo-club-hub__management {
  &__clubs-data-grid {
    .MuiDataGrid-row {
      background-color: $operational-surface;
      border-radius: $data-grid-row-border-radius;

      &.Mui-selected, &.Mui-selected:hover, &:hover {
        background-color: $operational-surface;
      }
    }

    .ayo-link {
      font-weight: bold;
    }

    &__updates-icon {
      svg * {
        @include fill-icon-element($typography-labels);
      }
    }
  }
}
