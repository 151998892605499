$add-course-dropdown-br: 16px;
$popper-container-padding-top: 8px;
$popper-container-max-height: 400px;
$popper-z-index: 1301;

.ayo-add-courses-dropdown {
  max-height: 100%;
  position: relative;

  &--opened {
    background-color: $operational-surface;
    border-radius: $add-course-dropdown-br;
  }

  &__popper {
    z-index: $popper-z-index;
    background-color: $operational-surface;
    border-radius: $add-course-dropdown-br;

    &-container {
      padding-top: $popper-container-padding-top;
      max-height: $popper-container-max-height;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: $scrollbar-width-sm;
      }
    }
  }

  .MuiButtonBase-root {
    justify-content: left;
  }
}
