$image-width-xs: 74%;

.ayo-lesson-planner-landing__how-it-works-section {
  &__image {
    @media (max-width: 600px) {
      width: $image-width-xs;
      height: auto;
    }
  }
}
