$leadership-attributes-explore-more-content-block-border-radius: 24px;
$leadership-attributes-explore-more-parent-student-background-color: #030235;
$leadership-attributes-explore-more-logged-out-svg-height: 230px;
$leadership-attributes-explore-more-text-block-max-width: 560px;
$leadership-attributes-explore-more-login-button-min-width: 196px;
$leadership-attributes-explore-more-login-button-min-width-sm: 252px;
$leadership-attributes-explore-more-redirect-button-min-width: 288px;

.ayo-leadership-attributes-explore-more {
  background-color: $leadership-attributes-explore-more-parent-student-background-color;
  border-radius: $leadership-attributes-explore-more-content-block-border-radius;

  &__logged-in {
    &--text-block {
      max-width: $leadership-attributes-explore-more-text-block-max-width;
    }

    &--button-wrapper {
      min-width: $leadership-attributes-explore-more-redirect-button-min-width;
    }
  }

  &__logged-out {
    height: 100%;
    box-sizing: border-box;
    align-items: center;
    display: flex;
    flex-direction: column;

    &--button-wrapper {
      min-width: $leadership-attributes-explore-more-login-button-min-width;

      @media (max-width: 600px) {
        min-width: $leadership-attributes-explore-more-login-button-min-width-sm;
      }
    }

    svg {
      height: $leadership-attributes-explore-more-logged-out-svg-height;
    }
  }
}
