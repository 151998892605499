$aptitude-card-padding-x: 16px;
$aptitude-card-padding-y: 16px;
$aptitude-card-illustration-mr: 16px;

.ayo-aptitude-card {
  .MuiCardContent-root {
    padding: $aptitude-card-padding-y $aptitude-card-padding-x;
  }

  &__main-content {
    display: flex;
    flex-direction: row;
  }

  &__illustration {
    flex-shrink: 0;
    margin-right: $aptitude-card-illustration-mr;
  }
}