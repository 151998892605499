$ayo-switch-width: 44px;
$ayo-switch-height: 22px;
$ayo-switch-thumb-width: 14px;
$ayo-switch-thumb-height: 14px;
$ayo-switch-padding: 2px;
$ayo-switch-margin: 0 8px;
$ayo-switch-track-radius: 16px;
$ayo-switch-switchBase-top: -4px;
$ayo-switch-switchBase-left: -4px;

.ayo-switch {
  width: $ayo-switch-width;
  height: $ayo-switch-height;
  padding: $ayo-switch-padding;
  margin: $ayo-switch-margin;

  .MuiSwitch-switchBase {
    top: $ayo-switch-switchBase-top;
    left: $ayo-switch-switchBase-left;
    color: $primary-500;
  }

  .MuiSwitch-thumb {
    width: $ayo-switch-thumb-width;
    height: $ayo-switch-thumb-height;
  }

  .MuiIconButton-root {
    &:hover {
      background-color: transparent;
    }
  }

  .MuiSwitch-track {
    opacity: 1;
    border: 1px solid $primary-500;
    background-color: $operational-surface;
    border-radius: $ayo-switch-track-radius;
  }

  .Mui-disabled {
    color: $operational-disabled-fill;

    + .MuiSwitch-track {
      opacity: 1;
      background-color: $operational-surface;
      border-color: $operational-disabled-bg;
    }
  }

  .Mui-checked {
    color: $operational-surface;

    + .MuiSwitch-track {
      opacity: 1;
      background-color: $primary-500;
    }
  }

  .Mui-focusVisible {
    color: $secondary-500;
    opacity: 1;

    + .MuiSwitch-track {
      background-color: $operational-surface;
      border-color: $secondary-500;
    }
  }
  
  .Mui-checked.Mui-focusVisible {
    color: $operational-surface;

    + .MuiSwitch-track {
      opacity: 1;
      background-color: $secondary-500;
      border-color: $secondary-100;
    }
  }
  
  .Mui-checked.Mui-disabled {
    color: $operational-disabled-fill;

    + .MuiSwitch-track {
      opacity: 1;
      background-color: $operational-disabled-bg;
      border-color: $operational-disabled-bg;
    }
  }
}
