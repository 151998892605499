$heat-map-chart-divider-width: 77px;
$heat-map-chart-container-border: 1px solid #C1C0C9;
$heat-map-chart-container-border-radius: 16px;
$heat-map-chart-info-icon-top: 3px;
$heat-map-chart-y-legend-margin-bottom: 30px;
$heat-map-chart-y-legend-margin-bottom-xl: 38px;
$heat-map-chart-y-legend-margin-bottom-sm: 24px;
$heat-map-chart-y-legend-min-width-sm: 100px;
$heat-map-chart-caption-font-size-sm: 1rem;
$heat-map-chart-caption-font-size-line-height-sm: 1.667rem;
$heat-map-chart-grid-template-rows: repeat(5, 1fr);
$heat-map-chart-grid-row-gap: 8px;
$heat-map-chart-grid-row-gap-sm: 16px;
$heat-map-chart-grid-column-gap: 8px;
$heat-map-chart-grid-column-gap-sm: 4px;
$heat-map-chart-item-border-radius: 8px;
$heat-map-chart-item-low-background-color: #FFF9FE;
$heat-map-chart-item-medium-background-color: #F8CAEE;
$heat-map-chart-item-high-background-color: #FC86E2;
$heat-map-chart-item-very-high-background-color: #C03BA3;

.ayo-heat-map-stat-card {
  &__stats-container {
    min-width: max-content;

    @media (max-width: 600px) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__divider {
    width: $heat-map-chart-divider-width;
  }
}

.ayo-heat-map-chart {
  border: $heat-map-chart-container-border;
  border-radius: $heat-map-chart-container-border-radius;

  &__title {
    svg {
      position: relative;
      top: $heat-map-chart-info-icon-top;
    }
  }

  @media (max-width: 600px) {
    .ayo-typography--caption {
      font-size: $heat-map-chart-caption-font-size-sm;
      line-height: $heat-map-chart-caption-font-size-line-height-sm;
    }
  }


  &__y-legend {
    margin-bottom: $heat-map-chart-y-legend-margin-bottom;
    min-width: max-content;
    display: grid;
    grid-row-gap: $heat-map-chart-grid-row-gap;
    grid-template-rows: $heat-map-chart-grid-template-rows;

    @media (min-width: 1920px) {
      margin-bottom: $heat-map-chart-y-legend-margin-bottom-xl;
    }

    @media (max-width: 600px) {
      min-width: $heat-map-chart-y-legend-min-width-sm;
      margin-bottom: $heat-map-chart-y-legend-margin-bottom-sm;
    }
  }

  &__chart-container {
    width: 100%;
    max-height: 100%;
    display: grid;
    grid-column-gap: $heat-map-chart-grid-column-gap;

    @media (max-width: 600px) {
      grid-column-gap: $heat-map-chart-grid-column-gap-sm;
    }

    &__column {
      height: 100%;
      display: grid;
      grid-row-gap: $heat-map-chart-grid-row-gap;
      grid-template-rows: $heat-map-chart-grid-template-rows;

      @media (max-width: 600px) {
        grid-row-gap: $heat-map-chart-grid-row-gap-sm;
      }

      &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        max-height: 100%;
        border-radius: $heat-map-chart-item-border-radius;

        &--low {
          background-color: $heat-map-chart-item-low-background-color;
        }

        &--medium {
          background-color: $heat-map-chart-item-medium-background-color;
        }

        &--high {
          background-color: $heat-map-chart-item-high-background-color;
        }

        &--very-high {
          background-color: $heat-map-chart-item-very-high-background-color;

          .ayo-typography--subtitle2 {
            color: $typography-white;
          }
        }
      }
    }
  }
}
