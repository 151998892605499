$settings-link-pl: 2px;
$tooltip-margin: 8px 0;

.voice-animation {
  circle {
    transition: r ease-out 0.23s;
  }
}

.ayo-stt-helper {
  .ayo-stt-helper__settings-link {
    padding-left: $settings-link-pl;
  }

  margin: $tooltip-margin;
}
