$breadcrumbs-separator-margin: 0 16px;
$breadcrumbs-margin-right: 16px;

.ayo-breadcrumb-navigation {
  @media (min-width: 600px) {
    .MuiBreadcrumbs-ol {
      flex-wrap: nowrap;
    }
  }

  @media (min-width: 1280px) {
   margin-right: $breadcrumbs-margin-right;
  }

  .MuiBreadcrumbs-separator {
    margin: $breadcrumbs-separator-margin;

    svg * {
      @include fill-icon-element($typography-labels);
    }
  }
}
