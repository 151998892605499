$tooltip-padding: 0 20px;
$tooltip-text-padding: 24px;
$tooltip-shadow: 0 4px 18px RGB(94 94 94 / 25%);
$tooltip-border-radius: 16px;
$basic-tooltip-padding: 16px;
$tooltip-max-width-lg: calc((100vw - 400px) / 2);
$tooltip-max-width-xl: calc((100vw - 528px) / 2);
$tooltip-max-width-md: calc((100vw - 62px) / 2);
$tooltip-max-width-mobile: calc(100vw - 62px);
$tooltip-light-max-width: 560px;


.ayo-tooltip {
  @include caption;

  word-break: break-word;
  color: $operational-surface;
  background-color: $tooltip-background;
  padding: $basic-tooltip-padding;
  border-radius: $tooltip-border-radius;
  max-width: $tooltip-max-width-lg;

  @media (min-width: 1920px) {
    max-width: $tooltip-max-width-xl;
  }

  @media (max-width: 960px) {
    max-width: $tooltip-max-width-md;
  }

  @media (max-width: 600px) {
    max-width: $tooltip-max-width-mobile;
  }

  &.ayo-tooltip--light {
    background-color: $operational-surface;
    padding: $tooltip-text-padding;
    box-shadow: $tooltip-shadow;
    max-width: $tooltip-light-max-width;

    .ayo-tooltip__close-button {
      @include close-button-dark;
    }
  }
}

.ayo-tooltip-popper {
  &.ayo-tooltip-popper--light {
    pointer-events: inherit;
  }
}
