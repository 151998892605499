$link-item-border-radius: 16px;
$link-item-progress-height: 6px;
$link-item-progress-bg-color: #efefef;
$link-item-progress-base-margin: 16px;

.ayo-filelink-removable-item {
  background-color: $operational-surface;
  border-radius: $link-item-border-radius;
  display: flex;
  flex-direction: row;

  &__link {
    font-weight: bold;

    &__file-title {
      @include wrapped-text;
    }

    &__file-type {
      min-width: max-content;
    }
  }

  .ayo-linear-progress {
    background-color: $link-item-progress-bg-color;
    border-radius: $link-item-border-radius;
    flex: 1;
    height: $link-item-progress-height;
    margin: 0 2 * $link-item-progress-base-margin 0 $link-item-progress-base-margin;

    .MuiLinearProgress-barColorPrimary {
      background-color: $data-visualization-green-080-090;
    }
  }

  .progress-label {
    position: absolute;
    right: 2 * $link-item-progress-base-margin;
    top: -$link-item-progress-base-margin;
  }

  .error-label {
    color: $operational-error;
  }
}
