$intervention-alert-margin-bottom: 24px;
$intervention-alert-grid-template-columns: 36px auto;
$intervention-alert-icon-padding-top: 6px;
$intervention-alert-box-shadow: 0 12px 16px 6px RGB(0 0 0 / 6%);
$intervention-alert-acknowledged-padding: 16px;
$intervention-alert-acknowledged-max-width: 504px;
$intervention-alert-acknowledged-action-padding: 24px 0 0 32px;

.ayo-intervention-alert {
  .ayo-alert {
    margin-bottom: $intervention-alert-margin-bottom;
    box-shadow: $intervention-alert-box-shadow;

    .MuiAlert-icon {
      padding-top: $intervention-alert-icon-padding-top;
    }

    &.acknowledged {
      max-width: $intervention-alert-acknowledged-max-width;
      display: grid;
      padding: $intervention-alert-acknowledged-padding;
      grid-template-columns: $intervention-alert-grid-template-columns;

      .MuiAlert-action {
        padding: $intervention-alert-acknowledged-action-padding;
        min-width: max-content;
      }

      .MuiAlert-icon {
        padding-top: 0;
      }
    }
  }
}
