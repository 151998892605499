$dialog-container-min-width: 504px;
$dialog-container-min-width-xl: 680px;
$dialog-back-drop-background-color: RGB(0 0 0 / 70%);
$text-badge-height: 18px;
$comment-card-padding: 16px 32px;
$empty-state-padding-top: 40px;

.ayo-comments-dialog {
  .ayo-dialog__container {
    min-width: $dialog-container-min-width;

    @media (min-width: 1920px) {
      min-width: $dialog-container-min-width-xl;
    }
  }

  .ayo-text-badge {
    height: $text-badge-height;
  }

  .ayo-card.MuiPaper-elevation1 {
    box-shadow: none;
  }

  .ayo-comment-card {
    &.MuiCardContent-root {
      padding: $comment-card-padding;
    }

    border: 1px solid $operational-border;
  }

  .ayo-comments-block {
    padding-bottom: 0;
  }

  .ayo-initiative-empty-state {
    padding-top: $empty-state-padding-top;

    &__body {
      max-width: 90%;
    }
  }
}

.ayo-comments-dialog__back-drop {
  background-color: $dialog-back-drop-background-color;
}

.ayo-comments-dialog__button {
  background-color: $typography-white;

  .ayo-dot-indicator .MuiBadge-dot.MuiBadge-anchorOriginTopRightCircular {
    right: 10%;
    top: 10%;
  }
}
