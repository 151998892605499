$demo-user-snackbar-bg-color: RGB(0 0 0 / 85%);
$demo-user-snackbar-padding: 16px 24px;
$demo-user-snackbar-padding-mobile: 16px;
$demo-user-snackbar-gap-mobile: 24px;
$demo-user-snackbar-br: 16px;
$demo-user-snackbar-stick-top: 24px;
$demo-user-snackbar-stick-top-mobile: 16px;

.demo-user-snackbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $demo-user-snackbar-bg-color;
  padding: $demo-user-snackbar-padding;
  border-radius: $demo-user-snackbar-br;

  @media (max-width: 600px) {
    flex-direction: column;
    gap: $demo-user-snackbar-gap-mobile;
    padding: $demo-user-snackbar-padding-mobile;
    align-items: flex-start;
  }

  .MuiTypography-root,
  button:not(:focus, :hover) {
    color: $typography-white;
  }

  button:not(:focus, :hover) svg * {
    @include fill-icon-element($typography-white);
  }

  &__sticky {
    position: sticky;
    top: $demo-user-snackbar-stick-top;
    pointer-events: all;

    @media (max-width: 600px) {
      top: $demo-user-snackbar-stick-top-mobile;
    }
  }

  &__container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    height: 100%;
    pointer-events: none;
  }
}