$dialog-container-w-lg: 864px;
$dialog-container-w-md: 624px;
$label-border-radius: 16px;
$label-padding: 12px;

.ayo-club-hub__visibility-dialog {
  background-color: $operational-background-grey;

  @include dialog-full-width-mobile;

  .ayo-tabs {
    &.ayo-tabs--outlined {
      border: none;
    }

    background-color: $operational-surface;
    border: none;
  }

  @media (min-width: 960px) {
    .ayo-dialog__container {
      box-sizing: border-box;
      max-width: $dialog-container-w-md;
      min-width: $dialog-container-w-md;
    }
  }

  @media (min-width: 1920px) {
    .ayo-dialog__container {
      box-sizing: border-box;
      max-width: $dialog-container-w-lg;
      min-width: $dialog-container-w-lg;
    }
  }

  .ayo-form-control-label {
    min-width: 100%;
    padding: $label-padding;
    border-radius: $label-border-radius;
    background-color: $operational-surface;

    .MuiFormControlLabel-label {
      font-weight: bold;
    }
  }

  &--error {
    .ayo-form-control-label {
      border: 1px solid $operational-error;
    }
  }

  &__helper-text {
    color: $operational-error;
  }

}
