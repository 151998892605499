$assignment-insight-selected-group-padding: 24px;
$assignment-insight-selected-group-br: 16px;
$assignment-insight-selected-group-select-width: 50%;

.assignment-insight-selected-group {
  background-color: $operational-surface;
  padding: $assignment-insight-selected-group-padding;
  border-radius: $assignment-insight-selected-group-br;

  .ayo-dropdown {
    width: $assignment-insight-selected-group-select-width;
  }

  .ayo-chip.ayo-expandable-menu-title {
    min-width: 0;
  }

  .static-student {
    border: 1px solid $operational-border;
    background-color: $operational-surface;

    .MuiChip-label {
      color: $typography-titles;
      font-weight: bold;
    }
  }

  .editable-student:not(:focus, :hover) {
    background-color: $primary-900;

    .MuiChip-label {
      color: $typography-white;
    }

    svg * {
      @include fill-icon-element($typography-white);
    }
  }
}