$chat-message-label-mb: 4px;
$chat-message-label-ml: 56px;
$chat-message-text-br: 16px;
$chat-message-text-ml: 8px;
$chat-message-own-label-mr: 56px;
$chat-message-own-text-mr: 8px;

.family-feed-chat-message {
  &__label {
    margin-bottom: $chat-message-label-mb;
    margin-left: $chat-message-label-ml;
  }

  &__body {
    display: flex;

    &__text {
      background-color: $operational-background;
      border-radius: $chat-message-text-br;
      margin-left: $chat-message-text-ml;
    }
  }

  &--own {
    .family-feed-chat-message__label {
      margin-left: 0;
      margin-right: $chat-message-own-label-mr;
      text-align: right;
    }

    .family-feed-chat-message__body {
      flex-direction: row-reverse;

      &__text {
        background-color: $primary-050;
        margin-left: 0;
        margin-right: $chat-message-own-text-mr;
      }
    }
  }
}
