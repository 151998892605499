$ayo-comments-block-padding-bottom: 80px;
$ayo-comments-block-MuiTimelineOppositeContent-root-padding: 0 0 0 16px;
$ayo-comments-block-MuiTimelineConnector-root-width: 1px;
$ayo-comments-block-MuiTimeline-root-padding-top: 0;
$ayo-comments-block-timeline-lastBlock-height: 60px;

.ayo-comments-block {
    padding-bottom: $ayo-comments-block-padding-bottom;

    .MuiTimelineOppositeContent-root {
        text-align: left;
        padding: $ayo-comments-block-MuiTimelineOppositeContent-root-padding;
        width: 95%;

        @media (max-width: 640px) {
            min-width: 100%;
        }
    }

    .MuiTimelineConnector-root {
        width: $ayo-comments-block-MuiTimelineConnector-root-width;
    }

    .MuiTimeline-root {
        padding-top: $ayo-comments-block-MuiTimeline-root-padding-top;
    }

    ul > li:last-child > div:first-child {
        height: $ayo-comments-block-timeline-lastBlock-height;
    }

    .ayo-card {
        overflow: visible;
    }
}
