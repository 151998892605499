$avatar-zoom-slider-height: 8px;
$avatar-zoom-slider-br: 21px;
$avatar-zoom-slider-thumb-size: 24px;
$avatar-zoom-slider-thumb-focus-border-width: 5px;
$avatar-zoom-slider-thumb-focus-background-color: RGB(107 173 106 / 35%);
$avatar-zoom-slider-height-mobile: 220px;
$avatar-zoom-slider-my-mobile: 16px;
$avatar-zoom-buttons-size: 48px;
$avatar-guidelines-zone-bg-color: RGB(255 243 224 / 50%);
$avatar-guidelines-zone-padding: 16px;
$avatar-guidelines-zone-mb: 40px;
$avatar-sections-main-br: 16px;
$avatar-drop-zone-padding: 24px;
$avatar-drop-here-bg-color: #e8f6e8;
$avatar-drop-here-main-icon-size: 56px;
$avatar-drop-here-sup-padding: 16px;
$avatar-dialog-confirm-backdrop: #4d4d4d;
$avatar-dialog-padding-x: 40px;
$avatar-dialog-min-width: 480px;
$avatar-dialog-mobile-min-width: 300px;
$avatar-dialog-mobile-py: 16px;
$avatar-dialog-actions-mt: 16px;
$avatar-editor-bg-color: RGB(0 0 0 / 50%);
$avatar-uploaded-file-py: 16px;
$avatar-reposition-instruction-padding: 12px;
$avatar-reposition-instruction-position-top: 12px;
$avatar-reposition-instruction-bg-color: #404040;
$avatar-reposition-instruction-content-gap: 8px;

.drop-here {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: $avatar-drop-here-bg-color;
  border-radius: $avatar-sections-main-br;
  border: 2px solid $data-visualization-green-080-090;
  z-index: 10;
  flex: 1;
  margin: 0 $avatar-dialog-padding-x;

  .MuiTypography-root {
    color: $data-visualization-green-080-090;
  }

  &__main > svg {
    width: $avatar-drop-here-main-icon-size;
    height: $avatar-drop-here-main-icon-size;

    * {
      @include fill-icon-element($data-visualization-green-080-090);
    }
  }

  &__sup {
    background-color: $data-visualization-green-080-090;
    width: fit-content;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    border-radius: $avatar-sections-main-br;
    padding: $avatar-drop-here-sup-padding;

    .MuiTypography-root {
      color: $typography-white;
    }

    svg * {
      @include fill-icon-element($typography-white);
    }
  }
}

.ayo-dialog-confirm__back-drop {
  background: $avatar-dialog-confirm-backdrop;
}

.avatar-dialog {
  &__main-zone {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;

    &.drop-active {
      overflow: visible;
      min-height: 0;
    }
  }

  .guidelines-zone {
    background-color: $avatar-guidelines-zone-bg-color;
    padding: $avatar-guidelines-zone-padding;
    border-radius: $avatar-sections-main-br;
    margin-bottom: $avatar-guidelines-zone-mb;

    ul li {
      @include caption;
    }
  }

  .drop-zone {
    padding: $avatar-drop-zone-padding;
    border-radius: $avatar-sections-main-br;
    background-color: $operational-disabled;
    display: flex;
    align-items: center;
    justify-content: center;

    &__open-button {
      background-color: $primary-050;
    }
  }

  .ayo-dialog__container {
    box-sizing: border-box;
    min-width: $avatar-dialog-min-width;
    padding-left: 0;
    padding-right: 0;

    @media (min-width: 601px) {
      .MuiDialogActions-root,
      .MuiDialogContent-root,
      .MuiDialogTitle-root {
        padding: 0 $avatar-dialog-padding-x;
        box-sizing: border-box;
      }

      .MuiDialogActions-root {
        margin-top: $avatar-dialog-actions-mt;
      }
    }
  }

  @media (max-width: 600px) {
    .ayo-dialog__container {
      min-width: $avatar-dialog-mobile-min-width;
      padding: $avatar-dialog-mobile-py 0;
    }
  }

  .control-button {
    width: $avatar-zoom-buttons-size;
    height: $avatar-zoom-buttons-size;
    min-width: $avatar-zoom-buttons-size;
  }

  .avatar-editor {
    background-color: $avatar-editor-bg-color;
    border-radius: $avatar-sections-main-br;

    &:focus {
      outline: none;
      border: 2px solid $secondary-500;
    }
  }

  .uploaded-file {
    background-color: $operational-background;
    padding-top: $avatar-uploaded-file-py;
    padding-bottom: $avatar-uploaded-file-py;
  }

  .reposition-instruction {
    position: absolute;
    top: $avatar-reposition-instruction-position-top;
    left: 50%;
    transform: translateX(-50%);
    padding: $avatar-reposition-instruction-padding;
    background-color: $avatar-reposition-instruction-bg-color;
    border-radius: $avatar-sections-main-br;
    display: flex;
    align-items: center;
    gap: $avatar-reposition-instruction-content-gap;

    .MuiTypography-root {
      color: $typography-white;
    }
  }

  .ayo-slider {
    margin: 0;

    &,
    .MuiSlider-rail {
      height: $avatar-zoom-slider-height;
      border-radius: $avatar-zoom-slider-br;
    }

    .MuiSlider-track {
      height: $avatar-zoom-slider-height;
      background: $primary-500;
    }

    .MuiSlider-rail {
      background: $operational-background;
      opacity: 1;
    }

    .MuiSlider-mark {
      height: $avatar-zoom-slider-height;
      background-color: transparent;
      border-radius: 0;
      border-top: none;
    }

    .MuiSlider-markLabel {
      color: $typography-white;

      &:nth-last-child(2) {
        transform: translateX(-50%);
      }
    }

    .MuiSlider-thumb {
      width: $avatar-zoom-slider-thumb-size;
      height: $avatar-zoom-slider-thumb-size;
      border-radius: 50%;
      background-color: $primary-500;
      box-shadow: 0 1px 6px 1px RGB(30 32 64 / 25%);

      input:focus + div::before {
        position: absolute;
        width: calc(100% + #{$avatar-zoom-slider-thumb-focus-border-width * 2});
        height: calc(100% + #{$avatar-zoom-slider-thumb-focus-border-width * 2});
        top: -$avatar-zoom-slider-thumb-focus-border-width;
        left: -$avatar-zoom-slider-thumb-focus-border-width;
        border-radius: 50%;
        content: '';
        background-color: $avatar-zoom-slider-thumb-focus-background-color;
      }
    }
  }
}
