$mood-illustration-size: 120px;

.ayo-recommendations-page {
  flex: 1;

  &__mood-illustration {
    width: $mood-illustration-size;
    height: auto;
  }
}
