$agenda-block-min-height: calc(100vh - 2 * 24px);
$agenda-block-bg-color: #f3f3f3;
$agenda-block-border-color: #b8b8b8;
$agenda-block-border-radius: 16px;
$agenda-text-editor-height: calc(100% - 78px);
$agenda-text-editor-error-bottom: -24px;
$agenda-calendar-icon-mr: 12px;
$agenda-green-block-bg-color: #daefb6;
$agenda-green-block-border-color: #abc87b;
$agenda-yellow-block-bg-color: #fdebb4;
$agenda-yellow-block-border-color: #e8c261;
$agenda-blue-block-bg-color: #cff1ff;
$agenda-blue-block-border-color: #5fbbe2;

#lesson-block-agenda {
  display: flex;
  flex-direction: column;
  min-height: $agenda-block-min-height;

  @media (display-mode: fullscreen) {
    padding: 0;

    & > div:first-child {
      display: none;
    }
  }

  .agenda-board-container {
    background-color: $agenda-block-bg-color;
    background-image: url('../../../../../../resources/images/lesson_page_dots.svg');
    border: 1px solid $agenda-block-border-color;
    border-radius: $agenda-block-border-radius;
    display: flex;
    flex-direction: column;
    flex: 1;

    .MuiGrid-root {
      display: flex;
      flex: 1;
    }

    .ayo-typography--label {
      background-color: $agenda-block-bg-color;
      color: $typography-labels-dark;
    }

    .calendar-icon {
      margin-right: $agenda-calendar-icon-mr;

      * {
        @include fill-icon-element($typography-labels-dark);

        stroke-width: 1;
      }
    }

    .ayo-informational-caption {
      margin-left: $agenda-calendar-icon-mr;

      svg * {
        @include fill-icon-element($typography-body);
      }
    }

    .preview-btn {
      background-color: $operational-surface;
      position: absolute;
      right: 0;
      top: 0;
    }

    .ayo-rich-text-editor {
      height: $agenda-text-editor-height;

      .ql-toolbar,
      .ql-container {
        background-color: $operational-surface;
      }

      &--static {
        height: 100%;

        .ql-toolbar,
        .ql-container {
          background-color: transparent;
        }

        .ql-editor {
          padding: 0;
        }
      }

      & + .error-msg {
        position: absolute;
        bottom: $agenda-text-editor-error-bottom;
      }
    }

    .green-wrapper {
      background-color: $agenda-green-block-bg-color;
      border: 2px solid $agenda-green-block-border-color;
      border-radius: $agenda-block-border-radius;
    }

    .yellow-wrapper {
      background-color: $agenda-yellow-block-bg-color;
      border: 2px solid $agenda-yellow-block-border-color;
      border-radius: $agenda-block-border-radius;
    }

    .blue-wrapper {
      background-color: $agenda-blue-block-bg-color;
      border: 2px solid $agenda-blue-block-border-color;
      border-radius: $agenda-block-border-radius;
    }
  }
}
