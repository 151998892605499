$ayo-pie-chart-outline-offset: -10px;
$ayo-pie-chart-outline-width: 10px;
$ayo-pie-chart-outline-stroke-width: 1px;
$ayo-pie-chart-legend-item-margin: 0 14px 0 4px;
$ayo-pie-chart-legend-icon-mb: 5px;

.ayo-pie-chart-component {
  fill: none;
  outline-style: inherit;
  outline-offset: $ayo-pie-chart-outline-offset;
  outline-width: $ayo-pie-chart-outline-width;

  &--read-only {
    .recharts-layer {
      pointer-events: none;
    }
  }

  &:not(&--read-only) {
    .recharts-layer {
      cursor: pointer;
    }
  }
}

.ayo-chart-legend {
  .recharts-legend-item {
    svg {
      margin-bottom: $ayo-pie-chart-legend-icon-mb;
    }

    div {
      margin: $ayo-pie-chart-legend-item-margin;
    }
  }
}
