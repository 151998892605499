$dialog-padding: 64px;
$dialog-min-width: 560px;
$dialog-padding-small: 56px 40px 40px;
$dialog-padding-mobile: 56px 24px 40px;
$dialog-width-small: 400px;
$dialog-border-radius: 12px;
$dialog-margin: 16px;
$dialog-max-height: calc(100% - 32px);
$dialog-max-width-xl: calc(100% - 128px);
$dialog-width-mobile: calc(100% - 56px);
$dialog-element-width: 100%;
$dialog-close-button-position-top: 8px;
$dialog-close-button-position-right: 8px;
$dialog-close-button-icon-size: 24px;
$dialog-1032-max-width: 1032px;
$dialog-900-max-width: 900px;
$dialog-800-max-width: 800px;
$dialog-700-max-width: 700px;
$dialog-600-max-width: 600px;
$dialog-500-max-width: 500px;
$dialog-content-scroll-distance: 47px;
$dialog-content-scroll-distance-mobile: 32px;

.ayo-dialog {
  &__container {
    padding: $dialog-padding;
    min-width: $dialog-min-width;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    @media (max-width: 1600px) {
      padding: $dialog-padding-small;
      min-width: $dialog-width-small;
      max-width: $dialog-width-small;
    }

    @media (max-width: 600px) {
      padding: $dialog-padding-mobile;
      max-width: $dialog-width-mobile;
      min-width: $dialog-width-mobile;
    }

    @media (min-width: 1920px) {
      max-width: $dialog-max-width-xl;
    }

    &-text {
      max-width: unset;
      width: 100%;
      box-sizing: border-box;
    }
  }

  .MuiDialog-paper {
    display: flex;
    border-radius: $dialog-border-radius;
    margin: $dialog-margin;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: flex-start;

    @media (max-width: 600px) {
      height: 100%;
      justify-content: center;
    }
  }

  .MuiDialog-paperFullWidth {
    width: auto;
  }

  .MuiDialog-paperScrollPaper {
    max-height: $dialog-max-height;
  }

  .MuiDialogTitle-root {
    width: $dialog-element-width;
    padding: 0;
  }

  .MuiDialogContent-root {
    padding: 0 $dialog-content-scroll-distance 0 0;
    width: $dialog-element-width;
    overflow: auto;

    @media (max-width: 600px) {
      padding: 0 $dialog-content-scroll-distance-mobile 0 0;
    }
  }

  .MuiDialogActions-root {
    width: $dialog-element-width;
    padding: 0;
    justify-content: flex-start;
  }

  &__paper-center {
    justify-content: center;
    align-items: center;
  }

  &__close-button {
    padding: 0;
    position: absolute;
    top: $dialog-close-button-position-top;
    right: $dialog-close-button-position-right;

    svg {
      height: $dialog-close-button-icon-size;
      width: $dialog-close-button-icon-size;

      * {
        fill: $typography-labels-dark;
      }
    }
  }

  &__paper-width-1032 {
    max-width: $dialog-1032-max-width;
  }

  &__paper-width-900 {
    max-width: $dialog-900-max-width;
  }

  &__paper-width-800 {
    max-width: $dialog-800-max-width;
  }

  &__paper-width-700 {
    max-width: $dialog-700-max-width;
  }

  &__paper-width-600 {
    max-width: $dialog-600-max-width;
  }

  &__paper-width-500 {
    max-width: $dialog-500-max-width;
  }
}
