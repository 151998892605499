$admin-page-min-height: calc(100vh - 96px - 80px);
$admin-page-table-min-height-large: 867px;
$admin-page-table-min-height: 771px;
$admin-page-table-mb: 80px;
$admin-page-role-option-radio-icon-mr: 4px;
$admin-page-role-option-mr: 16px;
$admin-page-filter-box-height: 340px;
$admin-page-menu-item-padding: 12px 28px 12px 16px;

.ayo-admin-page {
  min-height: $admin-page-min-height;
  flex-direction: column;

  &__table {
    min-height: $admin-page-table-min-height;
    margin-bottom: $admin-page-table-mb;

    @media (min-width: 1920px) {
      min-height: $admin-page-table-min-height-large;
    }
  }

  .ayo-radio.MuiRadio-root {
    margin-right: $admin-page-role-option-radio-icon-mr;
  }

  .ayo-form-control-label {
    margin-right: $admin-page-role-option-mr;
  }

  .filters-controls-box {
    min-height: $admin-page-filter-box-height;
  }

  &__filters {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
  }

  &__actions-menu {
    width: auto;

    .MuiMenuItem-root {
      padding: $admin-page-menu-item-padding;
    }
  }
}
