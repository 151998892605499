$extracurriculars-background: #f0f8f2;
$card-border-radius: 16px;
$card-padding: 16px 24px;
$card-box-shadow: 0 4px 8px 2px RGB(0 0 0 / 6%);
$background-position: center top 35px, bottom left 30%;

.ayo-portfolio-extracurriculars {
  background-color: $extracurriculars-background;
  background-image: url('../../../../../resources/images/portfolio/extracurriculars-bg-top.svg'), url('../../../../../resources/images/portfolio/extracurriculars-bg-bottom.svg');
  background-repeat: no-repeat;
  background-position: $background-position;

  &__content {
    &__card {
      background-color: $operational-surface;
      border: none;
      border-radius: $card-border-radius;
      box-shadow: $card-box-shadow;
      color: $primary-500;
      padding: $card-padding;

      &__title {
        color: $primary-500;
      }
    }
  }
}
