$ayo-endorsements-grid-block-max-width: 95%;
$ayo-endorsements-grid-block-margin-top: 26px;
$ayo-endorsements-grid-block-border-left: 2px solid #d1d3fe;
$ayo-endorsements-grid-item-padding: 16px 0 40px 20px;
$ayo-endorsements-grid-item-padding-interested: 0 0 40px 0;
$ayo-endorsements-label-margin-left: -2px;
$ayo-endorsements-label-margin-top: -10px;
$ayo-endorsements-label-top: -10px;
$ayo-endorsements-label-wrapper-min-height: 58px;
$ayo-endorsements-label-wrapper-min-height-large: 64px;
$ayo-endorsements-train-ayo-button-margin-top: 24px;
$ayo-endorsements-timeline-border-bottom: 2px solid #d1d3fe;
$ayo-endorsements-timeline-width: calc(100% + 24px);
$ayo-endorsements-timeline-dot-bottom: -13px;
$ayo-endorsements-timeline-dot-bottom-mobile: 20px;
$ayo-endorsements-timeline-dot-left-mobile: -29px;
$ayo-endorsements-timeline-dot-inactive-bottom: -11px;
$ayo-endorsements-timeline-dot-inactive-padding-top: 4px;
$ayo-endorsements-timeline-dot-inactive-bottom-mobile: 20px;
$ayo-endorsements-timeline-dot-inactive-left: -27px;
$ayo-endorsements-try-mobile-message-margin: 80px auto 0;
$ayo-endorsements-try-mobile-message-margin-read-only: 40px auto 0;

.ayo-endorsements {
  &__grid-block {
    &__interested {
      @media (max-width: 600px) {
        max-width: $ayo-endorsements-grid-block-max-width;

        .MuiGrid-spacing-xs-8 {
          margin: 0;
        }
      }
    }

    @media (max-width: 600px) {
      max-width: $ayo-endorsements-grid-block-max-width;
      border-left: $ayo-endorsements-grid-block-border-left;
      margin-top: $ayo-endorsements-grid-block-margin-top;

      .MuiGrid-spacing-xs-8 {
        margin: 0;
      }
    }
  }

  &__grid-item {
    &__interested {
      &.MuiGrid-item {
        @media (max-width: 600px) {
          padding: $ayo-endorsements-grid-item-padding-interested;

          &.last-item {
            padding-bottom: 0;
          }
        }
      }
    }

    &.MuiGrid-item {
      @media (max-width: 600px) {
        padding: $ayo-endorsements-grid-item-padding;

        &.last-item {
          padding-bottom: 0;
        }
      }
    }
  }

  &__label-wrapper {
    min-height: $ayo-endorsements-label-wrapper-min-height;

    @media (min-width: 1920px) {
      min-height: $ayo-endorsements-label-wrapper-min-height-large;
    }
  }

  &__label {
    @media (max-width: 600px) {
      position: relative;
      top: $ayo-endorsements-label-top;
      margin-left: $ayo-endorsements-label-margin-left;
      margin-top: $ayo-endorsements-label-margin-top;
    }
  }

  &__timeline {
    border-bottom: $ayo-endorsements-timeline-border-bottom;
    width: $ayo-endorsements-timeline-width;

    &.last-item {
      width: auto;
    }

    @media (max-width: 600px) {
      border: none;
    }
  }

  &__timeline-dot {
    position: relative;
    bottom: $ayo-endorsements-timeline-dot-bottom;

    @media (max-width: 600px) {
      bottom: $ayo-endorsements-timeline-dot-bottom-mobile;
      left: $ayo-endorsements-timeline-dot-left-mobile;
    }

    &--inactive {
      position: relative;
      bottom: $ayo-endorsements-timeline-dot-inactive-bottom;
      padding-top: $ayo-endorsements-timeline-dot-inactive-padding-top;

      @media (max-width: 600px) {
        bottom: $ayo-endorsements-timeline-dot-inactive-bottom-mobile;
        left: $ayo-endorsements-timeline-dot-inactive-left;
      }
    }
  }

  &__try-mobile-message {
    margin: $ayo-endorsements-try-mobile-message-margin;

    &.read-only-mode {
      margin: $ayo-endorsements-try-mobile-message-margin-read-only;
    }
  }

  &__train-ayo-button {
    @media (max-width: 600px) {
      margin-top: $ayo-endorsements-train-ayo-button-margin-top;
    }
  }
}
