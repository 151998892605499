$certificates-card-header-background-color: #b68cb8;

.ayo-portfolio-certificates-section {
  background-image: url('../../../../../resources/images/portfolio/certificates_bg.svg');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom;

  .ayo-card .MuiCardContent-root {
    padding: 0;
  }

  &__card {
    &__content {
      &__detail {
        @include extra-large-icon;

        .ayo-typography--label {
          font-weight: bold;
        }

        svg * {
          @include fill-icon-element($typography-body);
        }
      }
    }

    &__header {
      background-color: $certificates-card-header-background-color;
      background-size: cover;

      .ayo-icon-button {
        svg * {
          @include fill-icon-element($typography-white);
        }

        &:focus, &:hover {
          svg * {
            @include fill-icon-element($primary-500);
          }
        }
      }
    }
  }

  .ayo-delete-item-wrapper {
    &:hover, &:focus {
      .ayo-portfolio-certificates-section__card__header {
        background-color: $certificates-card-header-background-color;

        div {
          background-color: $certificates-card-header-background-color;
        }

        &__chip div {
          background-color: $secondary-050;
        }

        .ayo-typography--text-light {
          color: $typography-white;
        }
      }
    }
  }
}

