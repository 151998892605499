$members-section-border-radius: 16px;
$members-table-cell-height-xl: 76px;
$members-table-cell-height: 62px;
$members-table-cell-link-margin-right: 16px;
$members-table-grid-actions-container-padding-right: 16px;
$members-table-requirements-content-border: 1px solid #d4d4d4;
$members-table-requirements-editing-button-margin-bottom: 8px;
$members-table-requirements-padding: 8px 16px;

.ayo-club-hub-details__members-section {
  &__cell {
    display: flex;
    height: $members-table-cell-height;
    align-items: center;
    background-color: $operational-surface;

    @media (min-width: 1920px) {
      height: $members-table-cell-height-xl;
    }

    .ayo-club-hub__club-data-grid-actions__container {
      padding-right: $members-table-grid-actions-container-padding-right;
    }

    &__link {
      .ayo-link {
        font-weight: bold;
        margin-right: $members-table-cell-link-margin-right;
      }
    }
  }

  &__requirements {
    padding: $members-table-requirements-padding;
    border-radius: 0 0 $members-section-border-radius $members-section-border-radius;
    background-color: $operational-surface;

    .ayo-editing-button {
      margin-bottom: $members-table-requirements-editing-button-margin-bottom;
    }

    &__content {
      border-radius: $members-section-border-radius;
      border: $members-table-requirements-content-border;

      .ayo-typography--label {
        font-weight: bold;
      }
    }
  }

  .MuiTableBody-root {
    .MuiTableRow-root {
      td:first-child {
        .ayo-club-hub-details__members-section__cell {
          border-top-left-radius: $members-section-border-radius;
        }

        .ayo-club-hub-details__members-section__cell--simple {
          border-bottom-left-radius: $members-section-border-radius;
        }
      }

      td:last-child {
        .ayo-club-hub-details__members-section__cell {
          border-top-right-radius: $members-section-border-radius;
        }

        .ayo-club-hub-details__members-section__cell--simple {
          border-bottom-right-radius: $members-section-border-radius;
        }
      }

      .MuiTableCell-body {
        padding: 0;
      }
    }
  }
}
