$title-padding-bottom: 8px;
$actions-padding: 0 40px;
$dialog-container-height: 100%;
$dialog-container-width: 624px;
$dialog-classes-container-padding: 12px 16px 0 4px;

.ayo-adjust-schedule-dialog {
  background-color: $operational-background-grey;

  &__title {
    padding-bottom: $title-padding-bottom;
  }

  &__date {
    svg * {
      @include fill-icon-element($operational-overlay);
    }
  }

  &__actions {
    padding: $actions-padding;
    margin-top: auto;
  }

  .ayo-dialog__container {
    height: $dialog-container-height;
    min-width: $dialog-container-width;
    max-width: $dialog-container-width;
    box-sizing: border-box;
  }

  .ayo-dialog__close-button {
    svg * {
      @include fill-icon-element($operational-overlay);
    }

    &:hover {
      svg * {
        @include fill-icon-element($primary-500);
      }
    }
  }

  .MuiDialogContent-root, .ayo-day-schedule {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .ayo-day-schedule, .ayo-day-schedule > div {
    flex-basis: 100%;
    overflow: hidden;
  }

  .ayo-day-schedule > div {
    display: flex;
    flex-direction: column;
  }

  .classes-container {
    padding: $dialog-classes-container-padding;
    overflow: auto;
  }
}
