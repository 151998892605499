$extracurricular-card-mb: 24px;
$available-extracurriculars-divider-mt: 16px;
$extracurricular-card-px: 16px;

.ayo-extracurriculars-block {
  .ayo-card { 
    .MuiDivider-root {
      margin-top: $available-extracurriculars-divider-mt;
    }
  }
  
  .extracurriculars-years-container {
    @media (min-width: 600px) {
      &:first-child {
        padding-right: $extracurricular-card-px;
      }

      &:nth-child(2) {
        padding-left: $extracurricular-card-px;
      }
    }

    @media (max-width: 600px) {
      &:first-child {
        margin-bottom: $extracurricular-card-mb;
      }
    }
  }
}