@import './components/chat-message/chat-message';

$conversations-chat-header-padding: 24px 60px 24px 24px;
$conversations-chat-go-back-mr: 4px;
$conversations-chat-messages-gap: 16px;
$conversations-chat-messages-scroll-padding-block: 400px;

.family-feed-chat {
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: $scrollbar-width-sm;
  }

  &__header {
    display: flex;
    align-items: center;
    padding: $conversations-chat-header-padding;
    background-color: $operational-background-grey;

    &__go-back-icon {
      margin-right: $conversations-chat-go-back-mr;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    row-gap: $conversations-chat-messages-gap;
    flex: 1;
    overflow-y: auto;
    scroll-padding-block: $conversations-chat-messages-scroll-padding-block;

    .ayo-initiative-empty-state {
      margin: auto;

      &__body {
        max-width: 100%;
      }
    }
  }

  &__footer {
    background-color: $operational-background-grey;
  }
}

$ayo-conversation-padding: 16px 24px;
$ayo-conversation-border: 2px solid transparent;
$ayo-conversation-br: 16px;

.ayo-chat-list__conversation {
  padding: $ayo-conversation-padding;
  border: $ayo-conversation-border;
  border-radius: $ayo-conversation-br;

  &:hover {
    border-color: $primary-100;
    background-color: $primary-050;
  }

  &:focus {
    outline: none;
    border-color: $secondary-500;
    background-color: $secondary-050;
  }
}