$ayo-my-profile-button-divider-margin: 0 16px;
$ayo-my-profile-page-interests-info-max-width: 503px;
$reaction-dialog-desktop-width: 500px;
$ayo-my-profile-page-disliked-editing-button-padding-bottom: 6px;
$ayo-my-profile-page-disliked-editing-button-svg-height: 16px;
$section-br: 16px;

.ayo-my-profile-page {
  flex: 1;

  &--interests-info {
    max-width: $ayo-my-profile-page-interests-info-max-width;

    @media (min-width: 1920px) {
      max-width: initial;
    }
  }

  .MuiDivider-vertical {
    margin: $ayo-my-profile-button-divider-margin;
    height: auto;
    background-color: $operational-border;
  }

  .ayo-my-disliked-interests {
    .ayo-editing-button {
      padding-bottom: $ayo-my-profile-page-disliked-editing-button-padding-bottom;

      svg {
        height: $ayo-my-profile-page-disliked-editing-button-svg-height;
      }
    }
  }
}

.ayo-reaction-dialog {
  min-width: $reaction-dialog-desktop-width;
  max-width: $reaction-dialog-desktop-width;

  @media (max-width: 600px) {
    min-width: auto;
  }

  .MuiDialogContent-root {
    overflow: visible;
  }
}
