$ayo-tabs-mb: 32px;
$ayo-tabs-border-width: 1px;
$ayo-tabs-border-radius: 9px;
$ayo-tabs-padding: 4px;
$ayo-tabs-min-height: 38px;
$ayo-tab-min-height: 32px;
$ayo-tab-opacity: 1;
$ayo-tab-pb: 11px;
$ayo-tab-regular-pr: 16px;
$ayo-tab-regular-min-width: 110px;
$ayo-indicator-max-width: 50px;
$ayo-indicator-height: 4px;
$ayo-hotspot-ml: -32px;
$ayo-tabs-outlined-border: 1px solid #d3d9e1;
$ayo-tabs-outlined-br: 16px;
$ayo-tabs-outlined-padding: 8px;
$ayo-tabs-outlined-tab-padding: 6px 16px;
$ayo-tabs-outlined-tab-min-width: 100px;
$ayo-tabs-outlined-tab-selected-border: 2px solid $primary-300;

.ayo-tabs {
  &.MuiTabs-root {
    padding: $ayo-tabs-padding;
    overflow: visible;
    position: relative;
    min-height: $ayo-tabs-min-height;
    align-items: center;

    .MuiTab-root {
      @include button1;

      border-radius: $ayo-tabs-border-radius;
      color: $primary-500;
      min-height: $ayo-tab-min-height;
      opacity: $ayo-tab-opacity;
      padding-bottom: $ayo-tab-pb;
    }

    .ayo-hotspot {
      margin-left: $ayo-hotspot-ml;
    }
  }

  &:not(.ayo-tabs--outlined) {
    .MuiTabs-fixed {
      border-bottom: $ayo-tabs-border-width solid $operational-border;

      .MuiTab-root {
        align-items: flex-start;
        color: $typography-labels;
        min-width: $ayo-tab-regular-min-width;
        padding-left: 0;
        padding-right: $ayo-tab-regular-pr;

        &.Mui-selected {
          color: $primary-500;
        }
      }
    }

    .MuiTabs-indicator {
      background-color: $primary-500;
      border-radius: $ayo-tabs-border-radius;
      max-width: $ayo-indicator-max-width;
      height: $ayo-indicator-height;
    }
  }

  &--outlined {
    &.ayo-tabs.MuiTabs-root {
      border: $ayo-tabs-outlined-border;
      border-radius: $ayo-tabs-outlined-br;
      padding: $ayo-tabs-outlined-padding;

      .MuiTabs-fixed {
        border: none;

        .MuiTabs-indicator {
          display: none;
        }

        .MuiTab-root {
          min-width: $ayo-tabs-outlined-tab-min-width;
          padding: $ayo-tabs-outlined-tab-padding;
          align-items: center;
          color: $primary-500;
          border-radius: $ayo-tabs-outlined-br;

          &.Mui-selected {
            color: $primary-800;
            background-color: $primary-050;
            border: $ayo-tabs-outlined-tab-selected-border;
          }
        }
      }
    }
  }
}
