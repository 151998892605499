@mixin ayo-typography-titles {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: bold;
  color: $typography-titles;
  letter-spacing: 0;
}

@mixin ayo-typography-body {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: $typography-body;
  letter-spacing: 0;
}

@mixin ayo-typography-label {
  color: $typography-labels;
}

@mixin ayo-typography-text--light {
  color: $operational-surface;
}

@mixin ayo-typography--disabled {
  color: $typography-disabled;
}

@mixin ayo-typography--error {
  color: $operational-error;
}

@mixin display {
  @include ayo-typography-titles;

  font-size: 4.209rem;
  line-height: 5.5rem;
}

@mixin h1 {
  @include ayo-typography-titles;

  font-size: 3.157rem;
  line-height: 4.25rem;

  &.MuiTypography-paragraph {
    margin-bottom: 1rem;
  }
}

@mixin h2 {
  @include ayo-typography-titles;

  font-size: 2.369rem;
  line-height: 3.25rem;

  @media (max-width: 600px) {
    line-height: 3.5rem;
  }

  &.MuiTypography-paragraph {
    margin-bottom: 0.667rem;
  }
}

@mixin h3 {
  @include ayo-typography-titles;

  font-weight: normal;
  font-size: 2.369rem;
  line-height: 3.25rem;

  @media (max-width: 600px) {
    line-height: 3.5rem;
  }

  &.MuiTypography-paragraph {
    margin-bottom: 0.5rem;
  }
}

@mixin subtitle1 {
  @include ayo-typography-titles;

  font-size: 1.777rem;
  line-height: 2.625rem;
}

@mixin subtitle2 {
  @include ayo-typography-titles;

  font-size: 1.333rem;
  line-height: 2.1875rem;
}

@mixin label-highlighted {
  @include ayo-typography-titles;

  color: $primary-700;
  font-size: 1rem;
  line-height: 1.5rem;
}

@mixin body1 {
  @include ayo-typography-body;

  font-size: 1.777rem;
  line-height: 3.0625rem;
}

@mixin body2 {
  @include ayo-typography-body;

  font-size: 1.333rem;
  line-height: 2.1875rem;
}

@mixin body3 {
  @include ayo-typography-body;

  font-size: 1rem;
  line-height: 1.6875rem;
}

@mixin button1 {
  @include ayo-typography-titles;

  color: $operational-surface;
  font-size: 1.333rem;
  line-height: 2.0625rem;
  text-transform: none;
}

@mixin link1 {
  @include ayo-typography-body;

  color: $primary-500;
  font-size: 1.333rem;
  line-height: 2.0625rem;
  text-decoration-color: initial;
}

@mixin caption {
  @include ayo-typography-body;

  font-size: 1.166rem;
  line-height: 1.833rem;
}

@mixin quote {
  @include ayo-typography-titles;

  font-style: italic;
  font-size: 2.369rem;
  line-height: 3.75rem;
  position: relative;
  z-index: 1;

  &::before {
    background-repeat: no-repeat;
    display: block;
    position: absolute;
    z-index: -1;
    content: '';

    @media (max-width: 600px) {
      display: none;
    }
  }
}

.ayo-typography {
  &--display {
    @include display;
  }

  &--h1 {
    @include h1;
  }

  &--h2 {
    @include h2;
  }

  &--h3 {
    @include h3;
  }

  &--inherit {
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    letter-spacing: inherit;
  }

  &--subtitle1 {
    @include subtitle1;
  }

  &--subtitle2 {
    @include subtitle2;
  }

  &--label-highlighted {
    @include label-highlighted;
  }

  &--body1 {
    @include body1;
  }

  &--body2 {
    @include body2;
  }

  &--body3 {
    @include body3;
  }

  &--caption {
    @include caption;
  }

  &--quote {
    @include quote;
  }

  &--label {
    @include ayo-typography-label;
  }

  &--text-light {
    @include ayo-typography-text--light;
  }

  &--disabled {
    @include ayo-typography--disabled;
  }

  &--error {
    @include ayo-typography--error;
  }
}
