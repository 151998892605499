$ayo-portfolio-preview-dialog-container-min-width: 620px;
$ayo-portfolio-preview-dialog-container-padding: 40px;
$ayo-portfolio-preview-dialog-content-padding-right: 40px;

.ayo-portfolio__preview-dialog {
  .ayo-dialog__container {
    min-width: $ayo-portfolio-preview-dialog-container-min-width;
    padding: $ayo-portfolio-preview-dialog-container-padding;
  }

  .MuiDialogContent-root {
    padding-right: $ayo-portfolio-preview-dialog-content-padding-right;

    &::-webkit-scrollbar {
      width: $scrollbar-width-sm;
    }
  }
}
