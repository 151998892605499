$ayo-edit-dialog-form-padding: 0;
$ayo-edit-dialog-container-w-md: 644px;
$ayo-edit-dialog-container-w-lg: 864px;
$ayo-edit-dialog-back-button-margin-bottom: 8px;

.ayo-edit-post-dialog {
  &--visibility-setting {
    background-color: $operational-background-grey;
  }

  .ayo-feed-post__form {
    padding: $ayo-edit-dialog-form-padding;
  }

  &__back-button {
    max-width: fit-content;
    margin-bottom: $ayo-edit-dialog-back-button-margin-bottom;
  }

  @include dialog-full-width-mobile;

  @media (min-width: 960px) {
    .ayo-dialog__container {
      box-sizing: border-box;
      max-width: $ayo-edit-dialog-container-w-md;
      min-width: $ayo-edit-dialog-container-w-md;
    }
  }

  @media (min-width: 1920px) {
    .ayo-dialog__container {
      box-sizing: border-box;
      max-width: $ayo-edit-dialog-container-w-lg;
      min-width: $ayo-edit-dialog-container-w-lg;
    }
  }
}
