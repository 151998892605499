$zoom-widget-height: 360px;
$zoom-widget-mr: 16px;
$zoom-widget-button-size: 40px;
$zoom-widget-bg-color: RGB(255 255 255 / 10%);
$zoom-widget-padding: 16px;
$zoom-widget-padding-mobile: 8px;
$zoom-widget-br: 16px;
$zoom-widget-slider-height: 8px;
$zoom-widget-slider-br: 21px;
$zoom-widget-thumb-size: 24px;
$zoom-slider-thumb-focus-border-width: 5px;
$zoom-slider-thumb-focus-background-color: RGB(107 173 106 / 35%);
$zoom-slider-height-mobile: 220px;
$zoom-slider-my-mobile: 16px;
$zoom-button-my: 32px;
$zoom-button-my-mobile: 8px;

.zoom-widget {
  background: $interests-map-widgets-bg-color;
  padding: $zoom-widget-padding;
  border-radius: $zoom-widget-br;
  margin-right: $zoom-widget-mr;
  height: $zoom-widget-height;
  pointer-events: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .ayo-button {
    width: $zoom-widget-button-size;
    min-width: $zoom-widget-button-size;
    height: $zoom-widget-button-size;
    min-height: $zoom-widget-button-size;
    box-sizing: border-box;
    border: 2px solid transparent;

    svg {
      path,
      rect {
        fill: $typography-white;
      }
    }

    &:hover {
      border-color: $primary-100;

      svg {
        path[fill],
        rect[fill] {
          fill: $primary-500;
        }

        path[stroke] {
          stroke: $primary-500;
        }
      }
    }

    &:focus {
      border-color: $secondary-500;
      background: $secondary-050;
      color: #4f4f4f;

      svg path[fill],
      svg rect[fill] {
        fill: $primary-800;
      }

      svg path[stroke] {
        stroke: $primary-800;
      }
    }

    &:first-child {
      margin-bottom: $zoom-button-my;
    }

    &:last-child {
      margin-top: $zoom-button-my;
    }
  }

  .MuiTypography-root {
    color: $typography-white;
  }

  .ayo-slider {
    margin: 0;

    &,
    .MuiSlider-rail {
      width: 8px;
      border-radius: $zoom-widget-slider-br;
    }

    .MuiSlider-track {
      width: 8px;
      background: $data-visualization-green-080-090;
    }

    .MuiSlider-rail {
      background: $operational-background;
      opacity: 1;
    }

    .MuiSlider-mark {
      width: 8px;
    }

    .MuiSlider-markLabel {
      color: $typography-white;

      &:nth-last-child(2) {
        transform: translateX(-50%);
      }
    }

    .MuiSlider-thumb {
      width: $zoom-widget-thumb-size;
      height: $zoom-widget-thumb-size;
      border-radius: 50%;
      background-color: $data-visualization-green-080-090;
      box-shadow: 0 1px 6px 1px RGB(30 32 64 / 25%);

      input:focus + div::before {
        position: absolute;
        width: calc(100% + #{$zoom-slider-thumb-focus-border-width * 2});
        height: calc(100% + #{$zoom-slider-thumb-focus-border-width * 2});
        top: -$zoom-slider-thumb-focus-border-width;
        left: -$zoom-slider-thumb-focus-border-width;
        border-radius: 50%;
        content: '';
        background-color: $zoom-slider-thumb-focus-background-color;
      }
    }
  }

  @media (max-width: 600px) {
    height: auto;
    margin-right: 0;
    padding: $zoom-widget-padding-mobile;

    .zoom-slider {
      height: $zoom-slider-height-mobile;
      margin: $zoom-slider-my-mobile 0;
    }

    .ayo-button:first-child {
      margin-bottom: $zoom-button-my-mobile;
    }

    .ayo-button:last-child {
      margin-top: $zoom-button-my-mobile;
    }
  }
}
