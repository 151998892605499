$ayo-mood-details-page-padding: 24px;
$ayo-mood-details-page-border-radius: 16px;
$ayo-mood-details-page-description-margin-top: 8px;

.ayo-mood-details-page {
  background-color: $operational-surface;
  padding: $ayo-mood-details-page-padding;
  border-radius: $ayo-mood-details-page-border-radius;

  &__description {
    margin-top: $ayo-mood-details-page-description-margin-top;

    @include wrapped-text(3);

    &--collapsed {
      @include wrapped-text(100);
    }
  }
}
