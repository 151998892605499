@import './lesson-planner-create/components/courses-settings/courses-settings';
@import './lesson-planner-create/components/courses-settings/components/rotation-days-schedule/rotation-days-schedule';
@import './lesson-planner-create/components/courses-settings/components/rotation-weeks-schedule/rotation-weeks-schedule';
@import './components/lesson-repository-block/lesson-repository-block';
@import './components/manage-access-dialog/manage-access-dialog';

.ayo-my-lessons-page {
  flex: 1;

  .add-lesson__link {
    &__wrapper {
      width: fit-content;
    }

    &.Mui-disabled {
      .MuiButton-endIcon svg * {
        @include fill-icon-element($typography-disabled);
      }
    }
  }
}
