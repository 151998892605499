$ayo-aptitudes-benefit-section-background: linear-gradient(180deg, RGB(255 231 231 / 71%) 0%, #E4FEDF 100%);
$ayo-aptitudes-benefit-section-card-border-radius: 24px;
$ayo-aptitudes-benefit-section-card-border-radius-sm: 16px;

.ayo-aptitudes-benefit-section {
  background: $ayo-aptitudes-benefit-section-background;

  &__card {
    min-height: 100%;
    border-radius: $ayo-aptitudes-benefit-section-card-border-radius;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 600px) {
      border-radius: $ayo-aptitudes-benefit-section-card-border-radius-sm;
    }
  }
}
