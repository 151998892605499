$ayo-privileges-group-border-radius: 16px;
$ayo-privileges-group-column-gap: 8px;

.ayo-manage-user-privileges-dialog {
  &__privileges-group {
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: $ayo-privileges-group-column-gap;
    border: 1px solid $operational-border;
    border-radius: $ayo-privileges-group-border-radius;
  }
}
