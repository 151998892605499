$question-image-size: 200px;

.ayo-mood-questions-dialog{
  &__button {
    &--selected {
      background-color: $primary-050;
    }
  }

  &__image {
    width: $question-image-size;
    height: $question-image-size;
  }

  @media (max-width: 600px) {
    &.MuiDialog-paperFullWidth {
      width: 100%;
    }
  }
}
