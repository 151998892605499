$informational-message-shadow-position: 0 1px 6px 1px;
$informational-message-border-radius: 16px;
$informational-message-description-padding: 0 16px;
$informational-message-background-position-mobile: top left, bottom right;
$informational-message-text-min-wight: 206px;
$informational-message-text-min-wight-sm: 260px;
$informational-download-button-margin-bottom: 7px;
$informational-download-button-height: 60px;
$informational-download-button-height-xl: 72px;
$informational-download-button-app-store-svg-mr: 16px;

.ayo-try-mobile-informational-message {
  background-color: $operational-surface;
  box-shadow: $informational-message-shadow-position $operational-border;
  border-radius: $informational-message-border-radius;
  width: fit-content;

  @media (max-width: 960px) {
    background-image: url("../../../resources/images/try_mobile_info_bg_mobile_vector.svg"),
    url("../../../resources/images/try_mobile_info_bg_mobile_dots.svg");
    background-repeat: no-repeat;
    background-attachment: initial;
    background-position: $informational-message-background-position-mobile;
  }

  @media (max-width: 600px) {
    width: 100%;
  }


  &__description {
    background-image: url("../../../resources/images/try_mobile_info_bg_descktop.svg");
    background-repeat: no-repeat;
    background-size: contain;
    padding: $informational-message-description-padding;
    border-radius: $informational-message-border-radius;

    @media (max-width: 960px) {
      background-image: none;
    }

    .ayo-link {
      line-height: initial;
    }
  }

  &__text {
    color: $operational-surface;

    @media (min-width: 600px) {
      min-width: $informational-message-text-min-wight-sm;
    }

    @media (min-width: 960px) {
      min-width: $informational-message-text-min-wight;
    }
  }

  &__download-button {
    svg {
      width: auto;
      height: $informational-download-button-height;

      @media (min-width: 1920px) {
        height: $informational-download-button-height-xl;
      }
    }

    &__app-store {
      svg {
        margin-right: $informational-download-button-app-store-svg-mr;

        @media (max-width: 960px) {
          margin-bottom: $informational-download-button-margin-bottom;
        }
      }

      .ayo-link--with-icon + svg {
        width: auto;
        height: $informational-download-button-height;

        @media (min-width: 1920px) {
          height: $informational-download-button-height-xl;
        }
      }
    }
  }
}
