$activity-log-button-badge-offset: 3px;
$activity-log-button-badge-width-height: 12px;
$activity-log-dialog-size: 540px;
$activity-log-dialog-title-pb: 40px;
$activity-log-dialog-export-right: 60px;
$activity-log-dialog-export-right-sm: 35px;
$activity-log-dialog-actions-py: 24px;
$activity-log-list-item-py: 16px;
$activity-log-list-item-badge-left: 8px;
$activity-log-load-next-size: 1px;
$activity-log-primary-color-w-opacity: RGB(96 101 168 / 25%);
$activity-log-green-color-w-opacity: RGB(176 212 117 / 25%);
$activity-log-grey-color-w-opacity: RGB(193 192 201 / 25%);
$activity-log-secondary-color-w-opacity: RGB(255 224 178 / 25%);

.ayo-activity-log {
  &__button {
    .MuiBadge-badge {
      width: $activity-log-button-badge-width-height;
      height: $activity-log-button-badge-width-height;

      &.MuiBadge-anchorOriginTopRightCircular {
        right: $activity-log-button-badge-offset;
        top: $activity-log-button-badge-offset;
      }
    }
  }

  &__dialog {
    .ayo-dialog__container {
      max-width: $activity-log-dialog-size;
      padding-bottom: 0;

      @media (min-width: 960px) {
        min-width: $activity-log-dialog-size;
      }
    }

    .MuiBadge-dot {
      border: none;
    }

    .MuiDialogTitle-root {
      align-items: center;
      display: flex;
      padding-bottom: $activity-log-dialog-title-pb;

      .MuiButton-root {
        position: absolute;
        right: $activity-log-dialog-export-right;

        @media (max-width: 600px) {
          right: $activity-log-dialog-export-right-sm;
        }
      }
    }

    .MuiDialogActions-root {
      padding-top: $activity-log-dialog-actions-py;
      padding-bottom: $activity-log-dialog-actions-py;
    }

    .MuiListItem-root {
      align-items: flex-start;
      padding-top: $activity-log-list-item-py;
      padding-bottom: $activity-log-list-item-py;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }

      &:focus-visible {
        outline: none;
      }

      .MuiBadge-badge {
        left: $activity-log-list-item-badge-left;
        top: $activity-log-list-item-badge-left / 2;
      }
    }
  }

  &__avatar {
    &--green {
      background-color: $activity-log-green-color-w-opacity;
    }

    &--grey {
      @include subtitle2;

      background-color: $activity-log-grey-color-w-opacity;
      color: $typography-disabled;
    }

    &--primary {
      @include subtitle2;

      background-color: $activity-log-primary-color-w-opacity;
      color: $primary-400;
    }

    &--secondary {
      background-color: $activity-log-secondary-color-w-opacity;

      &--filled {
        svg * {
          @include fill-icon-element($secondary-700);
        }
      }
    }
  }

  &__load-next-anchor {
    width: $activity-log-load-next-size;
    height: $activity-log-load-next-size;
  }
}
