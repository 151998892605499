$search-widget-width: 320px;
$search-widget-open-button-size: 54px;
$search-widget-open-button-size-lg: 71px;
$search-widget-open-button-icon-size: 24px;
$search-widget-stt-margin: 64px;

.search-widget {
  width: $search-widget-width;

  & > * {
    pointer-events: auto;
  }

  .ayo-stt-helper {
    .MuiTooltip-tooltipPlacementBottom {
      margin-top: $search-widget-stt-margin;
    }
  }

  .ayo-button {
    height: $search-widget-open-button-size;
    width: $search-widget-open-button-size;
    box-sizing: border-box;

    @media (min-width: 1920px) {
      height: $search-widget-open-button-size-lg;
      width: $search-widget-open-button-size-lg;
    }

    svg {
      width: $search-widget-open-button-icon-size;
      height: $search-widget-open-button-icon-size;
    }

    svg path[fill] {
      fill: $typography-white;
    }

    svg path[stroke] {
      stroke: $typography-white;
    }
  }

  .ayo-textfield {
    .MuiAutocomplete-inputRoot {
      background-color: $interests-map-widgets-bg-color;
    }

    .MuiAutocomplete-popupIndicator {
      background: transparent;
    }

    &__input-label,
    .MuiInputBase-input {
      color: $typography-white;
      background: none;
    }

    .Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: $operational-border;
    }

    button {
      border: 2px solid transparent;

      svg {
        path[fill] {
          fill: $typography-white;
        }

        path[stoke] {
          stroke: $typography-white;
        }
      }

      &:hover {
        border-color: $primary-100;
        background: $primary-050;

        svg {
          path[fill],
          rect[fill] {
            fill: $primary-500;
          }

          path[stroke] {
            stroke: $primary-500;
          }
        }
      }

      &:focus {
        border-color: $secondary-500;
        background: $secondary-050;

        .ayo-searchfield__action-icon {
          outline: none;
        }

        svg path[fill],
        svg rect[fill] {
          fill: $primary-800;
        }

        svg path[stroke] {
          stroke: $primary-800;
        }
      }
    }
  }
}
