$fab-sticky-bottom: 16px;
$fab-sticky-bottom-large: 24px;
$fab-sticky-padding-right: 12px;
$fab-sticky-padding-right-large: 48px;

.page-end-scroll-to-top-container {
  position: sticky;
  bottom: $fab-sticky-bottom;
  display: flex;
  justify-content: flex-end;
  padding-right: $fab-sticky-padding-right;
  width: fit-content;
  margin-left: auto;

  @media (min-width: 1920px) {
    padding-right: $fab-sticky-padding-right-large;
    bottom: $fab-sticky-bottom-large;
  }
}
