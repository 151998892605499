$date-display-size: 36px;
$date-display-size-xl: 48px;
$date-display-ml: -8px;
$schedule-page-header-pb: 40px;
$schedule-page-calendar-pt: 40px;
$schedule-page-calendar-box-shadow: inset 0 10px 6px -6px RGB(0 0 0 / 6%);
$schedule-page-calendar-selected-date-margin: 0 32px 0 16px;
$tours-menu-padding-top: 80px;
$hotspot-margin-left: 8px;

.ayo-schedule-page {
  background-color: $operational-surface;

  &__main {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__header {
    padding-bottom: $schedule-page-header-pb;
  }

  &__calendar {
    &__wrapper {
      background-color: $operational-background-grey;
      padding-top: $schedule-page-calendar-pt;
      box-shadow: $schedule-page-calendar-box-shadow;
      flex: 1;
    }

    &__selected-date {
      color: $primary-500;
      margin: $schedule-page-calendar-selected-date-margin;
      cursor: pointer;
    }
  }

  .ayo-bottom-bar {
    background-color: $operational-background-grey;
  }

  .ayo-tabs.MuiTabs-root .ayo-hotspot {
    margin-left: $hotspot-margin-left;
  }

  .ayo-tours-menu__container {
    padding-top: $tours-menu-padding-top;
    background-color: $operational-background-grey;
  }

  .add-content__hotspot--hidden {
    display: none;
  }
}
