$ayo-image-file-previewer-image-br: 16px;
$ayo-image-file-previewer-controls-indent: 24px;
$ayo-image-file-previewer-controls-ml: 16px;
$ayo-image-file-image-height: 360px;

.ayo-image-file-previewer {
  position: relative;

  &__image {
    width: 100%;
    border-radius: $ayo-image-file-previewer-image-br;
    object-fit: cover;

    &__multiple {
      object-fit: scale-down;
      max-height: $ayo-image-file-image-height;
      min-height: $ayo-image-file-image-height;
    }
  }

  &__controls {
    position: absolute;
    right: $ayo-image-file-previewer-controls-indent;
    top: $ayo-image-file-previewer-controls-indent;

    &>.ayo-button:not(:first-child) {
      margin-left: $ayo-image-file-previewer-controls-ml;
    }
  }
}
