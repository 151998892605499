$session-timeout-dialog-content-max-width: 460px;
$session-timeout-dialog-content-max-width-small: 640px;

.ayo-session-timeout-dialog--content {
  max-width: $session-timeout-dialog-content-max-width;

  @media (max-width: 640px) {
    max-width: $session-timeout-dialog-content-max-width-small;
  }
}
