$fileupload-list-item-radius: 16px;
$fileupload-list-item-title-max-width: 160px;
$fileupload-list-item-label-ml: 8px;
$fileupload-list-item-error-bg-color: RGB(210 101 86 / 5%);

.ayo-fileupload {
  &__image-preview {
    width: 100%;
  }

  &__list-item {
    border-radius: $fileupload-list-item-radius;

    .ayo-typography--subtitle2 span {
      display: inline-block;
      vertical-align: top;

      &:first-child {
        max-width: $fileupload-list-item-title-max-width;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &:last-child {
        margin-left: $fileupload-list-item-label-ml;
      }
    }

    .ayo-typography--label {
      margin-left: $fileupload-list-item-label-ml;
    }

    &--success {
      background-color: $operational-background;

      .ayo-typography--label {
        color: $typography-labels-dark;
      }
    }

    &--error {
      background-color: $fileupload-list-item-error-bg-color;

      .ayo-typography--label {
        color: $operational-error;
      }
    }
  }

  &__add-btn {
    p {
      font-weight: 700;
    }

    svg {
      height: auto;
      width: auto;
    }
  }
}
