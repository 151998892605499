$ayo-mood-card-height: 152px;
$ayo-mood-card-main-content-padding: 20px;
$ayo-mood-card-illustration-height: 80px;
$ayo-support-card-background: #51a7db;

.ayo-mood-card {
  background-color: $primary-700;
  height: $ayo-mood-card-height;
  display: flex;
  justify-content: center;

  .ayo-card__main-content {
    align-items: center;
    padding: $ayo-mood-card-main-content-padding;
    height: calc(100% - #{$ayo-mood-card-main-content-padding} * 2);
  }

  &__illustration {
    height: $ayo-mood-card-illustration-height;
  }

  &__title {
    color: $typography-white;
  }

  &__support {
    background-color: $ayo-support-card-background;
  }
}

.ayo-mood-card.ayo-clickable-card {
  &:hover {
    .ayo-mood-card__title {
      color: $primary-500;
    }
  }

  &:focus {
    .ayo-mood-card__title {
      color: $primary-800;
    }
  }
}
