$ff-post-badges-br: 16px;
$ff-post-badges-py: 16px;
$ff-post-svg-height: 250px;
$ff-post-svg-mobile-height: 120px;

@mixin family-feed-post {
  background-color: $primary-050;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: $ff-post-badges-br;
  padding: $ff-post-badges-py 0;
}

.ayo-club-link{
  color: $primary-500
}

.family-feed-post__badges {
  @include family-feed-post;
}

.family-feed-post__achievements {
  @include family-feed-post;

  background-image: url('../../../resources/images/achievements_background.svg');
  background-size: contain;

  svg {
    height: $ff-post-svg-height;
    width: auto;
  }
}

@media (max-width: 600px) {
  .family-feed-post__achievements {
    svg {
      height: $ff-post-svg-mobile-height;
      width: auto;
    }
  }
}

$family-feed-post-conversation-opener-button-padding: 4px;
$family-feed-post-conversation-opener-button-br: 9px;

.family-feed-post__conversations-opener {
  background-color: $primary-050;
  padding: $family-feed-post-conversation-opener-button-padding;
  border-radius: $family-feed-post-conversation-opener-button-br;
  font-weight: bold;
}