$lottie-border: 2px solid transparent;
$lottie-border-radius: 16px;

.ayo-lottie {
  svg {
    border: $lottie-border;
    border-radius: $lottie-border-radius;
    box-sizing: border-box;
  }

  div[role="button"]:focus svg {
    border-color: $secondary-500;
  }
}
