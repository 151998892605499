$ayo-badge-card-card-focus-outline-size: 2px;
$ayo-badge-card-min-width: 152px;
$ayo-badge-card-svg-width: 88px;
$ayo-badge-card-min-height: 160px;
$ayo-badge-card-min-height-large: 180px;
$ayo-badge-card-padding: 10px 6px;
$ayo-badge-card-rating-svg-width: 16px;
$ayo-badge-card-rating-min-height: 20px;

.ayo-badge-card {
  min-width: $ayo-badge-card-min-width;
  padding: 0;

  &__title{
    text-align: center;
  }

  &--clickable {
    cursor: pointer;

    .ayo-typography--subtitle2 {
      color: $primary-500;
    }

    &:hover {
      background-color: $primary-050;
      outline: $ayo-badge-card-card-focus-outline-size solid $primary-100;
    }

    &:focus {
      background-color: $secondary-050;
      outline: $ayo-badge-card-card-focus-outline-size solid $secondary-500;

      .ayo-clickable-card__title {
        color: $primary-800;
      }
    }
  }

  &.ayo-card .MuiCardContent-root {
    padding: $ayo-badge-card-padding;
  }

  &__content {
    min-height: $ayo-badge-card-min-height;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;

    svg {
      width: $ayo-badge-card-svg-width;
      height: auto;
    }

    @media (min-width: 1920px) {
      min-height: $ayo-badge-card-min-height-large;
    }
  }

  &__rating{
    display: flex;
    justify-content: center;
    min-height: $ayo-badge-card-rating-min-height;

    svg {
      width: $ayo-badge-card-rating-svg-width;
      height: auto;
    }
  }
}
