$ayo-youtube-card-margin: 12px 8px;
$ayo-youtube-card-height: 240px;
$ayo-youtube-card-width: 240px;
$ayo-youtube-card-padding: 16px;

.ayo-youtube-card {
  .ayo-card {
    margin: $ayo-youtube-card-margin;
    background-image: url("../../../../resources/images/youtube_card.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;

    @media (max-width: 960px) {
     height: $ayo-youtube-card-height;
    }

    @media (max-width: 600px) {
      width: $ayo-youtube-card-width;
    }
  }

  .MuiCardContent-root {
    height: 100%;
    padding: $ayo-youtube-card-padding;
    text-align: left;
  }

  &__caption {
    max-width: 90%;
  }
}
