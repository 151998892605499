$interest-suggested-hover-color: #FBD44C;
$interest-suggested-focus-stroke-width: 2px;

g.interest-figure {
  .innertext {
    @include label-highlighted;
    @include ayo-typography-titles;

    color: $typography-white;
  }

  &.interest-suggested, &.interest-other {
    .innertext {
      color: $typography-titles;
    }
  }

  .uppertext-object {
    overflow: visible;

    .uppertext-container {
      transform: translateX(-50%);

      p {
        @include ayo-typography-titles;
        @include label-highlighted;

        color: $typography-white;
        white-space: nowrap;
      }
    }
  }

  &[tabindex] {
    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }

    &.interest-suggested:focus {
      circle {
        stroke-width: $interest-suggested-focus-stroke-width;
        stroke: $primary-050;
        fill: $secondary-500;
      }
    }

    &.interest-suggested:hover {
      circle {
        fill: $interest-suggested-hover-color;
      }
    }

    &.interest-liked:focus {
      .outer-main-circle {
        stroke: $secondary-500;
      }

      .inner-circle {
        fill: $secondary-200
      }

      .innertext {
        color: $primary-800;
      }
    }

    &.interest-liked:hover {
      .outer-main-circle {
        stroke: $primary-100;
      }

      .inner-circle {
        fill: $primary-600;
      }

      .innertext {
        color: $typography-white;
      }
    }
  }
}