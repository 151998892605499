$ayo-lesson-repository-badge-bg-color: #eaebff;
$ayo-lesson-repository-badge-pl: 4px;
$ayo-lesson-repository-accordion-p: 16px 0;
$ayo-lesson-repository-accordion-item-p: 8px 32px;
$ayo-lesson-repository-accordion-item-mt: 8px;
$ayo-lesson-repository-accordion-item-br: 16px;
$ayo-lesson-repository-accordion-hotspot-ml: -8px;

.ayo-lesson-repository {
  &__tabs.ayo-tabs.ayo-tabs--outlined.MuiTabs-root {
    background-color: $operational-surface;
    border: none;
    width: fit-content;

    .MuiTab-root {
      min-width: 180px;
    }
  }

  &__accordion {
    background-color: transparent;
    margin-top: 0;

    .MuiAccordionDetails-root {
      padding: $ayo-lesson-repository-accordion-p;
    }
  }

  &__item {
    background-color: $operational-surface;
    padding: $ayo-lesson-repository-accordion-item-p;
    margin-top: $ayo-lesson-repository-accordion-item-mt;
    border-radius: $ayo-lesson-repository-accordion-item-br;
    align-items: center;

    &__link {
      font-weight: bold;
    }

    &__hotspot {
      margin-left: $ayo-lesson-repository-accordion-hotspot-ml;

      .ayo-hotspot--inner {
        background-color: $ayo-lesson-status-badge-draft-hotspot-bcolor;
      }

      &--published {
        .ayo-hotspot--inner {
          background-color: $ayo-lesson-status-badge-published-hotspot-bcolor;
        }
      }
    }
  }

}
