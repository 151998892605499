$data-grid-cell-padding: 0 16px;
$data-grid-cell-height: 23px;
$data-grid-pagination-pt: 24px;
$data-grid-pagination-item-height: 40px;
$data-grid-pagination-item-min-width: 40px;
$data-grid-pagination-item-border-radius: 16px;
$data-grid-pagination-item-font-size: 1.333rem;
$data-grid-pagination-item-line-height: 2.083rem;
$data-grid-pagination-item-icon-height: 2.25rem;
$data-grid-pagination-divider-height: 24px;
$data-grid-pagination-divider-margin: 0 24px;
$data-grid-pagination-input-padding: 0 24px 0 8px;
$data-grid-pagination-input-width: 196px;
$data-grid-pagination-input-width-large-screen: 244px;

.ayo-data-grid {
  border: none;

  .MuiDataGrid-columnHeaders,
  .MuiDataGrid-footerContainer {
    border: none;
  }

  .MuiDataGrid-columnSeparator {
    display: none;
  }

  .MuiDataGrid-colCellWrapper {
    height: $data-grid-cell-height;
  }

  .MuiDataGrid-cell,
  .MuiDataGrid-columnHeader {
    border: none;
    padding: $data-grid-cell-padding;

    &:focus {
      outline: none;
    }
  }

  .MuiDataGrid-footer.MuiDataGrid-footer-justifyContentEnd {
    justify-content: flex-start;
  }

  .MuiDataGrid-row.Mui-selected,
  .MuiDataGrid-row:hover {
    background-color: $primary-050;
  }

  &__cell-tooltip {
    & > .MuiBox-root {
      display: block;
    }
  }

  &-header__sorted--desc {
    display: inline-flex;
    align-items: center;
    font-weight: bold;
  }

  &.MuiDataGrid-root .MuiDataGrid-main {
    .MuiDataGrid-columnHeader,
    .MuiDataGrid-cell {
      outline: none;
    }
  }
}

.ayo-pagination {
  flex: 1;
  padding-top: $data-grid-pagination-pt;

  .ayo-pagination__divider {
    height: $data-grid-pagination-divider-height;
    margin: $data-grid-pagination-divider-margin;
    background-color: $operational-border;
  }

  .ayo-textfield {
    width: $data-grid-pagination-input-width;
    padding: $data-grid-pagination-input-padding;

    @media (min-width: 1920px) {
      width: $data-grid-pagination-input-width-large-screen;
    }
  }

  .ayo-pagination__pages {
    .MuiPaginationItem-page {
      height: $data-grid-pagination-item-height;
      min-width: $data-grid-pagination-item-min-width;
      border-radius: $data-grid-pagination-item-border-radius;
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: $data-grid-pagination-item-font-size;
      line-height: $data-grid-pagination-item-line-height;
      color: $primary-500;

      &.Mui-selected,
      &:hover {
        background-color: $primary-050;
        border: 2px solid $primary-100;
      }

      .MuiPaginationItem-icon {
        font-size: $data-grid-pagination-item-icon-height;
      }
    }
  }
}
