$goal-form-milestone-timeline-mb: 16px;
$goal-form-milestone-timeline-dot-border: 4px;
$goal-form-info-text-icon-margin: 0 0 5px;

.ayo-goal-form {
  .ayo-timeline {
    padding: 0;
  }

  &__milestone-timelines {
    .MuiTimelineOppositeContent-root {
      max-width: 100%;
      min-width: 0;
    }

    .MuiTimelineItem-root {
      .MuiTimelineDot-root::before {
        content: none;
      }

      .MuiTimelineOppositeContent-root {
        width: 100%;
      }

      .MuiTimelineSeparator-root,
      .MuiTimelineOppositeContent-root {
        padding-top: 0;
        padding-bottom: 0;
      }

      .MuiTimelineDot-root {
        background-color: $operational-surface;
        border: $goal-form-milestone-timeline-dot-border solid $secondary-500;
      }

      .MuiTimelineDot-root .ayo-goal-milestone-timeline-dot-completed .MuiTimelineDot-defaultGrey {
        background-color: $secondary-500 !important;
      }

      .MuiTimelineConnector-root {
        background-color: $operational-border-alpha;
      }
    }

    .ayo-timelines-completed {
      background-color: $secondary-500 !important;
    }

    &__card {
      margin-bottom: $goal-form-milestone-timeline-mb;

      &--no-margin {
        margin-bottom: 0;
      }
    }
  }

  &__info-text {
    @include icon-text-align($goal-form-info-text-icon-margin);
  }
}
