$leadership-attributes-section-background-color: #FFDBD0;
$leadership-attributes-section-images-block-padding-right: 12px;
$leadership-attributes-section-images-card-min-height: 174px;
$leadership-attributes-section-images-card-min-height-large: 192px;
$leadership-attributes-section-images-card-svg-dimentions: 86px;
$leadership-attributes-section-graph-margin-top: 24px;
$leadership-attributes-section-graph-border-radius: 16px;
$leadership-attributes-section-graph-padding: 16px;

.ayo-portfolio__leadership-attributes-section {
  background-color: $leadership-attributes-section-background-color;

  &__images-block {
    display: flex;
    flex-wrap: wrap;
    padding-right: $leadership-attributes-section-images-block-padding-right;
  }

  &__card {
    display: flex;
    justify-content: center;
    text-align: center;
    min-height: $leadership-attributes-section-images-card-min-height;

    @media (min-width: 1920px) {
      min-height: $leadership-attributes-section-images-card-min-height-large;
    }

    &__title {
      vertical-align: middle;
      text-align: center;
      color: $primary-500;
    }

    svg {
      width: $leadership-attributes-section-images-card-svg-dimentions;
      height: $leadership-attributes-section-images-card-svg-dimentions;
    }
  }

  &__graph {
    margin-top: $leadership-attributes-section-graph-margin-top;

    .ayo-leadership-attributes-graph {
      border-radius: $leadership-attributes-section-graph-border-radius;
      padding: $leadership-attributes-section-graph-padding;
    }

    .ayo-radar-chart {
      flex-direction: row-reverse;

      @media (max-width: 960px) {
        flex-direction: column-reverse;
      }
    }

    .MuiFormControlLabel-root {
      cursor: initial;
    }
  }
}
