$notifications-popover-max-height: 400px;
$notifications-popover-mb: 24px;
$notifications-popover-box-shadow: 0 12px 16px 6px RGB(0 0 0 / 6%);
$notifications-popover-border-radius: 16px;
$notification-item-border-radius: 16px;
$notification-item-margin: 16px 0;
$notification-item-max-width: 430px;
$notification-item-padding: 8px 8px 8px 20px;
$notification-item-badge-top: 20px;
$notification-item-badge-left: 10px;
$notification-item-chip-bg: #dfeff8;
$students-notification-item-chip-bg: #e7f8df;
$notification-item-chip-padding: 0 8px;
$notification-item-text-padding: 12px 16px;
$notification-item-svg-padding-right: 4px;
$notification-item-svg-width-height: 24px;

.ayo-notifications {
  &__item-text {
    color: $primary-500;
    padding: $notification-item-text-padding;
  }

  &__trend {
    background-color: $secondary-050;
  }

  &__popover {
    background-color: transparent;

    .ayo-editing-button {
      svg * {
        @include fill-icon-element($primary-500);

        fill-opacity: 1;
        stroke-opacity: 1;
      }
    }

    .MuiPopover-paper {
      overflow: hidden;
    }

    .MuiBackdrop-invisible {
      background-color: transparent;
    }

    // TODO: should be applied globally for all Menus, Popovers, Dropdowns (see UI Refactoring)
    .MuiPaper-elevation8 {
      box-shadow: $notifications-popover-box-shadow;
    }

    .MuiPaper-rounded {
      border-radius: $notifications-popover-border-radius;
    }

    .MuiList-root {
      max-height: $notifications-popover-max-height;

      @media (max-width: 600px) {
        max-height: calc(100vh - 240px);
      }

      overflow-x: hidden;
      overflow-y: auto;
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: $notifications-popover-mb;
    }

    .MuiListItem-root {
      border: 2px solid transparent;
      border-radius: $notification-item-border-radius;
      align-items: flex-start;
      flex-direction: column;
      margin: $notification-item-margin;
      padding: $notification-item-padding;
      max-width: $notification-item-max-width;

      &:first-of-type {
        margin-top: 0;
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      &:hover {
        background-color: $primary-050;
        border-color: $primary-100;
      }

      &.Mui-focusVisible {
        background-color: $secondary-050;
        border-color: $secondary-500;
      }

      .MuiBadge-root {
        position: absolute;
        top: $notification-item-badge-top;
        left: $notification-item-badge-left;
      }

      .ayo-chip {
        @include caption;

        background-color: $notification-item-chip-bg;
        color: $primary-900;
        padding: $notification-item-chip-padding;

        svg {
          padding-right: $notification-item-svg-padding-right;
          width: $notification-item-svg-width-height;
          height: $notification-item-svg-width-height;

          * {
            @include fill-icon-element($primary-900);
          }
        }

        &.my-students,
        &.students,
        &.family-feed,
        &.my-feed {
          background-color: $students-notification-item-chip-bg;
        }

        &.club-hub {
          background-color: #f8f5df;
        }
      }
    }
  }
}
