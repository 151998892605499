$club-banner-upload-container-br: 16px;
$club-banner-divider-width: 32px;

.club-banner__upload-container {
  background-color: $operational-disabled-bg;
  border-radius: $club-banner-upload-container-br;

  .drop-here {
    margin: 0;
  }

  &__divider {
    width: $club-banner-divider-width;
    background: $typography-labels-dark;
  }
}
