$avatar-4x-size: 160px;
$avatar-3x-size: 120px;
$avatar-normal-size: 40px;

.ayo-avatar {
  &--full {
    height: auto;
    width: auto;
  }

  &--4x {
    height: $avatar-4x-size;
    width: $avatar-4x-size;
  }

  &--3x {
    height: $avatar-3x-size;
    width: $avatar-3x-size;
  }

  &--normal {
    height: $avatar-normal-size;
    width: $avatar-normal-size;
  }
}
