$ayo-academic-record-bg: linear-gradient(180deg, RGB(142 48 120 / 65%) 0, RGB(0 0 0 / 15%) 100%), linear-gradient(180deg, #6065A8 0, RGB(92 67 161 / 84%) 100%);
$ayo-academic-record-caption-mt: 8px;

.ayo-portfolio__academic-record-section {
  background: url('../../../../../resources/images/portfolio/academic_record_bg.svg') no-repeat left top 130px, $ayo-academic-record-bg;
  background-size: 100% auto;

  &__caption {
    margin-top: $ayo-academic-record-caption-mt;
    color: $typography-white;
  }
}
