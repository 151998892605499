@import "./components/sections/lesson-planner-about/lesson-planner-about-section";
@import "./components/sections/lesson-planner-definition/lesson-planner-definition-section";
@import "./components/sections/lesson-planner-explore-more/lesson-planner-explore-more-section";
@import "./components/sections/lesson-planner-faq/lesson-planner-faq-section";
@import "./components/sections/lesson-planner-for-who/lesson-planner-for-who-section";
@import "./components/sections/lesson-planner-how-it-works/lesson-planner-how-it-works-section";
@import "./components/sections/lesson-planner-what-is-it/lesson-planner-what-is-it";

.ayo-lesson-planner-landing {
  background-color: $operational-surface;

  &__sections {
    background-image: url("../../../resources/images/lesson-planner/main_desktop_bg.svg");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 100% auto;

    @media (max-width: 600px) {
      background-image: url("../../../resources/images/lesson-planner/main_mobile_bg.svg");
      background-size: contain;
      background-position: top 2.5% center;
    }
  }
}
