$train-ayo-page-questionnaire-illustration-position-top: calc(50% - 125px);
$train-ayo-page-questionnaire-fab-offset: 72px;
$train-ayo-page-questionnaire-fab-pb: 16px;

.ayo-questionnaire-page {
  white-space: pre-wrap;

  .ayo-bottom-bar--simple {
    margin-top: 0;
  }

  .ayo-tours-fab__wrapper {
    margin-top: -$train-ayo-page-questionnaire-fab-offset;
    padding-bottom: $train-ayo-page-questionnaire-fab-pb;
  }

  &--main-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    position: relative;
    padding-bottom: $train-ayo-page-questionnaire-fab-offset;
  }

  &__questionnaire-illustration {
    top: $train-ayo-page-questionnaire-illustration-position-top;
    width: 100%;
    display: flex;
  }

}
