$opportunities-explore-more-section-border-radius: 40px;
$opportunities-explore-more-section-border-radius-mobile: 32px;
$opportunities-explore-more-section-chip-padding: 0 8px;
$opportunities-explore-more-section-chip-margin-bottom: 8px;
$opportunities-explore-more-section-svg-margin-bottom: -5px;
$opportunities-explore-more-section-student-bg-color: #304D97;
$opportunities-explore-more-section-student-bg-color: #304D97;
$opportunities-explore-more-section-teacher-bg-color: #5B4783;
$opportunities-explore-more-section-teacher-logged-out-bg-color: #F6F1FF;
$opportunities-explore-more-section-parent-bg-color: #30663C;
$opportunities-explore-more-section-parent-logged-out-bg-color: #E5FFEB;
$opportunities-explore-more-section-student-chip-bg-color: #CBE3FC;
$opportunities-explore-more-section-teacher-chip-bg-color: #DBC9FE;
$opportunities-explore-more-section-parent-chip-bg-color: #AAEAA9;

@mixin opportunities-explore-more-block($chip-color, $background-color) {
  .ayo-chip {
    background-color: $chip-color;
  }

  background-color: $background-color;
}

@mixin parent-teacher-logged-out-block($justify-content) {
  svg {
    margin: 0;
  }

  &__illustration-container {
    display: flex;
    justify-content: $justify-content;
  }

  @media (min-width: 960px) {
    svg {
      position: absolute;
      bottom: 0;
    }
  }
}

.ayo-opportunities-explore-more-section {
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  border-radius: $opportunities-explore-more-section-border-radius;

  svg {
    margin-bottom: $opportunities-explore-more-section-svg-margin-bottom;
  }

  &__button {
    background-color: $typography-white;
  }


  @media (max-width: 600px) {
    border-radius: $opportunities-explore-more-section-border-radius-mobile;
  }

  .ayo-chip {
    @include subtitle2;

    padding: $opportunities-explore-more-section-chip-padding;
    margin-bottom: $opportunities-explore-more-section-chip-margin-bottom;
  }

  &--student {
    @include opportunities-explore-more-block($opportunities-explore-more-section-student-chip-bg-color,
      $opportunities-explore-more-section-student-bg-color
    );
  }

  &--teacher {
    @include opportunities-explore-more-block($opportunities-explore-more-section-teacher-chip-bg-color,
      $opportunities-explore-more-section-teacher-bg-color
    );

    &--logged-out {
      @include parent-teacher-logged-out-block(start);

      svg {
        left: 0;
      }

      @include opportunities-explore-more-block($opportunities-explore-more-section-teacher-chip-bg-color,
        $opportunities-explore-more-section-teacher-logged-out-bg-color,
      );
    }
  }

  &--parent {
    @include opportunities-explore-more-block($opportunities-explore-more-section-parent-chip-bg-color,
      $opportunities-explore-more-section-parent-bg-color
    );

    &--logged-out {
      @include parent-teacher-logged-out-block(end);

      svg {
        right: 0;
      }

      @include opportunities-explore-more-block($opportunities-explore-more-section-parent-chip-bg-color,
        $opportunities-explore-more-section-parent-logged-out-bg-color,
      );
    }
  }
}
