.ayo-feedback-dialog {
  overflow: visible;

  @media (max-width: 600px) {
    &.MuiDialog-paperFullWidth.MuiDialog-paper {
      width: 100%;
      justify-content: center;
    }

    &.MuiDialog-paperScrollPaper {
      height: inherit;
    }

    .ayo-dialog__container {
      max-width: 100%;
    }
  }

  .MuiDialogContent-root {
    overflow: auto;
  }

  &--submited {
    .MuiDialogContent-root {
      padding: 0;
    }
  }
}
