$ayo-link-with-snackbar-snackbar-padding: 18px 0;
$ayo-link-with-snackbar-snackbar-max-width: 34rem;
$ayo-link-with-snackbar-alert-border-radius: 9px;
$ayo-link-with-snackbar-snackbar-alert-padding: 6px 24px;

.ayo-link-with-snackbar {
  &.MuiSnackbar-root {
    .MuiAlert-message {
      padding: $ayo-link-with-snackbar-snackbar-padding;
      max-width: $ayo-link-with-snackbar-snackbar-max-width;
    }

    .MuiAlert-root {
      background-color: $operational-surface;
      border-radius: $ayo-link-with-snackbar-alert-border-radius;
      padding: $ayo-link-with-snackbar-snackbar-alert-padding;

      .ayo-button {
        @include close-button-dark;
      }

      .ayo-link {
        font-size: inherit;
        line-height: inherit;
      }
    }
  }
}
