$form-control-label-mr: 0;
$form-control-label-ml: 0;
$form-control-height: 2.1875rem;

.ayo-form-control-label {
  box-sizing: border-box;

  &.MuiFormControlLabel-root {
    margin-right: $form-control-label-mr;
    align-items: flex-start;
    margin-left: $form-control-label-ml;

    .MuiFormControlLabel-label.Mui-disabled {
      color: $typography-disabled;
    }

    .ayo-checkbox,
    .ayo-radio {
      height: $form-control-height;
      box-sizing: border-box;
    }
  }

  .MuiFormControlLabel-label {
    @include body2;

    color: $typography-body;
  }
}
