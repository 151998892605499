$ayo-lesson-page-teks-accordion-summary-pr: 24px;
$ayo-lesson-page-teks-accordion-p: 16px 24px;

.ayo-lesson-page-teks {
  &__suggested {
    background-color: $secondary-200;

    .ayo-accordion__summary {
      padding-right: $ayo-lesson-page-teks-accordion-summary-pr;
    }

    .MuiAccordionDetails-root {
      padding: $ayo-lesson-page-teks-accordion-p;
    }
  }
}
