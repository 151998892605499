$ayo-ml-input-padding: 4px 8px;
$ayo-ml-input-height: 26px;
$ayo-ml-input-height-large: 32px;
$ayo-ml-input-margin-large: 4px;

.ayo-ml-input-chip {
    @include body3;

    background-color: $secondary-050;
    padding: $ayo-ml-input-padding;
    color: $primary-600;
    font-weight: bold;
    line-height: initial;
    border: none;
    height: $ayo-ml-input-height;

    // TODO: remove this hack padding when getIsDomElementCropped would be revised
    .ayo-text-with-tooltip-content {
      padding-top: 1px;
      padding-bottom: 1px;
    }

    &.ayo-chip-rtl .MuiChip-icon {
        margin-left: 0;

        @media (min-width: 1920px) {
            margin-left: $ayo-ml-input-margin-large;
        }
    }

    @media (min-width: 1920px) {
        height: $ayo-ml-input-height-large;
    }
}
