/* stylelint-disable no-descending-specificity */
$bubble-liked-font-size: 1.833rem;
$bubble-liked-line-height: 3.2rem;
$interests-area-background-color: #f4f4f4;
$interests-map-br: 16px;
$zoom-factor: 1.8;
$zoom-max-level: 5;
$zoom-minimum-scale: pow($zoom-factor, $zoom-max-level);
$interest-focus-stroke-width: 0.2px;
$interest-other-focus-color: #4f4f4f;
$interest-not-interested-focus-color: RGB(45 46 63 / 50%);
$reaction-thumb-focus-color: RGB(242 243 255 / 50%);
$reaction-thumb-focus-stroke-width: RGB(242 243 255 / 50%);
$interests-map-widgets-bg-color: #393144;
$bubble-text-main-size: 1.166rem;
$bubble-text-main-line-height: 2.5rem;
$uppertext-main-size: 1rem;
$uppertext-main-line-height: 1.5rem;
$current-category-padding: 16px;
$current-category-br: 16px;
$current-category-width: 220px;
$skip-to-next-section-margin: 24px;
$current-category-width-mobile: 140px;
$widget-button-size: 54px;
$search-widget-mr-mobile: 8px;
$full-screen-overlay-width: 100vw;
$full-screen-overlay-height: 100vh;
$reaction-slider-track-default: $primary-500;
$reaction-slider-track-other: #C1BFC9;
$reaction-slider-track-suggested: #FFC107;
$tip-zoom-scale: pow($zoom-factor, 3);
$tip-arrow-size: 36px;
$tip-font-size: 1.5rem;
$reaction-text-padding: 16px;
$reaction-slider-mt: 16px;
$interest-definition-link-gap: 12px;
$interest-definitions-gap-y: 40px;
$reaction-info-icon-mr: 16px;

.ayo-interests-map {
  &.full-screen {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1299;
    background: $operational-black;
    height: 100%;
    overflow-y: auto;
  }

  .interest-universe-definition-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .definition-content {
      .definitions-list {
        display: flex;
        flex-direction: column;
        gap: $interest-definitions-gap-y 0;
      }

      .no-definition-link {
        display: flex;
        gap: $interest-definition-link-gap;

        svg * {
          @include fill-icon-element($typography-white);
        }
      }

      .MuiTypography-root {
        color: $typography-white;
      }

      .definition-body {
        @include wrapped-text(3);

        @media (max-width: 600px) {
          @include wrapped-text(5);
        }
      }
    }
  }

  .reaction-tip {
    &-arrow {
      fill: $data-vizualization-pink;
      width: calc(#{$tip-arrow-size} / #{$tip-zoom-scale});
      height: calc(#{$tip-arrow-size} / #{$tip-zoom-scale});
    }

    &-text {
      @include ayo-typography-titles;

      fill: $data-vizualization-pink;
      font-size: calc(#{$tip-font-size} / #{$tip-zoom-scale});
    }
  }

  .ayo-skip-to-next-section {
    @extend .ayo-skip-to-main-content;

    top: $skip-to-next-section-margin;
    left: $skip-to-next-section-margin;
  }

  .current-category {
    padding: $current-category-padding;
    background-color: $interests-map-widgets-bg-color;
    border-radius: $current-category-br;
    width: $current-category-width;
    box-sizing: border-box;

    @media (max-width: 600px) {
      width: $current-category-width-mobile;
      transform: translateX(-50%);
    }

    &:empty {
      @include sr-only;
    }

    p {
      color: $typography-white;
    }
  }

  .ayo-interests-area {
    border-radius: $interests-map-br;

    .interest {
      display: none;

      &:hover {
        cursor: pointer;

        & + .uppertext-object .zoom-level-1 p {
          display: block;
        }
      }

      &.displayed {
        display: block;
      }

      &:focus {
        outline: none;
      }

      .text {
        display: none;

        &.displayed {
          display: block;
        }
      }

      .interest-bubble-text {
        color: $operational-surface;

        &.interest-bubble-text {
          &-liked {
            font-size: calc(#{$bubble-liked-font-size} / #{$zoom-minimum-scale});
            line-height: calc(#{$bubble-liked-line-height} / #{$zoom-minimum-scale});

            &.zoom-level-4 {
              font-size: calc(#{$bubble-text-main-size} / #{pow(1.8, 4)});
              line-height: calc(1.8333rem / #{pow(1.8, 4)});
            }
          }

          &-other {
            color: $typography-titles;
            font-size: calc(#{$bubble-text-main-size} / #{$zoom-minimum-scale});
            line-height: calc(#{$bubble-text-main-line-height} / #{$zoom-minimum-scale});
            max-width: 75%;
          }

          &-suggested {
            color: $typography-titles;
            font-size: calc(#{$bubble-text-main-size} / #{$zoom-minimum-scale});
            line-height: calc(#{$bubble-text-main-line-height} / #{$zoom-minimum-scale});
            max-width: 80%;
          }

          &-not-interested {
            color: $typography-white;
            font-size: calc(#{$bubble-text-main-size} / #{$zoom-minimum-scale});
            line-height: calc(#{$bubble-text-main-line-height} / #{$zoom-minimum-scale});
            max-width: 75%;
          }

          &-reaction {
            font-size: calc(#{$bubble-text-main-size} / #{$zoom-minimum-scale});
            line-height: calc(#{$bubble-text-main-line-height} / #{$zoom-minimum-scale});
            max-width: 75%;

            &.other,
            &.suggested {
              color: $typography-titles;
            }
          }
        }
      }

      &-suggested:focus {
        stroke-width: $interest-focus-stroke-width;
        stroke: $primary-050;
      }

      &-other:focus {
        stroke-width: $interest-focus-stroke-width;
        stroke: $primary-050;

        circle {
          fill: $interest-other-focus-color;
        }

        .interest-bubble-text {
          color: $typography-white;
        }
      }

      &-not-interested:focus {
        stroke-width: $interest-focus-stroke-width;
        stroke: $primary-050;

        circle {
          fill: $interest-not-interested-focus-color;
        }
      }

      &-liked:focus {
        .outer-main-circle {
          stroke: $secondary-500;
        }

        .inner-circle {
          fill: $secondary-200
        }
      }

      &-liked:hover {
        .outer-main-circle {
          stroke: $primary-100;
        }

        .inner-circle {
          fill: $primary-600;
        }
      }
    }

    .empty-text {
      background-color: $interests-area-background-color;
    }

    .uppertext {
      white-space: nowrap;
      color: $operational-surface;
      font-size: #{$uppertext-main-size};
      line-height: #{$uppertext-main-line-height};
      margin-bottom: 2px;

      &-object {
        overflow: visible;
        display: none;

        &.displayed {
          display: flex;
          align-items: flex-end;
        }
      }

      &-block {
        transform: translateX(-50%);
        display: flex;
        align-items: flex-end;
        height: 100%;

        &.zoom-level {
          &-0 {
            .uppertext {
              display: none;

              &.interest-level-0 {
                display: block;
              }
            }
          }

          &-1 {
            max-width: calc(100px / #{pow(1.8, 1)});
            width: calc(100px / #{pow(1.8, 1)});

            .uppertext {
              white-space: initial;
              font-size: calc(#{$uppertext-main-size} / #{pow(1.8, 1)});
              line-height: calc(#{$uppertext-main-line-height} / #{pow(1.8, 1)});
              display: none;

              &.interest-level-0 {
                display: block;
              }
            }
          }

          &-2 {
            max-width: calc(80px / #{pow(1.8, 2)});
            width: calc(80px / #{pow(1.8, 2)});

            .uppertext {
              white-space: initial;
              font-size: calc(#{$uppertext-main-size} / #{pow(1.8, 2)});
              line-height: calc(#{$uppertext-main-line-height} / #{pow(1.8, 2)});

              &.liked {
                margin-bottom: 3px;
              }
            }
          }

          &-3 {
            max-width: calc(120px / #{pow(1.8, 3)});
            width: calc(120px / #{pow(1.8, 3)});

            .uppertext {
              white-space: initial;
              font-size: calc(#{$uppertext-main-size} / #{pow(1.8, 3)});
              line-height: calc(#{$uppertext-main-line-height} / #{pow(1.8, 3)});
              margin-bottom: 1px;

              &.liked {
                margin-bottom: 4px;
              }
            }
          }

          &-4 {
            max-width: calc(140px / #{pow(1.8, 4)});
            width: calc(140px / #{pow(1.8, 4)});

            .uppertext {
              white-space: initial;
              font-size: calc(1.166rem / #{pow(1.8, 4)});
              line-height: calc(1.833rem / #{pow(1.8, 4)});
              margin-bottom: 0.8px;
            }
          }

          &-5 {
            .uppertext {
              display: none;
            }
          }
        }
      }
    }

    .interest-liked + .uppertext-object .zoom-level-4 .uppertext {
      display: none;
    }

    &.safari {
      .uppertext-object .uppertext-block {
        transform: none;
      }
    }
  }

  .reaction-widget {
    display: flex;
    flex-direction: column;
    align-items: center;

    .ayo-checkbox {
      input[type='checkbox'] {
        & + svg * {
          @include fill-icon-element($typography-white);
        }

        &:hover + svg * {
          @include fill-icon-element($primary-100);
        }

        &:focus + svg {
          box-shadow: $checkbox-focus-shadow-position $secondary-500;

          * {
            @include fill-icon-element($secondary-050);
          }
        }
      }

      &.Mui-checked {
        input[type='checkbox']:hover + svg {
          box-shadow: $checkbox-focus-shadow-position $primary-100;

          * {
            @include fill-icon-element($typography-white);
          }
        }
      }
    }

    &__actions {
      @media (min-width: 600px) {
        width: 50%;
      }
    }

    .ayo-slider {
      margin-top: $reaction-slider-mt;

      @media (max-width: 600px) {
        max-width: calc(100% - 32px);
      }

      --track-color: #{$reaction-slider-track-default};

      &.reaction-slider {
        &-1 {
          --track-color: #{$reaction-slider-track-other};

          &.suggested-slider {
            --track-color: #{$reaction-slider-track-suggested};
          }
        }
      }

      .MuiSlider-track {
        background: var(--track-color);
      }

      .MuiSlider-rail {
        background: $operational-background;
        opacity: 1;
      }

      .MuiSlider-markLabel {
        color: $typography-white;
      }

      .MuiSlider-thumb input:focus + svg circle:first-child {
        fill: $reaction-thumb-focus-color;
        stroke: $primary-100;
        stroke-width: $reaction-thumb-focus-stroke-width;
        opacity: 1;
        r: 23;
      }
    }

    .ayo-button--text {
      color: $typography-white;

      &:hover,
      &:focus {
        color: $primary-500;
      }
    }

    .light-text, .light-label .MuiTypography-root {
      color: $typography-white;
    }

    .info-icon {
      margin-right: $reaction-info-icon-mr;
      flex-shrink: 0;

      * {
        @include fill-icon-element($typography-white);
      }
    }
  }

  &__full-screen-overlay {
    background: $operational-black;
    width: $full-screen-overlay-width;
    height: $full-screen-overlay-height;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1298;
    bottom: 0;
  }

  @import './widgets/zoom-widget/zoom-widget';
  @import './widgets/search-widget/search-widget';
}

@import './widgets/filter-widget/filter-widget';

.interests-widgets {
  pointer-events: none;

  .widget-button {
    pointer-events: auto;
    width: $widget-button-size;
    height: $widget-button-size;
    box-sizing: border-box;
  }

  button.full-screen-toggle {
    display: none;
    position: absolute;
  }

  @media (max-width: 600px) {
    width: calc(100% - 32px);
    height: calc(100% - 32px);
    box-sizing: border-box;

    .filter-widget--container {
      width: auto;
      margin-right: 0;
    }

    button.full-screen-toggle {
      display: block;
    }

    .search-widget {
      width: auto;
      margin-right: $search-widget-mr-mobile;
      display: flex;
      justify-content: flex-end;
      flex: 1;

      & > div {
        width: 100%;
      }
    }

    .zoom-widget {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

}
