$campus-selection-icon-height-xl: 40px;
$form-control-label-margin-bottom: 20px;

.ayo-campus-selection {
 .ayo-card {
   height: 100%;

   &__main-content {
     height: 100%;
     display: flex;

    .ayo-radiogroup {
      width: 100%;

      &--multiple {
        .ayo-form-control-label:first-of-type {
          margin-bottom: $form-control-label-margin-bottom;
        }
      }
    }
   }
 }

  &__icon {
    @media (min-width: 1920px) {
        height: $campus-selection-icon-height-xl;
        width: auto;
    }
  }
}
