$no-student-screen-secondary-text-max-width: 504px;
$no-student-screen-secondary-text-max-width-xl: 680px;

.ayo-no-student-screen {
  .ayo-page-illustration-block__content__main-header {
    padding-bottom: 0;
  }

  &__secondary-text {
    max-width: $no-student-screen-secondary-text-max-width;

    @media (min-width: 1920px) {
      max-width: $no-student-screen-secondary-text-max-width-xl;
    }
  }
}
