$chip-padding: 6px 12px;
$chip-border-radius: 18px;
$chip-border-width: 2px;
$chip-icons-margin-side: 4px;
$chip-icon-size: 24px;
$ayo-chip-outlined-border-radius: 6px;
$ayo-chip-outlined-padding: 0 8px;
$ayo-chip-caption-border-radius: 9px;
$ayo-chip-caption-padding: 5px 8px;

.ayo-chip {
  background-color: $primary-050;
  padding: $chip-padding;
  border-radius: $chip-border-radius;
  height: unset;
  max-width: 100%;
  border: $chip-border-width solid transparent;

  @include body2;

  color: $primary-500;

  &.MuiChip-clickable {
    &:hover,
    &:focus,
    &.Mui-focusVisible {
      background-color: $primary-050;
    }

    &:hover {
      border-color: $primary-500;
    }

    &:focus,
    &.Mui-focusVisible {
      border-color: $primary-100;
      color: $primary-900;
    }
  }

  &--multiline {
    p {
      @include caption;
      @include wrapped-text;
    }
  }

  .MuiChip-label {
    padding: 0;

    p {
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .MuiChip-icon {
    margin-left: 0;
    margin-right: $chip-icons-margin-side;
  }

  .MuiChip-deleteIcon {
    margin: 0 0 0 $chip-icons-margin-side;
    height: $chip-icon-size;
    width: $chip-icon-size;
    min-height: $chip-icon-size;
    min-width: $chip-icon-size;
  }

  &-rtl {
    flex-direction: row-reverse;

    .MuiChip-icon {
      margin-right: 0;
      margin-left: $chip-icons-margin-side;
    }
  }

  .MuiTouchRipple-root {
    inset: -$chip-border-width;
    border: $chip-border-width solid transparent;
  }

  &--outlined {
    @include caption;

    background-color: $operational-background;
    border: 1px solid $data-visualization-green-090-100;
    border-radius: $ayo-chip-outlined-border-radius;
    color: $data-visualization-green-090-100;
    padding: $ayo-chip-outlined-padding;
  }

  &--caption {
    @include caption;

    background-color: $operational-background;
    border-radius: $ayo-chip-caption-border-radius;
    color: $typography-labels-dark;
    padding: $ayo-chip-caption-padding;
    border: none;
  }
}
