$date-display-divider-color: #DBDBDB;
$date-display-divider-mr: 24px;
$date-display-divider-height: 50%;

.date-display-box {
  display: flex;
  align-items: center;

  .date-display-divider {
    height: $date-display-divider-height;
    margin-right: $date-display-divider-mr;
    border-color: $date-display-divider-color;
  }
}