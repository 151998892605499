$ayo-leadership-attributes-hotspot-margin-left: 4px;
$ayo-leadership-attributes-illustration-margin-bottom: -5px;
$ayo-leadership-attributes-border-radius: 25px;
$ayo-leadership-attributes-graph-container-border-radius: 16px;

.ayo-leadership-attributes {
  &--main-block {
    background-color: #f5f9fe;
    border-radius: $ayo-leadership-attributes-border-radius;
  }

  &--illustration {
    @extend .ayo-svg-illustration;

    margin-bottom: $ayo-leadership-attributes-illustration-margin-bottom;
  }

  @media (max-width: 600px) {
    .ayo-hotspot {
      margin-left: $ayo-leadership-attributes-hotspot-margin-left;
    }
  }

  .ayo-leadership-attributes-graph {
    .ayo-radar-chart {
      flex-direction: column-reverse;

      @media (min-width: 960px) {
        flex-direction: row-reverse;
      }
    }
  }

  &__graph-container {
    background-color: $operational-surface;
    border-radius: $ayo-leadership-attributes-graph-container-border-radius;
  }
}
