$card-content-padding: 16px 16px 24px;
$card-media-border-radius: 16px;
$card-media-height-lg: 156px;
$card-media-height-sm: 146px;
$card-media-height: 120px;
$card-title-margin-bottom: 8px;
$card-title-min-height-xl: 72px;
$card-title-min-height: 52.5px;

.ayo-club-hub__card {
  .MuiCardHeader-root {
    position: absolute;
    right: 0;
  }

  .MuiCardContent-root {
    padding: $card-content-padding;
  }

  .ayo-clickable-card__title {
    min-height: $card-title-min-height;
    margin-bottom: $card-title-margin-bottom;

    @media (min-width: 1920px) {
      min-height: $card-title-min-height-xl;
    }
  }

  &--joined {
    background-color: $primary-800;

    .ayo-clickable-card__title {
      color: $operational-surface;
    }

    &:hover, &:focus {
      .ayo-typography--body2 {
        color: $typography-titles;
      }

      .ayo-typography--label {
        color: $typography-labels;
      }

      .ayo-clickable-card__title {
        color: $primary-700;
      }
    }
  }

  .ayo-card__media {
    height: $card-media-height;
    width: 100%;
    border-radius: $card-media-border-radius;

    @media (max-width: 600px) {
      height: $card-media-height-sm;
    }

    @media (min-width: 1536px) {
      height: $card-media-height-lg;
    }
  }

  div.ayo-card__media {
    background-color: $operational-disabled-bg;
  }
}
