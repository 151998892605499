$ayo-portfolio__employments-section-margin-top: 24px;
$ayo-portfolio__employments-section-textmargin-left: 8px;
$ayo-portfolio__employments-section-wrapper-width: 50%;
$ayo-portfolio__employments-section-card-padding: 24px;
$ayo-portfolio__employments-section-background-color: #FFFEF0;

.ayo-portfolio__employments-section {
  background-color: $ayo-portfolio__employments-section-background-color;
  background-image: url('../../../../../resources/images/portfolio/employment-bg.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  .ayo-delete-item-wrapper {
    width: $ayo-portfolio__employments-section-wrapper-width;
  }

  &__card {
    margin-top: $ayo-portfolio__employments-section-margin-top;

    &.ayo-card .MuiCardContent-root {
      padding: $ayo-portfolio__employments-section-card-padding;
    }

    &__content {
      display: flex;
      justify-content: space-between;

      &__text {
        margin-left: $ayo-portfolio__employments-section-textmargin-left;
      }
    }

    &__cropped {
      width: $ayo-portfolio__employments-section-wrapper-width;
    }
  }
}

.ayo-portfolio-add-employment-dialog {
  .ayo-dialog__container {
    @media (min-width: 1920px) {
      max-width: min-content;
    }
  }
}
