$leadership-attributes-galery-assessment-home-icon-button-svg-size: 18px;
$leadership-attributes-galery-assessment-home-levels-images-block-padding-top: 24px;
$leadership-attributes-galery-assessment-home-levels-images-block-margin-left: -8px;
$leadership-attributes-galery-assessment-home-levels-images-block-padding-top-mobile: 16px;

.ayo-leadership-assessment-home-page {
  flex: 1;

  &__images {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: $leadership-attributes-galery-assessment-home-levels-images-block-padding-top;
    margin-left: $leadership-attributes-galery-assessment-home-levels-images-block-margin-left;

    @media (max-width: 600px) {
      margin-top: $leadership-attributes-galery-assessment-home-levels-images-block-padding-top-mobile;
    }
  }

  &__icon-button {
    svg {
      width: $leadership-attributes-galery-assessment-home-icon-button-svg-size;
      height: $leadership-attributes-galery-assessment-home-icon-button-svg-size;
    }
  }

  .ayo-button {
    &.Mui-hover,
    &:hover {
      background-color: $operational-surface;
    }

    &.Mui-focusVisible,
    &:focus {
      background-color: $operational-surface;
    }
  }
}
