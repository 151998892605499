$endorsement-card-details-box-border-radius: 16px;
$endorsement-card-details-box-border-padding: 16px;
$endorsement-card-details-box-padding: 16px;
$endorsement-card-background-color: #d8d9e9;
$endorsement-card-details-box-background-color: #f4f5ff;
$endorsement-card-image-height: 169px;
$endorsement-card-label-min-height: 227px;

.ayo-endorsement-card {
  .MuiCardContent-root {
    padding: $endorsement-card-details-box-padding;
    background-color: $primary-800;

    .ayo-typography--subtitle2 {
      color: $operational-surface;
    }
  }

  .MuiCardMedia-img {
    height: $endorsement-card-image-height;
  }

  &--interested {
    min-height: $endorsement-card-label-min-height;

    .MuiCardContent-root {
      background-color: $operational-surface;

      .ayo-typography--subtitle2 {
        color: $typography-titles;
      }
    }
  }

  &--inactive {
    .MuiCardMedia-img {
      opacity: 0.6;
    }

    .MuiCardContent-root {
      background-color: $endorsement-card-background-color;

      .ayo-typography--subtitle2 {
        color: $typography-titles;
      }
    }
  }

  &__details-box {
    padding: $endorsement-card-details-box-border-padding;
    background-color: $endorsement-card-details-box-background-color;
    border-radius: $endorsement-card-details-box-border-radius;

    &__description {
      @include wrapped-text(2);

      &--collapsed {
        @include wrapped-text(100);
      }
    }

    &__action-buttons {
      .ayo-icon-button {
        height: initial;
        width: initial;
      }
    }
  }
}
