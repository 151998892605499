$textfield-label-padding: 0 6px;
$textfield-input-padding: 14px 16px;
$textfield-input-padding-y-large: 18px;
$textfield-input-error-mb: 8px;
$textfield-input-error-mb-large: 16px;
$textfield-input-border-radius: 16px;
$textfield-adornedEnd-position-top: 14px;
$textfield-adornedEnd-position-right: 14px;
$textfield-error-icon-size: 18px;
$textfield-input-label-stt-max-width: calc(100% - 85px);
$textfield-input-label-search-max-width: calc(100% - 130px);
$textfield-input-label-stt-reset-max-width: calc(100% - 120px);
$textfield-input-bg-color-dark: #393144;

.ayo-textfield {
  .MuiInputLabel-root {
    @include body2;

    background-color: $operational-surface;
    color: $typography-labels;
    transform: translate(16px, 14px) scale(1);

    @media (min-width: 1920px) {
      transform: translate(16px, 18px) scale(1);
    }

    &.MuiInputLabel-shrink {
      @include body3;

      padding: $textfield-label-padding;
      transform: translate(10px, -10px);

      @media (min-width: 1920px) {
        transform: translate(10px, -14px);
      }
    }

    &.Mui-focused {
      color: $primary-500;
    }

    &.Mui-disabled {
      background-color: $operational-background;
      color: $typography-disabled;
    }

    &.Mui-error {
      color: $operational-error;
    }
  }

  .MuiOutlinedInput-root {
    background-color: $operational-surface;
    border-radius: $textfield-input-border-radius;

    .MuiInputBase-input {
      @include body2;

      background-color: $operational-surface;
      color: $typography-body;
      height: auto;
      padding: $textfield-input-padding;
      border-radius: $textfield-input-border-radius;

      @media (min-width: 1920px) {
        padding-top: $textfield-input-padding-y-large;
        padding-bottom: $textfield-input-padding-y-large;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: $operational-surface;
      border-radius: $textfield-input-border-radius;
    }

    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: $primary-500;
      }
    }

    &.Mui-disabled {
      background-color: $operational-background;

      .MuiInputBase-input {
        background-color: $operational-background;
        color: $typography-disabled;
      }

      .MuiOutlinedInput-notchedOutline {
        border: 2px solid $operational-border;
      }

      svg path,
      svg rect {
        @include fill-icon-element($typography-disabled);
      }
    }

    &.Mui-error {
      .MuiOutlinedInput-notchedOutline {
        border-color: $operational-error;
      }

      .ayo-icon-button {
        &:hover,
        &.Mui-focusVisible {
          background-color: $icon-button-error-color;
          border-color: $operational-error;
        }
      }

      svg path,
      svg rect {
        @include fill-icon-element($operational-error);
      }
    }
  }

  .MuiInputBase-multiline {
    align-items: flex-start;
    padding: 0;

    &.MuiInputBase-adornedEnd {
      padding-right: $textfield-adornedEnd-position-right;

      img {
        padding-top: $textfield-adornedEnd-position-top;
      }
    }
  }

  .MuiFormHelperText-root {
    &.Mui-error {
      @include ayo-typography-body;

      color: $operational-error;
      font-size: 1rem;
      line-height: 1.333rem;
      position: absolute;
      top: 100%;
      margin-left: 10px;
      margin-right: 10px;

      &.Mui-error--light {
        color: $operational-surface;
      }
    }
  }

  &__input-label {
    overflow: hidden;
    text-overflow: ellipsis;

    &.with-search {
      max-width: $textfield-input-label-search-max-width;
      white-space: nowrap;
    }

    &.with-stt {
      max-width: $textfield-input-label-stt-max-width;

      &.with-reset:not(.MuiInputLabel-shrink) {
        max-width: $textfield-input-label-stt-reset-max-width;
      }
    }
  }

  &--w-bottom-space {
    margin-bottom: $textfield-input-error-mb;

    @media (min-width: 1920px) {
      margin-bottom: $textfield-input-error-mb-large;
    }
  }

  @at-root #{&}--outlined {
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: $operational-border;
    }
  }
}

.ayo-textfield.dark {
  .MuiOutlinedInput-root {
    background-color: $textfield-input-bg-color-dark;

    .MuiInputBase-input {
      background-color: inherit;
      color: $typography-white
    }

    &.Mui-focused {
      outline: 1px solid $operational-border;
    }
  }

  .MuiOutlinedInput-root.Mui-error .ayo-icon-button:hover {
    border-color: $operational-error-focus-border;
  }

  .ayo-textfield__input-label {
    background-color: $textfield-input-bg-color-dark;
    color: $typography-white;

    &.Mui-error {
      color: $operational-error-focus-border;
    }
  }

  .MuiAutocomplete-popupIndicator {
    background: transparent;
  }

  .MuiOutlinedInput-root.Mui-error svg * {
    @include fill-icon-element($operational-error-focus-border);
  }

  .MuiFormHelperText-root.Mui-error {
    color: $operational-error-focus-border;
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .MuiOutlinedInput-root.Mui-error {
    outline: none;
    border: 1px solid $operational-error-focus-border;
  }

  button {
    border: 2px solid transparent;

    svg {
      path[fill] {
        fill: $typography-white;
      }

      path[stoke] {
        stroke: $typography-white;
      }
    }

    &:hover {
      border-color: $primary-100;
      background: $primary-050;

      svg {
        path[fill],
        rect[fill] {
          fill: $primary-500;
        }

        path[stroke] {
          stroke: $primary-500;
        }
      }
    }

    &:focus {
      border-color: $secondary-500;
      background: $secondary-050;

      .ayo-searchfield__action-icon {
        outline: none;
      }

      svg path[fill],
      svg rect[fill] {
        fill: $primary-800;
      }

      svg path[stroke] {
        stroke: $primary-800;
      }
    }
  }
}
