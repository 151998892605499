$ayo-contact-info-popover-shadow: 0 4px 8px 2px RGB(0 0 0 / 6%);
$ayo-contact-info-font: 1.166rem;
$ayo-contact-info-popover-border-radius: 16px;
$ayo-contact-info-popover-width: 328px;
$ayo-contact-info-popover-close-btn-size: 24px;
$ayo-contact-info-popover-close-btn-border-radius: 4px;

.ayo-contact-info-popover {
  background-color: transparent;

  .MuiPopover-paper {
    max-width: $ayo-contact-info-popover-width;
    overflow: hidden;
  }

  .MuiPaper-elevation8 {
    box-shadow: $ayo-contact-info-popover-shadow;
  }

  .MuiPaper-rounded {
    border-radius: $ayo-contact-info-popover-border-radius;
  }

  &__card {
    &__close-btn {
      min-width: $ayo-contact-info-popover-close-btn-size;
      width: $ayo-contact-info-popover-close-btn-size;
      height: $ayo-contact-info-popover-close-btn-size;
      border-radius: $ayo-contact-info-popover-close-btn-border-radius;
    }

    .ayo-link {
      &--with-icon {
        text-decoration: underline;
        font-size: $ayo-contact-info-font;
        flex: 1;
      }
    }
  }
}

@media (max-width: 600px) {
  .ayo-contact-info-popover {
    .MuiPopover-paper {
      width: 90%;
      max-width: none;
    }
  }
}
