$feed-post-roles-visibility-picker-mt: 24px;
$feed-post-roles-visibility-picker-label-mb: 8px;

.ayo-feed-roles-visibility-picker {
  margin-top: $feed-post-roles-visibility-picker-mt;

  .MuiFormLabel-root {
    @include subtitle2;

    margin-bottom: $feed-post-roles-visibility-picker-label-mb;
  }

  h4 {
    margin-top: 0;
  }
}
