$ayo-maturity-level-rubric-label-margin-right: 8px;
$ayo-maturity-level-rubric-padding: 8px;
$ayo-maturity-level-rubric-padding-bottom: 16px;
$ayo-maturity-level-rubric-users-count-min-width: 24px;
$ayo-maturity-level-rubric-users-count-padding-left: 4px;
$ayo-maturity-level-rubric-users-count-padding-right: 8px;
$ayo-maturity-level-rubric-users-count-padding-top: 8px;
$ayo-maturity-level-rubric-min-height: 52px;
$ayo-maturity-level-rubric-button-margin-left: 16px;
$ayo-maturity-level-rubric-button-margin-left-mobile: 4px;
$ayo-maturity-level-rubric-button-width-height: 40px;
$ayo-maturity-level-rubric-label-border-radius: 16px;
$ayo-maturity-level-rubric-accordion-icon-min-width: 48px;

.ayo-maturity-level-rubric {
  display: flex;
  align-items: center;
  padding-bottom: $ayo-maturity-level-rubric-padding-bottom;

  @media (max-width: 600px) {
    align-items: initial;
  }

  &__users-count {
    min-width: $ayo-maturity-level-rubric-users-count-min-width;
    padding-left: $ayo-maturity-level-rubric-users-count-padding-left;
    padding-right: $ayo-maturity-level-rubric-users-count-padding-right;

    @media (max-width: 600px) {
      padding-top: $ayo-maturity-level-rubric-users-count-padding-top;
    }
  }

  &__rubric-button {
    background-color: $operational-surface;
    text-align: start;
    justify-content: space-between;
    padding: $ayo-maturity-level-rubric-padding;

    .ayo-button--icon {
      visibility: hidden;
      margin-left: $ayo-maturity-level-rubric-button-margin-left;

      @media (max-width: 600px) {
        margin-left: $ayo-maturity-level-rubric-button-margin-left-mobile;
      }
    }

    &:hover,
    &:focus-within {
      background-color: $primary-050;
      border-color: transparent;

      .ayo-button--icon {
        visibility: visible;
      }
    }
  }

  &__arrow {
    transform: rotate(90deg);
  }

  &__goal-indicator {
    display: inline;
    vertical-align: middle;
  }

  &__level-text {
    display: inline;
    margin-right: $ayo-maturity-level-rubric-label-margin-right;
  }

  &__ml-input-label {
    margin-right: $ayo-maturity-level-rubric-label-margin-right;
  }

  &__label:hover {
    cursor: default;
  }
}
