@import "./club-hub-management/club-hub-management";
@import "club-hub-components/club-data-grid-actions/club-data-grid-actions";
@import "club-hub-components/club-hub-tabs/club-hub-tabs";
@import "club-hub-components/club-join-actions/club-join-actions";
@import "club-hub-components/club-join-dialog/club-join-dialog";
@import "club-hub-components/club-links/club-links";
@import "club-hub-components/club-visibility-dialog/club-visibility-dialog";
@import './club-hub-components/club-banner/club-banner';
@import './club-hub-components/clubs-deactivation-dialog/clubs-deactivation-dialog';
@import './club-hub-details-page/components/club-details-banner/club-details-banner';

$initiative-empty-state-padding: 40px 16px;
$page-margin-bottom: 40px;

.ayo-club-hub {
  flex: 1;
  margin-bottom: $page-margin-bottom;

  .ayo-initiative-empty-state {
    padding: $initiative-empty-state-padding;
  }
}

.ayo-club-requirements-link {
  display: flex;
  max-width: fit-content;

  div {
    display: flex;
    align-items: center;
  }

  svg {
    min-width: fit-content;

    path {
      @include fill-icon-element($typography-body);
    }
  }

  @include extra-large-icon;
}
