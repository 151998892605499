$ayo-timeline-chip-padding: 8px 18px;
$ayo-timeline-chip-border-radius: 12px 12px 12px 0;
$ayo-timeline-chip-svg-top: -4px;
$ayo-timeline-chip-svg-top-large: -6px;

.ayo-timeline-chip {
    & p {
        padding: $ayo-timeline-chip-padding;
        background-color: $primary-800;
        border-radius: $ayo-timeline-chip-border-radius;
        color: $operational-surface;
        max-width: fit-content;
    }

    & svg {
        position: relative;
        top: $ayo-timeline-chip-svg-top;

        @media (min-width: 1920px) {
            top: $ayo-timeline-chip-svg-top-large;
        }
    }
}
