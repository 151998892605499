.ayo-family-activity-dialog {
  .ayo-chip {
    background-color: $operational-black;
    color: $typography-white;
  }

  &__link {
    word-break: break-all;
  }

  &__insight-type {
    white-space: nowrap;
  }
}
