$link-focus-outline: $primary-900 auto 1px;
$ayo-link-width: 25px;
$ayo-link-width-lg: 32px;

.ayo-link {
  @include link1;

  &:hover {
    color: $primary-600;
  }

  &:focus {
    outline: none;
  }

  &.Mui-focusVisible {
    color: $primary-700;
    outline: $link-focus-outline;
  }

  &.Mui-active,
  &:active {
    color: $primary-500;
  }

  &.Mui-disabled {
    color: $typography-disabled;
    cursor: default;

    svg * {
      @include fill-icon-element($typography-disabled);
    }
  }

  &.ayo-link--contained {
    font-size: inherit;
    line-height: inherit;
    cursor: pointer;
    vertical-align: baseline;
  }

  .ayo-link--with-icon {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    white-space: pre-line;
    overflow: hidden;
    text-overflow: ellipsis;

    & + svg {
      height: auto;
      min-width: $ayo-link-width;
    }

    @media (min-width: 1920px) {
        height: auto;
        min-width: $ayo-link-width-lg;
    }
  }
}
