$nav-drawer-width: 236px;
$nav-drawer-width-xl: 304px;
$nav-drawer-border: none;
$nav-drawer-shadow: 0 2px 6px 2px RGB(0 0 0 / 6%);
$nav-drawer-list-padding: 16px 24px;
$nav-drawer-list-item-border-radius: 16px;
$nav-drawer-list-item-padding: 12px 16px;
$nav-drawer-list-item-mb: 16px;
$nav-drawer-page-px-sm: 56px;
$nav-drawer-page-max-width-lg: 1496px;
$nav-drawer-mobile-width: 341px;
$nav-drawer-list-item-icon-min-width: 32px;
$nav-drawer-list-item-svg-icon-width-height: 24px;
$nav-drawer-ayo-hotspot-right: -16px;
$nav-drawer-ayo-dot-indicator-right: 30px;
$nav-drawer-inner-list-padding: 8px 0 0 16px;
$nav-drawer-items-gap: 16px;

.ayo-nav-drawer {
  .MuiDrawer-paper {
    background-color: $operational-surface;
    border: $nav-drawer-border;
    box-shadow: $nav-drawer-shadow;
    width: $nav-drawer-width;

    @media (min-width: 1920px) {
      width: $nav-drawer-width-xl;
    }
  }

  .items-list {
    display: flex;
    flex-direction: column;
    row-gap: $nav-drawer-items-gap;
  }

  .MuiList-root {
    padding: $nav-drawer-list-padding;

    &.inner-list {
      padding: $nav-drawer-inner-list-padding;
    }

    .MuiListItemIcon-root {
      min-width: $nav-drawer-list-item-icon-min-width;
    }

    .MuiListItem-button {
      margin-bottom: 0;

      svg {
        width: $nav-drawer-list-item-svg-icon-width-height;
        height: $nav-drawer-list-item-svg-icon-width-height;
      }
    }
  }

  .MuiDivider-root {
    margin-bottom: 16px;
  }

  + .ayo-page {
    padding-left: $nav-drawer-width;

    @media (min-width: 1920px) {
      padding-left: $nav-drawer-width-xl;
    }

    @media (min-width: 600px) {
      .MuiContainer-root {
        padding-left: $nav-drawer-page-px-sm;
        padding-right: $nav-drawer-page-px-sm;
      }
    }

    @media (min-width: 1280px) {
      .MuiContainer-maxWidthLg {
        max-width: $nav-drawer-page-max-width-lg;
      }
    }
  }

  &.MuiDrawer-modal {
    .MuiDrawer-paper {
      width: $nav-drawer-mobile-width;
    }
  }

  .ayo-dot-indicator {
    position: absolute;
    right: $nav-drawer-ayo-dot-indicator-right;
  }
}
