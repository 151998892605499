$ayo-family-activities-page-min-width: 160px;
$ayo-family-activities-page-search-paper-pt: 8px;
$ayo-family-activities-page-search-loader-height: 24px;

.ayo-family-activities-page {
  flex: 1;

  .ayo-stt-wrapper {
    width: 100%;
  }

  &__tabs.ayo-tabs.ayo-tabs--outlined.MuiTabs-root {
    background-color: $operational-surface;
    border: none;
    width: fit-content;

    .MuiTab-root {
      min-width: $ayo-family-activities-page-min-width;
    }
  }
}

.MuiAutocomplete-popper {
  .ayo-family-activities-page__search__paper {
    margin-top: $ayo-family-activities-page-search-paper-pt;
    background-color: $operational-surface;

    .ayo-simple-loader {
      img {
        width: auto;
        height: $ayo-family-activities-page-search-loader-height;
      }
    }
  }
}
