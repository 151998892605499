$new-features-tour-dialog-width: 560px;

.ayo-features-tour {
  .ayo-dialog__container {
    max-width: $new-features-tour-dialog-width;
  }

  .ayo-features-tour__illustration {
    height: 100%;
    position: absolute;
    top: 0;
  }

  &__new-feature-item {
    &:focus {
      outline: none;
    }
  }

  @media (max-width: 600px) {
    .ayo-try-mobile__block {
      align-items: center;

      svg {
        max-width: 100%;
      }

      .ayo-try-mobile-informational-message__download-button__app-store {
        svg {
          margin: 0;
        }
      }
    }
  }
}

.__floater__open button {
  &[data-action="primary"] {
    &:hover {
      background-color: $primary-600 !important;
      border-color: $primary-100 !important;
    }

    &:focus {
      background-color: $secondary-200 !important;
      border-color: $secondary-500 !important;
      color: $primary-800 !important;
    }
  }

  &[data-action="back"],
  &[data-action="skip"] {
    &:hover {
      background-color: $primary-050 !important;
      border-color: $primary-100 !important;
    }

    &:focus {
      background-color: $secondary-050 !important;
      border-color: $secondary-500 !important;
      color: $primary-800 !important;
    }
  }
}
