$opportunity-card-base-padding: 8px;
$opportunity-actions-height: 54px;
$opportunity-actions-height-xl: 64px;
$opportunity-link-icon-size: 18px;
$activity-chip-bg-color: #ffcfd4;
$extracurricular-chip-bg-color: #fff3d6;
$course-certification-chip-bg-color: #dbc9fe;
$course-certification-card-bg-color: #5b4783;
$course-certification-scroll-bg-color: #bca0f3;
$librarybook-chip-bg-color: #aaeaa9;
$librarybook-card-bg-color: #30663c;
$librarybook-scroll-bg-color: #6bad6a;
$libraryvideo-chip-bg-color: #cbe3fc;
$libraryvideo-card-bg-color: #304d97;
$libraryvideo-tag-bg-color: #1a2f64;
$libraryvideo-scroll-bg-color: #5d82e0;
$studies-chip-bg-color: #fbe1ff;
$studies-card-bg-color: #823097;
$studies-scroll-bg-color: #d159ef;
$volunteering-chip-bg-color: #71f9fd;
$volunteering-card-bg-color: #24606d;
$volunteering-tag-bg-color: #164748;
$volunteering-scroll-bg-color: #409b9d;

.ayo-opportunity-card {
  &__info-bubble {
    position: absolute;
    right: 2 * $opportunity-card-base-padding;
    top: 2 * $opportunity-card-base-padding;
    z-index: 1;
  }

  .ayo-flippable-card__inner--flipped {
    .ayo-flippable-card__front {
      .ayo-opportunity-card__info-bubble,
      .ayo-button {
        display: none;
      }
    }
  }

  &__scrollable-container {
    height: calc(100% - #{$opportunity-actions-height} - 2 * #{$opportunity-card-base-padding});
    margin: $opportunity-card-base-padding 0 $opportunity-card-base-padding
      $opportunity-card-base-padding;
    padding-right: $opportunity-card-base-padding;
    overflow-y: auto;

    @media (min-width: 1920px) {
      height: calc(
        100% - #{$opportunity-actions-height-xl} - 2 * #{$opportunity-card-base-padding}
      );
    }

    &::-webkit-scrollbar {
      width: $scrollbar-width-sm;
    }
  }

  .ayo-tags-block {
    text-align: left;
  }

  .ayo-chip {
    @include body3;

    padding-top: 0;
    padding-bottom: 0;
  }

  .ayo-ml-input-chip {
    color: $primary-600;
    font-weight: bold;
  }

  .ayo-link {
    @include caption;

    svg {
      max-width: $opportunity-link-icon-size;
      min-width: $opportunity-link-icon-size;
    }
  }

  .MuiDivider-vertical {
    margin: $opportunity-card-base-padding / 2;
  }

  &.activity {
    .ayo-flippable-card__front .ayo-chip {
      background-color: $activity-chip-bg-color;
    }
  }

  &.extracurricular {
    .ayo-flippable-card__front .ayo-chip {
      background-color: $extracurricular-chip-bg-color;
    }
  }

  &.certification,
  &.course {
    .ayo-flippable-card__front .ayo-chip {
      background-color: $course-certification-chip-bg-color;
    }

    .ayo-flippable-card__back {
      background-color: $course-certification-card-bg-color;
    }

    ::-webkit-scrollbar-thumb {
      background-color: $course-certification-scroll-bg-color;
    }
  }

  &.librarybook {
    .ayo-flippable-card__front .ayo-chip {
      background-color: $librarybook-chip-bg-color;
    }

    .ayo-flippable-card__back {
      background-color: $librarybook-card-bg-color;
    }

    ::-webkit-scrollbar-thumb {
      background-color: $librarybook-scroll-bg-color;
    }
  }

  &.libraryvideo {
    .ayo-flippable-card__front .ayo-chip {
      background-color: $libraryvideo-chip-bg-color;
    }

    .ayo-flippable-card__back {
      background-color: $libraryvideo-card-bg-color;

      .ayo-chip {
        background-color: $libraryvideo-tag-bg-color;
      }

      ::-webkit-scrollbar-thumb {
        background-color: $libraryvideo-scroll-bg-color;
      }
    }
  }

  &.studies {
    .ayo-flippable-card__front .ayo-chip {
      background-color: $studies-chip-bg-color;
    }

    .ayo-flippable-card__back {
      background-color: $studies-card-bg-color;

      ::-webkit-scrollbar-thumb {
        background-color: $studies-scroll-bg-color;
      }
    }
  }

  &.volunteering {
    .ayo-flippable-card__front .ayo-chip {
      background-color: $volunteering-chip-bg-color;
    }

    .ayo-flippable-card__back {
      background-color: $volunteering-card-bg-color;

      .ayo-chip {
        background-color: $volunteering-tag-bg-color;
      }

      ::-webkit-scrollbar-thumb {
        background-color: $volunteering-scroll-bg-color;
      }
    }
  }

  &.certification,
  &.course,
  &.librarybook,
  &.libraryvideo,
  &.studies,
  &.volunteering {
    .ayo-flippable-card__back {
      * {
        color: $operational-surface;
      }

      .ayo-link,
      .ayo-button:not(.Mui-focusVisible, :hover) {
        svg * {
          @include fill-icon-element($operational-surface);
        }
      }
    }
  }
}
