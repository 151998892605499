$ayo-chips-block-chip-padding: 4px 12px;
$ayo-chips-block-chip-icon-mr: 4px;

.ayo-chips-block {
  &__delete-chip {
    @include removable-chip-black;
  }

  &__clear-all-chips {
    svg * {
      fill: $primary-500;
    }
  }

  &__chip {
    background-color: $operational-black;
    padding: $ayo-chips-block-chip-padding;
    color: $operational-surface;
  }

  .MuiChip-icon {
    margin-right: $ayo-chips-block-chip-icon-mr;
  }
}
