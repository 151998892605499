$date-picker-calendar-border-radius: 16px;
$date-picker-calendar-icon-border-radius: 50%;
$date-picker-calendar-icon-height: 32px;
$date-picker-calendar-icon-width: 32px;
$date-picker-calendar-header-padding: 0 24px;
$date-picker-calendar-min-height: 214px;
$date-picker-calendar-header-height-lg: 35px;
$date-picker-calendar-header-space: 12px;
$date-picker-width: 280px;

.ayo-date-picker {
  &__calendar {
    border-radius: $date-picker-calendar-border-radius;

    .MuiCalendarOrClockPicker-root:not(:has(.MuiClockPicker-root)) > *,
    .MuiCalendarPicker-root {
      width: $date-picker-width;
    }

    .MuiClockPicker-root {
      .MuiClock-amButton .MuiTypography-root,
      .MuiClock-pmButton .MuiTypography-root,
      .MuiClockNumber-root {
        font-family: Lato, sans-serif;
        font-size: 1.166rem;
        line-height: 1.833rem;
      }

      .MuiClock-pin,
      .MuiClockPointer-root,
      .MuiClockNumber-root.Mui-selected {
        background-color: $primary-500;
      }
    }

    .MuiIconButton-root,
    .MuiPickersDay-root,
    .MuiDayPicker-weekDayLabel {
      height: $date-picker-calendar-icon-height;
      width: $date-picker-calendar-icon-height;
      padding: 0;
      border-radius: $date-picker-calendar-icon-border-radius;
    }
    
    .MuiPickersCalendarHeader-root {
      padding: $date-picker-calendar-header-padding;
    }

    .MuiDayPicker-slideTransition {
      margin-top: $date-picker-calendar-header-space;
      min-height: $date-picker-calendar-min-height;
    }

    .MuiPickersArrowSwitcher-spacer {
      width: $date-picker-calendar-header-space;
    }

    .MuiPickersCalendarHeader-label {
      @include subtitle2;

      text-transform: capitalize;

      @media (min-width: 1920px) {
        height: $date-picker-calendar-header-height-lg;
      }
    }

    .MuiDayPicker-weekDayLabel,
    .MuiPickersDay-root {
      @include body2;

      &.Mui-disabled {
        background-color: transparent;
        color: $typography-disabled;
        pointer-events: auto;
      }

      &.Mui-selected {
        background-color: $primary-500;
        color: $typography-white;
      }
    }

    .MuiPickersDay-today {
      border: none;
      color: $primary-500;
    }

    .MuiPickersArrowSwitcher-button {
      svg path[fill="none"] {
        fill: none;
      }

      .MuiTouchRipple-root {
        display: none;
      }
    }
  }
}
