$hotspot-size: 32px;
$hotspot-inner-size: 8px;
$hotspot-inner-background: #296139;
$hotspot-outer-background-light: #29613933;
$hotspot-outer-background-dark: #d4dfd7;

.ayo-hotspot {
  appearance: none;
  height: $hotspot-size;
  width: $hotspot-size;
  cursor: pointer;
  padding: ($hotspot-size - $hotspot-inner-size) / 2;
  position: relative;
  background: transparent;
  border: 0;
  border-radius: 0;
  margin-left: $hotspot-inner-size;
  min-width: $hotspot-size;
  min-height: $hotspot-size;
  box-sizing: border-box;

  .ayo-hotspot--inner {
    background: $hotspot-inner-background;
    border-radius: 50%;
    display: block;
    height: $hotspot-inner-size;
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    width: $hotspot-inner-size;
    animation: 1.2s ease-in-out 0s infinite ayo-hotspot--inner;
    z-index: 10;
  }

  .ayo-hotspot--outer {
    background: $hotspot-outer-background-light;
    border-radius: 50%;
    box-sizing: border-box;
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform-origin: center center;
    width: 100%;
    animation: 1.2s ease-in-out 0s infinite ayo-hotspot--outer;

    &.dark {
      background: $hotspot-outer-background-dark;
    }
  }

  &:not(.pulsating) {
    .ayo-hotspot--inner {
      animation: none;
    }

    .ayo-hotspot--outer {
      display: none;
    }
  }

  &:not(.clickable) {
    cursor: initial;
  }

  &:focus {
    outline: none;

    .ayo-hotspot--outer {
      border: 2px solid RGB(41 97 57 / 60%);
    }
  }
}

@keyframes ayo-hotspot--inner {
  20% {
    opacity: 0.8;
  }

  90% {
    opacity: 1;
  }
}

@keyframes ayo-hotspot--outer {
  0% {
    transform: scale(1);
  }

  45% {
    transform: scale(0.75);
  }

  100% {
    transform: scale(1);
  }
}
