$container-max-width: 504px;
$container-max-width-lg: 672px;
$margin-top: -2px;
$button-width: 240px;

.ayo-create-portfolio-section {
  &__content {
    max-width: $container-max-width;
    align-self: center;
    margin-top: $margin-top;
    text-align: center;

    @media (min-width: 1920px) {
      max-width: $container-max-width-lg;
    }

    &__button {
      width: $button-width;
    }
  }
}
