$empty-week-height-padding: 50px;
$empty-week-height-padding-xl: 56px;
$empty-week-bg: #EBEEEF;
$empty-week-br: 16px;
$day-container-padding: 4px 24px;
$days-gap: 24px;

.ayo-rotation-weeks-schedule {
  &__week-block {
    position: relative;
    max-width: 100%;
    max-height: max-content;
    display: grid;
    grid-column-gap: $days-gap;
    grid-template-columns: repeat(5, 1fr);

    .ayo-day-schedule__item__container {
      padding: $day-container-padding;
    }

    &__empty {
      height: calc(100% - #{$empty-week-height-padding});
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-radius: $empty-week-br;
      background-color: $empty-week-bg;

      @media (min-width: 1920px) {
        height: calc(100% - #{$empty-week-height-padding-xl});
      }
    }
  }
}
