@import './components/approve-moderation-toggle-button/approve-moderation-toggle-button';
@import './components/translate-moderation-button/translate-moderation-button';
@import './components/interests-moderation/interests-moderations';
@import './components/opportunities-moderation/opportunities-moderations';
@import './components/translation-edit-dialog/translations-edit-dialog';

$fab-sticky-bottom: 16px;
$fab-sticky-bottom-large: 24px;
$fab-sticky-padding-right: 12px;
$fab-sticky-padding-right-large: 48px;

.ayo-content-edit-page {
  &__scroll-button {
    position: sticky;
    bottom: $fab-sticky-bottom;
    display: flex;
    justify-content: flex-end;
    padding-right: $fab-sticky-padding-right;

    @media (min-width: 1920px) {
      padding-right: $fab-sticky-padding-right-large;
      bottom: $fab-sticky-bottom-large;
    }
  }
}
