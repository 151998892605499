$checkbox-border-radius: 6px;
$checkbox-icon-size: 2rem;
$checkbox-padding: 4px 4px 4px 0;
$checkbox-focus-shadow-position: 0 0 0 2px;
$checkbox-mr: 8px;

.ayo-checkboxgroup,
.ayo-checkbox {
  input[type='checkbox'] {
    & + svg {
      border-radius: $checkbox-border-radius;
    }

    &:hover + svg * {
      @include fill-icon-element($primary-600);
    }

    &:focus + svg {
      box-shadow: $checkbox-focus-shadow-position $secondary-100;

      * {
        @include fill-icon-element($secondary-500);
      }
    }
  }

  &.Mui-active input[type='checkbox'] {
    & + svg {
      box-shadow: none;

      * {
        @include fill-icon-element($primary-500);
      }
    }
  }

  &.Mui-disabled input[type='checkbox'] {
    & + svg * {
      @include fill-icon-element($operational-disabled-fill);
    }
  }

  &.MuiCheckbox-root {
    color: $primary-500;
    padding: $checkbox-padding;
    margin-right: $checkbox-mr;

    &:hover {
      background-color: transparent;
    }
  }

  .MuiFormLabel-root {
    @include subtitle1;
  }

  .MuiSvgIcon-root {
    font-size: $checkbox-icon-size;
  }
}

