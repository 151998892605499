$container-grid-gap: 12px;
$container-grid-template-columns: repeat(8, 1fr);
$container-grid-template-columns-md: repeat(5, 1fr);
$card-border-radius: 16px;

.ayo-portfolio-interests-section {
  background-color: $operational-black;
  background-image: url("../../../../../resources/images/portfolio/interests_bg.svg"),
  url("../../../../../resources/images/portfolio/interests_bg_top.svg"),
  url("../../../../../resources/images/portfolio/interests_bg_bottom.svg");
  background-position: center, top, bottom;
  background-repeat: no-repeat;
  background-size: 100% auto;

  &__container {
    justify-content: center;
    display: grid;
    gap: $container-grid-gap;
    grid-template-columns: $container-grid-template-columns;

    @media (max-width: 960px) {
      grid-template-columns: $container-grid-template-columns-md;
    }
  }

  &__card {
    border-radius: $card-border-radius;

    svg {
      width: 100%;
      height: auto;
    }
  }
}
