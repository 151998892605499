$leadership-attributes-how-does-it-work-section-arrow-illustration-margin: 24px 0;
$leadership-attributes-how-does-it-work-section-arrow-illustration-margin-small: 24px;
$leadership-attributes-how-does-it-work-section-arrow-illustration-height-small: 34px;
$leadership-attributes-how-does-it-work-section-text-block-mb: 80px;
$leadership-attributes-how-does-it-work-section-text-block-mb-small: 24px;
$leadership-attributes-how-does-it-work-section-illustration-block-mb-small: -50px;

.ayo-leadership-attributes-how-does-it-work {
  &__illustration {
    max-width: 100%;

    &__arrow {
      margin: $leadership-attributes-how-does-it-work-section-arrow-illustration-margin;

      @media (max-width: 600px) {
        transform: rotate(90deg);
        height: $leadership-attributes-how-does-it-work-section-arrow-illustration-height-small;
        margin: $leadership-attributes-how-does-it-work-section-arrow-illustration-margin-small;
      }
    }
  }

  &__illustration-block {
    @media (max-width: 600px) {
      margin-bottom: $leadership-attributes-how-does-it-work-section-illustration-block-mb-small;
    }

    @media (min-width: 1920px) {
      .MuiTypography-noWrap {
        overflow: visible;
      }
    }
  }

  &__text {
    margin-bottom: $leadership-attributes-how-does-it-work-section-text-block-mb;

    @media (max-width: 600px) {
      margin-bottom: $leadership-attributes-how-does-it-work-section-text-block-mb-small;
    }
  }
}
