
$legend-margin: 16px;
$legend-padding: 24px;
$legend-br: 9px;
$legend-item-mb: 16px;
$legend-spot-size: 16px;
$legend-spot-padding: 8px;
$legend-spot-secondary-size: 16px;
$legend-spot-small-size: 8px;
$legend-spot-main-size: 12px;
$legend-spot-mr: 24px;
$legend-comment-spot-size: 24px;

.ayo-interests-viewmap {
  .interests-viewmap-canvas {
    border-radius: $interests-map-br;
  }

  .map-legend {
    position: absolute;
    bottom: $legend-margin;
    right: $legend-margin;
    background: $interests-map-widgets-bg-color;
    padding: $legend-padding;
    border-radius: $legend-br;

    @media (max-width: 600px) {
      left: $legend-margin;
    }

    p,
    h4 {
      color: $typography-white;
    }

    .ayo-typography--caption {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: $legend-item-mb;
      }
    }

    .legend-spot.comment {
      width: $legend-comment-spot-size;
      height: $legend-comment-spot-size;
      margin-left: $legend-spot-padding / 2;
      margin-right: $legend-spot-mr + $legend-spot-padding / 2;
    }

    .legend-spot {
      display: inline-block;
      margin-right: $legend-spot-mr;
      border-radius: 50%;

      &.suggested {
        width: $legend-spot-size;
        height: $legend-spot-size;
        margin: $legend-spot-padding;
        margin-right: $legend-spot-padding + $legend-spot-mr;
        background: $secondary-500;
      }
    }
  }
}

$definition-screen-padding: 40px;
$definition-screen-br: 12px;
$definition-bubble-mr: 34px;
$definition-bubble-mb: 24px;
$definition-content-max-width: 55%;
$definition-learn-more-icon-gap: 12px;
$definition-empty-illustration-mb: 56px;
$definition-empty-illustration-mb-mobile: 40px;
$definition-container-br: 16px;
$definition-container-bg-planet-width: 90%;

.definition-container {
  position: relative;
  background-color: $operational-black;
  border-radius: $definition-container-br;
  background-image: url('../../../resources/images/planet.svg'), url('../../../resources/images/stars.svg');
  background-repeat: no-repeat, repeat;
  background-size: $definition-container-bg-planet-width auto, 100% auto;

  @media (max-width: 600px) {
    background-position-y: center, top;
  }

  .interest-definition-canvas {
    width: 100%;
    height: auto;
  }

  .definition-box {
    padding: $definition-screen-padding;
    display: flex;

    &.centered {
      justify-content: center;
    }

    .interest-bubble-container {
      @media (min-width: 600px) {
        margin-right: $definition-bubble-mr;
      }

      margin-bottom: $definition-bubble-mb;

      .interest-bubble-uppertext {
        color: $typography-white;
      }
    }

    .reflection.cropped {
      @include wrapped-text(3);
    }

    .expand-toggle-icon * {
      @include fill-icon-element($typography-white);
    }

    .definition-content-box {
      max-width: $definition-content-max-width;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .definitions-list {
        display: flex;
        flex-direction: column;
      }

      .no-definition-illustration {
        max-width: 100%;
        height: auto;
        align-self: center;
        margin-bottom: $definition-empty-illustration-mb;

        @media (max-width: 600px) {
          margin-bottom: $definition-empty-illustration-mb-mobile;
        }
      }

      .learn-more-link {
        display: inline-flex;
        gap: $definition-learn-more-icon-gap;

        svg * {
          @include fill-icon-element($typography-white);
        }
      }

      .MuiTypography-root {
        color: $typography-white;
      }

      .definition-body {
        @include wrapped-text(3);
      }

      button.back-to-interests {
        width: 50%;
      }
    }
  }

  @media (max-width: 600px) {
    .interest-definition-canvas {
      display: none;
    }

    .definition-box {
      position: relative;
      flex-direction: column;
      align-items: center;

      .definition-content-box {
        max-width: 100%;

        .definition-body {
          @include wrapped-text(6);
        }

        button.back-to-interests {
          width: 100%;
        }
      }
    }
  }
}
