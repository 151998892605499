$month-picker-padding: 24px 0;
$month-picker-heading-padding: 0 26px;
$month-picker-body-padding: 0 32px;
$month-picker-gap: 8px;
$month-picker-month-height: 48px;
$month-picker-month-br: 16px;

.ayo-month-picker {
  width: $date-picker-width;
  padding: $month-picker-padding;

  &__heading {
    padding: $month-picker-heading-padding;

    svg * {
      @include fill-icon-element($primary-500);
    }

    button:disabled svg * {
      @include fill-icon-element($typography-disabled);
    }
  }

  .MuiMonthPicker-root {
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding: $month-picker-body-padding;
    column-gap: $month-picker-gap;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    min-height: ($month-picker-month-height + $month-picker-gap) * 3;

    .PrivatePickersMonth-root {
      @include button1;

      color: $typography-titles;
      border-radius: $month-picker-month-br;
      height: $month-picker-month-height;
      margin: $month-picker-gap / 2 0;

      &.Mui-selected {
        color: $typography-white;
        background-color: $primary-500;
      }

      &:disabled {
        display: none;
      }
    }
  }
}
