$linear-progress-height: 7px;
$linear-progress-border-radius: 0 15px 15px 0;

.ayo-linear-progress {
  background-color: $operational-surface;
  height: $linear-progress-height;

  .MuiLinearProgress-barColorPrimary {
    background-color: $progressbar-bar;
  }

  .MuiLinearProgress-bar {
    border-radius: $linear-progress-border-radius;
  }
}
