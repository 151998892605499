$opportunities-container-bg-color: RGB(231 231 238);
$opportunities-container-border-radius: 47px;
$opportunities-container-border-radius-mobile: 16px;
$opportunities-container-pb-mobile: 56px;
$opportunities-container-content-height: 320px;
$opportunities-container-content-height-xl: 360px;
$opportunities-container-content-width: 640px;
$opportunities-container-content-width-xl: 720px;
$opportunities-carousel-button-offset-mobile: 12px;
$helper-offset-x: 130px;
$helper-offset-x-xl: 145px;
$helper-offset-y: 115px;
$helper-offset-y-xl: 130px;
$helper-label-offset-x: 60px;
$helper-label-max-width: 126px;
$thumbs-btn-stroke-width: 2px;
$opportunities-filters-width: 360px;
$opportunities-filters-width-xl: 440px;
$opportunities-filters-mx: 5px;

.ayo-opportunities {
  flex: 1;

  &__container {
    position: relative;
    background-color: $opportunities-container-bg-color;
    border-radius: $opportunities-container-border-radius;

    .ayo-initiative-empty-state {
      background: transparent;
    }

    @media (max-width: 600px) {
      border-radius: $opportunities-container-border-radius-mobile;
      padding-bottom: $opportunities-container-pb-mobile;

      .ayo-carousel > .ayo-button {
        position: absolute;
        bottom: $opportunities-carousel-button-offset-mobile;

        &:first-child {
          left: $opportunities-carousel-button-offset-mobile;
        }
    
        &:last-child {
          right: $opportunities-carousel-button-offset-mobile;
        }
      }
    }

    &--full {
      position: fixed;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 0;
      inset: 0;
      margin-bottom: 0;
      z-index: 10;

      .ayo-full-screen-btn {
        position: absolute;
        top: $opportunities-carousel-button-offset-mobile;
        left: $opportunities-carousel-button-offset-mobile;
      }

      .students-filters {
        position: absolute;
        right: $opportunities-carousel-button-offset-mobile;
        top: $opportunities-carousel-button-offset-mobile;
      }
    }

    &__content {
      display: flex;
      justify-content: center;

      @media (max-width: 600px) {
        width: 100%;
      }

      .ayo-carousel {
        height: $opportunities-container-content-height;
        width: $opportunities-container-content-width;

        @media (max-width: 600px) {
          width: 100%;
        }

        @media (min-width: 1920px) {
          height: $opportunities-container-content-height-xl;
          width: $opportunities-container-content-width-xl;
        }
      }
    }

    .ayo-tabs {
      .MuiTabs-fixed {
        border-bottom-width: 0;
      }

      .MuiTabs-flexContainer {
        justify-content: center;
      }
    }

    .ayo-flippable-helper {
      position: absolute;
      left: 50%;
      margin-left: $helper-offset-x;
      top: 50%;
      margin-top: $helper-offset-y;

      @media (min-width: 1920px) {
        margin-left: $helper-offset-x-xl;
        margin-top: $helper-offset-y-xl;
      }

      svg * {
        @include fill-icon-element($operational-overlay);
      }

      p {
        color: $typography-labels-dark;
        margin-left: $helper-label-offset-x;
        max-width: $helper-label-max-width;
      }
    }

    .ayo-thumbs-down-btn,
    .ayo-thumbs-up-btn {
      &:hover {
        cursor: pointer;

        circle {
          fill: $primary-050;
          stroke: $primary-100;
          stroke-width: $thumbs-btn-stroke-width;
        }
      }

      &:focus {
        outline: none;

        circle {
          fill: $secondary-050;
          stroke: $secondary-500;
          stroke-width: $thumbs-btn-stroke-width;
        }
      }
    }

    .ayo-thumbs-down-btn.selected circle {
      fill: $operational-error;
    }

    .ayo-thumbs-up-btn.selected circle {
      fill: $data-visualization-green-090-100;
    }
  }

  .filters-popover {
    @media (max-width: 600px) {
      width: calc(100vw - #{$opportunities-filters-mx} * 2);
    }

    .MuiPaper-root .filters-container {
      min-width: $opportunities-filters-width;

      @media (min-width: 1920px) {
        min-width: $opportunities-filters-width-xl;
      }

      @media (max-width: 600px) {
        min-width: 100%;
      }
    }
  }
}
