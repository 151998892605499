$accordion-action-icon-left: 24px;
$accordion-action-icon-top: 22px;
$accordion-border-radius: 16px;
$accordion-details-padding: 0 24px 24px;
$accordion-summary-padding: 24px 24px 24px 72px;
$dialog-container-w-lg: 864px;
$dialog-container-w-md: 624px;

.ayo-club-hub__join-dialog {
  @include dialog-full-width-mobile;

  .ayo-accordion {
    border-radius: $accordion-border-radius;
    background-color: $operational-background-grey;
  }

  .ayo-accordion .MuiAccordionDetails-root {
    padding: $accordion-details-padding;
  }

  .ayo-accordion .ayo-accordion__summary {
    padding: $accordion-summary-padding;

    .ayo-accordion__open-icon {
      circle {
        fill: $operational-surface;
      }
    }

    .ayo-accordion__close-icon, .ayo-accordion__open-icon {
      top: $accordion-action-icon-top;
      left: $accordion-action-icon-left;
    }
  }

  @media (min-width: 960px) {
    .ayo-dialog__container {
      box-sizing: border-box;
      max-width: $dialog-container-w-md;
      min-width: $dialog-container-w-md;
    }
  }

  @media (min-width: 1920px) {
    .ayo-dialog__container {
      box-sizing: border-box;
      max-width: $dialog-container-w-lg;
      min-width: $dialog-container-w-lg;
    }
  }
}
