$aptitudes-dialog-width: 544px;
$aptitudes-dialog-width-lg: 744px;
$aptitudes-dialog-padding: 40px;
$aptitudes-dialog-padding-sm: 24px;
$aptitudes-dialog-text-block-border-radius: 16px;
$aptitudes-dialog-text-block-ul-margin-top: 8px;
$aptitudes-dialog-illustration-width: 80px;

.ayo-aptitudes-dialog {
  .ayo-dialog__container {
    padding: $aptitudes-dialog-padding;
    min-width: $aptitudes-dialog-width;
    max-width: $aptitudes-dialog-width;

    @media (min-width: 1920px) {
      min-width: $aptitudes-dialog-width-lg;
      max-width: $aptitudes-dialog-width-lg;
    }

    @media (max-width: 600px) {
      min-width: auto;
      padding: $aptitudes-dialog-padding-sm;
    }
  }

  &__text {
    background-color: $operational-background;
    border-radius: $aptitudes-dialog-text-block-border-radius;

    ul {
      margin-top: $aptitudes-dialog-text-block-ul-margin-top;
    }

    li {
      @include body2;
    }
  }

  &__illustration {
    width: $aptitudes-dialog-illustration-width;
    height: auto;
  }
}
