$block-border-radius: 32px;
$button-min-width: 237px;
$chip-border-radius: 9px;
$chip-margin-bottom: 16px;
$chip-padding: 0 8px;
$parent-bg-color: #30663c;
$parent-chip-background-color: #aaeaa9;
$student-bg-color: #304d97;
$student-chip-background-color: #cbe3fc;
$student-parent-bg-position-mobile: center top 30px, center bottom 24px;
$student-parent-bg-position: top center, bottom;
$teacher-bg-color: #3b3355;
$teacher-bg-position-desktop: right 24px top 30px, center bottom -48px;
$teacher-bg-position-mobile: right 24px top 30px, center bottom -16px;
$teacher-chip-background-color: #dbc9fe;

@mixin ayo-lesson-planner-explore-more-block($bg-color,
 $chip-color,
 $desktop-bg-top,
 $desktop-bg-bottom,
 $mobile-bg-top,
 $mobile-bg-bottom,
 $desktop-bg-position,
 $mobile-bg-position,
) {
  background-color: $bg-color;

  .ayo-chip {
    background-color: $chip-color;
  }

  .ayo-button {
    min-width: $button-min-width;

    &--text {
      background-color: $typography-white;
    }
  }

  background-image: url($desktop-bg-top), url($desktop-bg-bottom);
  background-repeat: no-repeat, no-repeat;
  background-position: $desktop-bg-position;

  @media (max-width: 600px) {
    background-image: url($mobile-bg-top), url($mobile-bg-bottom);
    background-position: $mobile-bg-position;
  }
}

.ayo-lesson-planner-landing__explore-more-section {
  &__block {
    position: relative;
    border-radius: $block-border-radius;

    img {
      width: 100%;
    }

    .ayo-chip {
      margin-bottom: $chip-margin-bottom;
      padding: $chip-padding;
      border-radius: $chip-border-radius;

      @include ayo-typography-titles
    }

    &--student {
      background-size: contain, cover;

      @media (max-width: 600px) {
        background-size: contain, contain;
      }

      @include ayo-lesson-planner-explore-more-block($student-bg-color,
        $student-chip-background-color,
        "../../../../../../resources/images/lesson-planner/explore_more_section_student_desktop_bg_top.svg",
        "../../../../../../resources/images/lesson-planner/explore_more_section_student_desktop_bg_bottom.svg",
        "../../../../../../resources/images/lesson-planner/explore_more_section_student_mobile_bg_top.svg",
        "../../../../../../resources/images/lesson-planner/explore_more_section_student_mobile_bg_bottom.svg",
        $student-parent-bg-position,
        $student-parent-bg-position-mobile,
      )
    }

    &--teacher {
      @media (max-width: 600px) {
        background-size: auto, 105% auto;
      }

      @include ayo-lesson-planner-explore-more-block($teacher-bg-color,
        $teacher-chip-background-color,
        "../../../../../../resources/images/lesson-planner/explore_more_section_teacher_desktop_bg_top.svg",
        "../../../../../../resources/images/lesson-planner/explore_more_section_teacher_desktop_bg_bottom.svg",
        "../../../../../../resources/images/lesson-planner/explore_more_section_teacher_mobile_bg_top.svg",
        "../../../../../../resources/images/lesson-planner/explore_more_section_teacher_mobile_bg_bottom.svg",
        $teacher-bg-position-desktop,
        $teacher-bg-position-mobile,
      )
    }

    &--parent {
      background-size: contain, cover;

      @media (max-width: 600px) {
        background-size: contain, contain;
      }

      @include ayo-lesson-planner-explore-more-block($parent-bg-color,
        $parent-chip-background-color,
        "../../../../../../resources/images/lesson-planner/explore_more_section_parent_desktop_bg_top.svg",
        "../../../../../../resources/images/lesson-planner/explore_more_section_parent_desktop_bg_bottom.svg",
        "../../../../../../resources/images/lesson-planner/explore_more_section_parent_mobile_bg_top.svg",
        "../../../../../../resources/images/lesson-planner/explore_more_section_parent_mobile_bg_bottom.svg",
        $student-parent-bg-position,
        $student-parent-bg-position-mobile,
      )
    }
  }
}
