$club-banner-actions-activator-br: 16px;

.ayo-club-banner {
  &__actions {
    .ayo-icon-button {
      background-color: $primary-050;
      border-radius: $club-banner-actions-activator-br;
    }
  }
}
