$ayo-activity-description-dialog-scrollable-container-margin: 24px 0;
$ayo-activity-description-dialog-scrollable-container-border-radius: 16px;
$ayo-activity-description-dialog-padding: 16px;
$ayo-activity-description-dialog-chip-margin: 8px 8px 0 0;
$ayo-activity-description-dialog-description-item-border-radius: 16px;
$ayo-activity-description-dialog-description-item-hr-margin: 24px 0;
$ayo-activity-description-dialog-title-block-svg-width: 84px;
$ayo-activity-description-dialog-container-width: 624px;
$ayo-activity-description-dialog-title-margin-bottom: 8px;

.ayo-activity-description-dialog {
  &__scrollable-container {
    margin: $ayo-activity-description-dialog-scrollable-container-margin;

    &::-webkit-scrollbar {
      width: $scrollbar-width-sm;
    }

    &:focus {
      outline: none;
    }
  }

  .MuiDialogContent-root.ayo-activity-description-dialog__scrollable-container {
    padding-right: $ayo-activity-description-dialog-padding;
  }

  &__chip {
    margin: $ayo-activity-description-dialog-chip-margin;
    background-color: $operational-surface;
  }

  &__details-box {
    &__description {
      @include wrapped-text(2);

      @media (max-width: 600px) {
        @include wrapped-text(3);
      }

      &--collapsed {
        @include wrapped-text(100);
      }
    }

    &__action-buttons {
      .ayo-icon-button {
        height: initial;
        width: initial;
      }
    }
  }

  &__description-title{
    margin-bottom: $ayo-activity-description-dialog-title-margin-bottom;
    font-weight: 700;
  }

  &__close-button {
    width: 60%;

    @media (max-width: 600px) {
      width: 100%;
    }
  }

  &__title-block {
    display: flex;
    align-items: center;
    justify-content: space-between;

    img,
    svg {
      width: $ayo-activity-description-dialog-title-block-svg-width;
      height: auto;
    }
  }

  .ayo-dialog__container {
    min-width: $ayo-activity-description-dialog-container-width;
    max-width: $ayo-activity-description-dialog-container-width;

    @media (max-width: 600px) {
      min-width: auto;
    }
  }
}
