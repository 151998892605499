$delete-icon-pos-right: -18px;
$delete-icon-pos-top: -10px;
$delete-icon-size: 30px;
$delete-item-wrapper-border-radius: 16px;

.ayo-delete-item-wrapper {
  position: relative;
  outline: none;

  &:hover {
    outline: 2px solid $primary-100;

    .ayo-typography--text-light, .ayo-typography--subtitle2 {
      color: $primary-500;
    }

    & > span[role="button"] > div:first-child {
      background-color: $primary-050;
    }
  }

  &:focus {
    outline: 2px solid $secondary-500;

    .ayo-typography--text-light, .ayo-typography--subtitle2 {
      color: $primary-500;
    }

    & > span[role="button"] > div:first-child {
      background-color: $secondary-050;
    }
  }

  border-radius: $delete-item-wrapper-border-radius;

  &__delete-button {
    padding: 0;
    right: $delete-icon-pos-right;
    top: $delete-icon-pos-top;
    position: absolute;

    .ayo-editing-button {
      svg {
        height: $delete-icon-size;
        width: $delete-icon-size;
      }
    }
  }
}
