$table-head-cell-padding: 8px 16px;

.ayo-table {
  .MuiTableCell-head {
    padding: $table-head-cell-padding;
    border: none;
  }

  .MuiTableBody-root {
    .MuiTableRow-root {
      .MuiTableCell-body {
        border: none;
      }
    }
  }
}
