$ayo-comment-card-divider-margin: 5px 8px;
$ayo-comment-card-reply-title-max-width: 370px;
$ayo-comment-card-reply-title-max-width-mobile: 80%;
$ayo-comment-card-timeline-height: 20px;
$ayo-comment-card-timeline-width: 40px;
$ayo-comment-card-timeline-border-radius: 0 0 0 12px;
$ayo-comment-card-timeline-top: 60px;
$ayo-comment-card-timeline-left: -17px;
$ayo-comment-card-action-buttons-padding-left: 8px;
$ayo-comment-card-comment-body-min-height: 200px;
$ayo-comment-card-dialog-title-margin-bottom: 24px;
$ayo-comment-card-dialog-content-padding-top: 8px;
$ayo-comment-card-dot-indicator-top: 16px;
$ayo-comment-card-dot-indicator-left: -10px;

.ayo-comment-card {
  &__divider {
    margin: $ayo-comment-card-divider-margin;
  }

  &__title-box {
    white-space: nowrap;
    overflow: hidden;
    align-self: center;
  }

  &__title {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__body {
    white-space: pre-line;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow-wrap: anywhere;

    &--collapsed {
      -webkit-line-clamp: 2;

      @media (max-width: 640px) {
        -webkit-line-clamp: 4;
      }
    }
  }

  &__timeline {
    height: $ayo-comment-card-timeline-height;
    width: $ayo-comment-card-timeline-width;
    border-radius: $ayo-comment-card-timeline-border-radius;
    border-left: 1px solid $operational-border;
    border-bottom: 1px solid $operational-border;
    position: relative;
    top: $ayo-comment-card-timeline-top;
    left: $ayo-comment-card-timeline-left;
  }

  &__action-buttons {
    svg path {
      fill: $primary-500;
      fill-opacity: 1;
    }
  }

  &__edit-dialog {
    .MuiDialogContent-root {
      padding-top: $ayo-comment-card-dialog-content-padding-top;
    }

    .MuiDialogTitle-root {
      margin-bottom: $ayo-comment-card-dialog-title-margin-bottom;
    }

    @media (max-width: 600px) {
      &.MuiDialog-paperFullWidth.MuiDialog-paper {
        width: 100%;
        justify-content: center;
      }

      &.MuiDialog-paperScrollPaper {
        height: inherit;
      }

      .ayo-comment-body {
        &.ayo-textfield .MuiOutlinedInput-root .MuiInputBase-input {
          min-height: $ayo-comment-card-comment-body-min-height;
        }
      }

      .ayo-comment-buttons {
        flex-direction: column-reverse;
      }
    }
  }

  &__delete-dialog {
    @media (max-width: 600px) {
      &.MuiDialog-paperFullWidth.MuiDialog-paper {
        width: 100%;
        justify-content: center;
      }

      &.MuiDialog-paperScrollPaper {
        height: inherit;
      }
    }
  }

  &.MuiPaper-outlined {
    border: none;
  }

  .ayo-dot-indicator {
    position: absolute;
    top: $ayo-comment-card-dot-indicator-top;
    left: $ayo-comment-card-dot-indicator-left;
  }
}
