$opportunities-how-it-works-section-background-color: #FFF3D6;
$opportunities-how-it-works-section-text-min-height: 322px;
$opportunities-how-it-works-section-text-min-height-lg: 422px;
$opportunities-how-it-works-section-main-content-background-color: #F8F8F8;
$opportunities-how-it-works-section-main-content-section-mt: 120px;
$opportunities-how-it-works-section-main-content-circle-height: 52px;
$opportunities-how-it-works-section-main-content-circle-height-lg: 68px;
$opportunities-how-it-works-section-main-content-circle-width: 52px;
$opportunities-how-it-works-section-main-content-circle-width-lg: 68px;
$opportunities-how-it-works-section-main-content-circle-background-color: #FFCC80;

.ayo-opportunities-how-it-works-section {
  &__header {
    background-image: url("../../../../../../resources/images/opportunities/landing-page/opportunities-how-it-works-bg-lg.svg");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;

    @media (max-width: 1200px) {
      background-color: $opportunities-how-it-works-section-background-color;
      background-image: url("../../../../../../resources/images/opportunities/landing-page/opportunities-how-it-works-bg-md.svg");
    }

    @media (max-width: 600px) {
      background-image: url("../../../../../../resources/images/opportunities/landing-page/opportunities-how-it-works-bg-sm.svg");
    }

    &__text {
      min-height: $opportunities-how-it-works-section-text-min-height;

      @media (max-width: 960px) {
        min-height: 100%;
      }

      @media (min-width: 1920px) {
        min-height: $opportunities-how-it-works-section-text-min-height-lg;
      }
    }
  }

  &__main-content {
    background-color: $opportunities-how-it-works-section-main-content-background-color;

    &__block + &__block {
      margin-top: $opportunities-how-it-works-section-main-content-section-mt;
    }

    &__circle {
      height: $opportunities-how-it-works-section-main-content-circle-height;
      width: $opportunities-how-it-works-section-main-content-circle-width;
      min-width: $opportunities-how-it-works-section-main-content-circle-width;
      background-color: $opportunities-how-it-works-section-main-content-circle-background-color;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 90%;

      @media (min-width: 1920px) {
        height: $opportunities-how-it-works-section-main-content-circle-height-lg;
        min-width: $opportunities-how-it-works-section-main-content-circle-width-lg;
      }
    }
  }
}
