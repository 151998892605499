$ayo-lesson-assignment-options-padding: 24px;
$ayo-lesson-assignment-options-border: 1px solid $operational-border;
$ayo-lesson-assignment-options-border-radius: 16px;

.ayo-lesson-assignment-options {
  padding: $ayo-lesson-assignment-options-padding;
  border: $ayo-lesson-assignment-options-border;
  border-radius: $ayo-lesson-assignment-options-border-radius;

  &__textfield {
    flex: 1;
  }

  &__add-button{
    width: fit-content;
  }
}
