$week-hover-border-size: 2px;
$week-hover-border-style: solid;
$week-hover-br: 50%;

.week-picker.ayo-date-picker__calendar {
  .MuiPickersDay-root {
    &.is-between-hover {
      border-radius: 0;
      background-color: $primary-050;
      border-color: $primary-100;
      border-top-width: $week-hover-border-size;
      border-top-style: solid;
      border-bottom-width: $week-hover-border-size;
      border-bottom-style: solid;
    }

    &.is-first-hover {
      border-top-left-radius: $week-hover-br;
      border-bottom-left-radius: $week-hover-br;
      border-left-width: $week-hover-border-size;
      border-left-style: solid;
      padding-right: $week-hover-border-size;
    }

    &.is-last-hover {
      border-top-right-radius: $week-hover-br;
      border-bottom-right-radius: $week-hover-br;
      border-right-width: $week-hover-border-size;
      border-right-style: solid;
      padding-left: $week-hover-border-size;
    }

    &.is-between-selected {
      border: none;
      border-radius: 0;
      background-color: $primary-500;
      color: $typography-white;
      padding: 0;
    }

    &.is-first-selected {
      border-top-left-radius: $week-hover-br;
      border-bottom-left-radius: $week-hover-br;
    }

    &.is-last-selected {
      border-top-right-radius: $week-hover-br;
      border-bottom-right-radius: $week-hover-br;
    }

  }
}
