$choices-section-padding: 24px;
$choices-section-br: 16px;
$choices-add-choices-padding-y: 50px;
$choices-option-size: 190px;
$choices-option-delete-position-margin: 15px;
$choices-option-odd-padding: 40px;
$choices-error-margin-top: 16px;
$choices-options-row-gap: 40px;
$choices-options-column-gap: 40px;
$choices-edit-dialog-min-width: 600px;
$choices-edit-dialog-editor-height: 125px;

.choices-path {
  &__container {
    padding: $choices-section-padding;
    background-color: $operational-background-grey;
    border-radius: $choices-section-br;
  }

  &__layer-container {
    background-color: $operational-surface;
    padding: $choices-section-padding;
    border-radius: $choices-section-br;
  }

  &__add-path {
    background-color: $primary-050;
    padding-top: $choices-add-choices-padding-y;
    padding-bottom: $choices-add-choices-padding-y;
  }

  &__option-delete {
    position: absolute;
    top: $choices-option-delete-position-margin;
    right: $choices-option-delete-position-margin;

    svg {
      &:first-child,
      &:last-child {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }

  &__options-container {
    display: flex;
    row-gap: $choices-options-row-gap;
    column-gap: $choices-options-column-gap;
    flex-wrap: wrap;

    & > *:nth-child(odd) {
      padding-top: $choices-option-odd-padding;

      .choices-path__option-delete {
        top: $choices-option-delete-position-margin + $choices-option-odd-padding;
      }
    }

    & > *:only-child {
      padding-top: 0;
    }
  }

  .ayo-validation-error {
    margin-top: $choices-error-margin-top;
    color: $operational-error;
  }

  &__edit-dialog {
    .ayo-dialog__container {
      box-sizing: border-box;
      min-width: $choices-edit-dialog-min-width;
    }

    .ql-editor {
      height: $choices-edit-dialog-editor-height;
      max-height: $choices-edit-dialog-editor-height;
      overflow: auto;
    }
  }
}
