.ayo-aptitudes-how-to-learn-section {
  background-image: url("../../../../../../resources/images/aptitudes/landing-page/how-to-learn-aptitudes-bg.svg");
  background-size: 100% 100%;
  background-position: top;

  @media (min-width: 1400px) {
    background-size: 100% auto;
  }

  @media (max-width: 600px) {
    background-image: none;
  }

  &__description {
    max-width: 35%;

    @media (min-width: 1920px) {
      max-width: 50%;
    }

    @media (max-width: 960px) {
      max-width: 100%;
    }
  }
}
