$ayo-aptitudes-explore-more-section-border-radius: 50px;
$ayo-aptitudes-explore-more-section-border-radius-mobile: 16px;
$ayo-aptitudes-explore-more-section-chip-padding: 0 8px;
$ayo-aptitudes-explore-more-section-button-min-width: 184px;
$ayo-aptitudes-explore-more-section-button-min-width-xl: 300px;
$ayo-aptitudes-explore-more-section-student-chip-background-color: #CBE3FC;
$ayo-aptitudes-explore-more-section-teacher-chip-background-color: #DBC9FE;
$ayo-aptitudes-explore-more-section-parent-chip-background-color: #AAEAA9;

@mixin ayo-aptitudes-explore-more-block($chip-color, $desktop-bg, $mobile-bg) {
  .ayo-chip {
    background-color: $chip-color;
  }

  background-image: url($desktop-bg);

  @media (max-width: 600px) {
    background-image: url($mobile-bg);
  }
}

.ayo-aptitudes-explore-more-section {
  background-color: $operational-background;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: $ayo-aptitudes-explore-more-section-border-radius;

  @media (max-width: 600px) {
    border-radius: $ayo-aptitudes-explore-more-section-border-radius-mobile;
    background-position: top;
    background-size: 100% auto;
  }

  &--student {
    @include ayo-aptitudes-explore-more-block($ayo-aptitudes-explore-more-section-student-chip-background-color,
      "../../../../../../resources/images/aptitudes/landing-page/explore-more-student-desktop-bg.svg",
      "../../../../../../resources/images/aptitudes/landing-page/explore-more-student-mobile-bg.svg");
  }

  &--teacher {
    @include ayo-aptitudes-explore-more-block($ayo-aptitudes-explore-more-section-teacher-chip-background-color,
      "../../../../../../resources/images/aptitudes/landing-page/explore-more-teacher-desktop-bg.svg",
      "../../../../../../resources/images/aptitudes/landing-page/explore-more-teacher-mobile-bg.svg");
  }

  &--parent {
    @include ayo-aptitudes-explore-more-block($ayo-aptitudes-explore-more-section-parent-chip-background-color,
      "../../../../../../resources/images/aptitudes/landing-page/explore-more-parent-desktop-bg.svg",
      "../../../../../../resources/images/aptitudes/landing-page/explore-more-parent-mobile-bg.svg");
  }

  &__content {
    width: 35%;

    @media (min-width: 1920px) {
      width: 45%;
    }

    @media (max-width: 960px) {
      width: 65%;
    }

    @media (max-width: 600px) {
      width: inherit;
    }

    &__button {
      background-color: $typography-white;
    }
  }

  .ayo-chip {
    @include subtitle2;

    padding: $ayo-aptitudes-explore-more-section-chip-padding;
  }

  .ayo-button {
    min-width: $ayo-aptitudes-explore-more-section-button-min-width;

    @media (min-width: 1920px) {
      width: $ayo-aptitudes-explore-more-section-button-min-width-xl;
    }
  }
}
