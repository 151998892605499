$empty-state-radius: 12px;

.ayo-grades-section {
  &--empty-state {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    background-color: $content-placeholder;
    border-radius: $empty-state-radius;
  }
}
