/* stylelint-disable no-descending-specificity */
$choices-option-size: 190px;
$choices-option-control-icon: 40px;
$choices-option-control-position: 6px;
$choices-option-padding: 20px;
$choices-option-show-more-padding-xl: 6px;

.choices-path__option {
  --main-color: #{$primary-050};

  box-sizing: border-box;
  width: $choices-option-size;
  height: $choices-option-size;
  padding: $choices-option-padding;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--main-color);
  border: 2px solid transparent;
  position: relative;

  @include choices-path-bubble-box-shadow(var(--main-color-rgb));

  &__show-more {
    @media (min-width: 1920px) {
      padding: $choices-option-show-more-padding-xl;
    }
  }

  .checked-icon {
    *:first-child {
      @include fill-icon-element(var(--main-color));
    }
  }

  .delete-icon {
    display: none;
  }

  .checked-icon,
  .delete-icon {
    width: $choices-option-control-icon;
    height: $choices-option-control-icon;
    position: absolute;
    right: $choices-option-control-position;
    top: $choices-option-control-position;
  }

  &:not(.checked) {
    .checked-icon {
      display: none;
    }
  }

  &.checked {
    border-color: var(--main-color);
    background-color: $primary-050;
  }

  &--dark:not(:hover) {
    .checked-icon *:nth-child(2) {
      @include fill-icon-element($typography-titles);
    }
  }

  &.clickable {
    cursor: pointer;

    &:focus {
      border-color: $secondary-500;
      background-color: $secondary-050;
      outline: none;

      @include choices-path-bubble-box-shadow(rgba($secondary-500, 0.25));

      &.checked {
        .delete-icon {
          display: block;

          *:first-child {
            @include fill-icon-element($secondary-500);
          }
        }
      }
    }

    &:hover {
      border-color: $primary-500;
      background-color: $primary-050;

      @include choices-path-bubble-box-shadow(rgba($primary-500, 0.25));

      &.checked {
        .delete-icon {
          display: block;
        }
      }

      &:not(.checked) {
        .checked-icon {
          display: block;
        }
      }
    }
  }

  .ayo-rich-text-editor--static.quill {
    width: 100%;

    .ql-editor {
      @include wrapped-text(3);

      padding: 0;

      & > * {
        text-align: center;
        cursor: inherit;
      }
    }
  }

  &--light:not(.clickable),
  &--light:not(.checked):not(:hover):not(:focus) { /* stylelint-disable-line */
    .ayo-rich-text-editor--static.quill .ql-editor > *,
    .ayo-button--text:not(:hover) {
      color: $typography-white;
    }
  }
}
