$ayo-informational-caption-svg-height-lg: 28px;

.ayo-informational-caption {
  @include extra-large-icon($ayo-informational-caption-svg-height-lg);

  display: flex;

  &--light {
    path {
      fill: $typography-white;
    }
  }
}
