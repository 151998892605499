$continue-button-width: 280px;
$continue-button-width-lg: 320px;

.ayo-continue-button {
  &__next {
    width: $continue-button-width;

    @media (min-width: 1920px) {
      max-width: $continue-button-width-lg;
    }
  }
}
