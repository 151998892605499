$status-chip-active-background-color: #eaf9d2;
$status-chip-inactive-background-color: #ffecec;
$status-chip-padding: 2px 16px;
$status-chip-pending-background-color: #f1f1f1;
$status-dot-indicator-margin-right: 10px;
$status-dot-indicator-sizing: 8px;
$status-dot-pending-background-color: #adadad;


@mixin ayo-club-hub-status(
  $chip-color,
  $dot-color,
) {
  .ayo-chip {
    background-color: $chip-color;
  }

  .ayo-dot-indicator {
    .MuiBadge-dot {
      background-color: $dot-color;
    }
  }
}


.ayo-club-hub__status {
  height: max-content;

  .ayo-dot-indicator {
    margin-right: $status-dot-indicator-margin-right;

    .MuiBadge-dot {
      border: none;
      height: $status-dot-indicator-sizing;
      min-width: $status-dot-indicator-sizing;
    }
  }

  .ayo-chip {
    padding: $status-chip-padding;

    @include ayo-typography-body
  }

  &--PENDING, &--PENDING_REQUEST {
    @include ayo-club-hub-status($status-chip-pending-background-color, $status-dot-pending-background-color)
  }

  &--ACTIVE, &--JOINED {
    @include ayo-club-hub-status($status-chip-active-background-color, $data-visualization-green-090-100)
  }

  &--DECLINED, &--INACTIVE, &--REQUEST_REJECTED {
    @include ayo-club-hub-status($status-chip-inactive-background-color, $data-vizualization-red)
  }
}
