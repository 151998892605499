$achievements-info-icon-top: 2px;
$achievements-hot-spot-bottom: 8px;
$achievements-info-icon-margin-left: 8px;
$achievements-title-h2-max-width: 290px;
$achievements-title-subtitle1-max-width: 200px;

.ayo-achievements-section {
  &__title {
    svg {
      margin-left: $achievements-info-icon-margin-left;
      position: relative;
      top: $achievements-info-icon-top;
    }

    &__empty-state {
      span {
        position: relative;
        bottom: $achievements-hot-spot-bottom;
      }
    }

    &--h2 {
      @media (max-width: 600px) {
        max-width: $achievements-title-h2-max-width;
      }
    }

    &--subtitle1 {
      @media (max-width: 400px) {
        max-width: $achievements-title-subtitle1-max-width;
      }
    }
  }
}
