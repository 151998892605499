$searchfield-adornedEnd-pr: 16px;
$searchfield-autocomplete-height: 40px;
$searchfield-button-border-radius: 6px;
$searchfield-action-button-width: 100%;
$searchfield-action-button-ripple-width: 90%;
$searchfield-action-button-ripple-top: 2px;
$searchfield-autocomplete-item-padding: 8px 24px;
$searchfield-rp-for-adornments: 130px;
$searchfield-dark-bg-color: #393144;
$searchfield-dark-focus-bg-color: #4a4058;
$searchfield-suggestions-br: 16px;
$searchfield-suggestion-mx: 6px;
$searchfield-adornment-separator-height: 12px;
$searchfield-adornment-separator-mx: 4px;

.ayo-searchfield {
  &--active {
    .MuiAutocomplete-popupIndicator {
      background-color: $primary-050;
    }
  }

  .MuiAutocomplete-inputRoot {
    padding-top: 0;
    padding-bottom: 0;

    &.MuiInputBase-adornedEnd {
      padding-right: $searchfield-adornedEnd-pr;
    }
  }

  .MuiInputLabel-root.MuiInputLabel-shrink,
  .MuiOutlinedInput-notchedOutline span {
    @include sr-only;
  }

  .MuiAutocomplete-endAdornment {
    height: $searchfield-autocomplete-height;
    top: auto;
    display: flex;
    align-items: center;

    .MuiAutocomplete-popupIndicator,
    .MuiAutocomplete-clearIndicator {
      height: $searchfield-autocomplete-height;
      width: $searchfield-autocomplete-height;
      border-radius: $searchfield-button-border-radius;

      &.MuiAutocomplete-popupIndicatorOpen {
        transform: none;
      }

      .MuiTouchRipple-child {
        position: relative;
        height: $searchfield-action-button-ripple-width;
        width: $searchfield-action-button-ripple-width;
        top: $searchfield-action-button-ripple-top;
        left: $searchfield-action-button-ripple-top;
      }
    }

    .MuiAutocomplete-clearIndicator {
      margin-right: 0;

      svg * {
        fill: $typography-labels;
      }
    }
  }

  &.MuiAutocomplete-hasClearIcon {
    .MuiAutocomplete-clearIndicatorDirty {
      visibility: visible;
    }
  }

  @at-root .ayo-icon-button &__action-icon {
    height: initial;
    width: initial;
  }

  &.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
    padding-right: $searchfield-rp-for-adornments;
  }

  .adornment-separator {
    display: inline-block;
    visibility: hidden;
    height: $searchfield-adornment-separator-height;
    background-color: $operational-border;
    margin: 0 $searchfield-adornment-separator-mx;

    &.isVisible {
      visibility: visible;
    }
  }

  &.dark {
    .adornment-separator {
      background-color: $operational-surface;
    }
  }
}

.MuiAutocomplete-listbox {
  border-radius: $searchfield-suggestions-br;
  background: $typography-white;
  max-height: 50vh;

  .MuiAutocomplete-option {
    padding: $searchfield-autocomplete-item-padding;
  }

  &.dark {
    background: $searchfield-dark-bg-color;

    .MuiTypography-root {
      color: $typography-white;
    }

    .MuiAutocomplete-option {
      margin: 0 $searchfield-suggestion-mx;

      &.Mui-focused {
        background: $searchfield-dark-focus-bg-color;
        border-radius: $searchfield-suggestions-br;
      }
    }
  }
}

.MuiAutocomplete-popper {
  .MuiAutocomplete-noOptions {
    @include body2;

    background-color: $searchfield-dark-bg-color;
    color: $typography-white;
  }

  .MuiAutocomplete-paper {
    border-radius: $searchfield-suggestions-br;
    background-color: transparent;
  }
}
