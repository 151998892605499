$button-border-radius: 16px;
$button-padding: 12px 16px;
$button-padding-large: 16px 24px;
$icon-button-size: 48px;
$icon-button-size-large: 64px;
$icon-button-min-width: 40px;
$adaptive-container-max-width: 1280px;
$adaptive-container-px-md: 24px;
$adaptive-container-px-xs: 16px;
$adaptive-container-nav-offset: 236px;
$adaptive-container-nav-px: 56px;
$adaptive-total-columns-md: 12;
$adaptive-total-columns-xs: 4;

.ayo-button {
  @include button1;

  border-radius: $button-border-radius;
  padding: $button-padding;

  @media (min-width: 1920px) {
    padding: $button-padding-large;
  }

  @at-root a#{&}:focus {
    outline: none;
  }

  &--contained {
    background-color: $primary-500;
    border: 2px solid transparent;

    &.Mui-hover,
    &:hover {
      background-color: $primary-600;
      border-color: $primary-100;
    }

    &.Mui-focusVisible {
      background-color: $secondary-200;
      border-color: $secondary-500;
      color: $primary-800;
    }

    &.Mui-active {
      background-color: $primary-500;
      border-color: transparent;
      color: $typography-white;
    }

    &.Mui-disabled {
      background-color: $operational-disabled;
      color: $typography-disabled;
    }

    &.dark {
      background-color: #393144;
    }
  }

  &--outlined {
    border: 2px solid $primary-500;
    color: $primary-500;

    svg * {
      @include fill-icon-element($primary-500);
    }

    &.Mui-hover,
    &:hover {
      background-color: $primary-050;
      border: 2px solid $primary-500;
    }

    &.Mui-focusVisible {
      background-color: $secondary-050;
      border-color: $secondary-500;
      color: $primary-800;

      svg * {
        @include fill-icon-element($primary-800);
      }
    }

    &.Mui-active {
      background-color: transparent;
      border-color: $primary-500;
    }

    &.Mui-disabled {
      border-color: $operational-disabled;
      color: $typography-disabled;

      svg * {
        @include fill-icon-element($typography-disabled);
      }
    }
  }

  &--danger {
    background-color: $operational-error;
    border: 2px solid transparent;

    &.Mui-hover,
    &:hover {
      background-color: $operational-error-hover;
    }

    &.Mui-focusVisible {
      background-color: $operational-error-focus;
      border-color: $operational-error-focus-border;
    }

    &.Mui-active {
      background-color: $operational-error;
      border-color: transparent;
    }

    &.Mui-disabled {
      background-color: $operational-disabled;
      color: $typography-disabled;
    }
  }

  &--text-primary {
    background-color: $primary-050;
  }

  @at-root .ayo-icon-button,
    .MuiPickersArrowSwitcher-button,
    .ayo-rich-text-editor.quill .ql-formats button,
    &--text,
    &--text-primary {
    border: 2px solid transparent;
    color: $primary-500;

    svg * {
      @include fill-icon-element($primary-500);
    }

    &.Mui-hover,
    &:hover {
      background-color: $primary-050;
      border-color: $primary-100;
    }

    &.Mui-focusVisible,
    &:focus {
      background-color: $secondary-050;
      border-color: $secondary-500;
      color: $primary-800;

      svg * {
        @include fill-icon-element($primary-800);
      }
    }

    &.Mui-active {
      background-color: $primary-050;
      border-color: $primary-300;
      color: $primary-800;

      svg * {
        @include fill-icon-element($primary-800);
      }
    }

    &.Mui-disabled {
      color: $typography-disabled;

      svg * {
        @include fill-icon-element($typography-disabled);
      }
    }
  }

  &--icon {
    box-sizing: content-box;
    padding: 0;
    height: $icon-button-size;
    width: $icon-button-size;
    min-width: $icon-button-min-width;

    &.Mui-disabled {
      svg * {
        @include fill-icon-element($typography-disabled);
      }
    }

    .MuiButton-label {
      display: flex;
      flex: 1;
    }

    @media (min-width: 1920px) {
      height: $icon-button-size-large;
      width: $icon-button-size-large;
    }
  }

  &--adaptive {
    min-width: calc((100vw - 2 * #{$adaptive-container-px-xs}) / #{$adaptive-total-columns-xs} * 3);

    @media (min-width: 960px) {
      min-width: calc((#{$adaptive-container-max-width} - 2 * #{$adaptive-container-px-md}) / #{$adaptive-total-columns-md} * 3);
    }
  }

  &--hidden {
    visibility: hidden;
  }

  @at-root .ayo-nav-drawer + .ayo-page #{&}--adaptive {
    @media (min-width: 1280px) {
      min-width: calc((100vw - #{$adaptive-container-nav-offset} - 2 * #{$adaptive-container-nav-px}) / #{$adaptive-total-columns-md} * 3);
    }
  }
}
