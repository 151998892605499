$ayo-post-visibility-settings-item-p: 8px 12px;
$ayo-post-visibility-settings-item-br: 16px;
$ayo-post-visibility-settings-full-height: 55vh;

.ayo-post-visibility-settings {
  &__content {
    max-height: $ayo-post-visibility-settings-full-height;
  }

  &--full-height {
    .ayo-post-visibility-settings__content {
      height: $ayo-post-visibility-settings-full-height;
      display: flex;
      flex-direction: column;

      &__selection-block {
        display: flex;
        flex-direction: column;
        flex: 1;
      }
    }
  }

  .ayo-tabs.MuiTabs-root.content-section-tabs {
    background-color: $operational-surface;
    border: none;
  }

  .share-with-item {
    padding: $ayo-post-visibility-settings-item-p;
    background-color: $operational-surface;
    border-radius: $ayo-post-visibility-settings-item-br;
    width: 100%;
  }

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
  }
}
