@mixin flex-center-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@mixin fill-icon-element($color) {
  &[fill] {
    fill: $color;
  }

  &[stroke] {
    stroke: $color;
  }
}

@mixin insert-quote-image($url, $height, $width, $left: auto, $top: auto, $bottom: auto) {
  &::before {
    background-image: url($url);
    width: $width;
    height: $height;
    left: $left;
    top: $top;
    bottom: $bottom;
  }
}

$close-button-dark-svg-stroke-width: 2px;
$close-button-dark-svg-focused-size: 24px;

@mixin close-button-dark {
  height: auto;
  width: auto;
  min-width: auto;
  padding: 0;

  svg {
    path {
      fill: $typography-labels;
    }

    circle {
      fill: transparent;
    }
  }

  &:hover {
    background-color: transparent;
    border-color: transparent;
  }

  &.Mui-focusVisible {
    background-color: $secondary-050;
    height: $close-button-dark-svg-focused-size;
    width: $close-button-dark-svg-focused-size;
    margin: $close-button-dark-svg-stroke-width;

    .MuiButton-label {
      width: initial;
    }

    svg {
      path:first-child {
        fill: transparent;
      }

      /*
       * This is a hack. On focus we need to use other svg image, so changing dynamically fill path for this case
       */
      path:last-child {
        d: path(
          'M16.5757 15.1657C16.8101 15.4001 16.8101 15.78 16.5757 16.0143L16.0143 16.5757C15.7799 16.8101 15.4001 16.8101 15.1657 16.5757L12.4243 13.8343C12.1899 13.5999 11.8101 13.5999 11.5757 13.8343L8.83426 16.5757C8.59995 16.8101 8.22005 16.8101 7.98574 16.5757L7.42426 16.0143C7.18995 15.7799 7.18995 15.4001 7.42426 15.1657L10.1657 12.4243C10.4001 12.1899 10.4001 11.8101 10.1657 11.5757L7.42426 8.83426C7.18995 8.59995 7.18995 8.22005 7.42426 7.98574L7.98574 7.42426C8.22005 7.18995 8.59995 7.18995 8.83426 7.42426L11.5757 10.1657C11.8101 10.4001 12.1899 10.4001 12.4243 10.1657L15.1657 7.42426C15.4001 7.18995 15.7799 7.18995 16.0143 7.42426L16.5757 7.98574C16.8101 8.22005 16.8101 8.59995 16.5757 8.83426L13.8343 11.5757C13.5999 11.8101 13.5999 12.1899 13.8343 12.4243L16.5757 15.1657Z'
        );
        fill: $primary-800;
      }
    }
  }
}

@mixin sr-only {
  position: absolute;
  height: 1px;
  width: 1px;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  border: 0;
  outline: 0;
}

@mixin dialog-full-width-mobile {
  @media (max-width: 600px) {
    &.MuiDialog-paperFullWidth.MuiDialog-paper {
      width: 100%;
      justify-content: center;
    }

    &.MuiDialog-paperScrollPaper {
      height: inherit;
    }
  }
}

@mixin icon-text-align($margin) {
  svg {
    vertical-align: middle;
    margin: $margin;
  }
}

@mixin wrapped-text($line-clamp: 1) {
  position: relative;
  word-wrap: break-word;
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line-clamp;
  -webkit-box-orient: vertical;
}

@mixin extra-large-icon($height: 32px) {
  @media (min-width: 1920px) {
    svg {
      width: auto;
      height: $height;
    }
  }
}

@mixin removable-chip-black() {
  background-color: $operational-black;
  padding: 4px 12px;
  height: 100%;
  width: 100%;
  justify-content: space-between;

  svg {
    * {
      @include fill-icon-element($typography-white);
    }
  }

  .MuiChip-label p {
    color: $typography-white;
  }

  &:hover,
  &:focus-within {
    background-color: $secondary-200;
    border-color: $secondary-500;

    svg {
      * {
        @include fill-icon-element($primary-800);
      }
    }

    .MuiChip-label p {
      color: $primary-800;
    }
  }
}
