$ayo-portfolio__endorsements-section-margin-top: 24px;
$ayo-portfolio__endorsements-section-max-width: 308px;
$ayo-portfolio__endorsements-section-max-width-lg: 400px;
$ayo-portfolio__endorsements-section-min-height: 102px;
$ayo-portfolio__endorsements-section-title-margin-left: 16px;
$ayo-try-mobile-informational-message-margin-bottom: 16px;

.ayo-portfolio__endorsements-section {
  background-color: $operational-surface;
  background-image: url('../../../../../resources/images/portfolio/puzzles-bg.svg');
  background-position-x: right;
  background-repeat: no-repeat;
  background-size: contain;

  .ayo-delete-item-wrapper {
    width: max-content;
  }

  .ayo-try-mobile-informational-message {
    margin-bottom: $ayo-try-mobile-informational-message-margin-bottom;
  }

  &__card {
    margin-top: $ayo-portfolio__endorsements-section-margin-top;
    max-width: $ayo-portfolio__endorsements-section-max-width;
    min-height: $ayo-portfolio__endorsements-section-min-height;

    @media (min-width: 1920px) {
      max-width: $ayo-portfolio__endorsements-section-max-width-lg;
    }

    &__content {
      display: flex;

      &__title {
        color: $primary-500;
        margin-left: $ayo-portfolio__endorsements-section-title-margin-left;
      }
    }
  }
}
