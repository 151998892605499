$fab-size: 32px;
$fab-color: #3e3e3e;
$fab-bg-color: #DDD;
$fab-svg-color: #747474;
$fab-border-hover: 2px solid $primary-100;
$fab-border-focus: 2px solid $secondary-500;

.ayo-scroll-fab {
  position: absolute;
  background-color: $fab-bg-color;

  svg * {
    @include fill-icon-element($fab-svg-color);
  }

  &:hover {
    box-shadow: none;
    background-color: $fab-color;
    border: $fab-border-hover;

    svg * {
      @include fill-icon-element($operational-surface);
    }
  }
  
  &:focus {
    box-shadow: none;
    background-color: $secondary-050;
    border: $fab-border-focus;

    svg * {
      @include fill-icon-element($primary-800);
    }
  }

  &__container {
    position: relative;
    display: none;
    height: $fab-size;
    width: $fab-size;

    .MuiFab-root {
      transition: none;
    }

    &.button-visible {
      display: initial;
    }
  }
}