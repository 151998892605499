$focus-circle-color: $secondary-500;
$focus-checkmark-color: $primary-800;
$hover-circle-color: $primary-100;
$hover-checkmark-color: $primary-500;

.approve-button {
  &.ayo-editing-button svg {
    margin-left: 0;
    margin-right: 0;
  }

  &:not(.approve-button--approved) {
    svg * {
      @include fill-icon-element($typography-titles);
    }
  }

  &:focus {
    svg {
      circle {
        @include fill-icon-element($focus-circle-color);

        fill: $typography-white;
      }

      path {
        @include fill-icon-element($focus-checkmark-color);
      }
    }
  }

  &:hover {
    svg {
      circle {
        @include fill-icon-element($hover-circle-color);

        fill: $typography-white;
      }

      path {
        @include fill-icon-element($hover-checkmark-color);
      }
    }
  }

  &.approve-button--approved {
    svg {
      circle {
        fill: $data-visualization-green-090-100;
        stroke: $data-visualization-green-090-100;
      }

      path {
        @include fill-icon-element($typography-white);
      }
    }

    &:focus {
      svg {
        circle {
          fill: $secondary-050;
        }

        path {
          @include fill-icon-element($focus-checkmark-color);
        }
      }
    }

    &:hover {
      svg {
        circle {
          fill: $primary-050;
        }

        path {
          @include fill-icon-element($hover-checkmark-color);
        }
      }
    }
  }
}