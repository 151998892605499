$schedule-page-class-header-padding: 16px;
$schedule-page-class-content-padding-y: 24px;
$schedule-page-class-content-padding-x: 16px 24px;
$schedule-page-class-content-min-height: calc(
  120px - 2 * #{$schedule-page-class-content-padding-y}
);
$class-content-card-border: 1px solid #dedede;
$add-content-button-padding-lg: 12px 16px;

.ayo-classes-calendar-day-card {
  .ayo-card__main-content {
    padding: 0;

    .class-header {
      padding: $schedule-page-class-header-padding;
    }

    .class-content {
      padding: $schedule-page-class-content-padding-y $schedule-page-class-content-padding-x;
      min-height: $schedule-page-class-content-min-height;

      .ayo-card {
        border: $class-content-card-border;
      }

      &__add-button {
        background-color: $primary-050;

        @media (min-width: 1920px) {
          padding: $add-content-button-padding-lg;
        }
      }
    }
  }
}

.ayo-checkbox.ayo-class-sections-selector {
  &__checkbox {
    &--error {
      input[type='checkbox'] + svg {
        &,
        &:hover {
          * {
            @include fill-icon-element($operational-error);
          }
        }
      }
    }
  }
}
