$tabs-min-width: 190px;
$tabs-min-width-sm: 164px;
$tabs-border-radius: 16px;

.ayo-club-hub__tabs {
  .ayo-tabs--outlined.ayo-tabs.MuiTabs-root {
    border: none;
    border-radius: $tabs-border-radius;
    max-width: max-content;
    background-color: $operational-surface;

    .MuiTab-root {
      min-width: $tabs-min-width;

      @media (max-width: 600px) {
        min-width: $tabs-min-width-sm;
        max-width: 50%;
      }
    }
  }
}
