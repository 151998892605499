$skeleton-background-color: #e7e8f1;
$skeleton-border-radius: 16px;
$skeleton-min-height: 360px;
$shimmer-width: 320px;

.ayo-skeleton-mask {
  background-color: $skeleton-background-color;
  overflow: hidden;
  position: relative;

  &:not(.MuiSkeleton-circular) {
    border-radius: $skeleton-border-radius;
    min-height: $skeleton-min-height;
    width: 100%;
  }

  &::after {
    @keyframes ayo-shimmer-motion {
      0% {
        left: -$shimmer-width;
      }

      100% {
        left: 100%;
      }
    }

    animation: ayo-shimmer-motion 1s linear infinite;
    background: linear-gradient(90deg, transparent, $operational-surface, transparent);
    content: '';
    position: absolute;
    left: -$shimmer-width;
    height: 100%;
    width: $shimmer-width;
  }
}
