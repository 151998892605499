// stylelint-disable selector-not-notation
// TODO: investigate why :not(a, b) does not work when :not(a):not(b) works
$family-activities-suggested-block-border-radius: 16px;
$family-activities-suggested-block-padding: 24px;
$family-activities-suggested-block-show-btn-padding: 16px;

.ayo-children-card {
  border: 2px solid transparent;

  &.selected {
    background-color: $primary-050;
  }

  &:not(:hover):not(:focus).selected {
    border-color: $primary-300;
  }

  .MuiCardContent-root > div {
    display: flex;
    align-items: center;
  }
}

.ayo-kids-page__main {
  flex: 1;

  @media (min-width: 960px) {
    &__feed {
      flex-direction: row-reverse;
    }
  }

  .family-activities-suggested-block {
    background-color: $operational-surface;
    box-sizing: border-box;
    border-radius: $family-activities-suggested-block-border-radius;
    padding: $family-activities-suggested-block-padding;

    &__show-button {
      .MuiCardContent-root {
        padding: $family-activities-suggested-block-show-btn-padding;
      }
    }
  }

  .ayo-initiative-empty-state__body {
    max-width: 100%;
  }

  .go-to-kid-profile {
    background-color: $primary-050;
  }
}
