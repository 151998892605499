$ayo-accordion-item-border: 1px solid #C1C0C9;
$ayo-accordion-item-border-radius: 16px;
$ayo-accordion-item-margin-bottom: 8px;

.ayo-action-step-reflection-view-dialog {
  @include dialog-full-width-mobile;
  @include extra-large-icon;

  position: relative;

  .ayo-accordion.MuiAccordion-rounded {
    border: $ayo-accordion-item-border;
    box-sizing: border-box;
    border-radius: $ayo-accordion-item-border-radius;
    margin-bottom: $ayo-accordion-item-margin-bottom;
  }

  .MuiAccordionSummary-content {
    max-width: 100%;
  }
}
