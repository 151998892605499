$portfolio-main-page-card-padding: 16px;
$portfolio-main-page-card-title-margin-bottom: 8px;
$portfolio-main-page-card-title-print-margin-bottom: 40px;
$portfolio-main-page-card-title-print-margin-top: 40px;
$portfolio-main-page-card-title-margin-left: 77px;
$portfolio-main-page-card-buttons-min-width: 145px;
$portfolio-main-page-card-publish-padding: 60px 176px;
$portfolio-main-page-card-publish-padding-print: 60px 75px;

.ayo-portfolio-details-page {
  &__title-textfield {
    &.ayo-textfield {
      .MuiOutlinedInput-root {
        background-color: transparent;
      }

      .MuiOutlinedInput-root .MuiInputBase-input {
        background-color: transparent;

        @include h1
      }
    }
  }

  &__buttons-block {
    min-width: $portfolio-main-page-card-buttons-min-width;
  }

  &--publish-mode {
    background-color: $operational-surface;

    .ayo-portfolio-section {
      border-radius: 0;
      margin-bottom: 0;
      padding: $portfolio-main-page-card-publish-padding;
    }
  }
}

.ayo-portfolio-print-view {
  background-color: $operational-surface;

  .ayo-portfolio-section {
    padding: $portfolio-main-page-card-publish-padding-print;
  }

  *>.ayo-button,
  button,
  .ayo-ml-input-chip,
  .ayo-status-badge,
  .ayo-portfolio-attachments {
    display: none;
  }

  .ayo-portfolio-details-page__title {
    margin-top: $portfolio-main-page-card-title-print-margin-top;
    margin-bottom: $portfolio-main-page-card-title-print-margin-bottom;
  }

  .ayo-portfolio-section__reflection--cropped {
    -webkit-line-clamp: initial
  }

}
