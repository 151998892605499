$description-border-radius: 16px;
$dialog-container-w-lg: 864px;
$dialog-container-w-md: 604px;
$empty-state-padding: 40px;
$page-margin-bottom: 40px;
$moderator-actions-block-border-radius: 24px;
$moderator-actions-block-box-shadow: 0 2px 6px 2px RGB(0 0 0 / 6%);
$moderator-button-min-width: 169px;
$moderator-button-min-width-xl: 260px;
$link-chip-padding: 4px 8px;
$link-chip-border-radius: 12px;
$link-icon-height-lg: 64px;
$tabs-padding: 8px;
$tabs-width-xs: 108px;
$tabs-indicator-position: -4px;
$tabs-gap: 8px;
$tabs-gap-xs: 4px;
$tab-padding-xs: 6px;

@import './sections/clubs-members-section/clubs-members-section';
@import './pages/club-evens/club-evens';

.ayo-club-hub-details {
  flex: 1;
  margin-bottom: $page-margin-bottom;

  &__description,
  &__links,
  &__requirements {
    border-radius: $description-border-radius;
    background-color: $operational-surface;
  }

  &__description,
  &__requirements__description {
    white-space: pre-line;
  }

  &__links {
    .ayo-link {
      display: flex;
      justify-content: center;
    }

    &__empty-state {
      border-radius: $description-border-radius;
      background-color: $operational-disabled-bg;
    }

    &__icon {
      @media (min-width: 1600px) {
        height: $link-icon-height-lg;
        width: auto;
      }
    }

    &__chip {
      padding: $link-chip-padding;
      border-radius: $link-chip-border-radius;
      border: none;
      background-color: $operational-background;

      @include ayo-typography-body;
    }
  }

  .ayo-initiative-empty-state {
    padding: $empty-state-padding;

    &__body {
      margin: 0;
    }
  }

  &__visibility {
    .ayo-chip {
      background-color: $operational-black;
      padding: $ayo-chips-block-chip-padding;
      color: $operational-surface;
    }
  }

  &__edit-dialog {
    @include dialog-full-width-mobile;

    &__name,
    &__description {
      overflow: visible;
    }

    &__description,
    &__requirements,
    &__links,
    &__tags {
      @media (min-width: 960px) {
        .ayo-dialog__container {
          box-sizing: border-box;
          max-width: $dialog-container-w-md;
          min-width: $dialog-container-w-md;
        }
      }

      @media (min-width: 1920px) {
        .ayo-dialog__container {
          box-sizing: border-box;
          max-width: $dialog-container-w-lg;
          min-width: $dialog-container-w-lg;
        }
      }
    }

    &:not(&__tags) {
      .ayo-club-hub-details__edit-dialog__content {
        margin-top: 24px;
        margin-bottom: 24px;
      }
    }
  }

  .ayo-club-hub__tabs__educator {
    .ayo-tabs--outlined.ayo-tabs.MuiTabs-root {
      padding: 0;

      .MuiTabs-scroller.MuiTabs-fixed {
        padding: $tabs-padding;
      }

      .MuiTabs-flexContainer {
        gap: $tabs-gap;

        @media (max-width: 600px) {
          gap: $tabs-gap-xs;
        }
      }

      .MuiTab-root {
        position: relative;
        overflow: visible;
        padding: $tab-padding-xs;

        @media (max-width: 600px) {
          min-width: $tabs-width-xs;
          max-width: $tabs-width-xs;
        }
      }
    }

    .ayo-text-badge {
      position: absolute;
      right: $tabs-indicator-position;
      top: $tabs-indicator-position;
    }
  }

  &__moderator-actions {
    background-color: $operational-surface;
    box-shadow: $moderator-actions-block-box-shadow;
    border-radius: $moderator-actions-block-border-radius;

    .ayo-button {
      min-width: $moderator-button-min-width;

      @media (max-width: 600px) {
        min-width: 100%;
      }

      @media (min-width: 1920px) {
        min-width: $moderator-button-min-width-xl;
      }
    }

    &__decline {
      color: $operational-error;

      &:hover {
        border-color: $operational-error;
        background-color: $operational-error-hover-background;
      }

      &:focus,
      &:active {
        color: $operational-error;
      }
    }
  }

  &__settings-dialog {
    .ayo-dialog__container {
      @media (max-width: 600px) {
        padding-top: 0;
      }
    }
  }

  .ayo-comments-block {
    padding-bottom: 0;
  }
}
