$ayo-sel-follow-up-questions-border-radius: 16px;
$ayo-sel-follow-up-questions-padding: 16px;
$ayo-sel-follow-up-questions-margin-bottom: 16px;
$ayo-sel-follow-up-questions-image-dimensions: 80px;

.ayo-sel-follow-up-questions {
  &__question-item {
    border-radius: $ayo-sel-follow-up-questions-border-radius;
    background-color: $operational-surface;
    padding: $ayo-sel-follow-up-questions-padding;
    margin-bottom: $ayo-sel-follow-up-questions-margin-bottom;
    display: flex;
  }

  &__image {
    width: $ayo-sel-follow-up-questions-image-dimensions;
    height: $ayo-sel-follow-up-questions-image-dimensions;
  }
}
