$lesson-page-snackbar-top: 16px;
$lesson-page-snackbar-bg-color: #f2f2f2;
$lesson-page-snackbar-border: 1px solid #e6e6e6;
$lesson-page-header-shadow: 0 2px 6px 2px RGB(0 0 0 / 6%);
$lesson-page-error-icon-mr: 10px;
$lesson-page-notification-max-width: 600px;
$lesson-page-notification-description-padding: 20px 24px;
$lesson-page-notification-description-shadow: 0 12px 16px 6px RGB(0 0 0 / 6%);
$lesson-page-section-add-block-min-width: 192px;
$lesson-page-section-accordion-padding: 16px;
$lesson-page-section-accordion-margin-bottom: 64px;
$lesson-page-section-accordion-margin-last: 84px;
$lesson-page-section-divider-border-bottom: 1px solid #d2d1d1;
$lesson-page-section-divider-m-left: 16px;
$lesson-page-section-content-border-radius: 16px;
$lesson-page-section-content-p: 24px;
$lesson-page-section-content-mb: 24px;
$lesson-page-section-content-title-pb: 24px;
$lesson-page-footer-btn-min-width: 145px;
$lesson-page-assignment-br: 16px;
$lesson-page-assignment-padding: 24px;
$lesson-page-section-content-title: 16px;

@mixin choices-path-bubble-box-shadow($color) {
  box-shadow: 1px 4px 10px 2px $color;
}

@import '../assignment-components/assignments-action-buttons/assignment-action-buttons';
@import '../assignment-components/assignment-type-block/assignment-type-block';
@import '../assignment-components/assignment-insights/assignment-insights';
@import '../assignment-components/assignment-insights/components/insight-category-card/insight-category-card';
@import '../assignment-components/assignment-insights/components/insight-assignment-group/insight-assignment-group';
@import '../assignment-components/display-components/display-assignment-materials/display-assignment-materials';
@import '../assignment-components/display-components/display-assignment-students/display-assignment-students';
@import '../assignment-components/display-components/display-assignment-choices-path/display-assignment-choices-path';
@import '../assignment-components/edit-components/assignment-materials/assignment-materials';
@import '../assignment-components/edit-components/assignment-options/assignment-options';
@import '../assignment-components/edit-components/assignment-settings/assignment-settings';
@import '../assignment-components/edit-components/assignment-students/assignment-students';
@import '../assignment-components/edit-components/assignment-choices-path/assignment-choices-path';
@import '../assignment-components/choices-path-option-bubble/choices-path-option-bubble';
@import './components/lesson-page-teks-content/teks-block/teks-block';
@import './components/lesson-page-teks-content/teks-dialog/teks-dialog';
@import './components/lesson-page-teks-content/lesson-page-teks-content';
@import './components/lesson-page-agenda-board/lesson-page-agenda-board';

.ayo-lesson-page {
  .ayo-lesson-page-snackbar-container {
    position: absolute;
    top: $lesson-page-snackbar-top;
    left: 50%;
    height: 100%;

    .ayo-snackbar {
      position: sticky;
      transform: initial;

      .MuiPaper-root {
        background-color: $lesson-page-snackbar-bg-color;
        border: $lesson-page-snackbar-border;

        .ayo-snackbar--text {
          color: $typography-body;
        }
      }
    }
  }

  &__header {
    &:not(&--static) {
      background-color: $operational-surface;
      box-shadow: $lesson-page-header-shadow;
    }

    &__label {
      color: $typography-labels-dark;
    }
  }

  &__main {
    flex: 1;
  }

  .ayo-informational-message {
    max-width: $lesson-page-notification-max-width;

    &__description {
      padding: $lesson-page-notification-description-padding;
      box-shadow: $lesson-page-notification-description-shadow;
    }
  }

  &__error-box {
    border: 2px solid $operational-error;
    border-radius: $lesson-page-section-content-border-radius;

    .ayo-typography--subtitle1,
    .ayo-link {
      color: $operational-error;
    }

    svg {
      margin-right: $lesson-page-error-icon-mr;

      * {
        @include fill-icon-element($operational-error);
      }
    }
  }

  .ayo-lesson-page__section {
    background-color: transparent;

    .MuiAccordionDetails-root {
      padding-left: $lesson-page-section-accordion-padding;
      padding-right: $lesson-page-section-accordion-padding;
    }

    &__title {
      text-transform: uppercase;
      color: $typography-labels-dark;
    }

    &__divider {
      border-bottom: $lesson-page-section-divider-border-bottom;
      flex: 1;
      margin-left: $lesson-page-section-divider-m-left;
    }

    &__content {
      background-color: $operational-surface;
      border-radius: $lesson-page-section-content-border-radius;
      padding: $lesson-page-section-content-p;
      margin-bottom: $lesson-page-section-content-mb;

      &--static {
        background-color: transparent;
        padding: 0;
      }
    }

    .ayo-expandable-menu-title {
      min-width: $lesson-page-section-add-block-min-width;
    }

    &--open {
      margin-bottom: $lesson-page-section-accordion-margin-bottom;
    }

    &:last-of-type {
      margin-bottom: $lesson-page-section-accordion-margin-last;
    }
  }

  &__subcontainer {
    background-color: $operational-background-grey;
    border-radius: $lesson-page-section-content-border-radius;

    &__added-title {
      margin-bottom: $lesson-page-section-content-title;
    }
  }

  &__footer {
    background-color: $operational-surface;

    .ayo-button {
      min-width: $lesson-page-footer-btn-min-width;
    }

    &__delete-button {
      color: $operational-error;

      svg * {
        @include fill-icon-element($operational-error);
      }

      &:hover {
        background-color: $operational-error-hover-background;
        border-color: transparent;
        color: $operational-error-hover;
      }

      &:focus {
        background-color: $operational-error-focus-background;
        border-color: transparent;
        color: $operational-error-focus;

        svg * {
          @include fill-icon-element($operational-error-focus);
        }
      }

      &.Mui-active {
        background-color: $operational-error-focus-background;
        border-color: transparent;
        color: $operational-error-hover;

        svg * {
          @include fill-icon-element($operational-error-hover);
        }
      }
    }
  }

  .assignment {
    background-color: $operational-surface;
    border-radius: $lesson-page-assignment-br;
    padding: $lesson-page-assignment-padding;
  }

  .ayo-tabs.MuiTabs-root.content-section-tabs {
    background-color: $operational-surface;
    border: none;
    width: fit-content;
  }
}
