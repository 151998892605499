$paragraph-margin-bottom: 8px;
$input-adornment-margin-right: -8px;

.ayo-personal-information-section {
  background-color: $secondary-050;
  background-image: url('../../../../../resources/images/portfolio/personal-info-edit-mode-bg.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;

  &--view-mode {
    background-image: url('../../../../../resources/images/portfolio/personal-info-view-mode-bg.svg');

    .ayo-portfolio-section__title {
      @include h2;

      margin-bottom: $paragraph-margin-bottom;
    }
  }

  .MuiInputAdornment-positionStart {
    margin-right: $input-adornment-margin-right;
    z-index: 1;

    p {
      @include body2;
    }
  }
}
